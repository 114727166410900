import $ from 'jquery';

export default {
  selector: '.chosen-select',

  init() {
    $('.chosen-select').chosen({
      allow_single_deselect: true,
      no_results_text: 'No results matched',
      width: '200px',
      disable_search_threshold: 9
    });
  },

  destroy() {
    $('.chosen-select').chosen('destroy');
  }
};
