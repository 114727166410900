<template>
  <div
    @click="toggle"
    :class="[
      'minim-mobile-toggle',
      inactiveColor,
      active ? 'active' : null,
      disableCursor ? 'disable-cursor' : null,
      size === 'small' ? 'small' : 'large',
      enabled ? null : 'fade',
    ]"
  >
    <label v-if="showLabels" class="toggle-label off">{{ labels[0] }}</label>
    <div class="toggle-background">
      <div class="toggle-handle" style="touch-action: manipulation; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
        <minim-mobile-icon
          v-if="iconName"
          :iconName="iconName"
          :color="iconColor"
          :size="iconSize"
        />
      </div>
    </div>
    <label v-if="showLabels" class="toggle-label on">{{ labels[1] }}</label>
  </div>
</template>

<script>
import I18n from 'shared/lib/i18n';

export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },

    enabled: {
      type: Boolean,
      default: true
    },

    size: {
      type: String,
      required: false,
      default: 'small',
      validator(value) {
        if (value) {
          return ['small', 'large'].includes(value);
        }
        return true;
      }
    },

    showLabels: {
      type: Boolean,
      default: true
    },

    labels: {
      type: Array,
      default: () => [I18n.t('off'), I18n.t('on')]
    },

    inactiveColor: {
      type: String,
      default: null
    },

    iconName: {
      type: String,
      default: null
    },

    iconColor: {
      type: String,
      default: 'neutral-darkest'
    },

    iconSize: {
      type: String,
      default: 'x-small'
    },

    disableCursor: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    toggle() {
      if (this.enabled){
        this.$emit('click');
      }
    }
  }

};
</script>

<style scoped>
 .minim-mobile-toggle.fade {
   opacity: 30%;
 }
</style>
