const lansKey = 'minimLansData';

async function readAllLansData() {
  let allLansData = (await window.native.exec('get_config_item', lansKey)) || null;
  allLansData = JSON.parse(allLansData);
  allLansData = allLansData || {};

  return allLansData;
}

export default {
  namespaced: true,

  state: {
    // This store is used to maintain data about the current LAN that needs to be persisted in localStorage
    // but also needs to be easily accessible via vuex. The below object is meant to contain all the data
    // stored for the current LAN.
    lanData: {}
  },

  mutations: {
    setLanData(state, lanData) {
      state.lanData = lanData;
    }
  },

  actions: {
    async fetchLanData(context) {
      const lanId = context.rootState.LanStore.lan.id;
      const allLansData = await readAllLansData();
      const lanData = allLansData[lanId] || {};

      context.commit('setLanData', lanData);

      return lanData;
    },

    async setLanData(context, data) {
      const lanId = context.rootState.LanStore.lan.id;

      let allLansData = await readAllLansData();
      allLansData[lanId] = { ...allLansData[lanId], ...data }; // add the new data to the object, while perserving any old data

      await window.native.exec('set_config_item', { key: lansKey, value: JSON.stringify(allLansData) });
      context.commit('setLanData', data);

      return data;
    }
  },

  getters: {
    lastPowerCycled(state) {
      return (state.lanData || {}).lastPowerCycled || null;
    }
  }
};
