import Vue from 'vue/dist/vue.esm';
import minim_api from 'mobile/shared/utils/minim_api';

export default {
  namespaced: true,

  state: {
    user: {}, // Represents the current user using the app
    users: [] // Represents all the users that belong to the current LAN in the app
  },

  mutations: {
    one(state, data) {
      state.user = data;
      return state;
    },

    many(state, data) {
      state.users = data;
      return state;
    },

    set(state, data) {
      let i = state.users.findIndex((u) => u.uuid == data.uuid);
      i = i !== -1 ? i : state.users.length;

      Vue.set(state.users, i, data);

      return state;
    },

    setUser(state, data) {
      for (const [key, value] of Object.entries(data)) {
        Vue.set(state.user, key, value);
      }
    },

    delete(state, user_id) {
      const idx = state.users.findIndex((user) => user.uuid === user_id);
      state.users.splice(idx, 1);
      return state;
    },

    deleteAll(state) {
      state.users = [];
      return state;
    }
  },

  actions: {
    index(context) {
      return minim_api.get('mobile/users?lan_id={lan_id}').then((response) => {
        context.commit('many', response.data);
      });
    },

    show(context, id) {
      return minim_api.get(`mobile/users/${id}.json`).then((response) => {
        context.commit('one', response.data);
        return response.data;
      });
    },

    showMany(context, id) {
      return minim_api.get(`mobile/users/${id}`).then((response) => {
        context.commit('set', response.data);
        return response.data;
      });
    },

    async update({ dispatch }, user) {
      await minim_api.patch(`api/v1/users/${user.id}`, { user });
      await dispatch('show', user.id);
    },

    async register(context, params) {
      await minim_api.post('api/v1/account/register', { ...params, mobile_only: true });

      return context.dispatch('login', params);
    },

    async login(context, params) {
      await minim_api.createAPIKey(params);
      await minim_api.createAccessToken();

      return context.dispatch('show', minim_api.apiKey.user_id);
    },

    async logout(context) {
      // Log the user out of amplitude.
      // Need to regenerate the device ID to stop associating actions on the device with the user, as per the amplitude docs.
      window.amplitude.getInstance().setUserId(null);
      window.amplitude.getInstance().regenerateDeviceId();
      context.commit('one', {});

      // Clear the active session if there is one
      await minim_api.delete('api/v1/sessions');

      return await minim_api.deleteAPIKey();
    },

    async requestPasswordReset(context, data) {
      let response = await minim_api
        .post('api/v1/account/request_password_reset', data);

      return response;
    },

    updateNotificationPreference(context, preference) {
      let data = {};
      data[preference.slug] = preference.enabled;

      return minim_api
        .put('mobile/settings', { preferences: { notifications: data } })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    toggleBulkSecurityTutorial({ commit, getters }) {
      if (!getters.canBulkManageSecurity) {
        commit('setUser', {
          uuid: getters.currentUserId,
          can_bulk_manage_security: true,
          show_tutorial: true,
        });
        return minim_api.patch(`mobile/users/${getters.currentUserId}`, {
          can_bulk_manage_security: true,
        });
      }
    },

    invite(context, email) {
      let data = {
        email,
        mobile_only: true
      };

      return minim_api
        .post('api/v1/lans/{lan_id}/lan_users', data)
        .then(async (response) => {
          console.log(response);
          return await context.dispatch('showMany', response.data.id);
        });
    },

    reinvite(context, user_id) {
      return minim_api.post(`lans/{lan_id}/users/${user_id}/reinvite`);
    },

    delete(context, user_id) {
      return minim_api
        .delete(`api/v1/lans/{lan_id}/lan_users/${user_id}`)
        .then(() => {
          context.commit('delete', user_id);
        });
    },

    deleteAll(context) {
      return minim_api.delete('api/v1/lans/{lan_id}/lan_users').then(() => {
        context.commit('deleteAll');
      });
    }
  },

  getters: {
    pendingInvitationUsers(state) {
      return state.users.filter((user) => user.pending);
    },

    acceptedUsers(state) {
      return state.users.filter((user) => !user.pending);
    },

    canBulkManageSecurity(state) {
      return state.user.can_bulk_manage_security;
    },

    currentUserId(state) {
      return state.user.id;
    },

    currentUser(state) {
      return state.user;
    },

    userHasRole: (state) => (role) => {
      if (!(state.user || {}).roles) {
        return false;
      }

      return !!state.user.roles.find(r => r.key === role);
    },

    userIsMobileAdmin(_state, getters) {
      return !!['employee', 'mobile_admin'].find(r => getters.userHasRole(r));
    }
  },
};
