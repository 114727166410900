<template>
  <div class="lan-event" :data-test-id="'lan-event-' + this.eventData.id">
    <lan-activity-timestamp :timestamp="this.eventData.created_at" />
    <div class='event-body'>
      <div class='event-type'>{{ $I18n.t('timeline.lan_event') }}</div>
      <h2>{{ eventData.title }}</h2>
      <div class='meta'>
        <div class='meta-item' v-if="!!eventData.device_name">
          <i class='fas fa-desktop'></i>
          {{eventData.device_name}}
        </div>
        <div class='meta-item' v-if="!!eventData.message">
          <i class='fas fa-comment-o'></i>
          "{{ eventData.message }}"
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanActivityTimestamp from './lan_activity_timestamp.vue';

export default {
  props: ['lanId', 'eventData'],

  components: {
    'lan-activity-timestamp': LanActivityTimestamp
  }
};
</script>
