import jQuery from 'jquery';
import 'featherlight';
import 'chosen-js';
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import dashboard from 'dashboard';

// KLUDGE: This should be the line we want:
//import 'actiontext';
// But instead we need to copy it directly from the gem:
import { DirectUpload } from 'activestorage';

jQuery.featherlight.prototype.root = '#featherlightEntryPoint';

export class AttachmentUpload {
  constructor(attachment, element) {
    this.attachment = attachment;
    this.element = element;
    this.directUpload = new DirectUpload(attachment.file, this.directUploadUrl, this);
  }

  start() {
    this.directUpload.create(this.directUploadDidComplete.bind(this));
  }

  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener('progress', event => {
      const progress = event.loaded / event.total * 100;
      this.attachment.setUploadProgress(progress);
    });
  }

  directUploadDidComplete(error, attributes) {
    if (error) {
      throw new Error(`Direct upload failed: ${error}`);
    }

    this.attachment.setAttributes({
      sgid: attributes.attachable_sgid,
      url: this.createBlobUrl(attributes.signed_id, attributes.filename)
    });
  }

  createBlobUrl(signedId, filename) {
    return this.blobUrlTemplate
      .replace(':signed_id', signedId)
      .replace(':filename', encodeURIComponent(filename));
  }

  get directUploadUrl() {
    return this.element.dataset.directUploadUrl;
  }

  get blobUrlTemplate() {
    return this.element.dataset.blobUrlTemplate;
  }
}

import 'trix';

addEventListener('trix-attachment-add', event => {
  const { attachment, target } = event;

  if (attachment.file) {
    const upload = new AttachmentUpload(attachment, target);
    upload.start();
  }
});
// END KLUDGE

// Polyfills
import 'shared/polyfills/object_assign';

window.$ = window.jQuery = jQuery;

Rails.start();
Turbolinks.start();
dashboard.init();
