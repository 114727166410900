<template>
  <div>
    <wifi-optimization-modal
      v-if="isMounted"
      @update="updateWirelessEnvironment"
      :lanId="lanId"
      :open="wifiOptimizationModalIsOpen && inEditMode"
      :currentWifiSetting="currentWifiSetting"
      @close="toggleOptimizationModal"
    />
    <div>
      <h4>
        {{$I18n.t('lans.edit.wifi_optimization.header')}}
      </h4>
      <p class="minim-mobile-text secondary-font large m-t-16">
        {{$I18n.t('lans.edit.wifi_optimization.subheader')}}
      </p>
    </div>
    <div class="optimization-options-inset flex-container justify-between align-center" @click="toggleOptimizationModal" :class="inEditMode ? '' : 'disabled'">
      <div>
        <p class="minim-mobile-text secondary-font large" style="font-weight: 400">
          {{ optimizedHeader }}
        </p>
        <div class="m-t-6">
          <div>
            <p class="minim-mobile-text secondary-font medium text-neutral-darker-color" style="font-weight: 400">
              {{ optimizedEnvironment }}
            </p>
            <p class="minim-mobile-text secondary-font medium text-neutral-darker-color">
              {{ optimizedDescription }}
            </p>
          </div>
        </div>
      </div>
      <p v-html="triangle"> </p>
    </div>
    <div class="p-t-32 flex-container justify-between align-center">
      <p> {{ $I18n.t('lans.edit.wifi_optimization.allow_users_to_change') }} </p>
      <div>
        <minim-toggle
          @click="handleToggleForMobileOptimization"
          :showLabels="false"
          :active="isActive"
          :disableCursor="inEditMode ? false : true"
          :iconName="lanSupportsFeature('isp-disallow-mobile-users-to-optimize-wifi') ? 'lock-16' : ''"
          v-tippy="{
            placement: 'top-center',
            flip: false,
            onShow: () => !!toolTipText,
            content: toolTipText
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import MinimToggle from '../../../components/minim_toggle.vue';
import WifiOptimizationModal from './wifi_optimization_modal.vue';

import { mapGetters } from 'vuex';

export default {

  props: {
    componentId: {
      type: String,
      required: true
    },

    currentWifiSetting: {
      type: String,
      required: true
    },

    enabled: {
      type: Boolean,
      required: true
    },

    lan: {
      type: Object,
      required: true
    },

    lanId: {
      type: String,
      required: true
    }
  },

  data(){
    return {
      wifiOptimizationModalIsOpen: false,
      optimizedHeader: '',
      optimizedEnvironment: '',
      optimizedDescription: '',
      enableMobileUsersToOptimize: false,
      lanObject: {},
      isMounted: false
    };
  },
  components: {
    'minim-toggle': MinimToggle,
    'wifi-optimization-modal': WifiOptimizationModal
  },

  mounted(){
    this.lanObject = {...this.lan};
    this.setOptimizationSelectorText(this.lanObject.wireless_environment);
    this.isMounted = true;
  },

  computed: {
    ...mapGetters('LanStore', ['lanSupportsFeature']),
    ...mapGetters('RouterConfigsEditModeStore', ['componentIsInEditMode']),

    inEditMode() {
      return this.componentIsInEditMode(this.componentId);
    },

    isActive(){
      if(this.lanSupportsFeature('isp-disallow-mobile-users-to-optimize-wifi')) {
        return false;
      } else if (this.enabled && !this.lanSupportsFeature('isp-disallow-mobile-users-to-optimize-wifi')) {
        return true;
      } else {
        return false;
      }
    },

    toolTipText(){
      if (!this.inEditMode) return null;
      return (this.lanSupportsFeature('isp-disallow-mobile-users-to-optimize-wifi')) ? this.$I18n.t('lans.edit.wifi_optimization.disabled_globally'): '';
    },

    triangle(){
      return '&#9662;';
    }
  },

  methods: {
    handleToggleForMobileOptimization(){
      if(this.inEditMode && !this.lanSupportsFeature('isp-disallow-mobile-users-to-optimize-wifi') ){
        this.toggleFeatureForMobileWifiOptimization();
      }
    },

    toggleOptimizationModal(){
      if(this.inEditMode) {
        this.wifiOptimizationModalIsOpen = !this.wifiOptimizationModalIsOpen;
      }
    },

    updateWirelessEnvironment(value){
      this.lanObject.wireless_environment = value;
      this.setOptimizationSelectorText(value);
      this.$emit('set', this.lanObject.wireless_environment);
    },

    setOptimizationSelectorText(value){
      switch(value){
      case 'non_noisy':
        this.optimizedHeader = this.$I18n.t('lans.edit.wifi_optimization.prioritize_speed.header');
        this.optimizedEnvironment = this.$I18n.t('lans.edit.wifi_optimization.prioritize_speed.environment');
        this.optimizedDescription = this.$I18n.t('lans.edit.wifi_optimization.prioritize_speed.description');
        break;
      case 'noisy':
        this.optimizedHeader = this.$I18n.t('lans.edit.wifi_optimization.balanced.header');
        this.optimizedEnvironment = this.$I18n.t('lans.edit.wifi_optimization.balanced.environment');
        this.optimizedDescription = this.$I18n.t('lans.edit.wifi_optimization.balanced.description');
        break;
      case 'very_noisy':
        this.optimizedHeader = this.$I18n.t('lans.edit.wifi_optimization.prioritize_stability.header');
        this.optimizedEnvironment = this.$I18n.t('lans.edit.wifi_optimization.prioritize_stability.environment');
        this.optimizedDescription = this.$I18n.t('lans.edit.wifi_optimization.prioritize_stability.description');
        break;
      default:
        this.optimizedHeader = this.$I18n.t('lans.edit.wifi_optimization.custom_settings.header');
        this.optimizedEnvironment = this.$I18n.t('lans.edit.wifi_optimization.custom_settings.description');
        this.optimizedDescription = '';
      }
    },

    toggleFeatureForMobileWifiOptimization(){
      this.lanObject.disallow_mobile_users_to_optimize_wifi = !this.lanObject.disallow_mobile_users_to_optimize_wifi;
      this.$emit('toggle', this.lanObject.disallow_mobile_users_to_optimize_wifi);
    }
  }
};
</script>
