<template>
  <div class="device-filters filter-group">
    <div class="clearable-input">
      <input v-model="searchInput" @keyup="fuzzyFilterChange" name="lan_device_filter_fuzzy" :placeholder="$I18n.t('devices.show.filter_devices')">
      <div class="clear-button" v-if="filters_set" @click="clearFilters"><i class="fas fa-times" aria-hidden="true"></i></div>
    </div>

    <div v-if="!value.wired_connections_only" class="radio-buttons">
      <div v-for="(v, key) in toggles" :key="key" class="radio-button" :class="(key == value.selected_toggle) ? 'selected' : 'deselected'" @click="filterToggle(key)">
        {{ $I18n.t('components.devices.' + v.name) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'toggles'],

  data() {
    return{
      searchInput: ''
    };
  },

  methods: {
    fuzzyFilterChange() {
      this.$emit('updateFuzzy', this.searchInput);
    },

    filterToggle(key) {
      // When the filter changes, we probably don't care about which unum anymore
      this.$delete(this.value, 'selected_unum');
      this.$emit('input', this.value , key);
    },

    clearFilters() {
      this.searchInput = '';
      this.fuzzyFilterChange();
    }
  },

  computed: {
    filters_set() {
      return this.value.fuzzy != '';
    }
  }
};
</script>
