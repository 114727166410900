import moment from 'moment-timezone';

async function readAllOnboardingFeedbackPrompts() {
  let feedbackPrompts = await window.native.exec('fetch_preference', 'onboardingFeedbackPrompts');
  feedbackPrompts = JSON.parse(feedbackPrompts);
  feedbackPrompts = (!feedbackPrompts || feedbackPrompts === 'false') ? {} : feedbackPrompts;
  return feedbackPrompts;
}

function get2WeeksDate() {
  return moment.tz(moment().add('2', 'weeks'), moment.tz.guess()).toISOString();
}

function getCurrentDate() {
  return moment.tz(moment(), moment.tz.guess()).toISOString();
}

export default {
  namespaced: true,

  // This store is used to maintain data about the in app feedbacks that needs to be persisted in localStorage
  // but also needs to be easily accessible via vuex.
  state: {
    onboardingFeedbackPrompts: {}
  },

  mutations: {
    setOnboardingFeedbackPrompts(state, data) {
      state.onboardingFeedbackPrompts = data;
    }
  },

  actions: {
    async fetchOnboardingFeedbackPrompts(context) {
      let feedbackPrompts = await readAllOnboardingFeedbackPrompts();
      context.commit('setOnboardingFeedbackPrompts', feedbackPrompts);
      return feedbackPrompts;
    },

    async setOnboardingFeedbackPrompts(context, { uuid }) {
      if(!uuid) return;
      let feedbackPrompts = await readAllOnboardingFeedbackPrompts();
      feedbackPrompts[uuid] = get2WeeksDate();
      await window.native.exec('set_preference', { key: 'onboardingFeedbackPrompts', value: JSON.stringify(feedbackPrompts) });
      context.commit('setOnboardingFeedbackPrompts', feedbackPrompts);
      return feedbackPrompts;
    },

    async deleteOnboardingFeedbackByLan(context, { uuid }) {
      let feedbackPrompts = await readAllOnboardingFeedbackPrompts();
      delete feedbackPrompts[uuid];
      await window.native.exec('set_preference', { key: 'onboardingFeedbackPrompts', value: JSON.stringify(feedbackPrompts) });
      context.commit('setOnboardingFeedbackPrompts', feedbackPrompts);
      return feedbackPrompts;
    },
  },

  getters: {
    getOnboardingFeedbackPrompts(state) {
      return state.onboardingFeedbackPrompts;
    },

    showAppFeedbackByLan: (state) => (lanId) => {
      let  diffInDays = -1;
      if(state.onboardingFeedbackPrompts[lanId]) {
        // if days is less than zero, than it mean the date has been passed
        diffInDays = moment(state.onboardingFeedbackPrompts[lanId]).diff(getCurrentDate(), 'days');
      }

      return diffInDays >= 0;
    },
  }
};
