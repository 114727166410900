<template>
  <div class="minim-score-graph">
    <minim-graph
      @chart="setChart"
      @options="setOptions"
      :title="$I18n.t('components.minim_score.title')"
      graphName="network_score"
      :queryOpts="{ lan_id: lanId }"
      :autoRefresh="true"
      :timeRangeEnabled="false"
      :reloadInterval="15000"
    />

    <div v-if="hasExplanation" class="score-explanation">
      <minim-tooltip tooltipPlacement="below" tooltipAlignment="right">
        <i class="fas fa-info-circle" slot="hover-content" />
        <div slot="tooltip-content">
          <div class="contents" v-html="this.options.customData.info" />
        </div>
      </minim-tooltip>
    </div>

    <reset-minim-score-button
      v-if="chartHasLoaded"
      class="reset-score-button"
      :lanId="lanId"
      :lanIsOnline="lanIsOnline"
      :readOnlyUser="readOnlyUser"
      tooltipPlacement="below"
      tooltipAlignment="right"
    />
  </div>
</template>

<script>
import MinimTooltip from 'dashboard/components/shared/minim_tooltip';

export default {
  components: {
    'minim-tooltip': MinimTooltip
  },

  props: {
    lanId: {
      type: String,
      required: true
    },

    lanIsOnline: {
      type: Boolean,
      default: false
    },

    readOnlyUser: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      options: {},
      chart: null
    };
  },

  methods: {
    setOptions(options) {
      this.options = options || {};
    },

    setChart(chart) {
      this.chart = chart;
    }
  },

  computed: {
    chartHasLoaded() {
      return !!this.chart;
    },

    hasExplanation() {
      return !!(this.options.customData || {}).info;
    }
  }
};
</script>
