<template>
  <div class="lan-show-component">

    <summary-panel @change="filterChange" v-if="!lan_data.disabled" :lan="lan_data"
                   :initialNumDevicesOnline="initialNumDevicesOnline"
                   :initialNumTotalDevices="initialNumTotalDevices"
                   :initialNumIssues="initialNumIssues"
                   :lanId="lanId"
                   :userIsReadOnly="userIsReadOnly"
                   :hardwareKind="hardwareKind" />
    <unums @change="filterChange" :lan="lan_data" :lanId="lanId" :userIsReadOnly="userIsReadOnly" :businessPortal="businessPortal" :supports6ghz="supports6ghz"/>
    <div v-show="Object.keys(lan_data).length" class="loaded">
      <div v-if="!lan_data.disabled" class='page-section inset device-section'>
        <minim-graph :title="$I18n.t('bandwidth_usage')" graphName="bandwidth_by_device" width="988" :queryOpts="{lan_id: lanId}" :filters="filters" :features="lan_data.features" style="border-bottom: 1px solid #ccc;" />
        <device-filters :value="filters" :lan="lan_data" :toggles="radioToggles" @input="updateFilters" @updateFuzzy="updateFilterFuzzy" />
        <devices :devices="filteredDevices" :lan="lan_data"  :lanId="lanId"/>
      </div>
    </div>
    <div v-show="!Object.keys(lan_data).length" class="centered page-section inset" style="font-size: 50px;">
      {{$I18n.t('loading')}} <i class="fas fa-cog fa-spin"></i>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import LanModel from 'shared/models/lan.js';
import LanComponents from './lans/index';
import {mapGetters} from 'vuex';

let filter_logic = {
  fuzzy(val, obj) {
    return JSON.stringify(obj).toUpperCase().indexOf(val.toUpperCase()) !== -1;
  }
};

export default {
  props: ['lanId', 'subscription', 'initialNumDevicesOnline', 'initialNumTotalDevices', 'initialNumIssues', 'userIsReadOnly', 'businessPortal', 'hardwareKind'],

  async mounted() {
    LanModel.getAndSub(this.lanId, (data) => {
      $('#lan-bandwidth-graph .button.selected').click();
      this.lan_data = data;
    });

    this.supports6ghz = await this.fetch_supports_6ghz();
    await this.$store.commit('LanStore/setLan', this.lan_data);
  },

  updated() {
    this.filters.wired_connections_only = this.lan_data.wired_connections_only;
  },

  beforeDestroy() {
    LanModel.unsubscribe();
  },

  methods: {
    filterChange(values) {
      Object.assign(this.filters, values.filters);
    },

    async fetch_supports_6ghz() {
      let radios_index = (await axios.get(`/api/v1/lans/${this.lanId}/radios`)).data;
      let radios_data = (await axios.get(`/api/v1/lans/${this.lanId}/radios?id=${radios_index.map(i => i.id).join(',')}`)).data;
      return radios_data.some(r => r.kind === 'wifi_6');
    },
    
    updateFilters(data, key){
      this.filters = data;
      this.filters.selected_toggle = key;
    },

    updateFilterFuzzy(data){
      this.filters.fuzzy = data;
    }
  },

  computed: {
    ...mapGetters('LanStore', ['lanSupportsFeature']),

    filteredDevices() {
      var ret = [];
      if(this.lan_data.devices) {
        ret = this.lan_data.devices.filter((device) => {
          switch(this.filters.selected_toggle) {
          case 'all':
            break;
          case 'wifi_2_4':
            if (device.last_connection_kind != 'wifi_2_4') return false;
            break;
          case 'wifi_5':
            if (device.last_connection_kind != 'wifi_5') return false;
            break;
          case 'wifi_6':
            if(device.last_connection_kind != 'wifi_6') return false;
            break;
          case 'ethernet':
            if (device.last_connection_kind != 'ethernet') return false;
            break;
          }
          if (device.is_gateway_unum) {
            return false;
          }
          if(this.filters.selected_unum){
            if (device.unum_id != this.filters.selected_unum) return false;
          }
          if(this.filters.fuzzy && this.filters.fuzzy.length !== 0) {
            return filter_logic.fuzzy(this.filters.fuzzy, device);
          } else {
            return true;
          }
        });
      }

      return ret;
    },

    radioToggles(){
      let toggles = {
        all: { name: 'all' },
        wifi_2_4: { name: 'wifi_2_4' },
        wifi_5: { name: 'wifi_5' },
      };

      // Show the wifi6 toggle if any devices on the network are connected via wifi6
      if(this.supports6ghz) {
        toggles.wifi_6 = {name: 'wifi_6'};
      }

      toggles.ethernet = { name: 'ethernet' };
      return toggles;
    }
  },

  watch: {
    filteredDevices() {
      this.filters.mac_addresses = this.filteredDevices.map((d) => d.mac_address);
    }
  },

  data() {
    return {
      lan_data: {},
      supports6ghz: false,
      filters: {
        fuzzy: '',
        wired_connections_only: false,
        selected_toggle: 'all',
        selected_unum: '',
        mac_addresses: []
      }
    };
  },

  components: LanComponents
};
</script>
