import minim_api from 'mobile/shared/utils/minim_api';

export const USAGE_TYPES = {
  web: {
    minRequiredDownloadSpeedKbps: 64,
    associatedIconName: 'computer'
  },
  music: {
    minRequiredDownloadSpeedKbps: 384,
    associatedIconName: 'music'
  },
  sd: {
    minRequiredDownloadSpeedKbps: 1000,
    associatedIconName: 'tv'
  },
  games: {
    minRequiredDownloadSpeedKbps: 3000,
    associatedIconName: 'console'
  },
  hd: {
    minRequiredDownloadSpeedKbps: 4500,
    associatedIconName: 'tv'
  },
  '4k': {
    minRequiredDownloadSpeedKbps: 22500,
    associatedIconName: 'tv'
  }
};

export default {
  namespaced: true,

  state: {
    speedTests: [],
    mostRecentSpeedTest: {}
  },

  mutations: {
    many(state, data) {
      state.speedTests = data;
      return state;
    },

    mostRecentSpeedTest(state, speedTest) {
      state.mostRecentSpeedTest = speedTest;
    }
  },

  actions: {
    async index({ commit }) {
      const speedTests = await minim_api.multiget('api/v1/lans/{lan_id}/speed_tests');
      commit('many', speedTests);
    },

    async fetchMostRecent({ commit }) {
      const speedTestEndpoint = 'api/v1/lans/{lan_id}/speed_tests';

      // fetch the speed tests index route with a limit of 1
      // this will get us the most recent speed test, since they're returned in the order they were added
      const mostRecentSpeedTestIds = (await minim_api.get(`${speedTestEndpoint}?limit=1`)).data;

      // if there isn't a speed test, BAIL
      if (!mostRecentSpeedTestIds.length) {
        return;
      }

      const mostRecentSpeedTest = (await minim_api.get(`${speedTestEndpoint}/${mostRecentSpeedTestIds[0].id}`)).data;

      commit('mostRecentSpeedTest', mostRecentSpeedTest);
      return mostRecentSpeedTest;
    },

    async runSpeedTest() {
      const res = await minim_api.post('api/v1/lans/{lan_id}/speed_tests');
      return res;
    }
  },

  getters: {
    mostRecentSpeedTest(state) {
      return state.mostRecentSpeedTest;
    },

    getSpeedTests(state) {
      return state.speedTests;
    },

    usageTypes() {
      return USAGE_TYPES;
    },

    usageTypeIsSupported: () => (usageType, downloadSpeedKbps) => {
      return downloadSpeedKbps >= USAGE_TYPES[usageType].minRequiredDownloadSpeedKbps;
    }
  }
};
