import $ from 'jquery';

export default {
  selector: '.page.router-test-runs.show',

  init() {
    $('.test-row-heading').click(this.onToggleSection);
    $('.qa-tabs a').click(this.onToggleTab);
  },

  onToggleSection(event) {
    const collapse_section = $(event.currentTarget).next('div');

    if (collapse_section.is(':visible')) {
      collapse_section.hide({ duration: 300, easing: 'swing' });
      if ($('#expand-all').is(':checked')) {
        $('#expand-all').prop('checked', false);
      }
    } else {
      collapse_section.show({ duration: 300, easing: 'swing' });
    }
  },

  onToggleTab(event) {
    let target = event.target;
    if (target.classList.contains('active'))
      return;

    let curActive = $(target).siblings('.active');
    curActive.removeClass('active');
    let text = target.innerText.trim();
    $(target).parents('.collapsible, .log-container').find(':not(.qa-tabs) .active').removeClass('active');

    $(target).addClass('active');

    if (text === 'results')
      $(target).parents('.collapsible, .log-container').find('.points').addClass('active');
    else
      $(target).parents('.collapsible, .log-container').find(`[data-file='${text}']`).addClass('active');
  }
};
