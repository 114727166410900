import $ from 'jquery';

const IspOverview = {
  init(id, data) {
    $.each($('.isp-overview[data-id="' + id + '"]'), (_, target) => {
      this.ISPOverviewUpdateAllNumbers($(target).find('.overview'), data);

      if (data.isps_stats != null) {
        $.each(data.isps_stats, (k, v) => {
          this.ISPOverviewUpdateAllNumbers($(target).find('.search_result[data-id="' + k + '"]'), v);
        });
      }
    });
    $('body').attr('data-isp-initialized', 'loaded');
  },

  ISPOverviewUpdateAllNumbers(target, data) {
    this.ISPOverviewUpdateNumber(target, 'active-lan-count', data.active_lan_count, data.active_lan_count_before);
    this.ISPOverviewUpdateNumber(target, 'disabled-lan-count', data.disabled_lan_count);
    this.ISPOverviewUpdateNumber(target, 'inactive-lan-count', data.inactive_lan_count, data.inactive_lan_count_before);
    this.ISPOverviewUpdateNumber(target, 'vulnerable-lan-count', data.vulnerable_lan_count, data.vulnerable_lan_count_before);
    this.ISPOverviewUpdateNumber(target, 'device-count', data.device_count, data.device_count_before);
    this.ISPOverviewUpdateNumber(target, 'devices-per-subscriber-avg', Math.round((data.device_count / data.active_lan_count) * 10) / 10,
      Math.round((data.device_count_before / data.active_lan_count_before) * 10) / 10);
    this.ISPOverviewUpdateNumber(target, 'vulnerable-device-count', data.vulnerable_device_count, data.vulnerable_device_count_before);
  },

  ISPOverviewUpdateNumber(target, selector, now, then) {
    $(target).find('.' + selector).find('.number').html(now || '-');
    this.ISPOverviewUpdateTrend(target, selector, now, then);
  },

  ISPOverviewUpdateTrend(target, selector, now, then) {
    var element = $(target).find('.' + selector);
    if (!element.hasClass('trends') || (then == 0) || (then == null)) return;

    var percent_change = Math.round((now - then) * 100 / then);
    var str = '';

    if(percent_change > 0) {
      let klass = element.hasClass('up_good') ? 'status-ok' : 'status-alert';
      str = '<span class=\'' + klass + '\'><i class=\'fas fa-arrow-up\'>&nbsp;' + percent_change + '%</i></span>';
    } else if (percent_change < 0) {
      let klass = element.hasClass('up_good') ? 'status-alert' : 'status-ok';
      str = '<span class=\'' + klass + '\'><i class=\'fas fa-arrow-down\'>&nbsp;' + percent_change + '%</i></span>';
    }
    element.find('.trend').html(str);
  }
};

window.IspOverview = IspOverview;

export default IspOverview;
