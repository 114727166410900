<template>
  <div class="lan-heading inset" id="customer-data-container">
    <h2>{{$I18n.t('lans.header.speed_test')}}</h2>
    <div class="speed-test">
      <speedtest-display :test_data="recent_speedtest"></speedtest-display>
      <speedtest-runner @newTestRun="setTestData" :initial_test_data="recent_speedtest" :lanId="lanId" :unumId="unumId"></speedtest-runner>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import MinimCable from 'shared/lib/minim_cable';
import SpeedtestDisplay from 'shared/components/speedtest/display.vue';
import SpeedtestRunner from 'shared/components/speedtest/runner.vue';

export default {
  props: ['lanId', 'unumId'],

  mounted() {
    this.loadSpeedTest();
  },

  data() {
    return {
      recent_speedtest: null
    };
  },

  methods: {
    loadSpeedTest(id) {
      let url = `/lans/${this.lanId}/unums/${this.unumId}/speed_tests/${id == undefined ? 'most_recent' : id}`;
      $.get(url).then((data)=>{
        this.setTestData(data);
      });
    },

    setTestData(data) {
      this.recent_speedtest = data;
      this.subscribeToSpeedTests(data.id);
    },

    subscribeToSpeedTests(id) {
      this.subscription = MinimCable.cable.subscriptions.create({
        channel: 'SpeedTestChannel', id: id},
      { received: (data)=>{
        this.loadSpeedTest(data.id);
      }
      });
    },
  },

  components: {
    'speedtest-display': SpeedtestDisplay,
    'speedtest-runner': SpeedtestRunner
  }
};
</script>
