export default {
  namespaced: true,

  state: {
    isOnboarding: false, // Whether or not we're in the inital onboarding flow. Should be false if we're adding a device to an existting network
    creatingNewNetwork: false, // Whether or not we're in the part of the flow where we are creating a brand new network
    currentUnumId: null, // The ID (MAC Address) of the current unum that is being onboarded
    originalPrimaryUnumId: null, // The "primary unum" may change during onboarding, this tracks which unum was primary when onboarding began
    currentHardwareKindId: null, // The hardware kind ID of the current unum that is being onboarded,
    isFromMobileSettings: false,
    isFromNetworkSwitcher: false,
    changedWiFiCreds: false,
    serialNumber: null,
    onboardingSSID: null,
    onboardingPassword: null,
    key: null,
    qrResults: {},
  },

  mutations: {
    clearOnboarding(state) {
      state.isOnboarding = false;
      state.creatingNewNetwork = false;
    },

    setIsOnboarding(state, isOnboarding) {
      window.native.exec('set_config_item', { key: 'onboarded', value: !isOnboarding});

      state.isOnboarding = isOnboarding;
      return state;
    },

    setCreatingNewNetwork(state, creatingNewNetwork) {
      state.creatingNewNetwork = creatingNewNetwork;
      return state;
    },

    setCurrentUnumId(state, unumId) {
      state.currentUnumId = unumId;
      return state;
    },

    setIsFromNetworkSwitcher(state, fromNetworkSwitcher){
      state.isFromNetworkSwitcher = fromNetworkSwitcher;
      return state;
    },

    setOriginalPrimaryUnumId(state, unumId) {
      state.originalPrimaryUnumId = unumId;
      return state;
    },

    setCurrentHardwareKindId(state, hardwareKindId) {
      state.currentHardwareKindId = hardwareKindId;
      return state;
    },

    setIsFromMobileSettings(state, isFromMobileSettings){
      state.isFromMobileSettings = isFromMobileSettings;
      return state;
    },

    setChangedWiFiCreds(state, changedWiFiCreds) {
      state.changedWiFiCreds = changedWiFiCreds;
      return state;
    },

    setMeshData(state, obj) {
      state.serialNumber = obj.serialNumber;
      state.onboardingSSID = obj.onboardingSSID;
      state.onboardingPassword = obj.onboardingPassword;
      state.key = obj.key;
      return state;
    },

    setQrResults(state, qrResults){
      state.qrResults = qrResults;
      return state;
    }
  }
};
