<template>
  <div>
    <button
      @click="openModal"
      class="neighboring-access-points-button refresh-button"
      :class="isScanning ? 'scanning' : null"
      v-tippy="{ placement: 'top-center' }"
      :content="tooltipText"
    >
      <i class="minim-icon-refresh" />
    </button>

    <minim-modal
      :showCloseButton="false"
      :open="modalIsOpen"
      @close="closeModal"
      containerStyle="width: 588px; height: 260px; padding-top: 20px;"
    >
      <div class="minim-confirm-modal">
        <h2>{{ $I18n.t('lans.edit.neighboring_ap_scan_mikrotik_confirm.header') }}</h2>
        <p>{{ $I18n.t('lans.edit.neighboring_ap_scan_mikrotik_confirm.description') }}</p>
        <button
          @click="scanNeighbors"
          class="submit m-r-12"
        >
          {{ $I18n.t('lans.edit.neighboring_ap_scan_mikrotik_confirm.submit_button') }}
        </button>
        <button
          @click="closeModal"
          class="cancel"
        >
          {{ $I18n.t('lans.edit.neighboring_ap_scan_mikrotik_confirm.cancel_button') }}
        </button>
      </div>
    </minim-modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      modalIsOpen: false,
    };
  },

  props: {
    lanId: {
      type: String,
      required: true
    },

    unums: {
      type: Array,
      required: true
    },

    isScanning: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    tooltipText() {
      if (this.isScanning) {
        return this.$I18n.t('lans.edit.neighboring_ap_scan_active_tooltip');
      }

      return this.$I18n.t('lans.edit.neighboring_ap_scan_tooltip');
    },

    lanHasMeshTiks() {
      return this.unums.some(u => u.mesh_type === 'mesh_tik');
    }
  },

  methods: {
    async openModal() {
      if (this.isScanning) return;

      if (!this.lanHasMeshTiks) {
        return this.scanNeighbors();
      }

      this.modalIsOpen = true;
    },

    closeModal() {
      this.modalIsOpen = false;
    },

    async scanNeighbors() {
      await Promise.all(this.unums.map(async unum => {
        try {
          await axios.post(`/api/v1/lans/${this.lanId}/unums/${unum.lan_mac_address}/scan_neighboring_access_points`);
        } catch (error) {
          console.log(error);
        }
      }));

      window.location.reload();
    }
  }
};
</script>
