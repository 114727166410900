import $ from 'jquery';

export default {
  init() {
    if(navigator.userAgent.match(/MSIE 10\.0/)) {
      $(document.body).addClass('ie10');
    }

    if(navigator.userAgent.match(/Trident.*rv:11\./)) {
      $(document.body).addClass('ie11');
    }
  }
};
