<template>
  <div class="w-full flex-container align-center justify-between">
    <p>{{ $I18n.t('components.isolated') }}</p>
    <minim-mobile-toggle
      @click="handleClick"
      class="m-l-6"
      size="small"
      :showLabels="false"
      :disableCursor="toggleDisabled"
      :active="isGuestForm"
      :locked="lockToggleOn"
      style="margin: 0;"
      v-tippy="{
        placement: 'top-center',
        flip: false,
        content: tooltipContent
      }"
    />

    <div ref="tooltipContent" style="max-width: 260px;">
      <div v-if="routerDoesNotSupportGuestNetworks">
        {{ $I18n.t('components.router_no_guest_networks') }}
      </div>
      <div v-else-if="firmwareReleaseDoesNotSupportGuestNetworks">
        {{ $I18n.t('components.firmware_no_guest_networks') }}
      </div>
      <div v-else-if="cannotEnableDueToGuestApLimit">
        {{ $I18n.t('components.too_many_guest_networks') }}
      </div>
      <div v-else>
        <div v-if="lockToggleOn" class="flex-container align-center justify-center">
          <minim-device-icon category="lock-16" icon_font_size="16px" />
          <p class="ok-color m-l-6 m-t-0 m-b-2">{{ $I18n.t('components.one_must_enable') }}</p>
        </div>
        <p class="m-0">{{ $I18n.t('components.guest_network_description') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MinimMobileToggle from 'mobile/shared/components/global/minim_mobile_toggle';

export default {
  components: {
    'minim-mobile-toggle': MinimMobileToggle
  },

  props: {
    editingDisabled: {
      type: Boolean,
      required: true
    },

    isGuestForm: {
      type: Boolean,
      required: true
    },

    isGuestCurrent: {
      type: Boolean,
      required: true
    },

    radios: {
      type: Array,
      required: true
    },

    maxGuestApsPerRadio: {
      type: Number,
      required: true
    },

    inCreateMode: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      tooltipContent: null
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.tooltipContent = this.$refs.tooltipContent;
    });
  },

  computed: {
    ...mapGetters('LanStore', ['lanSupportsFeature']),
    ...mapGetters('AccessPointStore', ['guestAccessPointsForRadio', 'isLastAccessPointOnRadio']),

    toggleDisabled() {
      return (
        this.editingDisabled
        || this.cannotEnableDueToGuestApLimit
        || this.routerDoesNotSupportGuestNetworks
        || this.firmwareReleaseDoesNotSupportGuestNetworks
        || this.lockToggleOn
      );
    },

    cannotEnableDueToGuestApLimit() {
      // If the network is already a guest network this is irrelevant
      if (this.isGuestCurrent) return false;

      const index = this.radios.findIndex(radio => {
        const aps = this.guestAccessPointsForRadio(radio.id);

        return aps.length >= this.maxGuestApsPerRadio;
      });

      return index !== -1;
    },

    routerDoesNotSupportGuestNetworks() {
      return this.maxGuestApsPerRadio <= 0;
    },

    firmwareReleaseDoesNotSupportGuestNetworks() {
      return this.maxGuestApsPerRadio > 0 && !this.lanSupportsFeature('guest_network');
    },

    lockToggleOn() {
      return this.lanSupportsFeature('guest_network_only') && this.inCreateMode;
    }
  },

  methods: {
    handleClick() {
      if (this.toggleDisabled) return;

      this.$emit('click');
    }
  }
};
</script>
