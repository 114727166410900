
import Vue from 'vue';
import minim_api from 'mobile/shared/utils/minim_api';

export default {
  namespaced: true,

  state: {
    access_points: []
  },

  mutations: {
    set(state, new_access_point) {
      let i = state.access_points.findIndex(access_point => access_point.id === new_access_point.id);

      // If the index is not found...
      // we want to push it in at the end
      i = (i !== -1) ? i : state.access_points.length;

      Vue.set(state.access_points, i, new_access_point);
    },

    many(state, data) {
      state.access_points = data;
      return state;
    },

    remove(state, access_point_id) {
      let i = state.access_points.findIndex(ap => ap.id === access_point_id);
      if( i === -1) {
        return;
      }
      state.access_points.splice(i, 1);
    }
  },

  actions: {
    /**
     * Fetches all the access points on the LAN
     */
    async index(context) {
      // To fetch all the access points on the LAN we first need to fetch all the radios on the LAN
      await context.dispatch('RadioStore/index', null, { root: true });

      const radios = context.rootState.RadioStore.radios;

      // Returns an array of arrays, each containing all the access points for a given radio
      const accessPointsByRadio = await Promise.all(
        radios.map(async radio => {
          // Fetch the IDs & hrefs of all the access points for the given radio
          const accessPointIdsForCurrentRadio = (await minim_api.get(`api/v1/lans/{lan_id}/radios/${radio.id}/access_points`)).data;

          // Use the ids to fetch the data for each access point
          const accessPointsForCurrentRadio = await Promise.all(
            accessPointIdsForCurrentRadio.map(async ({ id }) => {
              return (await minim_api.get(`api/v1/lans/{lan_id}/radios/${radio.id}/access_points/${id}`)).data;
            })
          );

          return accessPointsForCurrentRadio;
        })
      );

      // Flattens out the array of access point arrays before committing it to the store
      let accessPoints = accessPointsByRadio.reduce((acc, aps) => {
        acc = acc.concat(aps);
        return acc;
      }, []);
      accessPoints = accessPoints.filter( (ap) => { return !ap.hidden; });
      context.commit('many', accessPoints);
      return accessPoints;
    },

    async create(context, { radio_id, data }){
      let res = await minim_api.post(`api/v1/lans/{lan_id}/radios/${radio_id}/access_points`, { access_point: data });
      context.dispatch('show', { radio_id: radio_id, access_point_id: res.data.id});
      return res;
    },

    /**
     * Fetches data for a single access point on a radio given a radio ID and an access point ID
     */
    async show(context, { radio_id, access_point_id }) {
      const accessPoint = (await minim_api.get(`api/v1/lans/{lan_id}/radios/${radio_id}/access_points/${access_point_id}`)).data;
      context.commit('set', accessPoint);
      return accessPoint;
    },

    /**
     * Updates a single access point
     */
    async update(context, { access_point, data }) {
      await minim_api.patch(`api/v1/lans/{lan_id}/radios/${access_point.radio_id}/access_points/${access_point.id}`, { access_point: data });
      context.commit('set', { ...access_point, ...data });
    },

    /*
     * Delete a single access point
     */
    async delete(context, { access_point }) {
      await minim_api.delete(`api/v1/lans/{lan_id}/radios/${access_point.radio_id}/access_points/${access_point.id}`);
      context.commit('remove', access_point.id);
    },
  },

  getters: {
    getAccessPoint: (state) => accessPointId => state.access_points.find(ap => ap.id === accessPointId),

    getAccessPoints(state) {
      return state.access_points;
    },

    getAccessPointsForRadioId: (state) => (radioId) => {
      return state.access_points.filter( ap => ap.radio_id === radioId);
    },

    mainAccessPoints(state) {
      return state.access_points.filter(ap => !ap.is_guest);
    },

    guestAccessPoints(state) {
      return state.access_points.filter(ap => ap.is_guest);
    },

    getInterfaceKind: (state, getters, _rootState, rootGetters) => (access_point_id) => {
      const access_point = getters.getAccessPoint(access_point_id);

      const { is_guest, radio_id } = access_point;
      const radio_kind = rootGetters['RadioStore/getRadioKind'](radio_id);

      if (!is_guest)
        return radio_kind;
      else if (radio_kind === 'wifi_2_4')
        return 'guest_2_4';
      else if (radio_kind === 'wifi_5')
        return 'guest_5';
      else
        return radio_kind;
    },

    getPairedAccessPoints: (state, getters) => (accessPointId) => {
      const accessPoint = getters.getAccessPoint(accessPointId);
      const pairedAccessPoints = state.access_points.filter(({ ssid, shared_key }) => accessPoint.ssid === ssid && accessPoint.shared_key === shared_key);

      return pairedAccessPoints;
    },

    getPairedAccessPointsForSsid: (state) => (ssid) => {
      return state.access_points.filter( (ap) => ssid === ap.ssid);
    },

    getAccessPointForSsid: (state) => (ssid) => {
      return state.access_points.find( (ap) => ssid === ap.ssid);
    },

    getAccessPointForSsidAndRadioId: (state) => (ssid, radioId) => {
      return state.access_points.find( (ap) => (ssid === ap.ssid) && (radioId === ap.radio_id) );
    },

    getNetworks: (state) => {
      return state.access_points.reduce((acc, ap) => {
        const idx = acc.findIndex(network => {
          return network[0].ssid === ap.ssid && network[0].shared_key === ap.shared_key;
        });

        if (idx === -1) {
          acc.push([ap]);
        } else {
          acc[idx].push(ap);
        }

        return acc;
      }, []);
    }
  }
};
