<template>
  <div class="known-vulnerabilities">
    <div class="headers">
      <h2 class="title">{{ $I18n.t('components.security_incidents.known_vulnerabilities') }}</h2>
      <div class="info">
        <h2>{{ $I18n.t('components.security_incidents.potentially_exploitable_devices') }}</h2>
        <i class="fas fa-info-circle" :content="$I18n.t('lans.security_incidents.tooltips.vulnerability_info')" style="color: #3DC1CF" v-tippy="{ placement: 'top-start', offset: '-18,0'}"></i>
      </div>
    </div>

    <div class="vulnerable-devices" v-if="Object.keys(incidents).length != 0">
      <table>
        <thead>
          <tr>
            <th>{{ $I18n.t('components.security_incidents.vulnerability') }}</th>
          </tr>
        </thead>
        <tbody>
          <template  v-for="incident in incidents" >
            <known-vulnerability-row
              :incident="incident"
              :key="incident.uuid"
              :disableButtons="disableButtons"
            />
          </template>
        </tbody>
      </table>
    </div>
    <div class="all-good" v-else>
      <table>
        <tr>
          <td class="cell">
            <i class="fas fa-circle"></i>
            <span>{{ $I18n.t('components.security_incidents.no_known_vuln') }}</span>
          </td>
        </tr>
      </table>
    </div>

  </div>
</template>

<script>
import KnownVulnerabilityRow from './known_vulnerability_row.vue';

export default {
  props: {
    incidents:{
      type: Array
    },
    disableButtons: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'known-vulnerability-row': KnownVulnerabilityRow,
  },

  methods: {
    updateIncident(uuid, status){
      this.$emit('update-incident', uuid, status);
    },
    onDeviceClick(evt, device_id){
      this.$emit('on-device-click', evt, device_id);
    },
  },
};
</script>
