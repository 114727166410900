import $ from 'jquery';

export default {
  selector: '.minim-countdown',

  init() {
    $(this.selector).each((i, ele) => {
      this.update_time(ele);

      setInterval(() => {
        this.update_time(ele);
      }, 1000);
    });
  },

  update_time(ele) {
    let scheduled_for = $(ele).data('scheduledFor');
    let seconds_to_schedule = Math.round(scheduled_for - (Date.now()/1000));

    $(ele).html(seconds_to_schedule > 0 ? `${seconds_to_schedule}s` : 'a moment');
  }
};
