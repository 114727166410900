<template>
  <div class="alert-box pointer" :class="status">
    {{message}}

    <status-icon :status="status" class="small"></status-icon>
  </div>
</template>

<script>

export default {
  props: ['status', 'message'],

  data() {
    return {
    };
  }
};
</script>
