import ActionCable from 'actioncable';
import $ from 'jquery';

// Singleton to manage subscriptions across the application
export default {
  subscribers: [],
  handlers: {},
  cable: null,

  init(sub_handlers) {
    this.cable = ActionCable.createConsumer();
    this.handlers = sub_handlers;
  },

  add(id, resource, subscriber, target) {
    let channel_info = {
      channel: resource + 'Channel',
      id: id
    };

    let opts = {
      received: this.received.bind({
        that: this,
        target: target,
        index: this.subscribers.length
      })
    };

    var sub = this.cable.subscriptions.create(channel_info, opts);
    var sub_info = { subscription: sub, subscriber: subscriber };

    this.handlers[sub_info.subscriber].initialize(target);

    this.subscribers.push(sub_info);
  },

  unload() {
    $.each(this.subscribers, (_, subscriber) => {
      if (typeof subscriber.component != 'undefined') {
        subscriber.component.unload();
      }

      this.cable.subscriptions.remove(subscriber.subscription);
    });

    this.subscribers = [];
  },

  received(data) {
    var subscriber = this.that.subscribers[this.index];
    this.that.handlers[subscriber.subscriber].received(this.target, data);
  }
};
