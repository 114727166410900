import minim_api from 'mobile/shared/utils/minim_api';

export default {
  namespaced: true,

  state: {
    customer_support_info: null,
    show_customer_support_info_modal: false
  },

  mutations: {
    one(state, data) {
      state.customer_support_info = data;
      return state;
    },

    setShowCustomerSupportInfoModal(state, value) {
      state.show_customer_support_info_modal = value;
      return state;
    }
  },

  actions: {
    async get({ commit, rootState }) {
      let data;
      let lanId;

      try {
        if (rootState.LanStore.lan.id) {
          lanId = rootState.LanStore.lan.id;
        } else if (rootState.LanStore.lans.length) {
          lanId = rootState.LanStore.lans[0].id;
        }

        data = (await minim_api.get(`api/v1/lans/${lanId}/customer_support_info`)).data;
      } catch(err) {
        // Ignore if 404 - means there is no support info
        if(err.response.status !== 404) {
          console.error(err);
        }
      }

      commit('one', data);
    }
  },

  getters: {
    data(state) {
      return state.customer_support_info;
    },

    get_show_customer_support_info_modal(state) {
      return state.show_customer_support_info_modal;
    },
  }
};
