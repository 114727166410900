<template>
  <div class="firmware_version">
    <div class="flex-container align-center">
      <template v-if="!updateOnly">
        <div>
          <strong class="label">{{$I18n.t('firmware_version')}}:</strong> <span>{{unum.firmware_version}}</span>
        </div>
      </template>

      <div style="margin-left: 5px" v-if="unum.is_firmware_update_available && unum.active">
        <component
          v-if="unum.is_updating"
          :is="buttonTagName" href="#"
          :class="unum.is_updating ? `big ${button_background_class}` : button_background_class"
          v-tippy="{ placement: 'top-center' }"
          :content="toolTipText"
        >
          <i class="fas fa-spinner fa-spin"></i>
          {{$I18n.t('unums_index.updating')}}
        </component>

        <component
          v-else
          href="#"
          :is="buttonTagName"
          :class="button_background_class"
          :data-id="unum.id"
          @click='on_update'
          v-tippy="{ placement: 'top-center' }"
          :content="toolTipText"
        >
          {{ buttonText }}
        </component>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    unum: Object,
    lanId: String,
    updateOnly: {
      type: Boolean,
      default: false
    },
    buttonTagName: {
      type: String,
      default: 'button'
    },
    disableCommandButtons: {
      type: Boolean,
      default: false
    },
    businessPortal: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      polling: null,
      updateFailed: null
    };
  },

  mounted(){
    if(this.unum.is_updating){
      this.pollData();
    }
  },

  beforeDestroy(){
    if(this.polling){
      clearInterval(this.polling);
    }
  },

  methods: {
    async on_update() {
      if(this.disableCommandButtons) { return; }
      if (confirm(this.$I18n.t('unums_index.update_confirm', { 'new_version': this.unum.firmware_update_version, 'who': this.$I18n.t(this.businessPortal ? 'customer' : 'user').toLowerCase() }))) {
        this.$emit('unum-updating',{ id : this.unum.id, is_updating: true });
        await axios.post(`/unums/${this.unum.id}/force_update`);
        this.pollData();
      }
    },

    pollData(){
      this.polling = setInterval(async () => {
        let unum =  (await axios.get(`/lans/${this.lanId}/unums/${this.unum.id}.json`)).data;

        if(!unum.is_updating){
          if(unum.is_firmware_update_available){
            clearInterval(this.polling);
            this.updateFailed = true;
            this.$emit('unum-updating',{ id : this.unum.id, is_updating: false });
          } else {
            clearInterval(this.polling);
            const data = {
              id : this.unum.id,
              is_updating: false,
              firmware_version: this.unum.firmware_update_version,
              is_firmware_update_available: false
            };
            this.$emit('update-unum', data);
          }
        }
      }, 3000);
    },
  },

  computed: {
    button_background_class() {
      if (this.disableCommandButtons){
        return 'update disabled';
      }
      if(this.updateFailed) {
        return 'update negative-color';
      }
      return 'update';
    },

    toolTipText(){
      if(this.unum.is_updating){
        return this.$I18n.t('unums_index.updating_to', { 'version_string': this.unum.firmware_update_version});
      }

      if(this.unum.is_firmware_update_available){
        return this.$I18n.t('unums_index.update_to', { 'version_string': this.unum.firmware_update_version});
      }

      if(this.updateFailed){
        return this.$I18n.t('unums_index.update_failed');
      }

      return '';
    },

    buttonText(){
      if(this.unum.is_firmware_update_available){
        return this.$I18n.t('unums_index.update');
      }

      if(this.updateFailed){
        return this.$I18n.t('unums_index.retry');
      }

      return '';
    }
  }
};
</script>
