var ipv6Regex = /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/;

export default {
  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  checkIfIPv6(str) {
    return ipv6Regex.test(str);
  },

  toIPv6(str) {
    if(str && ipv6Regex.test(str) && str.length > 20) {
      let strArr = str.match(/.{1,20}/g);
      return strArr[0] + '<br />' + strArr[1];
    }
    return str;
  },

  // Takes in an array of words and creates a sentence in the format: word1, word2, and word3
  createGrammaticallyCorrectList(words) {
    let lastWord = '';

    if (words.length > 1) {
      lastWord = ' and ' + words.pop();
      if (words.length > 1) lastWord = ',' + lastWord;
    }

    return words.join(', ') + lastWord;
  }
};
