import minim_api from 'mobile/shared/utils/minim_api';
import moment from 'moment';
import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    index: [],
    lans: [],
    lan: {},

    // all the following properties are for the current LAN
    queryStats: {},
    allowedDomains: [],
    blockedDomains: [],
    supportedChannels: {},
    top_people: [],
    top_devices: []
  },

  mutations: {
    set(state, data) {
      let i = state.lans.findIndex((d) => (d.id === data.id));

      // If the index is not found, push it in at the end
      i = (i !== -1) ? i : state.lans.length;

      Vue.set(state.lans, i, data);
    },

    many(state, data) {
      state.lans = data;
      return state;
    },

    one(state, data) {
      state.lan = data;
      return state;
    },

    setQueryStats(state, data) {
      state.queryStats = data;
      return state;
    },

    setAllowedDomains(state, data) {
      state.allowedDomains = data || [];
      return state;
    },

    setBlockedDomains(state, data) {
      state.blockedDomains = data || [];
      return state;
    },

    setBandwidthLeaderboard(state, data) {
      state.top_people = data.top_people;
      state.top_devices = data.top_devices;
      return state;
    },

    setSupportedChannels(state, data) {
      state.supportedChannels = data;
      return state;
    },

    remove(state, lanId) {
      let i = state.lans.findIndex(lan => lan.id === lanId);
      if( i === -1) {
        return;
      }
      state.lans.splice(i, 1);
    }
  },

  actions: {
    async index(context) {
      const lans = await minim_api.multiget('api/v1/lans');
      context.commit('many', lans);

      return lans;
    },

    /**
     * Fetches a LAN and updates it in state.
     * If no args are passed it will assume it should fetch and update the current LAN.
     */
    async show(context, opts = {}) {
      // By default - assume we should update the current LAN.
      // Also if no LAN ID is passed AND we're updating the current LAN,
      // just use the shortcut for getting current LAN ID from the minim API util
      const isCurrentLan = opts.isCurrentLan || true;
      const lanId = (isCurrentLan && !opts.lanId) ? '{lan_id}' : opts.lanId;

      const lan = (await minim_api.get(`api/v1/lans/${lanId}`)).data;
      context.commit('set', lan);

      if (isCurrentLan) {
        context.commit('one', lan);
      }

      return lan;
    },

    async update(context, { isCurrentLan = true, lan, data }) {
      // Update the lan with the provided data
      await minim_api.patch(`api/v1/lans/${lan.id}`, { lan: data });

      // after posting the update, update the object in state
      context.commit('set', { ...lan, ...data });

      if (isCurrentLan) {
        context.commit('one', { ...lan, ...data });
      }
    },

    async pause(_context, paused_until) {
      await minim_api.post('api/v1/lans/{lan_id}/pause', { pause_until: paused_until });

      await Promise.all([
        this.dispatch('PauseStatusStore/index')
      ]);
    },

    async unpause() {
      await minim_api.post('api/v1/lans/{lan_id}/unpause');

      await Promise.all([
        this.dispatch('PauseStatusStore/index')
      ]);
    },

    async setTimeZone(context, tz) {
      const currentLan = context.getters.currentLan;
      const current_tz = currentLan.timezone;
      const data = { timezone_override: tz };

      try {
        context.commit('one', { ...currentLan, ...{ timezone: tz }});
        await minim_api.patch('api/v1/lans/{lan_id}', { lan: data });
      } catch (err) {
        context.commit('one', { ...currentLan, ...{ timezone: current_tz }});
        throw err;
      }
    },

    async setDeviceName(context, name) {
      const currentLan = context.getters.currentLan;
      const data = { name: name };

      try {
        context.commit('one', { ...currentLan, ...{ name: name }});
        await minim_api.patch('api/v1/lans/{lan_id}', { lan: data });
      } catch (err) {
        context.commit('one', { ...currentLan, ...{ name: name }});
        throw err;
      }
    },

    async setFilterSettings(context, { newSettings }) {
      const currentLan = context.getters.currentLan;
      const currentSettings = context.getters.getFilterSettingsForLan;
      const data = { filter_settings: newSettings };

      try {
        // Commit the changes right away to make the UI update
        context.commit('one', { ...currentLan, ...data });
        await minim_api.patch('api/v1/lans/{lan_id}', { lan: data });
      } catch (err) {
        // If we failed to set the settings, rollback the store and
        // rethrow the error for the component to handle
        context.commit('one', { ...currentLan, filter_settings: currentSettings });
        throw err;
      }
    },

    async setLanIpSettings(context, {lan_ip, lan_mask, dhcp_start, dhcp_limit}) {
      const currentLan = context.getters.currentLan;
      const currentIP = currentLan.lan_ip;
      const currentMask = currentLan.lan_mask;
      const data = { lan_ip: lan_ip, lan_mask: lan_mask, dhcp_start: dhcp_start, dhcp_limit: dhcp_limit };

      try {
        // Commit the changes right away to make the UI update
        context.commit('one', { ...currentLan, ...data });
        await minim_api.patch('api/v1/lans/{lan_id}', { lan: data });
      } catch (err) {
        // If we failed to set the settings, rollback the store and
        // rethrow the error for the component to handle
        context.commit('one', { ...currentLan, lan_ip: currentIP, lan_mask: currentMask});
        throw err;
      }
    },

    async setUPNPStatus(context, isEnabled) {
      const currentLan = context.getters.currentLan;
      const current_upnp_status = currentLan.config_upnp_enabled;
      const data = { config_upnp_enabled: isEnabled };

      try {
        context.commit('one', { ...currentLan, ...{ config_upnp_enabled: isEnabled }});
        await minim_api.patch('api/v1/lans/{lan_id}', { lan: data });
        await minim_api.post('api/v1/lans/{lan_id}/apply_config.json');
      } catch (err) {
        context.commit('one', { ...currentLan, ...{ config_upnp_enabled: current_upnp_status }});
        throw err;
      }
    },

    async setLEDBrightnessValue(context, newValue) {
      const currentLan = context.getters.currentLan;
      const current_led_brightness_value = currentLan.config_led_brightness;
      const data = { config_led_brightness: newValue };

      console.log(data);

      try {
        context.commit('one', { ...currentLan, ...{ config_led_brightness: newValue }});
        await minim_api.patch('api/v1/lans/{lan_id}', { lan: data });
        await minim_api.post('api/v1/lans/{lan_id}/apply_config.json');
      } catch (err) {
        context.commit('one', { ...currentLan, ...{ config_led_brightness: current_led_brightness_value }});
        throw err;
      }
    },

    async fetchQueryStats({ commit }) {
      const queryStats = (await minim_api.get('api/v1/lans/{lan_id}/dns_query_stats')).data;
      commit('setQueryStats', queryStats);
      return queryStats;
    },

    async fetchAllowedDomains({ commit }) {
      const domains = (await minim_api.get('api/v1/lans/{lan_id}/allowed_domains')).data.domains;
      commit('setAllowedDomains', domains);
      return domains;
    },

    async fetchBlockedDomains({ commit }) {
      const domains = (await minim_api.get('api/v1/lans/{lan_id}/blocked_domains')).data.domains;
      commit('setBlockedDomains', domains);
      return domains;
    },

    async fetchBandwidthLeaderboard(context) {
      const data = (await minim_api.get('api/v1/lans/{lan_id}/bandwidth_leaderboard')).data;
      context.commit('setBandwidthLeaderboard', data);
      return data;
    },

    async fetchSupportedChannels(context) {
      const primaryUnum = context.rootGetters['UnumStore/primaryUnum'];
      const { supported_channels } = (await minim_api.get(`api/v1/lans/{lan_id}/unums/${primaryUnum.id}/supported_channels`)).data;

      context.commit('setSupportedChannels', supported_channels);

      return supported_channels;
    }
  },

  getters: {
    pausedUntil: (state, getters, rootState, rootGetters) => {
      let devices = rootGetters['PauseStatusStore/pausedDevices'];
      let max_paused = devices.reduce((max, d) => {
        return moment(d.paused_until).isAfter(moment(max)) ? d.paused_until : max;
      }, {});
      return(Object.keys(max_paused).length === 0 ? null : max_paused);
    },

    currentLan: (state) => {
      return state.lan;
    },

    primaryColor: (state) => {
      return state.lan.primary_color;
    },

    currentLansFeatures: state => state.lan.features,

    currentLanSupportsFeature: (state, getters) => (feature_name) => {
      let current_lan = getters.currentLan;

      if(current_lan.features) {
        return current_lan.features.includes(feature_name);
      }

      return false;
    },

    currentLanSupportsAllFeatures: (_state, getters) => (featureFilters) => {
      return featureFilters.every(feature => getters.currentLanSupportsFeature(feature));
    },

    quertyStatsForLan(state) {
      return state.queryStats;
    },

    getFilterSettingsForLan(_state, getters) {
      const currentLan = getters.currentLan || {};
      const filterSettingsForLan = currentLan.filter_settings || [];

      return filterSettingsForLan;
    },

    checkIfLanHasFilterEnabled: (_state, getters) => (filterTags) => {
      const filterSettingsForLan = getters.getFilterSettingsForLan;
      const matchingTags = filterSettingsForLan.filter(tag => filterTags.includes(tag));

      return filterTags.length === matchingTags.length;
    },

    currentLanSupportsContentFiltering(_state, getters) {
      const supportsHWContentFiltering = getters.currentLanSupportsFeature('hardware_content_filtering');
      const supportsISPContentFiltering = getters.currentLanSupportsFeature('isp_content_filtering');
      const contentFilteringIsDisabled = getters.checkIfLanHasFilterEnabled(['disabled']);

      return (
        supportsHWContentFiltering
        && supportsISPContentFiltering
        && !contentFilteringIsDisabled
      );
    },

    currentLanSupportsEDNS0Filtering(_state, getters) {
      return getters.currentLanSupportsContentFiltering && getters.currentLanSupportsFeature('edns0_content_filter');
    },

    currentLanSupportsIPBasedFiltering(_state, getters) {
      return getters.currentLanSupportsContentFiltering && getters.currentLanSupportsFeature('allow_ip_profile_filters');
    },

    currentLanSupportsChannelOptimization(_state, getters) {
      if((getters.currentLanSupportsFeature('isp-disallow-mobile-users-to-optimize-wifi') || getters.currentLanSupportsFeature('lan-disallow-mobile-users-to-optimize-wifi'))){
        return false;
      }

      if(getters.currentLanSupportsFeature('config_channel_width')){
        return true;
      }

      return false;
    },

    onboardingInitialWiFiSetupPath(_state, getters) {
      // Returns the first path a user should be directed to in the onboarding flow
      // when they get to the point of beginning to configure their actual WiFi network

      return getters.currentLanSupportsChannelOptimization ? 'onboarding_wifi_optimization_path' : 'network_setup_path';
    }
  }
};
