<template>
  <tr class="unknown-device">
    <td>
      <div class="description">
        <minim-device-icon class="device-icon" :category="unknownDevice['category']" icon_font_size="32px"/>
        <div class="body">
          <span style="color: #1b8c9c; cursor: pointer;" @click="onDeviceClick">{{ $I18n.t('components.security_incidents.prev_unseen_one', { mac: unknownDevice['mac_address'] }) }}</span>
          <p class="additional-incident-info clickable" @click="openLink">
            {{ $I18n.t('components.security_incidents.prev_unseen_two') }}<i class="minim-mobile-icon minim-icon-forward"></i>
          </p>
        </div>
      </div>
    </td>
    <td>
      <div class="btns">
        <div class="btn-1">
          <button
            @click="approveDevice"
            v-if="!(unknownDevice['paused'])"
            :class="`${disableButtons ? 'disabled' : 'clear'}`"
            :content="$I18n.t('lans.security_incidents.tooltips.known_device')"
            v-tippy="{ placement: 'top-end' }"
          >{{ $I18n.t('components.security_incidents.known_device') }}</button>
          <button
            v-else
            @click="rejectDevice"
            :class="`${disableButtons ? 'disabled' : 'clear'}`"
            :content="$I18n.t('lans.security_incidents.tooltips.clear_alert')"
            v-tippy="{ placement: 'top-end' }"
          >{{ $I18n.t('components.security_incidents.clear_alert') }}</button>
        </div>
        <button
          v-if="!(unknownDevice['paused'])"
          @click="pauseDevice()"
          :class="`${disableButtons ? 'disabled' : 'good'}`"
          :content="$I18n.t('lans.security_incidents.tooltips.pause_device')"
          v-tippy="{ placement: 'top-end' }"
        >{{ $I18n.t('components.security_incidents.pause_device') }}</button>
        <button
          v-else
          @click="unpauseDevice"
          :class="`${disableButtons ? 'disabled' : 'bad'}`"
          :content="$I18n.t('lans.security_incidents.tooltips.unpause_device')"
          v-tippy="{ placement: 'top-end' }"
        >{{ $I18n.t('components.security_incidents.unpause_device') }}</button>
      </div>
    </td>
  </tr>
</template>

<script>
import MinimDeviceIcon from '../../../../shared/components/minim_device_icon.vue';
import $ from 'jquery';

export default {
  props: {
    incident: {
      type: Object
    },

    unknownDevice: {
      type: Object
    },

    uuid: {
      type: String
    },

    disableButtons: {
      type: Boolean,
      default: false
    }
  },

  components: {
    'minim-device-icon': MinimDeviceIcon
  },
  methods: {
    approveDevice() {
      window.eventBus.$emit('approve-device', this.unknownDevice.uuid);
    },

    rejectDevice() {
      window.eventBus.$emit('reject-device', this.unknownDevice.uuid);
    },

    pauseDevice() {
      window.eventBus.$emit('pause-device', this.unknownDevice.uuid);
    },

    unpauseDevice() {
      window.eventBus.$emit('unpause-device', this.unknownDevice.uuid);
    },

    openLink() {
      window.open('https://www.comparitech.com/blog/information-security/byod-security-risks', '_blank', 'noopener');
    },

    onDeviceClick(){
      let uri =  'devices/' + this.unknownDevice.id + '.html';
      $.ajax({ type: 'GET', url: uri, success: function(data) { $.featherlight(data); } });
    },
  },
};
</script>
