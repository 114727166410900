<template>
  <div
    class="w-full flex-container align-center justify-between m-t-16 m-b-16"
    v-tippy="{
      placement: 'top-center',
      flip: false,
      onShow: () => !!tooltipContent,
      content: tooltipContent
    }"
  >
    <p class="p-0 m-0">{{ $I18n.t(radio.kind) }}</p>
    <minim-mobile-toggle
      @click="handleClick"
      :active="bandIsEnabled"
      :disableCursor="togglingDisabled"
      :showLabels="false"
      style="margin: 0;"
      size="small"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MinimMobileToggle from 'mobile/shared/components/global/minim_mobile_toggle';

export default {
  components: {
    'minim-mobile-toggle': MinimMobileToggle
  },

  props: {
    accessPoint: {
      type: Object,
      required: true
    },

    radio: {
      type: Object,
      required: true
    },

    editingDisabled: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapGetters('LanStore', ['lanSupportsFeature']),

    bandIsEnabled() {
      return this.accessPoint.enabled && this.radio.config_enabled;
    },

    togglingDisabled() {
      return this.lanSupportsFeature('wireless_always_enabled') || this.editingDisabled;
    },

    tooltipContent() {
      if (this.editingDisabled) return null;

      if (this.lanSupportsFeature('wireless_always_enabled')) return this.$I18n.t('router_configs.wireless_always_enabled_tooltip');
      if (this.lanSupportsFeature('single_wireless_authentication')) return this.$I18n.t('router_configs.single_wireless_auth_tooltip');
      return '';
    }
  },

  methods: {
    handleClick() {
      if (this.togglingDisabled) return;

      this.$emit('click', this.radio.id, !this.bandIsEnabled);
    }
  }
};
</script>
