<template>
  <div class="care-portal-traffic-legend-item-container">
    <div @click="toggleShowServices" class="flex-container device">
      <h3>{{ point.name }}</h3>
      <h3>{{ $I18n.n(point.percentage / 100, { style: 'percent' }) }}</h3>
    </div>
    <div v-if="showServices" class="services-container">
      <div class="flex-container">
        <h4>{{ $I18n.t('graphs.service_name') }}</h4>
        <h4>{{ $I18n.t('graphs.bandwidth_percentage') }}</h4>
      </div>
      <div class="flex-container" v-for="(data, index) in point.full_dns_data" :key="`${data.name}-${index}`">
        <p>{{ data.name }}</p>
        <p>{{ $I18n.n((data.y / total), { style: 'percent' }) }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    point: {
      type: Object,
      required: true
    },

    total: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      showServices: false
    };
  },

  methods: {
    toggleShowServices() {
      this.showServices = !this.showServices;
    }
  }
};
</script>
