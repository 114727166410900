import Vue from 'vue/dist/vue.esm';
import TurbolinksAdapter from 'vue-turbolinks';
import jQuery from 'jquery';
import i18n from 'shared/lib/i18n.js';
import { setI18nLanguage } from 'shared/lib/i18n.js';
import shared_components from 'shared/components/index.js';

const ComponentLoader = {
  async init(components, inline_components = []) {
    console.log('ComponentLoader -> init');

    // Combine the components passed in with the global shared components
    this.global_components = Object.assign({}, shared_components, components);
    this.inline_components = inline_components;

    // Load the global components into the Vue class
    for (let name in this.global_components) {
      console.log(`Loading component ${name}`);
      Vue.component(name, this.global_components[name]);
    }
  },

  process() {
    console.log('ComponentLoader -> Processing components on page');
    this.processSingleFileComponents();
    this.processInlineComponents();
  },

  processSingleFileComponents() {
    jQuery('.vue-component').each((_, ele) => {
      let component_name = jQuery(ele).data('name');
      let component = this.global_components[component_name];

      console.log(`loading... ${component_name}`);

      if (component) {
        component.mixins = component.mixins || [];
        component.mixins.push(TurbolinksAdapter);

        let vm = new Vue(Object.assign(component, { i18n, propsData: jQuery(ele).data() }));

        setI18nLanguage().then(() => {
          vm.$mount(ele);
        });
      } else {
        console.error(`A component with the name ${component_name} does not exist. Load it via the component_loader.js`);
      }
    });
  },

  processInlineComponents() {
    this.inline_components.forEach(({ selector, component }) => {
      if (!selector) return;

      jQuery(selector).each((_, ele) => {
        if (component) {

          let vm = new Vue(Object.assign(component, { i18n, propsData: jQuery(ele).data() }));

          setI18nLanguage().then(() => {
            vm.$mount(ele);
          });
        }
      });
    });
  }
};

export default ComponentLoader;