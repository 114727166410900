import { shouldPolyfill } from '@formatjs/intl-pluralrules/should-polyfill';

// Polyfills Intl.PluralRules if it isn't already present
async function polyfill(locale) {
  if (shouldPolyfill()) {
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-numberformat/polyfill');
  }

  if (Intl.PluralRules.polyfilled) {
    switch (locale) {
    case 'ru':
      await import('@formatjs/intl-pluralrules/locale-data/ru');
      break;
    default:
      await import('@formatjs/intl-pluralrules/locale-data/en');
      break;
    }
  }
}

export default polyfill;
