export default {
  signalStrengthRank(signal) {
    if (!signal) return 0;

    if (signal > -60) return 5;
    if (signal > -65) return 4;
    if (signal > -72) return 3;
    if (signal > -80) return 2;
    else return 1;
  },
  signalRankInWords(I18n, signal) {
    if (!signal) return I18n.t('offline');
    let signalMap = {
      5: I18n.t('mobile.devices.show.signal_strength_card.strength_levels.great'),
      4: I18n.t('mobile.devices.show.signal_strength_card.strength_levels.good'),
      3: I18n.t('mobile.devices.show.signal_strength_card.strength_levels.ok'),
      2: I18n.t('mobile.devices.show.signal_strength_card.strength_levels.bad'),
      1: I18n.t('mobile.devices.show.signal_strength_card.strength_levels.poor')
    };
    return signalMap[signal];
  }
};
