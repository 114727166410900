export default {
  namespaced: true,

  state: {
    pollingStatus: null,
  },

  mutations: {
    setPollingStatus(state, pollingStatus) {
      state.pollingStatus = pollingStatus;
      return state; 
    }
  },

  getters: {
    getPollingStatus(state){
      return state.pollingStatus; 
    }
  }
};