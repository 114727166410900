import minim_api from 'mobile/shared/utils/minim_api';

export default {
  namespaced: true,

  state: {
    terms: [],
  },

  mutations: {
    many(state, data) {
      state.terms = data;
      return state;
    }
  },

  actions: {
    async index(context) {
      let terms = await minim_api.multiget('api/v1/service_terms');
      context.commit('many', terms);

      return terms;
    },

    async accept({commit, state}, term){
      let res = await minim_api.post(`api/v1/service_terms/${term.id}/accept`);

      let idx = state.terms.indexOf(term);

      state.terms.splice(idx, 1);
      commit('many', state.terms);
      commit('UserServiceTermsStore/set', res.data, {root: true});

      return res;
    }
  },

  getters: {
    getByKind: (state) => (kind) => {
      return state.terms.find(term => term.kind === kind);
    },
    getTerm: (state) => (key) => {
      return state.terms.find(term => term.key === key);
    },
    getNonDataConsentTerms(state){
      return state.terms.filter(term => term.kind !== 'data_consent');
    }
  }
};