<template>
  <div class="lan-event">
    <lan-activity-timestamp :timestamp="this.eventData.created_at" />
    <div class="event-body">
      <div class="event-type" v-html="eventData.title" />
      <h2 v-html="eventData.message" />
    </div>
  </div>
</template>

<script>
import LanActivityTimestamp from './lan_activity_timestamp.vue';

export default {
  components: {
    'lan-activity-timestamp': LanActivityTimestamp
  },

  props: {
    lanId: {
      type: String,
      required: true
    },

    eventData: {
      type: Object,
      required: true
    },
  }
};
</script>
