import $ from 'jquery';

export default {
  selector: '.menu-trigger',

  init() {
    $(document).click((e) => {
      if (!$(e.target).hasClass('menu-trigger') && !$(e.target).parents('.menu-trigger').length) {
        $('.menu.dismissible').removeClass('expanded');
      }
    });
  }
};
