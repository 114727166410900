// This store should only contain data retrieved from the CdvPurchase cordova plugin
export default {
  namespaced: true,

  state: {
    productOptionPrices: {}
  },

  mutations: {
    setProductOptionPrices(state, productOptionPrices) {
      state.productOptionPrices = productOptionPrices;
      return state;
    }
  },

  getters: {
    getProductOptionPrice: (state) => (productOption) => {
      return state.productOptionPrices[productOption.id];
    }
  }
};
