import Turbolinks from 'turbolinks';
import $ from 'jquery';

export default {
  selector: '.lan-search-box',
  uri: null,

  init() {
    this.uri = $('.lan-search-box').data('uri');
    $('.lan-search-box').on('keydown', this.onKeyDown.bind(this));
    $('.lan-search-box').on('keyup', this.onKeyUp.bind(this));
    $('body').click(this.hideResults.bind(this));
    $('.more-results').click(() => $('.lan-search form').submit());
  },

  onKeyDown(event) {
    if (event.originalEvent.code == 'Enter') {
      let index = this.selectedResultIndex();
      event.preventDefault();
      if (index == -1) {
        $('.lan-search form').submit();
      } else {
        Turbolinks.visit('' + $($('.search-results .selectable')[index]).data('href'));
        this.onClearSearch();
      }
    } else if (event.originalEvent.code == 'ArrowUp') {
      event.preventDefault();
      this.onSelectPrevious();
    } else if (event.originalEvent.code == 'ArrowDown') {
      event.preventDefault();
      this.onSelectNext();
    }
  },

  onKeyUp(event) {
    if (event.originalEvent.code == 'Escape') {
      event.preventDefault();
      this.onClearSearch();
    } else if (event.originalEvent.code == 'Enter' || event.originalEvent.code == 'ArrowUp' || event.originalEvent.code == 'ArrowDown') {
      event.preventDefault();
    } else {
      let search_string = $('.lan-search-box').val();
      let data = { q: search_string };

      if (search_string) {
        $.getJSON(this.uri, data, this.renderResults.bind(this));
      } else {
        this.onClearSearch();
      }
      this.onClearSelection();
    }
  },

  onClearSelection() {
    $('.search-results li').removeClass('selected');
  },

  onSelectPrevious() {
    let total = this.resultsCount();
    let index = this.selectedResultIndex();
    if (total == 0 || index == -1) {
      return;
    }
    this.onClearSelection();
    $($('.search-results .selectable')[index - 1]).addClass('selected');
  },

  onSelectNext() {
    let total = this.resultsCount();
    let index = this.selectedResultIndex();
    if (total == 0 || index == total) {
      return;
    }
    this.onClearSelection();
    $($('.search-results .selectable')[index + 1]).addClass('selected');
  },

  selectedResultIndex() {
    return $('.search-results .selectable.selected').index('.search-result');
  },

  resultsCount() {
    return $('.search-results .selectable').length;
  },

  onClearSearch() {
    $('.lan-search-box').val('');
    $('.search-results .search-result').remove();
    $('.search-results').hide();
  },

  renderResults(lans) {
    $('.search-results .search-result').remove();
    if(lans.length > 0) {
      $('.no-results').hide();
      lans.forEach(this.renderResultRow.bind(this));
      if(lans.length == 5) {
        $('.more-results').addClass('selectable');
        $('.search-results').append($('.more-results').show());
      } else {
        $('.more-results').removeClass('selectable');
        $('.more-results').hide();
      }
    } else {
      $('.more-results').hide();
      $('.no-results').show();
    }
    $('.search-results').show();
  },

  onResultClick(evt) {
    let $target = $(evt.target);
    let result = $target.hasClass('search-result') ? $target : $target.closest('.search-result');
    Turbolinks.visit('' + result.data('href'));
  },

  renderResultRow(unum) {
    var status = 'disabled';
    let unum_status = '';

    if(unum.is_unmanaged){
      unum_status = '<span class="status status-offline"><i class="minim-icon-unmanaged-device-16"></i></span>';
    }
    if (unum.enabled && !unum.is_unmanaged) {
      status = unum.active ? 'ok' : 'warning';
      unum_status = '<span class="status status-' + status + '"><i class="fas fa-circle"></i></span>';
    }

    let $row = $('<li class="search-result selectable table-view-cell"' + ' data-href=' + unum.href +'>' +
      '<span class="search-result-lan">' +
        unum_status +
        '<span>' +
          '<span class=\'primary-label\'>' + (unum.name ? '' + unum.name + '</span> (' + unum.mac_address + ')' : '' + unum.mac_address + '</span>') + '<br>' +
          '<small>' + (unum.address || 'unknown address') + '</small>' +
        '</span>' +
      '</span>' +
    '</li>');

    $row.click(this.onResultClick.bind(this));

    $('.search-results').append($row);
  },

  hideResults(evt) {
    if($(evt.target).parents('.lan-search').length == 0) {
      $('.search-results').hide();
    }
  }
};
