import $ from 'jquery';

export default {
  selector: '.send-sms-credentials, .push-credentials',

  init() {
    $('.sms-credentials-toggle').click(this.onToggleForm);
    $('.cancel-sms-credentials').click(this.onToggleForm);
    $('.send-sms-credentials').click(this.onSendSMS);
    $('.push-credentials').click(this.onSendPush);
  },

  onToggleForm(event) {
    $(event.target).closest('.sms-credentials-toggler').find('.sms-credentials-toggle').toggleClass('hidden');
    $(event.target).closest('.sms-credentials-toggler').find('.sms-credentials-form').toggleClass('hidden');
    event.preventDefault();
  },

  onSendPush(event) {
    var confirmed = confirm($(event.target).data('confirmationMessage'));

    if(confirmed) {
      $.ajax({
        type: 'POST',
        url: $(this).data('path'),
        success(data) {
          alert(data['success']);
        },
        error(data) {
          alert(data.responseJSON['error']);
        }
      });
    }

    event.preventDefault();
  },

  onSendSMS(event) {
    var $element = $(event.currentTarget);

    $.ajax({
      type: 'POST',
      url: $(this).data('path'),
      data: { phone_number: $(this).parent().children('.phone')[0].value },
      success(data) {
        alert(data['success']);
        $element.parents('.access-point').find('.sms-credentials-toggle, .sms-credentials-form').toggleClass('hidden');
      },
      error(data) { alert(data.responseJSON['error']); }
    });

    event.preventDefault();
  }
};
