<template>
  <minim-modal
    :open="open"
    @close="close"
    containerStyle="width: auto; height: auto;"
    class="p-0"
  >
    <div class="inset">
      <h2>
        {{ $I18n.t("lans.show.neighbor_aps", { band: $I18n.t(radio.kind), ssids })}}
      </h2>
      <minim-graph
        :width="band === '2_4' ? 500 : 725"
        graphName='neighboring_access_points'
        :queryOpts="{
          band: band,
          unum_id: primaryUnumId,
          channel_width: chWidth || 20
        }"
        timeRangeEnabled="false"
        autoRefresh="false"
        class="time-scale graph bandwidth_graph minim-graph"
      />
      <div class="nap-legend">
        <div class="box">
        </div>
        <div class="label">
          <div class="row">
            {{ $I18n.t('current_channel')}}
          </div>
          <div class="row mini">
            {{ chWidth ? $I18n.t('lans.show.channel_width', {width: chWidth}) : $I18n.t('lans.show.estimated_channel_widths') }}
          </div>
        </div>
      </div>
    </div>
  </minim-modal>
</template>
<script>

export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },

    primaryUnumAps: {
      type: Array,
      required: true
    },

    primaryUnumId: {
      type: String,
      required: true
    },

    primaryUnumRadios: {
      type: Array,
      required: true
    },

    radio: {
      type: Object,
      required: true
    }
  },

  computed: {
    band(){
      return this.radio.kind.replace('wifi_', '');
    },

    radiosWithSameKind() {
      let radios = this.primaryUnumRadios.filter((r) => r.kind === `wifi_${this.band}`);
      return radios;
    },

    ssids() {
      let matchedAps = this.primaryUnumAps.filter((ap) => {
        return this.radiosWithSameKind.map((r) => {
          r.id === ap.radio_id;
        });
      });

      let ssids = matchedAps.map((ap) => {
        return ap.ssid;
      }).join(', ');

      return ssids;
    },

    chWidth(){
      // return the first channel width that isn't a null or undefined value
      return this.radiosWithSameKind.map((r) => r.channel_width).filter((width) => width !== 'undefined' || width !== null)[0];
    }
  },

  methods: {
    close(){
      this.$emit('close');
    }
  }
};
</script>

