<template>
  <div @click="$emit('click')">
    <div
      v-if="disabled"
      :content="tooltipText"
      v-tippy="{
        placement: 'top-center',
        flip: false,
        onShow: () => !!this.tooltipText // dont show anything if there's no tooltip
      }"
    >
      <button slot="trigger" class="add-radio-button" disabled>
        {{ `+ ${$I18n.t('components.add_band', { 'kind': $I18n.t(radio.kind) })}` }}
      </button>
    </div>

    <button v-else class="add-radio-button">
      {{ `+ ${$I18n.t('components.add_band', { 'kind': $I18n.t(radio.kind) })}` }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    radio: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: true
    },

    isGuest: {
      type: Boolean,
      required: true
    },

    maxApsPerRadio: {
      type: Number,
      required: true
    },

    maxGuestApsPerRadio: {
      type: Number,
      required: true
    }
  },

  computed: {
    ...mapGetters('LanStore', ['lanSupportsFeature', 'lanIsOnline']),
    ...mapGetters('AccessPointStore', [
      'accessPointCountForRadio',
      'guestAccessPointCountForRadio',
      'standardAccessPointCountForRadio'
    ]),

    disabled() {
      return (
        this.readOnly
        || !this.lanIsOnline
        || this.exceededMaxApsForRadio
        || this.disobeysGuestOnlyFlag
        || this.disableDueToGuestMaxLimit
      );
    },

    exceededMaxApsForRadio() {
      return this.maxApsPerRadio <= this.accessPointCountForRadio(this.radio.id);
    },

    disobeysGuestOnlyFlag() {
      if (this.isGuest) return false;

      return this.lanSupportsFeature('guest_network_only') && this.standardAccessPointCountForRadio(this.radio.id) >= 1;
    },

    exceededMaxGuestApsForRadio() {
      return this.maxGuestApsPerRadio <= this.guestAccessPointCountForRadio(this.radio.id);
    },

    disableDueToGuestMaxLimit() {
      return this.exceededMaxGuestApsForRadio && this.isGuest;
    },

    tooltipText() {
      const radioKind = this.$I18n.t(this.radio.kind);

      if (this.readOnly) return this.$I18n.t('lans.edit.read_only_user');
      if (!this.lanIsOnline) return this.$I18n.t('lans.edit.add_ap_button_disabled_tooltips.lan_offline');
      if (this.exceededMaxApsForRadio) return this.$I18n.t('lans.edit.add_ap_button_disabled_tooltips.max_aps_exceeded', { radio_kind: radioKind });
      if (this.disableDueToGuestMaxLimit) return this.$I18n.t('lans.edit.add_ap_button_disabled_tooltips.max_guest_aps_exceeded', { radio_kind: radioKind });
      if (this.disobeysGuestOnlyFlag) return this.$I18n.t('lans.edit.add_ap_button_disabled_tooltips.max_primary_aps_exceeded', { radio_kind: radioKind });
      return '';
    }
  }
};
</script>
