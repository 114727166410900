<template>
  <minim-mobile-toggle
    @click="onClick"
    :active="networkEnabled"
    :showLabels="false"
    :disableCursor="editingDisabled || supportsWirelessAlwaysEnabled"
    :content="$I18n.t('router_configs.wireless_always_enabled_tooltip')"
    size="large"
    style="margin: 0;"
    v-tippy="{
      placement: 'top-center',
      flip: false,
      onShow: () => !editingDisabled && supportsWirelessAlwaysEnabled
    }"
  />
</template>

<script>
import MinimMobileToggle from 'mobile/shared/components/global/minim_mobile_toggle';

export default {
  components: {
    'minim-mobile-toggle': MinimMobileToggle
  },

  props: {
    accessPoints: {
      type: Array,
      required: true
    },

    radios: {
      type: Array,
      required: true
    },

    editingDisabled: {
      type: Boolean,
      required: true
    },

    supportsWirelessAlwaysEnabled: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    networkEnabled() {
      return this.accessPoints.some(ap => {
        const radio = this.radios.find(radio => radio.id === ap.radio_id);

        return ap.enabled && radio.config_enabled;
      });
    }
  },

  methods: {
    onClick() {
      if (this.editingDisabled || this.supportsWirelessAlwaysEnabled) return;

      this.$emit('click', !this.networkEnabled);
    }
  }
};
</script>
