import MinimCable from 'shared/lib/minim_cable';
import $ from 'jquery';

export default {
  selector: '.cable-subscriber',

  init() {
    $.each($('.cable-subscriber'), (_, target) => {
      var id = $(target).data('id');
      var resource = $(target).data('resource');
      var subscriber = $(target).data('subscriber') + 'Subscriber';

      MinimCable.add(id, resource, subscriber, target);

    });
  },

  destroy() {
    MinimCable.unload();
  }
};
