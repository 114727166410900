import Vue from 'vue';
import minim_api from 'mobile/shared/utils/minim_api';
import noKindImage from '../../../../assets/images/no-kind.png';

const nullHardwareKind = {
  id: null,
  name: 'Unknown',
  hardware_image: noKindImage,
  is_unmanaged: false,
  is_modem: false
};

function isGreaterThanFifteenMinutesAgo(timestamp) {
  let currentTime = new Date();
  let queryTimeDifference = Math.floor((currentTime - timestamp)/60000);
  if(queryTimeDifference > 15) {
    return true;
  }
  return false;
}

export default {
  namespaced: true,

  state: {
    hardware_kinds: [],
    last_fetch_at_timestamps: {}
  },

  mutations: {
    set(state, data) {
      let i = state.hardware_kinds.findIndex((hk) => (hk.id === data.id)); // If the index is not found, push it in at the end
      i = (i !== -1) ? i : state.hardware_kinds.length;
      Vue.set(state.hardware_kinds, i, data);
    },

    setLastFetchAtTimestamp(state, hardwareKindKey) {
      let queryTime = new Date();
      state.last_fetch_at_timestamps[hardwareKindKey] = queryTime.getTime();
      return state;
    }
  },

  actions: {
    // Fetches all hardware kinds with is_public set to true
    // See controller for filtering logic
    async index(context) {
      let hardwareKinds = context.state.hardware_kinds;

      const hardwareKindId = 'all_hardware_kinds';
      const timestamp = context.state.last_fetch_at_timestamps[hardwareKindId];

      if (!timestamp || isGreaterThanFifteenMinutesAgo(timestamp)) {
        hardwareKinds = await minim_api.multiget('api/v1/hardware_kinds');

        // Save the last time we fetched all hw kinds so we can avoid doing unecesssary index requests
        context.commit('setLastFetchAtTimestamp', hardwareKindId);

        // Save each hw kind and last fetched time for each hw kind to the store
        // We commit these individually to make sure each individual hw kind has
        // its last fetched timestamp set and to avoid overwriting any non-public
        // hw kinds currently saved in the store
        hardwareKinds.forEach(hk => {
          context.commit('set', hk);
          context.commit('setLastFetchAtTimestamp', hardwareKindId);
        });
      }

      return hardwareKinds;
    },

    async show(context, hardwareKindId){
      if(!hardwareKindId) {
        return nullHardwareKind;
      }

      let hardwareKind = null;
      const timestamp = context.state.last_fetch_at_timestamps[hardwareKindId];
      if (!timestamp || isGreaterThanFifteenMinutesAgo(timestamp)) {
        hardwareKind = (await minim_api.get(`api/v1/hardware_kinds/${hardwareKindId}`)).data;
        context.commit('setLastFetchAtTimestamp', hardwareKindId);
        context.commit('set', hardwareKind);
      }else {
        hardwareKind = context.getters.getHardwareKind(hardwareKindId);
      }

      return hardwareKind;
    }
  },

  getters: {
    getHardwareKind: (state) => (hardwareKindId) => {
      if(!hardwareKindId) {
        return nullHardwareKind;
      }
      return state.hardware_kinds.find(hardware_kind => hardware_kind.id === hardwareKindId);
    },

    currentOnboardingHardwareKind(_state, getters, rootState) {
      const hardwareKindId = rootState.OnboardingStore.currentHardwareKindId;

      if (!hardwareKindId) {
        return nullHardwareKind;
      }

      return getters.getHardwareKind(hardwareKindId);
    }
  }
};
