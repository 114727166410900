import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';
Vue.use(Vuex);

import AccessPointStore from './stores/access_point_store';
import LanStore from './stores/lan_store';
import RouterConfigsEditModeStore from './stores/router_configs_edit_mode_store';

const storeConfig = {
  state() {
    return {};
  },

  modules: {
    AccessPointStore,
    LanStore,
    RouterConfigsEditModeStore
  }
};

const store = new Vuex.Store(storeConfig);
window.store = store;
export default store;
