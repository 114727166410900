import AlertBox from './alert-box.vue';
import LanActivityFeed from './lan_activity_feed.vue';
import MinimGraph from './minim_graph.vue';
import MinimModal from './minim_modal.vue';
import NumberStat from './number_stat.vue';
import PingFloodTest from './ping_flood_test.vue';
import StatusIcon from './status-icon.vue';
import DeviceList from './device-list.vue';
import SpeedTest from './speed-test.vue';
import MinimDeviceIcon from './minim_device_icon.vue';
import TrafficGraph from './dns_traffic_graph/traffic_graph.vue';
import CopyToClipboard from './copy_to_clipboard.vue';

export default {
  'alert-box': AlertBox,
  'lan-activity-feed': LanActivityFeed,
  'minim-graph': MinimGraph,
  'minim-modal': MinimModal,
  'number-stat': NumberStat,
  'ping-flood-test': PingFloodTest,
  'status-icon': StatusIcon,
  'device-list': DeviceList,
  'speed-test': SpeedTest,
  'minim-device-icon': MinimDeviceIcon,
  'traffic-graph': TrafficGraph,
  'copy-to-clipboard': CopyToClipboard
};
