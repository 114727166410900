/**
 * Takes in an object and returns a new object that only includes a selected set of properties from the original.
 *
 * @param {Object} obj - the object you want to extract the properties from
 * @param {Array} properties - the names of the properties you want to extract into the new object
 */
export default function extractProperties(object = {}, properties = []) {
  return properties.reduce((newObject, property) => {
    if (object && Object.prototype.hasOwnProperty.call(object, property)) {
      newObject[property] = object[property];
    }

    return newObject;
  }, {});
}
