<template>
  <div class="router-config-version" :data-test-id="'lan-event-' + this.eventData.id">
    <lan-activity-timestamp :timestamp="this.eventData.created_at" />
    <div class='event-body'>
      <div class='event-type'>{{ eventData.title }}</div>
      <h2
        v-for="(diff, index) in eventData.custom_data.parsed_diffs"
        :key="`config-diff-${index}`"
      >
        {{ diff }}
      </h2>
      <h2 v-if="eventData.custom_data.parsed_diffs.length === 0">{{ $I18n.t('timeline.config_change') }}</h2>
      <div class='meta'>
        <div class='meta-item'>
          <i class='far fa-user'></i>
          {{ eventData.custom_data.user || 'User' }}
        </div>
      </div>
      <a :href="details_url" data-featherlight>
        <i class='fas fa-search-plus'></i>
      </a>
    </div>
  </div>
</template>

<script>
import LanActivityTimestamp from './lan_activity_timestamp.vue';

export default {
  props: ['lanId', 'eventData'],

  computed: {
    details_url() {
      return `/lans/${this.lanId}/lan_events/${this.eventData.id}`;
    }
  },

  components: {
    'lan-activity-timestamp': LanActivityTimestamp
  }
};
</script>
