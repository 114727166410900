import $ from 'jquery';

export default {
  addStripeCardInputToForm(formId, elementId, errorsId) {
    var elements = window.stripe.elements();
    var card = elements.create('card', { style: { base: { fontSize: '18px' } } });
    card.mount('#' + elementId);
    card.addEventListener('change', function(event) {
      document.getElementById(errorsId).textContent = event.error ? event.error.message : '';
      $('#' + formId + ' input[type=submit]')[0].disabled = false;
    });

    var form = document.getElementById(formId);
    form.addEventListener('submit', function(event) {
      event.preventDefault();

      window.stripe.createToken(card).then(function(result) {
        if (result.error) {
          document.getElementById(errorsId).textContent = result.error.message;
        } else {
          var hiddenInput = document.createElement('input');
          hiddenInput.setAttribute('type', 'hidden');
          hiddenInput.setAttribute('name', 'stripeToken');
          hiddenInput.setAttribute('value', result.token.id);
          form.appendChild(hiddenInput);

          form.submit();
        }
      });
    });
  }
};
