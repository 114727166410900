<template>
  <div>
    <minim-tooltip :tooltipPlacement="tooltipPlacement" :tooltipAlignment="tooltipAlignment">
      <i
        @click="openModal"
        class="minim-mobile-icon minim-icon-cp-reset minim-score-reset-icon"
        :class="disabled ? 'reset-disabled' : null"
        slot="hover-content"
      />
      <p slot="tooltip-content">{{ tooltipText }}</p>
    </minim-tooltip>

    <minim-modal
      :showCloseButton="false"
      :open="modalIsOpen"
      @close="closeModal"
      containerStyle="width: 588px; height: 260px; padding-top: 20px;"
    >
      <div class="minim-confirm-modal">
        <h2>{{ $I18n.t('minim_factor.reset_confirm_header') }}</h2>
        <p>{{ $I18n.t('minim_factor.reset_confirm_description') }}</p>
        <button @click="resetScore" class="submit m-r-12">{{ $I18n.t('minim_factor.reset_confirm_button') }}</button>
        <button @click="closeModal" class="cancel">{{ $I18n.t('minim_factor.reset_cancel_button') }}</button>
      </div>
    </minim-modal>
  </div>
</template>

<script>
import axios from 'axios';
import MinimTooltip from 'dashboard/components/shared/minim_tooltip';

export default {
  components: {
    'minim-tooltip': MinimTooltip
  },

  data() {
    return {
      modalIsOpen: false,
    };
  },

  props: {
    lanId: {
      type: String,
      required: true
    },

    tooltipPlacement: {
      type: String,
      default: 'above'
    },

    tooltipAlignment: {
      type: String,
      default: 'center'
    },

    lanIsOnline: {
      type: Boolean,
      required: true
    },

    readOnlyUser: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    disabled() {
      return this.readOnlyUser || !this.lanIsOnline;
    },

    tooltipText() {
      if (this.readOnlyUser) return this.$I18n.t('minim_factor.reset_minim_score_tooltip.disabled_read_only');
      if (!this.lanIsOnline) return this.$I18n.t('minim_factor.reset_minim_score_tooltip.disabled_lan_offline');

      return this.$I18n.t('minim_factor.reset_minim_score_tooltip.default');
    }
  },

  methods: {
    openModal(e) {
      // The reset button is used within links. This is needed to prevent click events
      // on the button from triggering the links it's nested within.
      e.preventDefault();
      e.stopPropagation();

      if (this.disabled) return;

      this.modalIsOpen = true;
    },

    closeModal() {
      this.modalIsOpen = false;
    },

    async resetScore() {
      try {
        await axios.post(`/api/v1/lans/${this.lanId}/minim_score/reset.json`);
      } catch (error) {
        console.log(error);
      }

      window.location.reload();
    }
  }
};
</script>
