var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('minim-graph',{attrs:{"width":"988","graphName":"service_traffic_bandwidth_by_device","queryOpts":{ lan_id: _vm.lanId },"timeRangeEnabled":true,"autoRefresh":false,"isMultiseries":true,"optionsTweaks":{
      chart: {
        height: 700
      },
      plotOptions: {
        pie: {
          size: 420,
          dataLabels: {
            formatter: function() {
              return _vm.dataLabelFormatter(this);
            }
          }
        }
      }
    }},on:{"chart":_vm.handleChartUpdate}}),_vm._v(" "),_c('traffic-graph-legend',{attrs:{"chart":_vm.chart}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }