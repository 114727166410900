<template>
  <div class="unum flex-container column justify-between">
    <div>
        <div class="flex-container justify-between align-center">
          <div class="flex-container m-b-24" :class="device_type === 'Extender' ? 'align-end' : 'align-center'">
            <div class="icon flex-container column justify-between align-center p-r-20">
              <connectivity
                v-if="device_type === 'Extender'"
                :kind="interfaceKind"
                :signal_strength="signalStrength"
                :active="unum.active"
                :smallIcon="true"
                :device="device"
              />
              <img v-if="unum.hardware_kind_image" @click='on_router_image_click' class='hardware_thumb m-t-8' :src="unum.hardware_kind_image"/>
              <div v-else class='text-icon m-t-10'>
                ?
              </div>
            </div>
            <div class="flex-container column p-b-8">
              <div class="flex-container align-center">
                <h3 style="margin-right: 10px; line-height: 30px; max-width: 600px" class="minim-mobile-text primary-font medium-large bold truncate">
                  {{unum.name}}
                </h3>
                <h4 class="device-type" style="margin-bottom: 0">{{device_type}}</h4>
              </div>
              <div class="connectivity">
                <div v-if="unum.is_unmanaged" class="flex-container align-center">
                  <minim-icon
                    iconName="unmanaged-device-16"
                    color="unmanaged"
                    size="xx-small"
                  />
                  <template>
                    <span class="m-l-6 p-t-2"> {{ $i18n.t('unmanaged') }}</span>
                  </template>
                </div>
                <div v-else>
                  <i :class="`m-r-10 fas fa-circle ${unum_status}`" />

                  <!-- unum is active, actual uptime (or just the word "Online" if no uptime) -->
                  <template v-if="unum.active">
                    <span v-if="unum.uptime">{{$I18n.t('unums_index.uptime', { "since": unum.uptime })}}</span>
                    <span v-else>{{ $I18n.t('online') }}</span>
                    <br>

                  </template>
                  <!-- unum is not active, "Last active 5 days ago" -->
                  <template v-else>
                    {{last_active}}
                    <br>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-container justify-between">
            <button
              v-if="unum.active"
              @click="on_reboot"
              class="settings-button"
              :class="unum.is_rebooting? `grow ${button_background_class}` : button_background_class"
              style="margin-right: 10px"
              :style="unum.is_rebooting ? 'background: #ffffff': ''"
              :data-test-id="'reboot-unum-' + unum.id.replace(/:/g, '')"
              v-tippy="{ placement: 'top-center' }"
              :content="$I18n.t('unums_index.reboot')"
              :disabled="unum.is_rebooting"
            >
              <minim-icon
                iconName="refresh"
                :class="unum.is_rebooting ? 'rotate' : ''"
              />
              <p v-if="unum.is_rebooting" class="p-8" style="color: #777777">
                {{ $I18n.t('unums_index.rebooting') }}
              </p>
            </button>
            <button
              v-if="should_show_ssh"
              @click="on_ssh_session"
              class="settings-button"
              :class="button_background_class"
              style="margin-right: 10px"
              :data-test-id="'reboot-unum-' + unum.id.replace(/:/g, '')"
              v-tippy="{ placement: 'top-center' }"
              :content="$I18n.t('unums_index.configure_ssh')"
            >
              <minim-icon
                iconName="ssh-24"
              />
            </button>
            <button
              @click='on_unum_edit'
              class="settings-button"
              :class="button_background_class"
              :data-test-id="'edit-unum-' + unum.id.replace(/:/g, '')"
              v-tippy="{ placement: 'top-center' }"
              :content="$I18n.t('settings')"
            >
              <minim-icon
                iconName="nav-settings"
              />
            </button>
          </div>
        </div>
      </div>

      <div class="managed-devices-inset unums-index inset">

        <div class="managed-devices-container">
          <div>
            <h4 class="minim-mobile-text primary-font medium less-bold"> {{ $I18n.t('unums_index.device')}}</h4>
            <div class="flex-container column" style="margin-top: 20px">
              <div class="model" v-if="hardwareKindName"><strong class="label"> {{ $I18n.t('model') }}: </strong><span> {{ hardwareKindName }} </span></div>
              <div class="configuration"><strong class="label"> {{ $I18n.t('unums_index.configuration') }}: </strong><span> {{ device_type }} </span></div>
              <copy-and-paste-field :label="$I18n.t('mac_addr')" :value="unum.id" styleClass="mac_address" />

              <copy-and-paste-field v-if="unum.serial_number" :label="$I18n.t('serial_number')" :value="unum.serial_number" styleClass="serial_number" />

              <firmware-version :unum="unum" :lanId="lanId" :disableCommandButtons="disableCommandButtons" :businessPortal="businessPortal" @unum-updating="unumIsUpdating" @update-unum="updateUnum"/>
              <div v-if="unum.unum_agent_version" class="unum_agent_version"><strong class="label">{{$I18n.t('unum_agent_version')}}:</strong> <span>{{unum.unum_agent_version}}</span></div>

              <template v-if="unum.is_lte_modem && unum.lte_metrics">
                <a @click="show_lte_metrics = true" style="margin-left: 0; margin-bottom: 10px; font-weight: 600; cursor: pointer">{{ $I18n.t('unums_index.view_lte' )}}</a>
                <minim-modal
                  :open="show_lte_metrics"
                  @close="show_lte_metrics = false"
                  containerStyle="width: 435px; height: 505px; padding: 25px;"
                >
                  <div>
                    <h4 class="minim-mobile-text primary-font large p-t-10 p-l-10 m-b-24">{{ $I18n.t('unums_index.lte_metrics')}}</h4>
                    <div v-for="(val, key) in desired_lte_metrics" :key="key" class="p-l-10 m-b-10"><strong class="label">{{key}}:</strong> <span>{{val}}</span></div>
                  </div>
                </minim-modal>
              </template>
            </div>
          </div>
          <div>
            <h4 class="minim-mobile-text primary-font medium less-bold"> {{ $I18n.t('unums_index.connections')}}</h4>
            <div class="flex-container column" style="margin-top: 20px">
              <copy-and-paste-field v-if="unum.lan_ip_v4" :label="$I18n.t('unums_index.internal_ipv4')" :value="(unum.lan_ip_v4)" styleClass="internal_ip_address" />

              <copy-and-paste-field v-if="unum.is_gateway && unum.wan_ip_v4" :label="$I18n.t('unums_index.external_ipv4')" :value="unum.wan_ip_v4" styleClass="internal_ip_address" />

              <copy-and-paste-field v-if="unum.lan_primary_ip_v6" :label="$I18n.t('unums_index.primary_lan_ipv6')" :value="unum.lan_primary_ip_v6" :isIpv6="true" styleClass="internal_ip_address" />

              <copy-and-paste-field v-if="unum.is_gateway && unum.wan_primary_ip_v6" style="width: 300px" :label="$I18n.t('unums_index.primary_wan_ipv6')" :value="unum.wan_primary_ip_v6" :isIpv6="true" styleClass="internal_ip_address" />

              <div v-for="value in unum.lan_other_ip_v6" :key="value">
                <copy-and-paste-field :label="$I18n.t('unums_index.global_ipv6')" :value="value" :isIpv6="true" styleClass="internal_ip_address" />
              </div>
              <div v-for="value in unum.wan_other_ip_v6" :key="value">
                <copy-and-paste-field :label="$I18n.t('unums_index.global_ipv6')" :value="value" :isIpv6="true" styleClass="internal_ip_address" />
              </div>
              <div v-if="unum.is_gateway && unum.ipv6_prefix_delegations">
                <div v-for="prefix in unum.ipv6_prefix_delegations" :key="prefix">
                  <copy-and-paste-field :label="$I18n.t('unums_index.ipv6_prefix_delegation')" :value="prefix" :isIpv6="true" styleClass="internal_ip_address" />
                </div>
              </div>
            </div>
          </div>

          <div>
            <h4 class="minim-mobile-text primary-font medium less-bold"> {{ $I18n.t('unums_index.radios')}}</h4>
            <div class="flex-container column align-start">
              <div v-if="!wired_connections_only" class="radio radio-2_4">
                <button @click="changeFilter('wifi_2_4')" class="radio-button">
                  <div class="flex-container justify-between align-center">
                    <p  class="minim-mobile-text secondary-font medium bold" style="color: white">{{$I18n.t('wifi_2_4')}}</p>
                    <div class="flex-container align-center">
                      <number-stat :blob="blob" :number="deviceCount('2_4')" :icon="null" :label="deviceCount('2_4') > 1 || deviceCount('2_4') < 1  ? $I18n.t('unums_index.devices') : $I18n.t('unums_index.device').toLowerCase()"></number-stat>

                      <div v-if="signalIssuesCount('2_4') != 0" class="flex-container align-center">
                        <span style="color: white">→</span>
                        <number-stat :blob="blob" :number="signalIssuesCount('2_4')" :label="$I18n.t('unums_index.with_low_signal')"></number-stat>
                      </div>

                    </div>
                  </div>
                </button>
              </div>

              <div v-if="!wired_connections_only" class="radio radio-5">
                <button class="radio-button">
                  <div @click="changeFilter('wifi_5')" class="flex-container justify-between align-center">
                    <p class="minim-mobile-text secondary-font medium bold"  style="color: white">
                      {{$I18n.t('wifi_5')}}
                    </p>
                    <div class="flex-container align-center">
                      <number-stat :blob="blob" :number="deviceCount('5')" :icon="null" :label="deviceCount('5') > 1 || deviceCount('5') < 1 ? $I18n.t('unums_index.devices') : $I18n.t('unums_index.device').toLowerCase()"></number-stat>

                      <div v-if="signalIssuesCount('5') != 0" class="flex-container align-center">
                        <span style="color: white">→</span>
                        <number-stat :blob="blob" :number="signalIssuesCount('5')" :label="$I18n.t('unums_index.with_low_signal')"></number-stat>
                      </div>
                    </div>
                  </div>
                </button>
              </div>

              <div v-if="!wired_connections_only && supports6ghz" class="radio radio-6" >
                <button @click="changeFilter('wifi_6')" class="radio-button">
                  <div class="flex-container justify-between align-center">
                    <p  class="minim-mobile-text secondary-font medium bold" style="color: white">
                      {{$I18n.t('wifi_6')}}
                    </p>
                    <div class="flex-container align-center">
                      <number-stat :blob="blob" :number="deviceCount('6')" :icon="null" :label="deviceCount('6') > 1 || deviceCount('6') < 1 ? $I18n.t('unums_index.devices') : $I18n.t('unums_index.device').toLowerCase()"></number-stat>

                      <div v-if="signalIssuesCount('6') != 0" class="flex-container align-center">
                        <span style="color: white">→</span>
                        <number-stat :blob="blob" :number="signalIssuesCount('6')" :label="$I18n.t('unums_index.with_low_signal')"></number-stat>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

    </div>
  </div>
</template>

<script>
import moment from 'moment';
import $ from 'jquery';
import axios from 'axios';
import { mapGetters } from 'vuex';
import Connectivity from '../devices/connectivity.vue';
import FirmwareVersion from '../unums/firmware_version.vue';
import CopyPasteField from './copy_and_paste_field.vue';

export default {
  props: ['unum', 'devices', 'lan', 'lanId', 'disableCommandButtons', 'businessPortal', 'supports6ghz'],

  data() {
    return {
      show_lte_metrics: false,
      blob: null,
      hardwareKindName: null
    };
  },

  created() {
    if(this.unum.hardware_kind_id) {
      this.loadHardwareKind(this.unum.hardware_kind_id);
    }
  },

  async mounted(){
    await this.$store.commit('LanStore/setLan', this.lan);
  },

  methods: {
    on_router_image_click(e) {
      $.featherlight($(e.srcElement));
    },

    unumIsUpdating(data) {
      this.$emit('unum-updating',data);
    },

    updateUnum(data) {
      this.$emit('update-unum',data);
    },

    on_unum_edit() {
      $.featherlight(`/lans/${this.lanId}/unums/${this.unum.id}/edit`);
    },

    on_number_click() {
      if(this.blob) {
        let scroll_time = 350;
        $('html, body').animate({
          scrollTop: $('.device-section').offset().top - 100
        }, scroll_time);

        setTimeout(() => {
          this.$emit('change', this.blob);
        }, scroll_time);
      }
    },
    changeFilter(kind){
      this.blob = {
        filters: { selected_toggle: kind, selected_unum: this.unum.id }
      };
      this.on_number_click();
    },

    async on_ssh_session() {
      if(this.disableCommandButtons) {
        return;
      }
      if (confirm(this.$I18n.t('unums_index.webssh_confirm'))) {
        try {
          let resp = await axios.post(`/lans/${this.lanId}/unums/${this.unum.id}/start_webssh_session`);
          window.open(`${resp.data.public_endpoint}/ssh/token_session/${resp.data.token}`, '_blank');
        } catch (err) {
          this.handle_api_error(err, this.$I18n.t('components.error_webssh'));
          return;
        }
      }
    },

    async on_reboot() {
      if(this.disableCommandButtons) {
        return;
      }
      if (confirm(this.$I18n.t('unums_index.reboot_confirm', { 'who': this.$I18n.t(this.businessPortal ? 'customer' : 'user').toLowerCase() }))) {
        this.emitRebootingEvent(true);
        try {
          await axios.post(`/api/v1/lans/${this.lanId}/unums/${this.unum.id}/reboot.json`);
          this.pollUnum();
        } catch (err) {
          this.emitRebootingEvent(false);
          this.handle_api_error(err);
        }
      }
    },

    handle_api_error(err, fallback_msg) {
      if((err.response || {}).status === 401) {
        window.alert(`${this.$I18n.t('errors.unauthorized.title')}\n${this.$I18n.t('errors.unauthorized.body', { 'what': this.$I18n.t(this.business_portal ? 'account' : 'isp') })}`);
      } else {
        window.alert(fallback_msg || this.$I18n.t('errors.unknown'));
      }
    },

    deviceCount(band){
      let deviceCount = 0;
      let deviceEntries = Object.entries(this.devices);

      deviceEntries.map(device => {
        if((device[1]['interface_kind'] === `wifi_${band}`) && (device[1]['unum_id'] === this.unum.id)){
          deviceCount += 1;
        }
      });
      return deviceCount;
    },

    signalIssuesCount(band){
      let issueCount = 0;
      let fifteenMinutes = 900000;
      let deviceEntries = Object.entries(this.devices);

      deviceEntries.map(device => {
        let lastActiveMilliseconds = device[1]['last_active'] * 1000;
        let isOnline = lastActiveMilliseconds > (Date.now() - fifteenMinutes);

        if((isOnline) && (device[1]['interface_kind'] === `wifi_${band}`) && (device[1]['unum_id'] === this.unum.id) && (device[1]['signal'] <= -72)){
          issueCount += 1;
        }
      });
      return issueCount;
    },

    async loadHardwareKind(hardwareKindId) {
      this.hardwareKindName = (await axios.get(`api/v1/hardware_kinds/${hardwareKindId}`)).data.name;
    },

    pollUnum(){
      this.polling = setInterval(async () => {
        let unum =  (await axios.get(`/api/v1/lans/${this.lanId}/unums/${this.unum.id}.json`)).data;
        if(!unum.is_rebooting){
          clearInterval(this.polling);
          this.emitRebootingEvent(false);
        }
      }, 3000);
    },

    emitRebootingEvent(rebooting) {
      this.$emit('unum-rebooting', { id : this.unum.id, is_rebooting: rebooting });
    }
  },

  computed: {
    ...mapGetters('LanStore', ['lanSupportsFeature']),

    device() {
      return this.devices[this.unum.device_id] || {};
    },

    interfaceKind() {
      return this.device.interface_kind;
    },

    signalStrength(){
      return this.device.signal;
    },

    device_type() {
      if(this.unum.is_gateway && this.unum.is_lte_modem) {
        return `${this.$I18n.t('unums_index.gateway')} | ${this.$I18n.t('unums_index.lte_modem')}`;
      } else if(this.unum.is_gateway) {
        return this.$I18n.t('unums_index.gateway');
      } else if(this.unum.is_lte_modem) {
        return this.$I18n.t('unums_index.lte_modem');
      } else if(this.unum.is_bridge) {
        return this.$I18n.t('unums_index.bridge');
      } else {
        return this.$I18n.t('unums_index.extender');
      }
    },

    desired_lte_metrics() {
      let data = Object.assign({}, this.unum.lte_metrics, this.unum.lte_metrics.connections[0]);

      delete data['created_at'];
      delete data['lan_id'];
      delete data['unum_key'];
      delete data['unum_id'];
      delete data['isp_id'];
      delete data['connections'];
      delete data['is_primary'];

      return data;
    },

    wired_connections_only() {
      return this.unum.is_bridge || ( !this.unum.is_gateway && this.unum.is_lte_modem );
    },

    unum_status() {
      return (this.unum.active ? 'status-ok' : 'status-alert');
    },

    button_background_class() {
      return this.disableCommandButtons ? 'disabled' : 'abc';
    },

    last_active() {
      if (!this.unum.last_active_at) {
        return this.$I18n.t('unums_index.never_seen');
      } else {
        let lastActive = moment().locale(this.$I18n.locale).to(moment(this.unum.last_active_at));
        return this.$I18n.t('unums_index.last_seen_when', { 'when': lastActive });
      }
    },

    should_show_ssh() {
      // WebSSH button should be visible for MikroTiks with the isp_webssh feature flag enabled.
      return this.unum.hardware_kind_id && this.unum.hardware_kind_id.indexOf('mikrotik_') === 0
          && this.unum.features.includes('isp_webssh');
    }
  },

  components: {
    'connectivity': Connectivity,
    'firmware-version': FirmwareVersion,
    'copy-and-paste-field': CopyPasteField,
  }

};
</script>
