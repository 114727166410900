import Vue from 'vue/dist/vue.esm';
import minim_api from 'mobile/shared/utils/minim_api';

const ONE_MINUTE = 60 * 1000; // One minute in milliseconds

export default {
  namespaced: true,

  state: {
    devices: [],
    lastIndexCall: null,
    topSitesByDevice: {},
    trafficByDevice: {}
  },

  mutations: {
    many(state, data) {
      state.lastIndexCall = Date.now();
      state.devices = data.filter((device) => { return !device.is_gateway_unum; });
    },

    set_many(state, many_devices) {
      let all = [...state.devices, ...many_devices];
      return all.filter(device => all.findIndex(d => d.id === device.id) === -1 );
    },

    set(state, data) {
      let i = state.devices.findIndex((d) => (d.id == data.id));

      // If the index is not found...
      // we want to push it in at the end
      i = (i !== -1) ? i : state.devices.length;

      Vue.set(state.devices, i, data);
    },

    remove(state, deviceId) {
      let i = state.devices.findIndex((d) => (d.id == deviceId));

      if (i === -1) {
        return;
      }

      state.devices.splice(i, 1);
    },

    setTopSitesByDevice(state, { deviceId, siteData }) {
      const topSitesByDeviceCopy = { ...state.topSitesByDevice };
      topSitesByDeviceCopy[deviceId] = siteData.top_sites;
      state.topSitesByDevice = topSitesByDeviceCopy;
    },

    setTrafficByDevice(state, { deviceId, timeScale, traffic }) {
      const trafficByDeviceCopy = { ...state.trafficByDevice };

      if (!trafficByDeviceCopy[deviceId]) trafficByDeviceCopy[deviceId] = {};
      trafficByDeviceCopy[deviceId][timeScale] = traffic;

      state.trafficByDevice = trafficByDeviceCopy;
    },

    syncProfiles(state, person) {
      state.devices.forEach((device, i) => {
        if (person.device_ids.includes(device.id)) {
          Vue.set(state.devices[i], 'person_id', person.id);
        } else if (device.person_id === person.id) {
          Vue.set(state.devices[i], 'person_id', null);
        }
      });
    }
  },

  actions: {
    async index({ state, commit }) {
      // Only make a call to the index route if we haven't refreshed the data within the past minute
      if (!state.lastIndexCall || (Date.now() - state.lastIndexCall) > ONE_MINUTE ) {
        const all_devices = await minim_api.get('mobile/lans/{lan_id}/devices', { params: { app_version: 'v2' } });
        commit('many', all_devices.data);
      }
    },

    async show({ commit }, deviceId) {
      const device = (await minim_api.get(`api/v1/lans/{lan_id}/devices/${deviceId}`)).data;
      commit('set', device);
      return device;
    },

    async update({ dispatch }, device) {
      await minim_api.patch(`mobile/lans/{lan_id}/devices/${device.id}`, { device });
      await dispatch('show', device.id);
    },

    async hideAll({ commit }) {
      await minim_api.post('api/v1/lans/{lan_id}/devices/hide_all');
      commit('many', []);
    },

    async hide({ commit }, deviceId) {
      await minim_api.post(`api/v1/lans/{lan_id}/devices/${deviceId}/hide`);
      commit('remove', deviceId);
    },

    async pause({ dispatch }, data) {
      const deviceId = data.id;
      await minim_api.post(`api/v1/lans/{lan_id}/devices/${deviceId}/pause`, data);
      await dispatch('PauseStatusStore/index', deviceId, { root: true });
    },

    async unpause({ dispatch }, deviceId) {
      await minim_api.post(`api/v1/lans/{lan_id}/devices/${deviceId}/unpause`);
      await dispatch('PauseStatusStore/index', deviceId, { root: true });
    },

    async pauseNewDevices({ dispatch }) {
      await minim_api.post('mobile/lans/{lan_id}/devices/pause_new_devices');
      await dispatch('PauseStatusStore/index', null, { root: true});
    },

    async approveNewDevices() {
      await minim_api.post('mobile/lans/{lan_id}/devices/approve_new_devices');
    },

    async fetchTopSitesForDevice({ commit }, deviceId) {
      const siteData = (await minim_api.get(`api/v1/lans/{lan_id}/devices/${deviceId}/top_sites`)).data;
      commit('setTopSitesByDevice', { deviceId, siteData });
    },

    async fetchDevicesTraffic({ commit }, { deviceId, timeScale }) {
      const { traffic } = (await minim_api.get(`api/v1/lans/{lan_id}/devices/${deviceId}/traffic?time_scale=${timeScale}`)).data;
      commit('setTrafficByDevice', { deviceId, timeScale, traffic });
    }
  },

  getters: {
    getDevice: (state) => (device_id) => {
      return state.devices.find(d => (d.id === device_id));
    },

    getDevices: (state) => (deviceIds = []) => {
      return state.devices.filter(device => deviceIds.indexOf(device.id) !== -1);
    },

    getLastInterfaceTypeOfDevice: (state) => (device_id) => {
      const device = state.devices.find(d => (d.id === device_id));
      return device ? device.last_interface_type : undefined;
    },

    getDevicesForPerson: state => personId => {
      return state.devices.filter(d => d.person_id === personId);
    },

    getDeviceFromMacAddress: state => macAddress => {
      return state.devices.find(d => d.mac_address === macAddress);
    },

    getTopSitesForDevice: state => deviceId => {
      return state.topSitesByDevice[deviceId];
    },

    getTrafficForDevice: state => deviceId => {
      return state.trafficByDevice[deviceId] || {};
    },

    getNewDevices(state) {
      return state.devices.filter(d => d.approval_status === 'new_device');
    },
  }
};
