import UrlUtils from 'shared/lib/url_utils';
import Turbolinks from 'turbolinks';
import $ from 'jquery';

export default {
  selector: '.sortable-table',

  init() {
    $('.sortable-table').each((_, table) => {
      let prefix = typeof($(table).data('prefix')) == 'undefined' ? '' : $(table).data('prefix');
      let sorted = $(table).data('sorted');
      let direction = $(table).data('direction');

      $(table).find('tbody tr[data-href], tbody td[data-href]').each((_, table_element) => {
        $(table_element).addClass('clickable');

        $(table_element).one('click', (event) => {
          event.stopPropagation();
          event.preventDefault();
          Turbolinks.visit($(table_element).data('href'));
        });
      });

      $(table).find('td[data-sort="' + sorted + '"]').addClass('selected-column');

      $(table).find('th[data-sort]').each((_, column) => {
        let sort_by = $(column).data('sort');
        let location = window.location.href;

        location = UrlUtils.updateQueryParam(location, `${prefix}sort_by`, sort_by);
        location = UrlUtils.updateQueryParam(location, `${prefix}direction`, 'desc');

        if (sort_by == sorted) {
          $(column).addClass('selected-column ' + direction);
          if (direction == 'desc') {
            location = UrlUtils.updateQueryParam(location, `${prefix}direction`, 'asc');
          }
        }

        $(column).addClass('sortable').one('click', () => {
          Turbolinks.visit(location);
        });
      });
    });
  }
};
