import minim_api from 'mobile/shared/utils/minim_api';

export default {
  namespaced: true,

  state: {
    lanEvents: [],
    allEventsLoaded: false,
    curPage: 1
  },

  mutations: {
    many(state, data) {
      state.lanEvents = state.lanEvents.concat(data);
      return state;
    },

    finished(state) {
      state.allEventsLoaded = true;
    },

    incrementCurPage(state) {
      state.curPage += 1;
      return state;
    },

    resetState(state) {
      state.lanEvents = [];
      state.curPage = 1;
      state.allEventsLoaded = false;

      return state;
    }
  },

  actions: {
    async index(context, forceRefresh = false) {
      if (forceRefresh) {
        context.commit('resetState');
      }

      const events = (await minim_api.get(`lans/{lan_id}/lan_events.json?page=${context.state.curPage}`)).data;

      if (events && events.length) {
        context.commit('many', events);
        context.commit('incrementCurPage');
      } else {
        context.commit('finished');
      }

      return events;
    }
  }
};
