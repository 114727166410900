export default {
  namespaced: true,

  state: {
    lan: {}
  },

  mutations: {
    setLan(state, lan)  {
      state.lan = lan;
    },

    reset(state) {
      state.lan = {};
    }
  },

  getters: {
    lanSupportsFeature: (state) => (featureName) => {
      return (state.lan.features || []).includes(featureName);
    },

    lanIsOnline(state) {
      return state.lan.is_online;
    }
  }
};
