<template>
  <div
    v-if="disabled"
    v-tippy="{ placement: 'top-center', onShow: () => !!this.tooltipText }"
    :content="tooltipText"
  >
    <button
      slot="trigger"
      :disabled="true"
      class="generic-btn small-btn"
      style="margin: 32px auto;"
    >
      + {{ $I18n.t('components.add_network') }}
    </button>
  </div>

  <button
    data-test-id="add-network-button"
    v-else
    @click="$emit('click')"
    class="generic-btn small-btn"
    style="margin: 32px auto;"
  >
    + {{ $I18n.t('components.add_network') }}
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    radios: {
      type: Array,
      required: true
    },

    maxApsPerRadio: {
      type: Number,
      required: true
    },

    maxGuestApsPerRadio: {
      type: Number,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapGetters('LanStore', ['lanSupportsFeature', 'lanIsOnline']),
    ...mapGetters('AccessPointStore', ['accessPointsCount', 'guestAccessPointsCount']),
    ...mapGetters('RouterConfigsEditModeStore', ['editModeIsTaken', 'componentIsInEditMode']),

    maxAllowedAccessPoints() {
      return this.maxApsPerRadio * this.radios.length;
    },

    exceededMaxAccessPoints() {
      return (this.accessPointsCount >= this.maxAllowedAccessPoints);
    },

    maxAllowedGuestAccessPoints() {
      return this.maxGuestApsPerRadio * this.radios.length;
    },

    exceededMaxGuestAccessPoints() {
      return this.lanSupportsFeature('guest_network_only') && this.guestAccessPointsCount >= this.maxAllowedGuestAccessPoints;
    },

    disabled() {
      return (
        this.readOnly
        || !this.lanIsOnline
        || this.exceededMaxAccessPoints
        || this.exceededMaxGuestAccessPoints
        || this.editModeIsTaken
      );
    },

    tooltipText() {
      if (this.readOnly) return this.$I18n.t('lans.edit.read_only_user');
      if (!this.lanIsOnline) return this.$I18n.t('lans.edit.add_network_button_disabled_tooltips.lan_offline');
      if (this.exceededMaxAccessPoints || this.exceededMaxGuestAccessPoints) return this.$I18n.t('lans.edit.add_network_button_disabled_tooltips.exceeded_maximum_networks');
      if (this.editModeIsTaken) return this.$I18n.t('lans.edit.add_network_button_disabled_tooltips.edit_mode_taken');

      return null;
    }
  }
};
</script>
