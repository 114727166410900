// Toggles a form element based on a checkbox
//
// Specify the the following on the checkbox input,
//   class: 'js-form-toggler' 
//   data: {toggle_target: '.css-selector-here'}
//
import $ from 'jquery';

export default {
  selector: '.js-form-toggler',
  previous_data: '',

  init() {
    $(function() {
      $(this.selector).each((i, ele) => this.check($(ele)));
    });
    $(this.selector).change((ele) => this.check($(ele.currentTarget)));
  },

  check($ele) {
    let $target = $($ele.data('toggleTarget'));
    let message = $ele.data('toggleDisableMessage');

    if($ele[0].checked) {
      let current_val = $target.val();
      if(current_val !== message) {
        this.previous_data = current_val;
      }
      $target.val(message);
      $target.prop('disabled', true);
    } else {
      $target.val(this.previous_data);
      $target.prop('disabled', false);
    }
  }
};
