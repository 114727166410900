<template>
    <div :class="value ? 'copy-to-clipboard-parent' : ''" :style="!isIpv6 ? 'width: max-content !important;': 'margin-right: 25px;'">
        <div :class="[styleClass , value ? 'copy-to-clipboard' : '']" @click="onClickCopyToClipboard(`copy-text-${label}-${value}`, value)">
            <div :class="value ? 'margin-bottom flex-item-copy-div' : ''"><div><strong class="label">{{label}}:</strong> <span :id="`copy-text-${label}-${value}`">{{isIpv6 ? splitIpv6String(value) : value}}</span></div><i v-if="value" class="minim-mobile-icon minim-icon-copy-16 clickable"></i></div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm';
import Toasted from 'vue-toasted';
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    styleClass: {
      type: String,
    },
    isIpv6:{
      type: Boolean,
      default: false,
    },
  },

  mounted(){
    this.activateToaster();
  },

  methods: {
    activateToaster() {
      Vue.use(Toasted);

      Vue.toasted.register('minim_success', (message) => message, {
        duration: 3000,
        fullWidth: true,
        keepOnHover: true,
        closeOnSwipe: false,
        className: ['minim-toast', 'blue-toast'],
      });

      Vue.toasted.register('minim_error', (message) => message, {
        duration: 3000,
        fullWidth: true,
        keepOnHover: true,
        closeOnSwipe: false,
        className: ['minim-toast', 'pink-toast'],
      });
    },

    splitIpv6String(value) {
      return [value.slice(0, 10), '\n', value.slice(10)].join('');
    },

    onClickCopyToClipboard(elementId, value) {
      if(value) {
        this.copyClipboard(elementId, value);
      }
    },

    copyClipboard(elementId, value) {
      navigator.clipboard
        .writeText((document.getElementById(elementId).innerText))
        .then(
          () => {
            this.$toasted.global.minim_success(this.$I18n.t('mobile.copy_to_clipboard.success', {value}));
          },
          () => {
            this.$toasted.global.minim_error(this.$I18n.t('mobile.copy_to_clipboard.error'));
          }
        );
    }
  }
};
</script>
