/**
 * Used to extract the meaningful data from a VueRouter Route config object before committing it to the store.
 * It makes the data in the object JSON seriazable (needed for caching the store between app pauses/resumes) by
 * removing the circular references contained in VueRouter Route object and only returns the data that is relevant
 * to actual routing in the application.
 *
 * @param {route} - the VueRouter object we would like to extract the data from.
 */
function extractMeaningfulRouteData({ name, path, fullPath, meta, query, params }) {
  return { name, path, fullPath, meta, query, params };
}

export default {
  namespaced: true,

  state: {
    tabbed_history: {},
    current_tab: null,
    inAppBackButtonOverridePath: null,
    nativeBackButtonOverride: null // implementation in /mobile/index.js under this.native.on('backbutton')
  },

  mutations: {
    setCurrentTabName(state, tab) {
      state.current_tab = tab;
      return state;
    },

    /**
     * Takes in either a VueRouter router object, path or a function to be used
     * to override the behavior of the native android back button.
     */
    setNativeBackButtonOverride(state, override) {
      if (override && typeof override === 'object') {
        override = extractMeaningfulRouteData(override);
      }

      state.nativeBackButtonOverride = override;

      return state;
    },

    updatePathandCleanupHistory(state, route) {
      if (!state.tabbed_history[state.current_tab]) return;

      route = extractMeaningfulRouteData(route);
      state.tabbed_history[state.current_tab].route = route;

      const page_history = state.tabbed_history[state.current_tab].page_history;
      const previous_tab_route = page_history[page_history.length - 1];
      const first_tab_route = page_history[0];

      if (previous_tab_route && previous_tab_route.path == route.path) {
        state.tabbed_history[state.current_tab]['page_history'].pop();
        state.tabbed_history = Object.assign({}, state.tabbed_history);
      } else if (first_tab_route && first_tab_route.path == route.path) {
        state.tabbed_history[state.current_tab]['page_history'] = [];
        state.tabbed_history = Object.assign({}, state.tabbed_history);
      }

      return state;
    },

    setInitialHistoryForTab(state, { to, from, initialize_with_from_route }) {
      if (initialize_with_from_route) {
        state.tabbed_history[state.current_tab] = { page_history: [extractMeaningfulRouteData(from)], path: extractMeaningfulRouteData(to) };
      } else {
        state.tabbed_history[state.current_tab] = { page_history: [], path: extractMeaningfulRouteData(to) };
      }

      return state;
    },

    pushTabPath(state, { path, tab_name }) {
      if (!tab_name) {
        tab_name = state.current_tab;
      }
      path = extractMeaningfulRouteData(path);
      state.tabbed_history[tab_name]['page_history'].push(path);
      state.tabbed_history = Object.assign({}, state.tabbed_history);
      return state;
    },

    popTabPath(state) {
      state.tabbed_history[state.current_tab]['page_history'].pop();
      state.tabbed_history = Object.assign({}, state.tabbed_history);
      return state;
    },

    setInAppBackButtonOverridePath(state, path) {
      state.inAppBackButtonOverridePath = path;
      return state;
    }
  },

  actions: {
    /**
     * Used to pre-process routes and manage the recording of history in the router.before() hook.
     *
     * @param {object} context - deconstructed vuex context.
     * @param {object} routes - deconstructed object containing the routes that we're navigating to and from.
     */
    processRoute({ state, commit }, { to, from }) {
      // Ensure that no "stale" override behavior hangs around after navigating away from a page that sets a custom override path.
      commit('setNativeBackButtonOverride', null);
      commit('setInAppBackButtonOverridePath', null);

      // Track the old tab name in case we switch tabs
      const old_tab = state.current_tab;

      // If a tab name has been provided with the route, set that as the current tab
      const tab_name = to.query.tab_name;
      if (tab_name) commit('setCurrentTabName', tab_name);

      // If we've specified that we don't want to save history, quit here.
      if (to.query.skip_history) return;

      // If we were on a tab and we're switching to a new tab, we should explicitly track the
      // 'from' route in the old tab's history.
      if (tab_name && old_tab && old_tab !== tab_name) {
        if(tab_name == 'router' && old_tab == 'onboarding') commit('popTabPath');
        commit('pushTabPath', { path: from, tab_name: old_tab });
      }

      // If we don't have any history stored for the current tab, use the route we're going to, to initialize the history.
      if (!state.tabbed_history[state.current_tab]) {
        commit('setInitialHistoryForTab', { to, from, initialize_with_from_route: to.query.initialize_with_from_route });
        return;
      }

      // Start recording history.
      if (!tab_name) {
        const current_tab_page_history = state.tabbed_history[state.current_tab].page_history;
        const last_tab_page = current_tab_page_history[current_tab_page_history.length - 1];

        if (last_tab_page && to.path == last_tab_page.path) {
          commit('popTabPath');
        } else {
          // Don't push onboarding routes onto the back stack
          if (!from.path.includes('/onboarding')) {
            commit('pushTabPath', { path: from });
          } else {
            // without this, a back button shows up to route the user to the same page they're on
            commit('popTabPath');
          }
        }
      }
    },

    /**
     * Sends a route to the native app that custom apps can use to override routing.
     * The Zoom app is currently dependent on this so do not delete it.
     */
    saveRoute(_, route) {
      window.native.exec('set_volatile_config_item', { key: 'current_path', value: route.fullPath });
    }
  },

  getters: {
    getCurrentTab: (state) => {
      return state.current_tab;
    },

    getTabPath: (state) => (tab) => {
      if(state.tabbed_history[tab]) {
        return state.tabbed_history[tab]['route'];
      }
    },

    getLastTabPath: (state) => (tab) => {
      if(state.tabbed_history[tab]) {
        const history = state.tabbed_history[tab].page_history;
        return history[history.length - 1];
      }
    },

    /**
     * Checks to see if there is page history for the current tab. If so, it'll return the last accessed page
     * in the current_tab (AKA, the last page that was added to the page history stack).
     */
    getPreviousTabPath: ({ tabbed_history, current_tab }) => {
      const page_history = (tabbed_history[current_tab] || {}).page_history;

      if (page_history && page_history.length > 0) {
        return page_history[page_history.length - 1];
      }
    },

    getNativeBackButtonOverride: (state) => {
      return state.nativeBackButtonOverride;
    }
  }
};
