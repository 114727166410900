<template>
  <div class="label-container">
    <div
      v-for="label in labels"
      :key="`${label}`"
      v-html="label"
      class="minim-mobile-text secondary-font large p-b-10"
      :class="editingDisabled ? 'text-neutral-darker-color' : '' "

    />

    <select
      v-if="!supportsChannelWidth"
      @change="onSelect(radio.select_property, $event.target.value)"
      :disabled="editingDisabled"
      :value="radio.config_channel"
    >
      <option
        v-for="option in channelSelectOptions"
        :value="option"
        :key="option.id"
      >
        <span>{{ option === 'auto' ? $I18n.t('auto') : option }}</span>
      </option>
    </select>

    <select
      v-else
      @change="onSelect(radio.select_property, $event.target.value)"
      :value="radio.config_channel_width"
      :disabled="editingDisabled"
    >
      <option value="auto/0">
        <span> {{ $I18n.t('auto') }}</span>
      </option>

      <optgroup
        v-for="(channels, width) in channelWidthSelectOptions"
        :label="$I18n.t('router_configs.channel_width_optgroup_label', {'width': width})"
        :key="width"
      >
        <option
          v-for="channel in channels"
          :value="`${channel}/${width}`"
          :key="channel"
        >
          {{ `${$I18n.t('channel')} ${channel}` }}
        </option>
      </optgroup>
    </select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

const kindMapping = {
  wifi_6: 2,
  wifi_2_4: 1,
  wifi_5: 0
};

export default {
  props: {
    readOnly: {
      type: Boolean,
      required: true
    },

    supportsChannelWidth: {
      type: Boolean,
      required: true
    },

    lanHasAccessPoints: {
      type: Boolean,
      required: true
    },

    componentId: {
      type: String,
      required: true
    },

    radio: {
      type: Object,
      required: true
    },

    supportedFeatures: {
      type: Array,
      required: true
    },

    supportedChannels: {
      type: Object,
      required: true
    },

    primaryUnumRadios: {
      type: Array,
      required: true
    },

    areCustomSettings: {
      type: Boolean,
      required: true
    }
  },

  methods:{
    onSelect(fieldName, value) {
      if (this.editingDisabled) return;
      this.$emit('input', value);
    },

    isPresent(value) {
      return !['null', 'undefined'].includes(typeof value);
    }
  },

  computed: {
    ...mapGetters('RouterConfigsEditModeStore', ['componentIsInEditMode']),

    inEditMode() {
      return this.componentIsInEditMode(this.componentId);
    },

    editingDisabled() {
      return this.disabled || !this.inEditMode;
    },

    labels() {
      if (this.radio.mesh_radio && !this.supportedFeatures.includes('mesh-edit-channel')) {
        return [this.$I18n.t('router_configs.channel_select_disabled')];
      }

      if (this.supportsChannelWidth) {
        return this.channelWidthSelectLabels;
      } else {
        return this.channelSelectLabels;
      }
    },

    disabled() {
      if (this.readOnly) return true;

      if(!this.areCustomSettings) return true;

      if (this.radio.mesh_radio && !this.supportedFeatures.includes('mesh-edit-channel')) {
        return true;
      }

      return false;
    },

    // Generates an array of labels to be shown when the LAN only supports setting
    // the channel of radios and not the channel width
    channelSelectLabels() {
      let labels = [];

      labels.push(this.$I18n.t('lans.edit.label_radio_kind_channel', { label_radio_kind: this.$I18n.t(this.radio.kind) }));

      if (this.radio.config_channel !== 'auto') {
        const channel = this.unumRadioChannel || this.radio.config_channel;
        labels.push(this.$I18n.t('lans.edit.current_channel_html', { channel }));
      } else {
        labels.push(' ');
      }

      return labels;
    },

    channelSelectOptions() {
      if (!this.supportedChannels) {
        return [];
      }

      let width = this.radio.config_width;
      if (!width) {
        width = 20;
      }

      const kind = kindMapping[this.radio.kind];
      let options = this.supportedChannels[kind][width];

      if (!this.radio.config_is_mesh || options.length <= 0) {
        options.unshift('auto');
      }

      return options;
    },

    channelWidthSelectLabels() {
      let labels = [];

      labels.push(this.currentChannelWidthLabel);
      return labels;
    },

    currentChannelWidthLabel() {
      const configChannel = this.radio.config_channel;
      const configWidth = this.radio.config_width;

      const channelPresent = this.isPresent(configChannel);
      const widthPresent = this.isPresent(configWidth);

      if (channelPresent && widthPresent) {
        const channel = configChannel === 'auto' ? this.$I18n.t('auto') : configChannel;
        const width = configWidth === 0 ? this.$I18n.t('auto') : this.$I18n.t('router_configs.config_width_value', { configWidth: configWidth });
        return this.$I18n.t('lans.edit.current_channel_details', { channel, width });
      }
      else if (!channelPresent, widthPresent) {
        const channel = configWidth === 0
          ? this.$I18n.t('router_configs.config_width_channel_width', { configWidth: this.$I18n.t('auto')})
          : this.$I18n.t('router_configs.config_width_channel_width', { configWidth: this.$I18n.t('router_configs.config_width_value', { configWidth: configWidth })});
        return this.$I18n.t('lans.edit.current_channel_html', { channel });
      }
      else if (channelPresent, !widthPresent) {
        const channel = configChannel === 'auto' ? this.$I18n.t('auto') : configChannel;
        const width = configWidth === 0 ? this.$I18n.t('auto') : this.$I18n.t('router_configs.config_width_value', {configWidth: configWidth });

        return this.$I18n.t('lans.edit.current_channel_details', { channel, width });
      } else {
        return ' ';
      }
    },

    channelWidthSelectOptions() {
      let options = this.supportedChannels || {};
      options = options[kindMapping[this.radio.kind]] || {};

      return options;
    },

    unumRadioChannel() {
      const unumRadio = this.primaryUnumRadios.find(radio => radio.kind === this.radio.kind);

      return unumRadio.channel;
    }
  }
};
</script>
