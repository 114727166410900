import LanActivityFilters from './lan_activity_filters.vue';
import LanEvent from './lan_event.vue';
import Launch from './launch.vue';
import Message from './message.vue';
import RouterConfigVersion from './router_config_version.vue';
import SecurityIncident from './security_incident.vue';
import SpeedTest from './speed_test.vue';
import Diagnostics from './diagnostics.vue';
import MigrationTest from './migration_test.vue';

export default {
  'lan-activity-filters': LanActivityFilters,
  'lan-event': LanEvent,
  'launch': Launch,
  'message': Message,
  'first-version': RouterConfigVersion,
  'router-config-version': RouterConfigVersion,
  'security-incident': SecurityIncident,
  'speed-test': SpeedTest,
  'diagnostics': Diagnostics,
  'migration-test': MigrationTest
};
