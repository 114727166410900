<template>
  <div class='number-stat-container' :class="!!blob ? 'clickable' : ''" v-on:click="on_number_click()">
    <div class='number'>
      {{ number }}
      <status-icon v-if="icon != null" :status="icon" class='small number-icon'></status-icon>
    </div>
    <div class='unit'>{{ label }}</div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  props: ['number', 'icon', 'label', 'blob'],

  methods: {
    on_number_click() {
      if(this.blob) {
        let scroll_time = 350;

        $('html, body').animate({
          scrollTop: $('.device-section').offset().top - 100
        }, scroll_time);

        setTimeout(() => {
          this.$emit('change', this.blob);
        }, scroll_time);
      }
    }
  }
};
</script>
