<template>
  <div class="incident-events-table">
    <table class="property-table">
      <thead class="header">
        <th class="cell sortable" :class="sortableColumn('detected_at')" @click="onSort('detected_at')">{{ $I18n.t('components.security_incidents.detected') }}</th>
        <th class="cell sortable" :class="sortableColumn('ip')" @click="onSort('ip')">{{ $I18n.t('components.security_incidents.destination') }}</th>
        <th class="cell sortable" :class="sortableColumn('dns')" @click="onSort('dns')">{{ $I18n.t('components.security_incidents.dns_name') }}</th>
        <th class="cell sortable" :class="sortableColumn('protocol')" @click="onSort('protocol')">{{ $I18n.t('components.security_incidents.protocol') }}</th>
        <th class="cell sortable" :class="sortableColumn('port')" @click="onSort('port')">{{ $I18n.t('components.security_incidents.port') }}</th>
      </thead>
      <tbody>
        <tr v-for="(event, index) in sortedEvents" :key="`incident-event-${index}`">
          <td>{{lastDetectedAt(event)}}</td>
          <td>{{ip(event)}}</td>
          <td>{{dns(event)}}</td>
          <td>{{protocol(event)}}</td>
          <td>{{port(event)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from 'moment-timezone';

export default {

  props: {
    events: {
      type: Array
    }
  },

  data () {
    return{
      sort: { key: 'datetime', order: 'desc' },
    };
  },

  methods: {
    sortableColumn(key){
      if (this.sort.key == key) {
        return 'selected-column ' + this.sort.order;
      }
      return '';
    },

    onSort(key){
      if (key == this.sort.key) {
        this.sort.order = (this.sort.order == 'desc') ? 'asc' : 'desc';
      } else {
        this.sort.order = 'desc';
      }
      this.sort.key = key;
    },

    lastDetectedAt(event) {
      return event.detected_at ? moment.tz(event.detected_at, moment.tz.guess()).format('hh:mm A M/D/YY') : this.$I18n.t('unavailable');
    },

    ip(event){
      if(event.protocol === 'icmp'){
        return '';
      }
      return event.ip ? event.ip : this.$I18n.t('unavailable');
    },

    dns(event){
      if(event.protocol === 'icmp'){
        return '';
      }
      return event.dns ? event.dns : this.$I18n.t('unavailable');
    },

    protocol(event){
      return event.protocol ? event.protocol : this.$I18n.t('unavailable');
    },

    port(event){
      if(event.protocol === 'icmp'){
        return '';
      }
      return event.port ? event.port : this.$I18n.t('unavailable');
    }
  },

  computed: {
    sortedEvents() {
      return [...this.events].sort((a, b) => {
        let a_value = a[this.sort.key];
        let b_value = b[this.sort.key];

        if(this.sort.order == 'asc') {
          return (a_value > b_value) ? 1 : -1;
        } else {
          return (a_value <= b_value) ? 1 : -1;
        }
      });
    },
  }

};
</script>
