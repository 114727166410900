<template>
  <table class="flex-table property-table" v-if="this.incidents.length > 0">
    <thead class="grid-row header">
        <th class="cell sortable severity-header" :class="sortableColumn('severity')" @click="onIncidentSort('severity')">{{ $I18n.t('severity') }}</th>
        <th class="cell">{{ $I18n.t('name') }}</th>
        <th class="cell">{{ $I18n.t('description') }}</th>
        <th class="cell sortable" :class="sortableColumn('updated_at')" @click="onIncidentSort('updated_at')">{{ $I18n.t('components.security_incidents.last_detection') }}</th>
        <th></th>
    </thead>
    <malware-row v-for="incident in sortedIncidents"
      :disableButtons="disableButtons"
      :incident="incident"
      :key="incident.uuid"
    />
  </table>

  <table class="all-good" v-else>
    <tr>
      <td>
        <i class="fas fa-circle"></i>
        <span>{{ $I18n.t('components.security_incidents.malware_metrics_html', { ip_count: renownMetrics['ip_indicator_count'], dns_count: renownMetrics['domain_indicator_count'] }) }}</span>
      </td>
    </tr>
  </table>
</template>

<script>
import MalwareRow from './malware_row.vue';

export default {
  props: {
    incidents: {
      type: Array,
    },

    renownMetrics: {
      type: Object,
    },

    disableButtons: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    'malware-row': MalwareRow
  },

  data() {
    return {
      sort: { key: 'severity', order: 'desc' },
    };
  },
  methods: {
    onIncidentSort(key){
      if (key == this.sort.key) {
        this.sort.order = (this.sort.order == 'desc') ? 'asc' : 'desc';
      } else {
        this.sort.order = 'desc';
      }
      this.sort.key = key;
    },

    sortableColumn(key){
      if (this.sort.key == key) {
        return 'selected-column ' + this.sort.order;
      }
      return '';
    },
  },

  computed: {
    sortedIncidents(){
      return [...this.incidents].sort((a, b) => {
        let a_value = a;
        let b_value = b;
        if(this.sort.key == 'severity'){
          a_value = a.issue.severity;
          b_value = b.issue.severity;
        } else {
          a_value = a[this.sort.key];
          b_value = b[this.sort.key];
        }

        if(this.sort.order == 'asc') {
          return (a_value > b_value) ? 1 : -1;
        } else {
          return (a_value <= b_value) ? 1 : -1;
        }
      });
    }
  }
};
</script>
