export default {
  namespaced: true,

  state: {
    adminPassword: null,
    chosenConnectionKind: null,
    gatewayIPAddress: null,
    isFromFailedToActivatePage: false // whether or not we got into the setup wizard flow from the failed to activate page
  },

  mutations: {
    setGatewayIPAddress(state, ipAddress) {
      state.gatewayIPAddress = ipAddress;
      return state;
    },

    setChosenConnectionKind(state, chosenConnectionKind) {
      state.chosenConnectionKind = chosenConnectionKind;
      return state;
    },

    setAdminPassword(state, adminPassword) {
      state.adminPassword = adminPassword;
      return state;
    },

    setIsFromFailedToActivatePage(state, value) {
      state.isFromFailedToActivatePage = value;
      return state;
    }
  }
};
