import { shouldPolyfill } from '@formatjs/intl-getcanonicallocales/should-polyfill';

// Polyfills Intl.getCanonicalLocales if it isn't already present
async function polyfill() {
  if (shouldPolyfill()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill');
  }
}

export default polyfill;
