import store from 'mobile/vuex/index.js';

import axios from 'axios';
import qs from 'qs';

import I18n from 'shared/lib/i18n';

export default {
  // Custom axios instance with defaults set specifically for the Minim API
  axiosInstance: null,

  // Payload containing a Public API Key, Private API Key, API Key ID and User ID.
  // To see where this is generated reference the create method for the api/v1 api keys controller
  apiKey: null,

  // Access token payload, obtained from hitting api/oauth/token
  // Contains the actual token, token type, expiration date and created at date
  accessToken: null,

  init() {
    const token = document.getElementsByName('csrf-token')[0].getAttribute('content');

    this.axiosInstance = axios.create({
      // Have this resolve as a relative path for now
      // We want to point requests at whatever environment the app is being served from
      baseURL: '/',

      headers: {
        'X-CSRF-Token': token,
        'Accept': 'application/json'
      },

      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' });
      }
    });

    this.axiosInstance.interceptors.request.use((config) => {
      config.url = this._replaceUrlVars(config.url);

      if (this.accessToken) {
        config.params = {
          ...config.params,
          access_token: this.accessToken.access_token
        };
      }

      return config;
    }, (error) => {
      return Promise.reject(error);
    });

    this.axiosInstance.interceptors.response.use((response) => {
      return response;
    }, async (error) => {
      if (error.response && error.config) {
        const originalRequest = error.config;

        // If the original request failed due to a 401 and we were not
        // trying to create an access token or retry a request we should
        // create a new access token and retry the request with it
        if (
          error.response.status === 401
          && !originalRequest._createTokenRequest
          && !originalRequest._retry
          && this.apiKey
        ) {
          originalRequest._retry = true;
          await this.createAccessToken();
          return this.axiosInstance(originalRequest);
        }
      }

      return Promise.reject(error);
    });
  },

  get(...args) {
    return this.axiosInstance.get(...args);
  },

  post(...args) {
    return this.axiosInstance.post(...args);
  },

  patch(...args) {
    return this.axiosInstance.patch(...args);
  },

  put(...args) {
    return this.axiosInstance.put(...args);
  },

  delete(...args) {
    return this.axiosInstance.delete(...args);
  },

  /**
   * Fetches ALL the paginated IDs from a given api/v1 INDEX endpoint
   *
   * @param {*} urlPath - Path to an INDEX endpoint for a controller - ex: api/v1/lans/{lan_id}/devices
   */
  async fetchAllIDs(urlPath, opts = {}) {
    let aggregateData = [];
    let offset = 0;
    let finished = false;

    while(!finished) {
      Object.assign(opts, { offset });

      const res = await this.get(urlPath, opts);
      const ids = res.data.map(({ id }) => id);

      aggregateData = aggregateData.concat(ids);

      if (aggregateData.length >= parseInt(res.headers['x-total-count'])) {
        finished = true;
      } else {
        offset = aggregateData.length;
      }
    }

    return aggregateData;
  },

  /**
   * Uses the multi-get functionality to fetch all the resources from a given api/v1 INDEX endpoint
   *
   * @param {String} urlPath - Path to an INDEX endpoint for a controller - ex: api/v1/lans/{lan_id}/devices
   * @param {Array} ids -  Array containing ALL IDs of records that need to be fetched - [UUID1, UUID2, UUID3, etc...]
   * @param {Object} opts - Optional arguments - see usage below
   */
  async multiget(
    urlPath,
    opts = {
      maxMultigetLimit: null,
      callback: null,
      ids: null,
      params: {},
      timeout: null
    }
  ) {
    const ids = opts.ids || await this.fetchAllIDs(urlPath); // default to fetching all the IDs for a given resource
    const maxMultigetLimit = opts.maxMultigetLimit || 10;

    let idsInChunks = [];
    let aggregateData = [];

    for(let i = 0; i < ids.length; i += maxMultigetLimit) {
      idsInChunks.push(ids.slice(i, i + maxMultigetLimit));
    }

    for (let i = 0; i < idsInChunks.length; i++) {
      // Re-assign params to be an object that contains whatever was in the
      // original params object but override the current chunk of IDs
      const chunk = idsInChunks[i];
      const params = { ...(opts.params || {}), id: chunk.join(',') };
      const reqOptions = { params };

      if (opts.timeout !== null) {
        reqOptions.timeout = opts.timeout;
      }

      const res = await this.get(urlPath, reqOptions, true);

      // Pass the response to a callback if we have one
      if (opts.callback) {
        opts.callback(res);
      }

      aggregateData = [...aggregateData, ...res.data];
    }

    return aggregateData;
  },

  async createAPIKey(requestParams) {
    const app_install_id = await window.native.exec('get_app_id');
    const apiKey = (await this.post('api/v1/api_keys', {
      name: this._generateAPIKeyName(),
      app_install_id,
      ...requestParams
    })).data;

    await window.native.exec('set_preference', {
      key: 'minimApiKey',
      value: JSON.stringify(apiKey)
    });

    this.apiKey = apiKey;

    return apiKey;
  },

  async restoreAPIKey() {
    let apiKey = await window.native.exec('fetch_preference', 'minimApiKey');

    if (apiKey) {
      apiKey = JSON.parse(apiKey);
      this.apiKey = apiKey;
      return true;
    } else {
      return false;
    }
  },

  async createAccessToken() {
    const accessToken = (await this.post('api/oauth/token', {
      grant_type: 'client_credentials',
      client_id: this.apiKey.application_id,
      client_secret: this.apiKey.secret,
    }, {
      _createTokenRequest: true
    })).data;

    this.accessToken = accessToken;

    return accessToken;
  },

  async deleteAPIKey() {
    await this.delete(`api/v1/api_keys/${this.apiKey.id}`);
    await window.native.exec('remove_preference', 'minimApiKey');
    this.apiKey = null;
  },

  applyConfig(data = null, config = null) {
    return this.post('api/v1/lans/{lan_id}/apply_config', data, config);
  },

  request_local(method, url, config) {
    config = config || {};
    url = this._replaceUrlVars(url);
    return this.axiosInstance[method](`${url}`, config);
  },

  // Helper method to replace the string {lan_id} with the ID of the current LAN.
  // We have to pass the LAN ID with nearly every request we make so this is just
  // a nice shortcut that prevents us from having to import it from the store and
  // interpolate it all throughout the app.
  _replaceUrlVars(urlPath) {
    const lanId = (store.getters['LanStore/currentLan'] || {}).id;
    return urlPath.replace('{lan_id}', lanId);
  },

  _generateAPIKeyName() {
    const brand = store.getters['BrandingStore/brandSimpleName'];
    const deviceData = store.state.NativeInfoStore.device_data;

    let name = brand || 'Minim';

    if (deviceData && deviceData.platform) {
      name += ` (${deviceData.platform})`;
    }

    name += ' ';
    name += I18n.t('mobile.api_keys.mobile_api_key_name_suffix');

    return name;
  }
};
