<template>
  <div
    v-if="disabled"
    v-tippy="{ placement: 'top-center' }"
    :content="tooltipText"
  >
    <button class="edit-button disabled" :disabled="true">
      {{ $I18n.t('edit') }}
    </button>
  </div>

  <button
    v-else
    :data-test-id="testId"
    @click.prevent="handleClick"
    class="edit-button"
    :class="inEditMode ? 'editing' : ''"
  >
    {{ $I18n.t('edit') }}
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    componentId: {
      type: String,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: true
    },
    testId: {
      type: String,
      required: true
    }
  },

  beforeDestroy() {
    this.$store.commit('RouterConfigsEditModeStore/releaseEditMode');
  },

  computed: {
    ...mapGetters('LanStore', ['lanIsOnline']),
    ...mapGetters('RouterConfigsEditModeStore', ['editModeIsTaken', 'componentIsInEditMode']),

    disabled() {
      return this.readOnly || !this.lanIsOnline || this.otherComponentUsingEditMode;
    },

    inEditMode() {
      return this.componentIsInEditMode(this.componentId);
    },

    otherComponentUsingEditMode() {
      return (this.editModeIsTaken && !this.inEditMode);
    },

    tooltipText() {
      if (this.readOnly) return this.$I18n.t('components.no_edit_user_read_only');
      else if (!this.lanIsOnline) return this.$I18n.t('components.no_edit_lan_offline');
      else if (this.otherComponentUsingEditMode) return this.$I18n.t('components.no_edit_while_other_edits');
      else return '';
    },

    buttonClass() {
      if (this.inEditMode) return 'editing';
      if (this.disabled) return 'disabled';
      return '';
    }
  },

  methods: {
    handleClick() {
      if (this.inEditMode) {
        this.$store.commit('RouterConfigsEditModeStore/releaseEditMode');
      } else {
        this.$store.commit('RouterConfigsEditModeStore/setInUseBy', this.componentId);
      }
    }
  }
};
</script>
