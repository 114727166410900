<template>
  <div class="device-graph">
    <minim-graph @range_select="graphRangeSelected" @graph_type_change="onGraphTypeChange" width="600" :title="title" :graphName="graphName" :toggles="toggles" :queryOpts="queryOpts" />
      <div v-if="hasTrainingAccess">
        <br>

        <p style="color: red;" v-if="!enabled">
        In order to submit training data to the "bandwidth clasification network", please select a "user selected" timerange less than 4 hours on the bandwidth graph.
        </p>

        <div v-if="submitted">
          <p style="color: green;">Thank you for submitting some training data!</p>
        </div>
        <div class="network-training-form" v-else>
          <p>Try and get the start and the end of the bandwidth event to fill-up the width of the graph</p>

          <br>
          <b>Activity the person was doing:</b> <select :disabled="!enabled" v-model="activity">
            <option v-for="(val, index) in activities" :key="`activity-${index}`">
              {{val}}
            </option>
          </select>
          <br>
          <br>
          <button @click="submit" class="btn btn-submit" :disabled="!enabled">Submit Training Data</button>
          <div v-if="kibana_url" style="margin-top:10px;">
            <a target="_blank" :href="kibana_url">Kibana Connection Info</a>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import $ from 'jquery';

export default {
  props: ['title', 'graphName', 'toggles', 'queryOpts', 'options', 'activities', 'hasTrainingAccess', 'kibanaDeviceId'],

  data() {
    return {
      activity: null,
      start: null,
      end: null,
      graph_type: null,
      submitted: false
    };
  },

  methods: {
    graphRangeSelected(evt) {
      this.submitted = false;
      this.start = evt.starts_at;
      this.end = evt.ends_at;
    },

    onGraphTypeChange(graph_type) {
      this.submitted = false;
      console.log(graph_type);
      this.graph_type = graph_type;
    },

    submit() {
      let data = {
        activity: this.activity,
        start_time: this.start,
        end_time: this.end
      };

      let url = `/lans/${this.queryOpts.lan_id}/devices/${this.queryOpts.device_id}/create_connection_trainings`;

      console.log(data);
      console.log(url);

      $.post(url, data);
      this.submitted = true;
    }
  },

  computed: {
    enabled() {
      let ret = false;

      if(this.graph_type != 'bandwidth') return false;

      if(!!this.start && !!this.end) {
        let sec = (this.end - this.start);
        if(sec < 4 * 60 * 60) ret = true;
      }

      return ret;
    },

    kibana_url() {
      if(!this.start || !this.end) return null;

      let start = moment(this.start * 1000);
      let end = moment(this.end * 1000);

      return `https://kibana.minim.co/app/kibana#/dashboard/AWO3717_soC_ge_T0G7q?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${start.toISOString()}',interval:auto,mode:absolute,timezone:America%2FNew_York,to:'${end.toISOString()}'))&_a=(description:'',filters:!(),options:(darkTheme:!f),panels:!((col:1,id:AWO3qjIHsoC_ge_T0GtX,panelIndex:1,row:4,size_x:12,size_y:7,type:visualization),(col:1,id:AWO369IMsoC_ge_T0G65,panelIndex:2,row:1,size_x:12,size_y:3,type:visualization)),query:(query_string:(query:'device_key:${this.kibanaDeviceId}')),timeRestore:!f,title:'Network%20Classification%20Information',uiState:(P-1:(vis:(legendOpen:!f)),P-2:(vis:(legendOpen:!f))),viewMode:view)`;
    }
  }
};
</script>
