<template>
  <div class="launch" :data-test-id="'lan-event-' + this.eventData.id">
    <lan-activity-timestamp :timestamp="eventData.created_at"></lan-activity-timestamp>
    <div class='event-body'>
      <h2>{{ eventData.title }}</h2>
      <div v-if="crash_info" class='meta'>
        <div class='meta-item'>
          <i class='fas fa-bomb'></i>
          {{crash_info.description}}
        </div>
        <div class='meta-item'>
          <i class='fas fa-bomb-o'></i>
          <a :href="crash_info.report_url">{{ $I18n.t('timeline.download_stack_trace') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanActivityTimestamp from './lan_activity_timestamp.vue';

export default {
  props: ['lanId', 'eventData'],

  computed: {
    crash_info() {
      let custom_data = this.eventData.custom_data;

      if(!custom_data) return null;

      return custom_data.crash_info;
    }
  },

  components: {
    'lan-activity-timestamp': LanActivityTimestamp
  }
};
</script>
