import Vue from 'vue/dist/vue.esm';
import minim_api from 'mobile/shared/utils/minim_api';

export default {
  namespaced: true,

  state: {
    restrictions: [],
    restriction: {}
  },

  mutations: {
    many(state, data) {
      state.restrictions = data;
      return state;
    },

    set(state, data) {
      let i = state.restrictions.findIndex((r) => (r.id == data.id));
      Vue.set(state.restrictions, i, data);
      return state;
    }
  },

  actions: {
    index(context) {
      return minim_api.get('mobile/lans/{lan_id}/restrictions')
        .then((response) => {
          context.commit('many', response.data);
        });
    },

    async create({ commit }, data) {
      const res = (await minim_api.post(`mobile/lans/{lan_id}/people/${data.restriction.person_id}/restrictions`, data));
      commit('PersonStore/setRestrictions', [res.data], { root: true });
      return res.data;
    },

    update(context, data) {
      return minim_api.put(`mobile/lans/{lan_id}/people/${data.restriction.person_id}/restrictions/${data.restriction.id}`, data)
        .then((response) => {
          context.commit('PersonStore/setRestrictions', [response.data], { root: true });
          return response.data;
        });
    },

    snooze(context, data) {
      return minim_api.post(`mobile/lans/{lan_id}/people/${data.person_id}/restrictions/${data.restriction_id}/snooze`, {snoozed_until: data.snoozed_until})
        .then((response) => {
          context.commit('set', data);

          this.dispatch('PauseStatusStore/index').then(() => {
            return response.data;
          });
        });
    },

    delete(context, data) {
      return minim_api.delete(`mobile/lans/{lan_id}/people/${data.person_id}/restrictions/${data.id}`, data)
        .then(() => {
          return context.dispatch('PersonStore/index', { id:  data.lan_id }, { root: true }).then(() => {
            return context.dispatch('DeviceStore/index', null, { root: true });
          });
        });
    }
  },

  getters: {
    getRestrictionKind: (state) => (person_id, kind) => {
      return state.restrictions.filter((restriction) => (restriction.kind == kind));
    }
  }
};
