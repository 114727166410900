<template>
  <div class="devices-table">
    <h2>{{ $I18n.t('components.devices.recent_devices') }}</h2>
    <table
      v-if="devices.length > 0"
      class='device-list sortable property-table'
      data-test-id='devices-list'
      >
      <tr class='header'>
        <th @click="on_device_sort('category')" :class="sort_class('category')">{{ $I18n.t('components.devices.type') }}</th>
        <th @click="on_device_sort('manufacturer')" :class="sort_class('manufacturer')">{{ $I18n.t('components.devices.manufacturer') }}</th>
        <th @click="on_device_sort('product_name')" :class="sort_class('product_name')">{{ $I18n.t('components.devices.model') }}</th>
        <th @click="on_device_sort('device_name')" :class="sort_class('device_name')">{{ $I18n.t('components.devices.device') }}</th>
        <th @click="on_device_sort('last_active_at')" :class="sort_class('last_active_at')">{{ $I18n.t('components.devices.last_seen') }}</th>
        <th @click="on_device_sort('signal_strength')" :class="sort_class('signal_strength')" class='centered' width="120">{{ $I18n.t('components.devices.connectivity') }}</th>
        <th @click="on_device_sort('issues')" :class="sort_class('issues')">{{ $I18n.t('components.devices.issues_and_alerts') }}</th>
      </tr>
      <tr v-for="device in sorted_devices"
          :key="device.id"
          @click="on_device_click($event, device)"
          :id="'device-id-' + device.id"
          class="device pointer"
          :class="{ 'managed-device': device.isManaged }">
        <td nowrap='true' class='type_icon centered pad-top'>
          <span :title="title(device)" class='compound-icon'>
            <minim-device-icon icon_font_size="40px" :category="device.category" :os="os(device)" />
            <i v-if="device.paused" class="icon minim-icon-pause status-offset" style="font-size: 16px; color: #F06A7F;"></i>
          </span>
        </td>
        <td nowrap='true' class='manufacturer'>{{device.manufacturer}}</td>
        <td nowrap='true' class='product_name'>{{device.product_name}}</td>
        <td nowrap='true' class='device_name'>{{device.device_name}}</td>
        <td nowrap='true' class='last_seen'>
          <div class="flex-container align-center" v-if="isUnumUnmanaged(device.id)">
            <minim-icon
              iconName="unmanaged-device-16"
              color="unmanaged"
              size="xx-small"
            />
            <p class="m-l-6 p-t-2">{{$i18n.t('unmanaged')}}</p>
          </div>
          
          <div v-else>
            <span :class="'status-' + device.last_seen_status">
              <i class='fas fa-circle'></i>
            </span>&nbsp;&nbsp;{{last_seen(device)}}
          </div>
        </td>
        <td class='last_connection centered'>
          <connectivity
            :device="device"
            :kind="device.last_connection_kind"
            :signal_strength="device.signal_strength_decibels"
            :active="device.active"
          />
        </td>
        <td nowrap='true' class='alerts-col' data-tab-target='security-issue-content'>
          <div v-if="device.issues.length > 0" class="issue-container">
            <span class="issue-text">
              <template v-for="(icon, index) in icons(device)">
                <i
                  :class="icon"
                  style="font-size: 20px;"
                  :key="`device-${device.id}-icon-${index}`"
                />
              </template>
              <span class="alert-text">
                &nbsp;&nbsp;
                {{device.issues}}
              </span>
            </span>
          </div>
        </td>
      </tr>
    </table>
    <div v-else class='banner-message'>
      {{ $I18n.t('components.devices.no_devices_match_search') }}
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import cp_api from 'shared/utils/cp_api.js';
import DeviceComponents from './devices/index.js';

export default {
  props: ['devices', 'lan', 'lanId'],

  async mounted() {
    let height = $('.devices-table').height();
    this.unums = await cp_api.multiget(`/api/v1/lans/${this.lanId}/unums`);

    if(this.height < height) {
      $('.devices-table').css('height', `${height}px`);
      this.height = height;
    }
  },

  data() {
    return {
      unums: [],
      height: 0,
      sort: { key: 'last_active_at', order: 'asc' },
    };
  },

  methods: {
    sort_class(key) {
      var klass = key + ' ' + this.sort.order + ' sortable cell';
      if(key == this.sort.key) {
        klass = klass + ' selected-column';
      }

      return klass;
    },

    icons(device) {
      let icons = [];
      if( device.tags.find((tag)=>{ return(tag.kind == 'security');})) { icons.push('fas fa-exclamation-circle status-alert');}
      if( device.tags.find((tag)=>{ return(tag.kind == 'recommendation');})) { icons.push('minim-icon-cp-alert status-warning');}
      if( device.tags.find((tag)=>{ return(tag.name == 'paused');})) { icons.push('fas fa-pause-circle');}
      return(icons);
    },

    title(device) {
      var str = '';
      if (this.$I18n) {
        str = this.$I18n.t('iot.' + device.category);
        if (device.paused) { str = str + ' (' + this.$I18n.t('paused') + ')'; }
      }
      return str;
    },

    os(device) {
      var icon = null;
      switch(device.os.toLowerCase()) {
      case 'windows':
      case 'xbox os':
        icon = 'windows';
        break;
      case 'osx':
      case 'ios':
      case 'tvos':
      case 'watchos':
        icon = 'apple';
        break;
      case 'android':
        icon = 'android';
        break;
      case 'linux':
        icon = 'linux';
        break;
      }
      return (icon ? 'fab fa-' + icon : null);
    },

    last_seen(device) {
      return device.last_seen;
    },

    on_device_sort(key) {
      if (key == this.sort.key) {
        this.sort.order = (this.sort.order == 'desc') ? 'asc' : 'desc';
      } else {
        this.sort.order = 'asc';
      }

      this.sort.key = key;
    },

    on_device_click(evt, device) {
      let uri = '/lans/' + this.lan.id  + '/devices/' + device.id + '.html';
      let tab_target = $(evt.target).data('tab-target') || $(evt.target).parents('.alerts-col').data('tab-target');

      if(tab_target) {
        uri = uri + `?active_tab=${tab_target}`;
      }
      $.ajax({ type: 'GET', url: uri, success: function(data) { $.featherlight(data); } });
    },

    connection_kind_sort_value(value, device) {
      let ret = value;

      if(ret == null) {
        switch(device.last_connection_kind) {
        case 'wifi_5':
          ret = 50;
          break;
        case 'wifi_2_4':
          ret = 50;
          break;
        case 'moca':
          ret = 85;
          break;
        case 'ethernet':
          ret = 100;
          break;
        }
      }

      return ret;
    },

    getUnumByDeviceId(device_id){
      return this.unums.find(u => {
        return u.device_id === device_id;
      });
    },

    isUnumUnmanaged(device_id){
      let unum = this.getUnumByDeviceId(device_id);
      if(unum){
        return unum.is_unmanaged;
      } else {
        return false;
      }
    }
  },

  computed: {
    sorted_devices() {
      return [...this.devices].sort((a, b) => {
        var a_value = a[this.sort.key];
        var b_value = b[this.sort.key];
        var reverse = false;

        switch(this.sort.key) {
        case 'issues':
          reverse = true;
          break;
        case 'nice_name':
          a_value = a_value.toLowerCase();
          b_value = b_value.toLowerCase();
          break;
        case 'signal_strength':
          a_value = this.connection_kind_sort_value(a_value, a);
          b_value = this.connection_kind_sort_value(b_value, b);
          break;
        case 'last_active_at':
          reverse = true;
          break;
        }

        if (reverse) {
          var temp = a_value;
          a_value = b_value;
          b_value = temp;
        }

        if (this.sort.order == 'asc') {
          return (a_value > b_value) ? 1 : -1;
        } else {
          return (a_value <= b_value) ? 1 : -1;
        }
      });
    }
  },


  watch: {
    devices() {
      setTimeout(() => {
        let height = $('.devices-table').height();

        if(this.height < height) {
          $('.devices-table').css('height', `${height}px`);
          this.height = height;
        }
      }, 1000);
    }
  },

  components: DeviceComponents
};
</script>

<style scoped>
/* Used to better align the device icons with the other icons in the table */
.pad-top {
  padding-top: 14px;
}
</style>
