<template>
  <tr class="rogue-access-point">
    <td>
      <div class="incident-info description">
        <minim-device-icon class="device-icon" category="network_other" icon_font_size="32px"/>
        <div class="body">
            <p>{{ $I18n.t('components.security_incidents.rogue_one_html', { bssid: incident.bssid, ssid: incident.ssid }) }}</p>
          <p class="additional-incident-info clickable" @click="onLink">{{ $I18n.t('components.security_incidents.rogue_two') }}<i class="minim-mobile-icon minim-icon-forward"></i>
          </p>
        </div>
      </div>
    </td>
    <td style="vertical-align: baseline;">
      <div class="btns">
        <div class="btn-1">
          <button
            @click="markBssidAsManaged"
            :class="`${disableButtons ? 'disabled' : 'clear'}`"
            :content="$I18n.t('lans.security_incidents.tooltips.known_device')"
            v-tippy="{ placement: 'top-end' }"
          >{{ $I18n.t('components.security_incidents.known_device') }}</button>
        </div>
        <button
          @click="updateIncident('confirmed')"
          :class="`${disableButtons ? 'disabled' : 'good'}`"
          :content="$I18n.t('lans.security_incidents.tooltips.address_issue')"
          v-tippy="{ placement: 'top-end' }"
        >{{ $I18n.t('components.security_incidents.addressed') }}</button>
      </div>
    </td>
  </tr>
</template>
<script>
import MinimDeviceIcon from '../../../../shared/components/minim_device_icon.vue';

export default {
  props: {
    incident: {
      type: Object
    },
    disableButtons: {
      type: Boolean
    }
  },

  components: {
    'minim-device-icon': MinimDeviceIcon,
  },

  methods: {
    updateIncident(status){
      window.eventBus.$emit('update-incident', this.incident.uuid, status);
    },

    markBssidAsManaged(){
      window.eventBus.$emit('mark-bssid-as-managed', this.incident.uuid);
    },
    onLink(){
      window.open(this.incident.issue.information_uri, '_blank', 'noopener');
    }
  },
};
</script>
