import $ from 'jquery';

export default {
  selector: '.minim-js-form',

  init() {
    let $elements = $(this.selector);

    $elements.on('ajax:success', this.onSuccess);
    $elements.on('ajax:error', this.onError);
  },

  onError() {
    let [data, status, xhr] = event.detail;

    if(xhr.status == 422) {
      alert(data.toast);
    } else {
      alert(status);
    }
  },

  onSuccess() {
    location.reload();
  }
};
