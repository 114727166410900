<template>
  <div :data-test-id="testId">
    <div class="flash-container">
      <p v-for="(error, index) in errors" :key="`network-component-errors-${index}`" class="flash alert">
        <span>{{ error }}</span>
        <i
          @click="clearError(index)"
          class="fas fa-times-circle"
          style="position: absolute; right: 12px;"
        />
      </p>
    </div>

    <div v-if="isPrivateNetwork" class="wifi-settings-panel private-network">
      <h2 class="panel-title">{{ $I18n.t('private') }}</h2>
      <p>{{ $I18n.t('components.private_desc') }}</p>
    </div>

    <div v-else class="wifi-settings-panel">
      <div class="inner-top-section flex-container align-center justify-between">
        <div class="flex-container align-center">
          <network-toggle
            @click="toggleNetwork"
            :accessPoints="formAccessPoints"
            :radios="formRadios"
            :editingDisabled="editingDisabled"
            :supportsWirelessAlwaysEnabled="supportsWirelessAlwaysEnabled"
          />
          <h2 class="panel-title m-l-24 m-b-0 m-t-0">{{ currentSsid }}</h2>
        </div>
        <div class="flex-container align-center">
          <edit-button
            v-if="!inCreateMode"
            :testId="`network-settings-${networkIndex}`"
            :isEditable="isEditable"
            :componentId="componentId"
            :readOnly="readOnly"
            :lanIsOnline="lanIsOnline"
            style="margin:0;"
          />
          <button
            v-if="showDeleteButton"
            @click="toggleDeleteNetworkModal"
            class="delete-button m-l-12"
          >
            {{ $I18n.t('delete') }}
          </button>
        </div>
      </div>

      <div class="network-settings">
        <div class="inner-left-section">
          <template v-if="!inCreateMode">
            <push-credentials-button
              :accessPoints="accessPoints"
              :lanId="lanId"
              :businessPortal="businessPortal"
            />
            <text-credentials-button
              :accessPoints="accessPoints"
              :lanId="lanId"
            />
          </template>
        </div>

        <div class="inner-center-section">
          <h3 class="panel-sub-title">{{ $I18n.t('components.credentials') }}</h3>

          <div class="ssid-and-pw-form" :class="editingDisabled ? 'editing-disabled' : null">
            <label for="ssid">{{ $I18n.t('components.network_name') }}</label>
            <div class="ssid-field">
              <input
                @input="updateFieldForAllAccessPoints('ssid', $event.target.value)"
                :disabled="editingDisabled"
                :value="ssid"
                type="text"
              >
              <div v-if="lanSupportsFeature('supports_disabling_ssid_broadcast')" class="flex-container align-center broadcast-field">
                <label class="m-r-12">Broadcast</label>
                  <div
                    @click="updateFieldForAllAccessPoints('broadcasts_ssid', !broadcastsSsid)"
                    :class="editingDisabled ? 'disabled' : null"
                    class="broadcast-checkbox"
                  >
                    <div class="check" :class="broadcastsSsid ? 'selected' : null" />
                  </div>
              </div>
            </div>

            <div class="flex-container justify-between align-center">
              <password-field
                @input="updateFieldForAllAccessPoints('shared_key', $event.target.value)"
                :editingDisabled="editingDisabled"
                :password="password"
              />

              <div style="width: 147px;">
                <label>{{ $I18n.t('components.network_label') }}</label>
                <select
                  :disabled="editingDisabled"
                  @input="updateFieldForAllAccessPoints('label', $event.target.value)"
                  :value="networkLabel"
                >
                  <option
                    v-for="(option, value) in networkLabelOptions"
                    :value="value"
                    :key="`network-label-select-${option}`"
                  >
                    <span>{{ option }}</span>
                  </option>
                </select>
              </div>
            </div>
          </div>

          <save-section
            @save="updateOrCreateNetwork"
            @cancel="cancelChanges"
            :componentId="componentId"
            class="m-t-24"
          />
        </div>

        <div class="inner-right-section">
          <h3 class="panel-sub-title">{{ $I18n.t('components.radios') }}</h3>
          <div
            v-for="radio in formRadios"
            :key="`network-${networkIndex}-radio-${radio.id}`"
          >
            <band-toggle
              v-if="getAccessPointForRadio(radio)"
              @click="toggleBand"
              :accessPoint="getAccessPointForRadio(radio)"
              :radio="radio"
              :supportsWirelessAlwaysEnabled="supportsWirelessAlwaysEnabled"
              :editingDisabled="editingDisabled"
            />
            <add-band-button
              v-else
              @click="addAccessPointToNetwork(radio)"
              :radio="radio"
              :maxApsPerRadio="maxApsPerRadio"
              :maxGuestApsPerRadio="maxGuestApsPerRadio"
              :isGuest="isGuestCurrent"
              :readOnly="readOnly"
              :lanIsOnline="lanIsOnline"
              class="m-b-16"
            />
          </div>

          <h3 class="panel-sub-title m-b-6 m-t-12">{{ $I18n.t('components.features') }}</h3>
          <div class="w-full flex-container align-center justify-between">
            <p>{{ $I18n.t('hidden') }}</p>
            <minim-mobile-toggle
              @click="updateFieldForAllAccessPoints('hidden', !hiddenSsid)"
              v-tippy="{ placement: 'top-center', flip: false }"
              :content="$I18n.t('components.hidden_desc')"
              class="m-l-6"
              size="small"
              :active="hiddenSsid"
              :showLabels="false"
              :disableCursor="editingDisabled"
              style="margin: 0;"
            />
          </div>
          <div class="w-full flex-container align-center justify-between">
            <p>{{ $I18n.t('read_only') }}</p>
            <minim-mobile-toggle
              @click="updateFieldForAllAccessPoints('is_read_only', !readOnlySsid)"
              v-tippy="{ placement: 'top-center', flip: false }"
              :content="$I18n.t('components.read_only_desc')"
              class="m-l-6"
              size="small"
              :active="readOnlySsid"
              :showLabels="false"
              :disableCursor="editingDisabled"
              style="margin: 0;"
            />
          </div>
          <isolated-toggle
            @click="updateFieldForAllAccessPoints('is_guest', !isGuestForm)"
            :editingDisabled="editingDisabled"
            :inCreateMode="inCreateMode"
            :isGuestForm="isGuestForm"
            :isGuestCurrent="isGuestCurrent"
            :radios="radios"
            :maxGuestApsPerRadio="maxGuestApsPerRadio"
          />
        </div>
      </div>

      <delete-network-modal
        :open="deleteNetworkModalIsOpen"
        :currentSsid="currentSsid"
        @deleteNetwork="deleteNetwork"
        @close="toggleDeleteNetworkModal"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

import EditButton from '../../edit_button';
import SaveSection from '../../save_section';

import PushCredentialsButton from './push_credentials_button';
import TextCredentialsButton from './text_credentials_button';
import NetworkToggle from './network_toggle';
import BandToggle from './band_toggle';
import IsolatedToggle from './isolated_toggle';
import AddBandButton from './add_band_button';
import DeleteNetworkModal from './delete_network_modal';
import PasswordField from './password_field';

import MinimMobileToggle from 'mobile/shared/components/global/minim_mobile_toggle';

import extractProperties from 'shared/lib/extract_properties';
import FormattersMixin from 'shared/mixins/formatters';

const PERMITTED_AP_PARAMS = ['ssid', 'shared_key', 'is_guest', 'enabled', 'label', 'is_read_only', 'hidden', 'broadcasts_ssid'];
const AP_PARAMS_THAT_REQUIRE_CONFIG_PULL = ['ssid', 'shared_key', 'is_guest', 'enabled', 'broadcasts_ssid'];

export default {
  mixins: [FormattersMixin],

  components: {
    'edit-button': EditButton,
    'save-section': SaveSection,
    'push-credentials-button': PushCredentialsButton,
    'text-credentials-button': TextCredentialsButton,
    'network-toggle': NetworkToggle,
    'band-toggle': BandToggle,
    'isolated-toggle': IsolatedToggle,
    'add-band-button': AddBandButton,
    'delete-network-modal': DeleteNetworkModal,
    'password-field': PasswordField,
    'minim-mobile-toggle': MinimMobileToggle
  },

  props: {
    lanId: {
      type: String,
      required: true
    },

    accessPoints: {
      type: Array,
      required: true
    },

    networkIndex: {
      type: Number,
      required: true
    },

    radios: {
      type: Array,
      required: true
    },

    maxApsPerRadio: {
      type: Number,
      required: true
    },

    maxGuestApsPerRadio: {
      type: Number,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: true
    },

    businessPortal: {
      type: Boolean,
      required: true
    },

    lanIsMesh: {
      type: Boolean,
      required: true
    },
  },

  data() {
    return {
      formAccessPoints: [],
      formRadios: [],
      errors: [],

      deleteNetworkModalIsOpen: false,
      networkLabelOptions: {
        'home': this.$I18n.t('mobile.access_points.labels.home'),
        'work': this.$I18n.t('mobile.access_points.labels.work')
      }
    };
  },

  mounted() {
    this.cloneStartingData();

    if (this.inCreateMode) {
      this.toggleNetwork(true);
    }
  },

  computed: {
    ...mapGetters('LanStore', ['lanSupportsFeature', 'lanIsOnline']),
    ...mapGetters('AccessPointStore', ['getAccessPointsForRadio', 'isLastAccessPointOnRadio']),
    ...mapGetters('RouterConfigsEditModeStore', ['componentIsInEditMode']),

    componentId() {
      return `network-${this.networkIndex}`;
    },

    inCreateMode() {
      // If we don't have an ID for an AP we're in create mode
      return !(this.formAccessPoints[0] || {}).id;
    },

    isEditable() {
      return this.componentIsInEditMode(this.componentId);
    },

    editingDisabled() {
      return !this.isEditable;
    },

    // Gets the SSID currently saved in the DB by accessing it through the network prop
    // rather than through the accessPoints object - which we use to keep track of changes made
    // to the form
    currentSsid() {
      return (this.accessPoints[0] || {}).ssid;
    },

    ssid() {
      return (this.formAccessPoints[0] || {}).ssid || '';
    },

    password() {
      return (this.formAccessPoints[0] || {}).shared_key || '';
    },

    broadcastsSsid() {
      return !!(this.formAccessPoints[0] || {}).broadcasts_ssid || false;
    },

    networkLabel() {
      return (this.formAccessPoints[0] || {}).label || 'home';
    },

    readOnlySsid() {
      return !!(this.formAccessPoints[0] || {}).is_read_only || false;
    },

    hiddenSsid() {
      return !!(this.formAccessPoints[0] || {}).hidden || false;
    },

    // Represents the value being edited by the form
    isGuestForm() {
      return (this.formAccessPoints[0] || {}).is_guest || false;
    },

    // Represents the current value in the database
    isGuestCurrent() {
      return (this.accessPoints[0] || {}).is_guest || false;
    },

    isPrivateNetwork() {
      return this.formAccessPoints.some(ap => ap.private);
    },

    supportsWirelessAlwaysEnabled() {
      return this.lanSupportsFeature('wireless_always_enabled');
    },

    containsLastStandardAccessPointForRadio() {
      if (this.isGuestCurrent) return false;

      return this.formAccessPoints.findIndex(ap => this.isLastAccessPointOnRadio(ap, false)) !== -1;
    },

    showDeleteButton() {
      return (
        !this.containsLastStandardAccessPointForRadio
        && !this.inCreateMode
        && !this.readOnly
        && this.lanIsOnline
      );
    },

    changedAPParams() {
      return this.getChangedParams(this.formAccessPoints, this.accessPoints);
    },

    changedRadioParams() {
      return this.getChangedParams(this.formRadios, this.radios);
    },

    shouldEnqueueConfigPullOnUpdate() {
      // if there were any changes at all to the radios or we changed relevant
      // APs params then we should enqueue a pull router config
      return this.changedRadioParams.length || AP_PARAMS_THAT_REQUIRE_CONFIG_PULL.some(param => this.changedAPParams.includes(param));
    },

    testId() {
      return this.inCreateMode ? 'network-component-create-mode' : `network-component-${this.currentSsid}`;
    }
  },

  methods: {
    clearAllErrors() {
      this.errors = [];
    },

    clearError(index) {
      this.errors.splice(index, 1);
    },

    toggleDeleteNetworkModal() {
      this.deleteNetworkModalIsOpen = !this.deleteNetworkModalIsOpen;
    },

    cloneStartingData() {
      this.formAccessPoints = (this.accessPoints || []).map(networkAccessPoint => ({ ...networkAccessPoint }));
      this.formRadios = (this.radios || []).map(radio => ({ ...radio }));
    },

    getAccessPointForRadio(radio) {
      return this.formAccessPoints.find(ap => (ap.radio_id === radio.id));
    },

    getRadio(radioId) {
      return this.radios.find(radio => radio.id === radioId);
    },

    updateFieldForAllAccessPoints(fieldName, value) {
      if (this.editingDisabled) return;

      this.formAccessPoints.forEach(ap => ap[fieldName] = value);
    },

    toggleAP(radioId, toggleValue) {
      // use the radio ID to find the ap rather than the AP ID, since the AP ID won't exist on new networks
      const apIndex = this.formAccessPoints.findIndex(({ radio_id }) => radio_id === radioId);

      this.formAccessPoints[apIndex].enabled = toggleValue;
    },

    toggleRadio(radioId, toggleValue) {
      const radioIndex = this.formRadios.findIndex(({ id }) => id === radioId);

      this.formRadios[radioIndex].config_enabled = this.checkIfRadioShouldBeKeptEnabled(radioId) || toggleValue;
    },

    toggleBand(radioId, toggleValue) {
      // We need to keep the radio states in sync for devices that support single wireless auth,
      // so toggle the network instead of the AP in this case
      if (this.lanSupportsFeature('single_wireless_authentication')) {
        this.toggleNetwork(toggleValue);
        return;
      }

      this.toggleAP(radioId, toggleValue);
      this.toggleRadio(radioId, toggleValue);
    },

    // A radio should always be kept enabled if any of the access points on it are enabled or if it is mesh.
    checkIfRadioShouldBeKeptEnabled(radioId) {
      const radio = this.getRadio(radioId);

      if (radio.config_is_mesh && this.lanIsMesh) return true;

      const apsForRadio = this.getAccessPointsForRadio(radioId);

      // Ignore the current network's APs, since we're editing this network
      const currentNetworkAPIds = this.accessPoints.map(ap => ap.id);
      const filteredAPs = apsForRadio.filter(ap => !currentNetworkAPIds.includes(ap.id));

      return filteredAPs.some(ap => ap.enabled);
    },

    toggleNetwork(toggleValue) {
      this.formAccessPoints.forEach(ap => ap.enabled = toggleValue);
      this.formRadios.forEach((radio) => {
        radio.config_enabled = this.checkIfRadioShouldBeKeptEnabled(radio.id);

        let has_access_point = this.getAccessPointForRadio(radio);

        // If the access point doesnt need to be enabled, set it to whatever the toggle desires
        if(!radio.config_enabled && has_access_point) {
          radio.config_enabled = toggleValue;
        }
      });
    },

    cancelChanges() {
      this.clearAllErrors();

      if (this.inCreateMode) {
        this.cancelNetworkCreation();
      } else {
        this.cloneStartingData();
        this.$store.commit('RouterConfigsEditModeStore/releaseEditMode');
      }
    },

    cancelNetworkCreation() {
      this.$emit('cancelNetworkCreation');
    },

    async updateOrCreateNetwork() {
      try {
        await Promise.all(this.formAccessPoints.map(ap => {
          let endpoint = `/api/v1/lans/${this.lanId}/radios/${ap.radio_id}/access_points`;
          endpoint = this.inCreateMode ? endpoint : `${endpoint}/${ap.id}`;

          const data = { access_point: extractProperties(ap, PERMITTED_AP_PARAMS) };
          const method = this.inCreateMode ? 'post' : 'patch';

          return axios[method](endpoint, data);
        }));

        await Promise.all(this.formRadios.map(radio => {
          const endpoint = `/api/v1/lans/${this.lanId}/radios/${radio.id}`;
          const data = { radio: { config_enabled: radio.config_enabled } };

          return axios.patch(endpoint, data);
        }));

        if (this.inCreateMode || this.shouldEnqueueConfigPullOnUpdate) {
          this.sendConfigToRouterDeferred();
        } else {
          window.location.reload();
        }
      } catch (err) {
        const fallbackErrorMessage = this.inCreateMode ? this.$I18n.t('components.fail_create_network') : this.$I18n.t('components.fail_update_network');
        this.setErrors(err, fallbackErrorMessage);
      }
    },

    async deleteNetwork() {
      if (this.inCreateMode) {
        this.cancelNetworkCreation();
        return;
      }

      try {
        const apPromises = this.accessPoints.map(ap => axios.delete(`/api/v1/lans/${this.lanId}/radios/${ap.radio_id}/access_points/${ap.id}`));
        const radioPromises = this.radios.map(radio => axios.patch(`/api/v1/lans/${this.lanId}/radios/${radio.id}`, { radio: { config_enabled: this.checkIfRadioShouldBeKeptEnabled(radio.id) } }));

        await Promise.all([...apPromises, ...radioPromises]);

        this.sendConfigToRouterDeferred();
      } catch (err) {
        this.setErrors(err, this.$I18n.t('components.fail_delete_network'));
      }
    },

    async addAccessPointToNetwork(radio) {
      // Create a clone of the other AP on the network, but use the new radio and enable the AP
      let accessPoint = {
        ...this.accessPoints[0],
        enabled: true,
        radio_id: radio.id
      };

      // Strip any unwanted properties from that clone, such as the ID
      accessPoint = extractProperties(accessPoint, PERMITTED_AP_PARAMS);

      try {
        await Promise.all([
          axios.post(`/api/v1/lans/${this.lanId}/radios/${radio.id}/access_points`, { access_point: accessPoint }),
          axios.patch(`/api/v1/lans/${this.lanId}/radios/${radio.id}`, { radio: { config_enabled: true } })
        ]);

        this.sendConfigToRouterDeferred();
      } catch(err) {
        this.setErrors(err, this.$I18n.t('components.fail_add_band', { 'band': this.$I18n.t(radio.kind) }));
      }
    },

    // Call this after making any changes via the API that should be applied to the router. It makes an API
    // request that will start a countdown and eventually apply the updated config to the router.
    async sendConfigToRouterDeferred() {
      try {
        await axios.post(`/lans/${this.lanId}/router_configs/send_config_to_router_deferred.json`);
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    },

    setErrors(errorObject, fallbackMessage) {
      try {
        const errorsByField = errorObject.response.data.error;

        this.errors = Object.keys(errorsByField).reduce((errors, field) => {
          return errors.concat(errorsByField[field]);
        }, []);
      } catch(err) {
        this.errors = [fallbackMessage];
      }
    },

    // helper method which gets the names of params for either radios or APs that have been edited in the form
    getChangedParams(formData, originalData) {
      let changedParams = [];

      originalData.forEach(originalObj => {
        const formObj = formData.find(formObj => formObj.id === originalObj.id);

        changedParams = changedParams.concat(Object.keys(originalObj).filter(key => originalObj[key] !== formObj[key]));
      });

      return changedParams;
    }
  }
};
</script>
