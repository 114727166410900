// Used to store toast messages when forcibly switching routes
export default {
  namespaced: true,

  state: {
    toast: {}
  },

  mutations: {
    set(state, data) {
      state.toast = data;
      return state;
    },
  },

  actions: {
    update(context, data) {
      context.commit('set', data);
    }
  },

  getters: {
    getToast(state) {
      return state.toast;
    }
  }
};
