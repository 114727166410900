import UrlUtils from 'shared/lib/url_utils';
import Turbolinks from 'turbolinks';
import $ from 'jquery';

export default {
  selector: '.isp-switcher',

  init() {
    $('.isp-switcher').change(this.onIspChange);
  },

  onIspChange() {
    Turbolinks.visit(UrlUtils.updateQueryParam(window.location.href, 'isp_id', this.value));
  }
};
