<template>
  <div v-if="display_gateway_info" class="page-section lan-header">
    <div class="lan-heading inset" id="customer-data-container">
      <h2>{{$I18n.t('lans.header.speed_test')}}</h2>
      <div class="speed-test">
        <speedtest-display :test_data="recent_speedtest" :hardwareKind="hardwareKind"/>
        <speedtest-runner
          @newTestRun="setTestData"
          :initial_test_data="recent_speedtest"
          :lan="lan"
          :disableButtons="userIsReadOnly"
          :hardwareKind="hardwareKind"
        />
      </div>
    </div>
    <div class="lan-heading narrow" id="lan-stats-container">
      <ul class="lan-stats">
        <number-stat @click="on_number_click" :number="active_device_count" :label="$I18n.t('lans.header.devices_online_now')" :blob="{ filters: {}, sort: { key: 'last_active_at', order: 'asc' } }"></number-stat>
        <number-stat @click="on_number_click" :number="device_count" :label="$I18n.t('lans.header.total_devices')" :blob="{ filters: {}, sort: { key: 'last_active_at', order: 'asc' } }"></number-stat>
        <number-stat @click="on_number_click" :number="issue_count" :label="$I18n.t('lans.header.known_issues')" :blob="{ sort: { key: 'issues', order: 'asc' } }"></number-stat>
      </ul>
    </div>
    <div class="lan-heading" id="customer-map-container">
      <lan-map :lan="lan" />
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import MinimCable from 'shared/lib/minim_cable';
import LanMap from './lan_map.vue';
import SpeedtestDisplay from 'shared/components/speedtest/display.vue';
import SpeedtestRunner from 'shared/components/speedtest/runner.vue';

export default {
  props: ['lan', 'lanId', 'initialNumDevicesOnline', 'initialNumTotalDevices', 'initialNumIssues', 'userIsReadOnly', 'hardwareKind'],

  mounted() {
    this.loadSpeedTest();
  },

  data() {
    return {
      recent_speedtest: null
    };
  },

  computed: {
    devices() {
      return this.lan.devices ? this.lan.devices : [];
    },

    display_gateway_info() {
      let { manages_gateway, wired_connections_only, primary_unum_is_bridge } = this.lan || {};
      return manages_gateway || wired_connections_only || primary_unum_is_bridge;
    },

    active_devices() {
      return this.devices.filter((d) => d.active);
    },

    devices_with_issues_and_alerts() {
      return this.devices.filter((d) => d.tags.length > 0);
    },

    device_count() {
      return Object.keys(this.lan).length ? this.devices.length : this.initialNumTotalDevices;
    },

    online_count() {
      if (Object.keys(this.lan).length) {
        return '' + this.active_devices.length + '/' + this.devices.length;
      } else {
        return '' + this.initialNumDevicesOnline + '/' + this.initialNumTotalDevices;
      }
    },

    active_device_count() {
      return Object.keys(this.lan).length ? this.active_devices.length : this.initialNumDevicesOnline;
    },

    issue_count() {
      return Object.keys(this.lan).length ? this.devices_with_issues_and_alerts.length : this.initialNumIssues;
    }
  },

  methods: {
    on_number_click(values) {
      this.$emit('change', values);
    },

    loadSpeedTest(id) {
      let url = `/lans/${this.lanId}/speed_tests/${id == undefined ? 'most_recent' : id}`;
      $.get(url).then((data)=>{
        this.setTestData(data);
      });
    },

    setTestData(data) {
      this.recent_speedtest = data;
      this.subscribeToSpeedTests(data.id);
    },

    subscribeToSpeedTests(id) {
      this.subscription = MinimCable.cable.subscriptions.create({
        channel: 'SpeedTestChannel', id: id},
      { received: (data)=>{
        this.loadSpeedTest(data.id);
      }
      });
    },
  },

  components: {
    'lan-map': LanMap,
    'speedtest-display': SpeedtestDisplay,
    'speedtest-runner': SpeedtestRunner
  }
};
</script>
