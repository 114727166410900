import polyfillGetCanonicalLocales from './get_canoncial_locales';
import polyfillLocale from './locale';
import polyfillPluralRules from './plural_rules';
import polyfillNumberFormat from './number_format';

async function polyfill(locale) {
  // Order matters here as some of these polyfills depend on each other
  await polyfillGetCanonicalLocales();
  await polyfillLocale();
  await polyfillPluralRules(locale);
  await polyfillNumberFormat(locale);
}

export default polyfill;
