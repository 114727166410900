<template>
  <div class="security_issues">
    <div class="page-section inset">
      <detected-malware
        :incidents="malware"
        :renownMetrics="renownMetrics"
        :disableButtons="disableButtons"
      />
    </div>
    <div class="page-section inset">
      <detected-intrusions 
        :incidents="intrusions" 
        :unknownDevices="unknownDevices"
        :disableButtons="disableButtons" 
      />
    </div>
    <div class="page-section inset">
      <known-vulnerabilities 
        :incidents="vulnerabilities" 
        :disableButtons="disableButtons" 
      />
    </div>
      <issue-modal 
        :issue="this.issue" 
        :isOpen="this.showIssue" 
        @close="closeModal"
      />
  </div>
</template>

<script>
import DetectedMalware from './malware/detected_malware.vue';
import DetectedIntrusions from './intrusions/detected_intrusions.vue';
import KnownVulnerabilities from './vulnerabilities/known_vulnerabilities.vue';
import IssueModal from './issue_modal.vue';
import axios from 'axios';
import $ from 'jquery';
import minim_api from 'mobile/shared/utils/minim_api';

export default {
  props: {
    incidents: {
      type: Array,
    },

    renownMetrics: {
      type: Object
    },

    disableButtons: {
      type: Boolean,
      default: false
    },

    lanId: {
      type: String,
    },

    unknownDevices: {
      type: Array,
    },
  },

  components: {
    'detected-malware': DetectedMalware,
    'detected-intrusions': DetectedIntrusions,
    'known-vulnerabilities': KnownVulnerabilities,
    'issue-modal': IssueModal,
  },

  methods: {
    updateIncident(uuid, status) {
      if(!this.disableButtons) {
        const url = window.location.pathname + '/' + uuid;
        axios.patch(url, { 'status': status });
        let incident_index = this.incidents.findIndex(incident => incident.uuid == uuid);
        this.$delete(this.incidents, incident_index);
      }
    },

    approveDevice(device_uuid) {
      if(!this.disableButtons) {
        minim_api.post(`api/v1/lans/${this.lanId}/devices/${device_uuid}/approve`);
        const idx = this.unknownDevices.findIndex((device) => device.uuid === device_uuid);
        this.$delete(this.unknownDevices, idx);
      }
    },

    rejectDevice(device_uuid) {
      if(!this.disableButtons) {
        if (confirm(this.$I18n.t('lans.security_incidents.clear_warning'))) {
          minim_api.post(`api/v1/lans/${this.lanId}/devices/${device_uuid}/reject`);
          const idx = this.unknownDevices.findIndex((device) => device.uuid === device_uuid);
          this.$delete(this.unknownDevices, idx);
        }
      }
    },

    pauseDevice(device_uuid) {
      if(!this.disableButtons) {
        minim_api.post(`api/v1/lans/${this.lanId}/devices/${device_uuid}/pause`);
        const idx = this.unknownDevices.findIndex((device) => device.uuid === device_uuid);
        this.$set(this.unknownDevices[idx], 'paused', true);
      }
    },

    unpauseDevice(device_uuid) {
      if(!this.disableButtons) {
        minim_api.post(`api/v1/lans/${this.lanId}/devices/${device_uuid}/unpause`);
        const idx = this.unknownDevices.findIndex((device) => device.uuid === device_uuid);
        this.$set(this.unknownDevices[idx], 'paused', false);
      }
    },

    onDeviceClick(device_id) {
      let uri =  'devices/' + device_id + '.html';
      $.ajax({ type: 'GET', url: uri, success: function(data) { $.featherlight(data); } });
    },

    onIncidentClick(incident_id) {
      let uri = 'security_incidents/' + incident_id + '.html';
      $.ajax({ type: 'GET', url: uri, success: function(data) { $.featherlight(data, { variant: 'no-bottom-border' }); } });
    },

    markBssidAsManaged(uuid) {
      let incident_index = this.incidents.findIndex(incident => incident.uuid == uuid);
      if(!this.disableButtons) {
        minim_api.post(`lans/${this.lanId}/security_incidents/${uuid}/mark_bssid_as_managed`);
      }
      this.$delete(this.incidents, incident_index);
    },

    openIssueModal(issue) {
      this.issue = issue;
      this.showIssue = true;
    },

    openModal() {
      this.showIssue = true;
    },

    closeModal() {
      this.showIssue = false;
    }
  },

  data() {
    return {
      showIssue: false,
      issue: {},
    };
  },

  computed: {
    malware: function () {
      var res = [];
      for(const incident of this.incidents) {
        if(incident['issue']['classification'] == 'malware') {
          res.push(incident); 
        }
      }
      return res;
    },

    intrusions: function () {
      var res = [];
      for(const incident of this.incidents) {
        if(incident['issue']['classification'] == 'intrusion') {
          res.push(incident);
        }
      }
      return res;
    },

    vulnerabilities: function () {
      var res = [];
      for(const incident of this.incidents) {
        if(incident['issue']['classification'] == 'vulnerability') {
          res.push(incident);
        }
      }
      return res;
    }
  },

  mounted () {
    window.eventBus.$on('update-incident', (uuid, status) => {
      this.updateIncident(uuid, status);
    });

    window.eventBus.$on('open-issue-modal', (issue) => {
      this.openIssueModal(issue);
    });

    window.eventBus.$on('on-device-click', (uuid) => {
      this.onDeviceClick(uuid);
    });

    window.eventBus.$on('on-incident-click', (uuid) => {
      this.onIncidentClick(uuid);
    });

    window.eventBus.$on('approve-device', (uuid) => {
      this.approveDevice(uuid);
    });

    window.eventBus.$on('reject-device', (uuid) => {
      this.rejectDevice(uuid);
    });

    window.eventBus.$on('pause-device', (uuid) => {
      this.pauseDevice(uuid);
    });

    window.eventBus.$on('unpause-device', (uuid) => {
      this.unpauseDevice(uuid);
    });

    window.eventBus.$on('mark-bssid-as-managed', (bssid) => {
      this.markBssidAsManaged(bssid);
    });
  },
};

</script>
