<template>
  <span class='copy-to-clipboard' @click='copyToClipboard(valueToCopy)' v-html='getContent'></span>
</template>

<script>
export default {
  props: ['content', 'valueToCopy'],

  computed: {
    getContent() {
      return this.content + '<i class=\'minim-mobile-icon minim-icon-copy-16\' style=\'display: none\'></i>';
    }
  },

  methods: {
    copyToClipboard(text = '') {
      text = text.replace(/['"]+/g, '');
      navigator.clipboard
        .writeText(text)
        .then(
          () => {
            alert(this.$I18n.t('copy_to_clipboard.success', {value: text}));
          },
          () => {
            alert(this.$I18n.t('copy_to_clipboard.error'));
          }
        );
    }
  }
};
</script>
