import minim_api from 'mobile/shared/utils/minim_api';

export default {
  namespaced: true,

  state: {
    network_incidents: []
  },

  mutations: {
    many(state, data) {
      state.network_incidents = data;
      return state;
    }
  },

  actions: {
    async index({ commit }) {
      const network_incident_identifiers = (await minim_api.get('api/v1/lans/{lan_id}/network_incidents')).data;

      // Use the IDs to fetch every radio on the LAN's full data
      const network_incidents = await Promise.all(
        network_incident_identifiers.map(async ({ id }) => {
          return (await minim_api.get(`api/v1/lans/{lan_id}/network_incidents/${id}`)).data;
        })
      );

      commit('many', network_incidents);
      return network_incidents;
    }
  },

  getters: {
    getExistingNetworkIncidentsForDevice: state => device_id => {
      return state.network_incidents.filter(incident =>  {
        return !incident.is_resolved && incident.device_id === device_id;
      }) || [];
    }
  }
};
