<template>
  <div>
    <div v-if="errorMessage" class="flash-container">
      <p class="flash alert">
        <span>{{ errorMessage }}</span>
        <i
          @click="clearError()"
          class="fas fa-times-circle"
          style="position: absolute; right: 12px;"
        />
      </p>
    </div>
    <h2>
      {{ $I18n.t('router_configs.channel_selection') }}
    </h2>

    <div class="wifi-settings-panel flex-container column">
      <div class="inner-top-section">
        <div class="flex-container align-center justify-between">
          <h3> {{ $I18n.t('router_configs.channel_settings_for_all')}}</h3>
          <edit-button
            :componentId="componentId"
            testId="channel-config-edit-button-test-id"
            :readOnly="readOnly"
          />
        </div>
      </div>
      <div class="flex-container justify-between">
        <div v-if="supportsWifiOptimization" class="wifi-optimization-container">
          <wifi-optimization-settings
            :lan="lan"
            :lanId="lanId"
            :componentId="componentId"
            :enabled="lanObject.disallow_mobile_users_to_optimize_wifi"
            @toggle="toggleFeatureForWifiOptimization"
            @set="selectWirelessEnvironment"
            :currentWifiSetting="currentWifiSetting"
            ref="wifiOptimizationSettings"
          />
        </div>

        <div :class="radioSettingsClass" :style="supportsWifiOptimization ? 'display: block': 'display: grid'">
          <div v-for="radio in formRadios" :key="radio.id">
            <div class="radio">
              <h4>{{ $I18n.t(`${radio.kind}`) }}</h4>
              <radio-channel-select
                class="radio-channel-select"
                @input="(channelValue) => updateChannelValue(channelValue, radio)"
                :radio="radio"
                :componentId="componentId"
                :readOnly="readOnly"
                :primaryUnumRadios="primaryUnumRadios"
                :lanHasAccessPoints="lanHasAccessPoints"
                :supportedChannels="supportedChannels"
                :supportsChannelWidth="supportsChannelWidth"
                :supportedFeatures="supportedFeatures"
                :areCustomSettings="areCustomSettings"
                :data-test-id="`radio-channel-select-form-${radio.kind}`"
              />

              <div class="flex-container m-t-12 m-b-12">
                <band-access-point-graph
                  :open="apModalIsOpen[radio.kind]"
                  @close="toggleApModal(radio.kind)"
                  :radio="radio"
                  :primaryUnumId="primaryUnumId"
                  :primaryUnumAps="primaryUnumAps"
                  :primaryUnumRadios="primaryUnumRadios"
                />
                <button
                  @click="toggleApModal(radio.kind)"
                  class="neighboring-access-points-button graph-toggle-button"
                  :id="`neighboring-aps-${radio.kind}`"
                >
                  {{ neighboringApsButtonText(radio) }}
                </button>

                <scan-neighboring-aps-button
                  :lanId="lanId"
                  :unums="unums"
                  :isScanning="isScanningAps"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <save-section
        class="radio-settings-save-section"
        @save="save"
        @cancel="cancel"
        :componentId="componentId"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import BandAccessPointGraph from './band_access_point_graph.vue';
import EditButton from './edit_button';
import RadioChannelSelect from './radio_channel_select.vue';
import SaveSection from './save_section';
import ScanNeighboringApsButton from './scan_neighboring_aps_button';
import WifiOptimizationSettings from './wifi_optimization_settings.vue';

export default {
  props: {
    lan: {
      type: Object,
      required: true
    },

    lanId: {
      type: String,
      required: true
    },

    unums: {
      type: Array,
      required: true
    },

    primaryUnum: {
      type: Object,
      required: true
    },

    primaryUnumId: {
      type: String,
      required: true
    },

    primaryUnumRadios: {
      type: Array,
      required: true
    },

    primaryUnumAps: {
      type: Array,
      required: true
    },

    radios: {
      type: Array,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: true
    },

    isScanningAps: {
      type: Boolean,
      default: false
    },

    supportsChannelWidth: {
      type: Boolean,
      required: true
    },

    supportedFeatures: {
      type: Array,
      required: true
    },

    lanHasAccessPoints: {
      type: Boolean,
      required: true
    },

    supportedChannels: {
      type: Object,
      required: true
    },

    neighboringAps: {
      type: Object,
      required: true
    },

    currentUserIsEmployee: {
      type: Boolean,
      required: true
    }
  },

  components: {
    'band-access-point-graph': BandAccessPointGraph,
    'edit-button': EditButton,
    'radio-channel-select': RadioChannelSelect,
    'save-section': SaveSection,
    'scan-neighboring-aps-button': ScanNeighboringApsButton,
    'wifi-optimization-settings': WifiOptimizationSettings,
  },

  data() {
    return {
      componentId: 'radio-channel-settings',
      apModalIsOpen:{
        wifi_2_4: false,
        wifi_5: false,
        wifi_6: false
      },
      formRadios: [],
      lanObject: {},
      currentWifiSetting: '',
      errorMessage: null
    };
  },

  mounted() {
    this.$store.commit('LanStore/setLan', this.lan);
    this.lanObject = {...this.lan};
    if(!this.lanObject.wireless_environment){
      this.lanObject.wireless_environment = 'custom_settings';
    }
    if(!this.lanObject.disallow_mobile_users_to_optimize_wifi){
      this.lanObject.disallow_mobile_users_to_optimize_wifi = false;
    }
    this.currentWifiSetting = this.lanObject.wireless_environment;
    this.setFormRadios();
    this.setDisallowOptimizationFeature();
  },

  computed: {
    ...mapGetters('LanStore', ['lanSupportsFeature']),

    supportsWifiOptimization() {
      return this.lanSupportsFeature('config_channel_width');
    },

    areCustomSettings(){
      return (this.lanObject.wireless_environment === 'custom_settings' || !this.lanObject.wireless_environment);
    },

    radioSettingsClass(){
      let className = 'radio-settings';

      if(!this.areCustomSettings){
        className += ' text-neutral-darker-color';
      }

      return className;
    }
  },

  methods: {
    clearError() {
      this.errorMessage = '';
    },

    updateCurrentWifiSetting(value){
      this.currentWifiSetting = value;
    },

    toggleApModal(targetRadio) {
      this.apModalIsOpen[targetRadio] = !this.apModalIsOpen[targetRadio];
    },

    updateChannelValue(value, radio) {
      if (this.supportsChannelWidth) {
        radio.config_channel_width = value;
      } else {
        radio.config_channel = value;
      }
    },

    neighboringApsButtonText(radio) {
      const neighbors = this.neighboringAps[radio.kind];

      return neighbors.length > 0
        ? this.$I18n.t('lans.edit.neighboring_ap_count', { ap_count: neighbors.length })
        : this.$I18n.t('lans.edit.no_neighboring_aps');
    },

    cancel(){
      this.setFormRadios();
      this.setDisallowOptimizationFeature();
      this.currentWifiSetting = this.lan.wireless_environment;
      this.setOptimizationSelectorText(this.currentWifiSetting);
    },

    setDisallowOptimizationFeature(){
      this.lanObject.disallow_mobile_users_to_optimize_wifi = this.lan.disallow_mobile_users_to_optimize_wifi;
    },

    setFormRadios() {
      // Clones the radios array from props into the formRadios array
      // so we can edit the formRadios array freely, but still keep the
      // initial array unedited in order to support the cancel button behavior
      this.formRadios = this.radios.map(radio => ({ ...radio }));
    },

    toggleFeatureForWifiOptimization(value){
      this.lanObject.disallow_mobile_users_to_optimize_wifi = value;
    },

    setOptimizationSelectorText: function (value) {
      this.$refs.wifiOptimizationSettings.setOptimizationSelectorText(value);
    },

    selectWirelessEnvironment(value){
      this.lanObject.wireless_environment = value;
    },

    async save() {
      // Figure out which property we should be updating based on whether or not the
      // device supports configuring it's channel width and then only update that property
      const updatedProperty = this.supportsChannelWidth ? 'config_channel_width' : 'config_channel';
      try {
        if(this.supportsWifiOptimization){
          // set wireless environment
          await axios.patch(`/api/v1/lans/${this.lanId}/`, {
            lan: { wireless_environment: this.lanObject.wireless_environment},
          });
          // toggle feature flag for allowing mobile users to optimize their wifi environment
          await axios.patch(`/api/v1/lans/${this.lanId}`, {lan: { disallow_mobile_users_to_optimize_wifi: this.lanObject.disallow_mobile_users_to_optimize_wifi} });
        }
        if(this.lanObject.wireless_environment === 'custom_settings' || !this.lanObject.wireless_environment){
          // update radio channel settings
          await Promise.all(this.formRadios.map(async radio => {
            await axios.patch(`/api/v1/lans/${this.lanId}/radios/${radio.id}`, {
              radio: { [updatedProperty]: radio[updatedProperty]}
            });
          }));
        }
        await axios.post(`/lans/${this.lanId}/router_configs/send_config_to_router_deferred.json`);
      } catch (err) {
        this.errorMessage = this.$I18n.t('lans.edit.wifi_optimization.unable_to_apply');
        console.log(err);
        return;
      }
      window.location.reload();
    }
  }
};
</script>
