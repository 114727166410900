import ISPOverview from 'dashboard/lib/isp_overview';
import $ from 'jquery';

export default {
  initialize() {},

  received(target, data) {
    if ($(target).data('id') == data.id) {
      $.get('/accounts/' + $(target).data('id') + '.json', (isp_data) => {
        ISPOverview.init($(target).data('id'), isp_data);

        window.isp_active_heatmap.setData($.map(isp_data.active_locations, (val) => {
          return new window.google.maps.LatLng(val[0], val[1]);
        }));

        window.isp_inactive_heatmap.setData($.map(isp_data.inactive_locations, (val) => {
          return new window.google.maps.LatLng(val[0], val[1]);
        }));

        $('#isp-bandwidth-graph .button.selected').click();
      });
    }
  },

  unload() {}
};
