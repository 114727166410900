import minim_api from 'mobile/shared/utils/minim_api';

const FIFTEEN_MINUTES = 15 * 60 * 1000;

export default {
  namespaced: true,

  state: {
    deviceConnectionReport: {}
  },

  mutations: {
    setDeviceConnectionReport(state, devices) {
      const relevantDevices = Object.keys(devices || {}).reduce((relevantDevices, deviceId) => {
        const device = devices[deviceId];

        // Filter out gateway unums from the devices since we don't want to display them in the mobile app
        if (!device.is_gateway_unum) {
          relevantDevices[deviceId] = device;
        }

        return relevantDevices;
      }, {});

      state.deviceConnectionReport = relevantDevices;
      return state;
    }
  },

  actions: {
    async fetchDeviceConnectionReport({ state, commit }) {
      const report = (await minim_api.get('api/v1/lans/{lan_id}/device_connection_report')).data;
      commit('setDeviceConnectionReport', report.devices);
      return state.deviceConnectionReport;
    }
  },

  getters: {
    getReportForDevice: ({ deviceConnectionReport }) => deviceId => {
      return deviceConnectionReport[deviceId] || {};
    },

    getOnlineDeviceIds(state, getters, _rootState, rootGetters) {
      // If the LAN is offline all devices should be marked as offline.
      if (!rootGetters['LanStore/currentLan'].is_online) return false;

      const deviceConnectionReport = state.deviceConnectionReport;
      const deviceIds = Object.keys(deviceConnectionReport);

      return deviceIds.filter(id => getters.checkIfDeviceIsOnline(id));
    },

    getDeviceIdsForUnum: ({ deviceConnectionReport }) => unumId => {
      const deviceIds = Object.keys(deviceConnectionReport);
      return deviceIds.filter(id => {
        // Return true if the device has a matching unum id (meaning it's connected to it) but has a different MAC (meaning the device isnt the unum)
        return (unumId === deviceConnectionReport[id].unum_id) && (unumId !== deviceConnectionReport[id].mac_address) && !deviceConnectionReport[id].is_unum;
      });
    },

    getOnlineDeviceIdsForUnum: (_state, { getDeviceIdsForUnum, checkIfDeviceIsOnline }) => unumId => {
      return getDeviceIdsForUnum(unumId).filter(id => checkIfDeviceIsOnline(id));
    },

    getDeviceIdsForAccessPoint: ({ deviceConnectionReport }) => (accessPointId) => {
      const deviceIds = Object.keys(deviceConnectionReport);

      return deviceIds.filter(id => {
        const reportForDevice = deviceConnectionReport[id];
        return reportForDevice.access_point_key === accessPointId && !reportForDevice.is_unum;
      });
    },

    getOnlineDeviceIdsForAccessPoint: (_state, getters) => (accessPointId) => {
      const deviceIdsForAcccessPoint = getters.getDeviceIdsForAccessPoint(accessPointId);

      return deviceIdsForAcccessPoint.filter(id => getters.checkIfDeviceIsOnline(id));
    },

    checkIfDeviceIsOnline: (state,  _getters, _rootState, rootGetters) => deviceId => {
      // If the LAN is offline all devices should be marked as offline
      if (!rootGetters['LanStore/currentLan'].is_online) return false;

      // If there is no report for the device, assume it's offline
      const reportForDevice = state.deviceConnectionReport[deviceId];
      if (!reportForDevice) return false;

      // If the primary unum is offline, updating, or rebooting, then the device should be marked as offline.
      const primaryUnum = rootGetters['UnumStore/primaryUnum'];
      if (!primaryUnum.active || primaryUnum.is_updating || primaryUnum.is_rebooting) return false;

      // If we don't have a valid last active timestamp for the device assume it's offline
      const lastActiveSeconds = reportForDevice.last_active;
      if (typeof lastActiveSeconds !== 'number') return false;

      // Finally, convert the last active timestamp from seconds to milliseconds and use that to
      // check if the device has been active in the last 15 minutes. We use 15 minutes for this
      // as that is what the device.rb model uses for determining online/offline status
      const lastActiveMilliseconds = lastActiveSeconds * 1000;

      return lastActiveMilliseconds > (Date.now() - FIFTEEN_MINUTES);
    }
  }
};
