import Vue from 'vue';
import minim_api from 'mobile/shared/utils/minim_api';


export default {
  namespaced: true,

  state: {
    userServiceTerms: []
  },

  mutations: {
    set(state, term) {
      let i = state.userServiceTerms.findIndex((ust) => (ust.id == term));

      // If the index is not found...
      // we want to push it in at the end
      i = (i !== -1) ? i : state.userServiceTerms.length;

      Vue.set(state.userServiceTerms, i, term);
    },

    many(state, data) {
      state.userServiceTerms = data;
      return state;
    },
  },

  actions: {
    async index(context) {
      let userServiceTerms = await minim_api.multiget('api/v1/user_service_terms');

      context.commit('many', userServiceTerms);
      return userServiceTerms;
    },

  },

  getters: {

    checkAcceptance: (state, _g, _rs, rootGetters) => (kind) => {
      const serviceTerm = rootGetters['ServiceTermsStore/getByKind'](kind);

      // if there's no service term for this kind, the user doesn't need to accept it
      if (!serviceTerm) {
        return true;
      }

      const userServiceTerm = state.userServiceTerms.find(ust =>  ust.service_term_id === serviceTerm.id) || {};

      return !!userServiceTerm.accepted;
    }
  }
};
