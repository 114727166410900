<template>
  <div class="server-info" :class="klass" @click="openGoogleMaps(lat, lon)">
    <i v-if="validLocation" class="fal fa-map-marker-alt" style="font-size: 16px;"></i>  {{location_name}}&nbsp;&nbsp;&nbsp;{{ endpoint }}
  </div>

</template>

<script>

export default {
  props: ['location_name', 'lat', 'lon', 'endpoint'],
  methods: {
    openGoogleMaps(lat, lon){
      if (!this.validLocation){
        return;
      }
      window.open(`https://google.com/maps/search/?api=1&query=${lat},${lon}`, '_blank');
    },
  },

  computed: {
    klass(){
      return this.validLocation ? 'pointer' : '';
    },
    validLocation(){
      return this.lat && this.lon;
    },
  },
};
</script>
