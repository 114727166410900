import MinimCable from 'shared/lib/minim_cable';
import axios from 'axios';

export default {
  getAndSub(lanId, dataCb) {
    this.lanId = lanId;
    this.dataCb = dataCb;
    this.lanEtag = null;

    this._requestData();

    this.subscription = MinimCable.cable.subscriptions.create({ channel: 'LanChannel', id: this.lanId },
      { received: this.receivedUpdate.bind(this) });
  },

  unsubscribe() {
    this.subscription.unsubscribe();
  },

  _requestData() {
    axios.get(`/lans/${this.lanId}.json`).then((response) => {
      let data = response.data;

      this.cleanUpData(data);
      this.dataCb(data);
    });
  },

  receivedUpdate(lanData) {
    if (lanData.id === this.lanId) {
      console.log(`Retrieving some lan data for ${lanData.id}`);
      console.log(`oldEtag(${this.lanEtag}) newEtag(${lanData.etag})`);

      let oldEtag = this.lanEtag;
      if(oldEtag != lanData.etag) {
        this._requestData();
        this.lanEtag = lanData.etag;
      }
    }
  },

  cleanUpData(data) {
    if(data['people'] !== undefined) {
      for(let i = 0; i < data['people'].length; i++) {
        data['people'][i].devices = data.devices.filter((d) => (d.person_id == data['people'][i].id));
        data['people'][i].pausedCount = data['people'][i].devices.filter((d) => !!d.paused).length;
        data['people'][i].pausableCount = data['people'][i].devices.filter((d) => !d.protected_from_pause).length;
      }
    }
  }
};
