import MinimCable from 'shared/lib/minim_cable';
import $ from 'jquery';

export default {
  getAndSub(lanId, dataCb) {
    this.lanId = lanId;
    this.dataCb = dataCb;

    this._requestData();

    this.subscription = MinimCable.cable.subscriptions.create({ channel: 'LanChannel', id: this.lanId },
      { received: this.receivedUpdate.bind(this) });
  },

  unsubscribe() {
    this.subscription.unsubscribe();
  },

  _requestData() {
    $.get(`/lans/${this.lanId}/wan_status.json`, (data) => {
      this.dataCb(data);
    });
  },

  receivedUpdate(lanData) {
    if (lanData.id === this.lanId) {
      console.log(`Retrieving some lan wan_status data for ${lanData.id}`);
      this._requestData();
    }
  }
};
