<template>
  <div class="lan-activity-feed" data-test-id="timeline">
    <div class='filters-container clearfix'>
      <lan-activity-filters v-model="filters" :filterOptions="filterOptions" />
    </div>

    <div v-if="loaded">
      <div class='feed-contents'>
        <component
          v-for="feed_item in filtered_feed_items"
          class='feed-item'
          :is="feed_item.kind.replace(/_/g, '-')"
          :lanId="lanId"
          :eventData="feed_item"
          :key="feed_item.id"
        />
      </div>
      <div v-if="filtered_feed_items.length === 0" class='empty-state'>
        <i class='fas fa-3x fa-server'></i>
        <h2>{{ $I18n.t("timeline.no_events") }}</h2>
      </div>
      <div class="activity-feed-end" />
    </div>
    <div v-else class='loading-state centered'>
      {{ $I18n.t("timeline.loading_events") }} <i class="fas fa-cog fa-spin" />
    </div>
  </div>
</template>

<script>
import lanActivityComponents from './lan_activity_feed/index.js';
import $ from 'jquery';

export default {
  components: lanActivityComponents,

  props: ['lanId', 'filterOptions', 'lan_events'],

  data() {
    return {
      feed_items: [],
      filters: [],
      lastPageLoaded: 0,
      lastPageRequested: 0,
      reachedEnd: false,
      scrolling: false,
      loaded: false
    };
  },

  mounted() {
    this.fetch_events();
  },

  methods: {
    fetch_events() {
      if (this.reachedEnd || this.scrolling) { return; }

      if (this.lastPageRequested == this.lastPageLoaded) {
        this.lastPageRequested += 1;

        $.get(`/lans/${this.lanId}/lan_events.json?page=${this.lastPageRequested}`, (data) => {
          this.gotData(data);
        });
      }
    },

    gotData(data) {
      this.lastPageLoaded = this.lastPageRequested;
      if (data.length == 0) {
        this.reachedEnd = true;
      }
      this.feed_items = this.feed_items.concat(data);
      this.loaded = true;
      setTimeout(() => { this.handleScroll(); });
    },

    handleScroll() {
      if (this.reachedEnd || this.scrolling) { return; }

      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();

      var elemTop = $('.activity-feed-end').offset().top;
      var elemBottom = elemTop + $('.activity-feed-end').height();

      if ((elemBottom <= docViewBottom) && (elemTop >= docViewTop)) {
        this.scrolling = true;
        setTimeout(() => {
          this.scrolling = false;
          this.fetch_events();
        }, 200);
      }
    },
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  computed: {
    filtered_feed_items() {
      if (this.filters.length > 0) {
        return (this.feed_items.filter((item) => {
          return (this.filters.indexOf(item.kind) != -1);
        }));
      } else {
        return (this.feed_items);
      }
    }
  },

  watch: {
    filters() {
      setTimeout(() => { this.handleScroll(); });
    }
  }
};
</script>
