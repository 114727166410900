<template>
  <i @click="handleClick" :class="classes" />
</template>

<script>
export default {
  props: {
    // The name of the icon you want to render. Example: passing: "unknown" will render minim-icon-unknown
    iconName: {
      type: String
    },

    // Passing this prop will allow you to fallback to a font awesome or other icon class if there is no icon
    // class associated with your iconName value
    fallbackIconClass: {
      type: String
    },

    size: {
      type: String,
      default: 'small',
    },

    isCardHeaderIcon: {
      type: Boolean,
      default: false
    },

    color: {
      type: String,
      default: 'black',
    },

    skeletal: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes() {
      let classes = `minim-mobile-icon ${this.iconClass} ${this.size} ${this.color}`;

      if (this.isCardHeaderIcon) classes += ' card-header-icon';
      if (this.skeletal) classes += ' skeletal';

      if (this.color === 'primary') {
        classes += this.isCardHeaderIcon ? ' bg-primary-color' : ' text-primary-color';
      }

      return classes;
    },

    iconClass() {
      if (this.$props.iconName) {
        let iconName = this.mapDeviceCategoriesToIcoNames();
        iconName = iconName ? iconName : 'unknown'; // if there is no iconName default to the "unknown" icon

        let iconClass;

        // if we've passed in a fall back icon class and we currently dont have a "good" icon to show, default to the fallback
        if (iconName === 'unknown' && this.$props.fallbackIconClass) {
          iconClass = this.$props.fallbackIconClass;
        } else {
          // else, check to see if there is a brand override for the current icon class. if so use it,
          // if not it will default to what we passed in. then we can return whatever we have in the proper format
          iconClass = `minim-icon-${iconName}`;
        }

        return iconClass;
      } else if (this.$props.fallbackIconClass) {
        return this.$props.fallbackIconClass;
      }

      return null;
    }
  },

  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    },

    // checks if the icon name passed in via props is actually a device category
    // if so we wanna swap the the cateogry name to it's matched icon name
    mapDeviceCategoriesToIcoNames() {
      switch(this.$props.iconName) {
      case 'automation_other':
        return 'automation';
      case 'entertainment_other':
        return 'entertainment';
      case 'game_console':
        return 'console';
      case 'network_other':
        return 'network';
      case 'security_other':
      case 'security_lock':
        return 'security-system';
      case 'tv_box':
        return 'tv-box';
      case 'uncategorized':
        return 'unknown';
      default:
        return this.$props.iconName.toLowerCase().replace(/ /g, '-');
      }
    }
  }
};
</script>
