<template>
  <div class="speed-test-display">
    <div class="results">
      <speed-test-stat
        :is_maxed_out="false"
        :test_result_value="formatTestResult(speed_test.latency_ms, Math.round)"
        :hardwareKind="hardwareKind"
        test_result_units="ms"
        icon="fa-exchange"
      />
      <speed-test-stat
        :is_maxed_out="speed_test.download_speed_maxed_out"
        :test_result_value="formatTestResult(speed_test.download_speed_kbps, roundKbps)"
        :test_result_units="determineUnits(speed_test.download_speed_kbps)"
        :hardwareKind="hardwareKind"
        icon="fa-download"
      />
      <speed-test-stat
        :is_maxed_out="speed_test.upload_speed_maxed_out"
        :test_result_value="formatTestResult(speed_test.upload_speed_kbps, roundKbps)"
        :test_result_units="determineUnits(speed_test.upload_speed_kbps)"
        :hardwareKind="hardwareKind"
        icon="fa-upload"
      />
    </div>
    <div v-if="has_data">
      <hr/>
      <div class="real-world-implications">
        <div class="usage browsing"><i v-bind:class="speedIconClassString(64,512)" /><br/><span>{{$I18n.t('speed_tests.browsing')}}</span></div>
        <div class="usage audio"><i v-bind:class="speedIconClassString(384,512)" /><br/><span>{{$I18n.t('speed_tests.audio')}}</span></div>
        <div class="usage sd_video"><i v-bind:class="speedIconClassString(1000,3000)" /><br/><span>{{$I18n.t('speed_tests.sd_video')}}</span></div>
        <div class="usage hd_video"><i v-bind:class="speedIconClassString(4500,5000)" /><br/><span>{{$I18n.t('speed_tests.hd_video')}}</span></div>
        <div class="usage fourk_video"><i v-bind:class="speedIconClassString(22500,25000)" /><br/><span>{{$I18n.t('speed_tests.4k_video')}}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import SpeedTestsMixin from 'mobile/shared/mixins/speed_tests_mixin';
import SpeedTestStat from './stat';

export default {
  mixins: [SpeedTestsMixin],

  components: {
    'speed-test-stat': SpeedTestStat
  },

  props: {
    test_data: {
      type: Object
    },
    hardwareKind: {
      type: Object
    }
  },

  methods: {
    roundKbps(val) {
      const kbps = (val > 1024) ? (val / 1024) : val;

      // only one significant digit when above 100, two otherwise.
      const digits = kbps < 100 ? 2 : 1;
      const factor = 10 ** digits;

      return (Math.round(kbps * factor) / factor).toFixed(digits).replace('.', this.$I18n.t('number.format.separator'));
    },

    speedIconClassString(min_speed_kbps, good_speed_kbps) {
      let { download_speed_kbps } = this.test_data;

      if (download_speed_kbps < min_speed_kbps) return ['fa', 'fa-times-circle', 'status-alert'];
      if (download_speed_kbps < good_speed_kbps) return ['fa', 'fa-check-circle', 'status-warning'];

      return ['fa', 'fa-check-circle', 'status-ok'];
    },

    formatTestResult(initial_value, rounding_function) {
      if (initial_value != null && initial_value >= 0) {
        return rounding_function(initial_value);
      } else if (this.is_running) {
        return '<span class=\'fas fa-spinner fa-spin\'></span>';
      }

      return '??';
    },

    determineUnits(test_result_value) {
      if (test_result_value && test_result_value >= 0) {
        return (test_result_value > 1024) ? this.$I18n.t('units.megabitpersecond_abbrev') : this.$I18n.t('units.kilobitpersecond_abbrev');
      }

      return '';
    }
  },

  computed: {
    speed_test() {
      return this.test_data || {};
    },

    is_running() {
      return !!this.speed_test.is_running;
    },

    has_data() {
      return !!Object.keys(this.speed_test).length;
    }
  }
};
</script>
