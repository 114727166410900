import Vue from 'vue/dist/vue.esm';
import VueI18n from 'vue-i18n';
import intlPolyfill from 'shared/polyfills/intl/index.js';
import axios from 'axios';
import moment from 'moment';
import en_US from '../../../../public/locales/en-US.json';

const AVAILABLE_LOCALES = ['en-US', 'ru-RU', 'es-ES', 'es-MX', 'fr-CA', 'emo'];

export function defaultLocale() {
  // let lang = window.minimLang || window.navigator.userLanguage || window.navigator.language || 'en';

  let lang = window.minimLang || 'en-US'; // changed this to allow for Russian translation to be tested. Change this code back when the implementation is complete.

  return AVAILABLE_LOCALES.includes(lang) ? lang : 'en-US';
}

// Gets the SHA associated with the current webpack bundle
// We use this to cache bust the locales when loading them
function getWebpackBundleSHA() {
  const scripts = document.getElementsByTagName('script');

  let sha = '';

  for (let i = 0; i < scripts.length; i++) {
    let match = scripts[i].src.match(/(dashboard|mobile)-([0-9a-f]+)\.js/);

    if (match) {
      sha = match[2];
      break;
    }
  }

  return sha;
}

// ensure Intl is polyfilled for use with vue-i18n's n() function.
// vue-i18n tries to use Intl under the hood for this function but does not fully
// pollyfill Intl and all of it's methods, so we polyfill it here before initializing it
intlPolyfill(defaultLocale());

Vue.use(VueI18n);

const i18n = new VueI18n({
  fallbackLocale: 'en-US',
  messages: en_US
});

export async function setI18nLanguage(locale) {
  locale = locale || defaultLocale();

  if (!Object.keys(i18n.messages).includes(locale)) {
    const res = await axios.get(`/locales/${locale}.json?cache=${getWebpackBundleSHA()}`);
    i18n.setLocaleMessage(locale, res.data[locale]);
  }

  i18n.locale = locale;

  if (locale !== 'emo') {
    moment.locale = locale;
  }
}

Vue.prototype['$I18n'] = i18n;
window.$I18n = i18n;

export default i18n;
