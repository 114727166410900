import $ from 'jquery';

export default {
  selector: '.accordion-table',

  init() {
    $('.tab-contents').width($('.tab-content').outerWidth());
    $('.summary-row').click(this.onSummaryRowClick);
    $('.detail-row').click(this.onDetailRowClick);
  },

  onSummaryRowClick(evt) {
    let $target = $(evt.currentTarget);
    $target.toggleClass('open').next('.detail-row').toggleClass('open');
    $target.find('.toggle-col i').toggleClass('fa-caret-down fa-caret-up');
    $('.tab-contents').height($target.parents('.tab-content ').outerHeight());
  },

  onDetailRowClick(evt) {
    let $target = $(evt.currentTarget);
    $target.toggleClass('open').prev('.summary-row').toggleClass('open').find('.toggle-col i').toggleClass('fa-caret-down fa-caret-up');
    $('.tab-contents').height($target.parents('.tab-content ').outerHeight());
  }
};
