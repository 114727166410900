<template>
  <div class='device-list'>
    <transition name="input-expand-right">
      <div v-if="this.show" class="content-padded device-search-container icon-input-wrapper input-icon-right">
        <i @click="onSearchClear" class="fas fa-close field-icon" />
        <input v-model="searchValue" class="device-search-box" type="text" @blur="onSearchBlur($event)">
      </div>
    </transition>

    <ul class="table-view device-list icon-table vue">
      <li
        v-for="device in filteredDevices"
        class="table-view-cell media"
        :class="{ paused: isPaused(device) }"
        :key="device.id"
      >
        <router-link :to="{ name: 'lan_device_path', params: { lanId: device.lan_id, id: device.id }}" class="navigate-right">
          <div class="media-icon pull-left">
            <span class="status-icon-container">
              <minim-device-icon :category="device.category" />
              <i v-if="isPaused(device)" class="fas fa-pause-circle paused-status" style='right: 0; bottom: -5px;'></i>
            </span>
          </div>
            <div class="media-body truncate">
              <div class="col-left">
                <span class="primary truncate">
                  <i class='fa' :class="{ 'fa-circle': device.active, 'fa-circle-o': !device.active, 'online': device.active, 'offline': !device.active}"></i>
                  {{device.device_name || device.nice_name}}
                </span>
                <span class="secondary truncate" :class="device.person_name ? '' : 'unassigned'">{{device.person_name || $I18n.t('components.devices.assign_person') }}</span>
              </div>
              <div class="col-right">
                <span class="primary truncate">{{device.manufacturer}}</span>
                <span class="secondary truncate">{{device.product_name || device.id}}</span>
              </div>
          </div>
        </router-link>
      </li>
      <li v-if="filteredDevices.length == 0" class='table-view-cell media'>{{ $I18n.t('components.devices.no_devices_found') }}</li>
    </ul>
  </div>
</template>

<script>
import $ from 'jquery';
import MinimDeviceIcon from './minim_device_icon.vue';

export default {
  props: ['lanId', 'devices', 'showSearch'],

  data() {
    return {
      searchValue: '',
      show: false
    };
  },

  mounted() {
    this.show = this.showSearch;
    $('.device-search-toggle').on('touchstart', this.toggleSearch.bind(this));
  },

  methods: {
    fuzzy_search(val, obj) {
      return JSON.stringify(obj).toUpperCase().indexOf(val.toUpperCase()) !== -1;
    },

    isPaused(device) {
      return !!this.$store.getters['PauseStatusStore/forDevice'](device.id);
    },

    toggleSearch() {
      this.show = !this.show;
      if(!this.show) {
        this.searchValue = '';
      } else {
        setTimeout(()=>{$('.device-search-box').focus();}, 500);
      }
    },

    onSearchClear() {
      if(this.searchValue.length > 0) {
        this.searchValue = '';
      } else {
        this.toggleSearch();
      }
    },

    onSearchBlur(evt) {
      if(!$(evt.target).hasClass('device-search-box')) {
        this.toggleSearch();
      }
    }
  },

  computed: {
    device_list() {
      return(this.devices || []);
    },

    filteredDevices() {
      var filtered = [];
      if(this.device_list) {
        filtered = this.device_list.filter((device) => {
          if(this.searchValue && this.searchValue.length !== 0) {
            return this.fuzzy_search(this.searchValue, device);
          } else {
            return true;
          }
        });
      }
      return filtered.sort((a,b) => {
        var a_active = a.active;
        var b_active = b.active;

        var a_name = (a.device_name || a.nice_name).toLowerCase();
        var b_name = (b.device_name || b.nice_name).toLowerCase();

        if(a_active && !b_active) return -1;
        if(!a_active && b_active) return 1;

        if(a_name < b_name) return -1;
        if(a_name > b_name) return 1;
        return 0;
      });
    }
  },

  components: MinimDeviceIcon
};
</script>
