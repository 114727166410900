import minim_api from 'mobile/shared/utils/minim_api';
import Vue from 'vue';

export default  {
  namespaced: true,

  state: {
    radios: []
  },

  mutations: {
    set(state, data) {
      let i = state.radios.findIndex((radio) => (radio.id === data.id));

      // If the index is not found...
      // we want to push it in at the end
      i = (i !== -1) ? i : state.radios.length;

      Vue.set(state.radios, i, data);
    },

    many(state, data) {
      state.radios = data;
      return state;
    }
  },

  actions: {
    /**
     * Fetches the full data for all the radios on the LAN
     */
    async index(context) {
      // Get the IDs & hrefs for every radio on the LAN
      const radioIds = (await minim_api.get('api/v1/lans/{lan_id}/radios')).data;

      // Use the IDs to fetch every radio on the LAN's full data
      const radios = await Promise.all(
        radioIds.map(async ({ id }) => {
          return (await minim_api.get(`api/v1/lans/{lan_id}/radios/${id}`)).data;
        })
      );

      context.commit('many', radios);
      return radios;
    },

    /**
     * Fetch a single radio on the LAN, given a radio ID
     */
    async show(context, radioId) {
      const radio = (await minim_api.get(`api/v1/lans/{lan_id}/radios/${radioId}`)).data;

      // Update the radio in state
      context.commit('set', radio);
      return radio;
    },

    /**
     * Updates a single radio
     */
    async update(context, { radio, data }) {
      // Update the radio with the provided data
      await minim_api.patch(`api/v1/lans/{lan_id}/radios/${radio.id}`, { radio: data });

      // after posting the update, update the object in state
      await context.dispatch('show', radio.id);
    }
  },

  getters: {
    getRadio: (state) => (radio_id) => {
      return(state.radios.find((radio) => (radio.id === radio_id)) || {});
    },

    getRadios(state) {
      return state.radios.sort((a,b) => a.kind > b.kind ? 1 : -1);
    },

    getRadioByKind: (state) => (kind) => {
      return state.radios.find((radio) => (radio.kind === kind)) || {};
    },

    getRadioKind: (_state, getters) => (radio_id) => {
      return getters.getRadio(radio_id).kind;
    },

    getRadioMaxAps: (_state, getters, _, rootGetters) => {
      const unum = rootGetters['UnumStore/primaryUnum'];
      const hardwareKind = rootGetters['HardwareKindStore/getHardwareKind'](unum.hardware_kind_id);
      return hardwareKind.max_aps_per_radio;
    },

    canCreateAp: (_state, getters, _, rootGetters) => (radio_id, is_guest) => {
      let aps = rootGetters['AccessPointStore/getAccessPointsForRadioId'](radio_id);
      let guest_ap = aps.find((ap) => ap.is_guest);
      let unum = rootGetters['UnumStore/primaryUnum'];
      let hardwareKind = rootGetters['HardwareKindStore/getHardwareKind'](unum.hardware_kind_id);
      let is_room = hardwareKind.max_aps_per_radio > aps.length;
      // Allow creating a regular ap if there is room
      // allow creating a guest ap if there isn't already one on this radio and there's room
      return is_guest ? !guest_ap && is_room : is_room;
    },

    canAddGuest: (_state, getters, _, rootGetters) => (radio_id) => {
      let aps = rootGetters['AccessPointStore/getAccessPointsForRadioId'](radio_id);
      let guest_ap = aps.find((ap) => ap.is_guest);
      return !guest_ap;
    },

    /**
     * Helper method used to check if we should keep a given radio enabled.
     * A radio should be enabled if the radio is the backhaul for mesh AND any of the unums on the LAN are mesh,
     * OR if it has access points on it that are enabled.
     *
     * @param {String} radio_id - ID of the radio we want to check
     * @param {Array} ignored_ap_ids - an optional array of access point IDs to ignore when checking if the radio has enabled APs
     */
    radioNeedsToBeEnabled: (_state, getters, _rootState, rootGetters) => (radio_id, ignored_ap_ids = []) => {
      const radio = getters.getRadio(radio_id);

      if (radio.config_is_mesh && rootGetters['UnumStore/hasMeshUnums']) {
        return true;
      }

      const aps = rootGetters['AccessPointStore/getAccessPointsForRadioId'](radio_id);
      const filtered_aps = aps.filter(ap => !ignored_ap_ids.includes(ap.id));

      return !!filtered_aps.filter(ap => ap.enabled).length;
    }
  }
};
