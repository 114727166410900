<template>
  <div class="lan-wan-status">
    <ul>
      <li class="connectivity">
        <div v-if="isUnumUnmanaged">
          <span>
            <minim-icon
              iconName="unmanaged-device-16"
              color="unmanaged"
              size="x-small"
            />
          </span>
          <span> {{ $i18n.t('unmanaged') }}</span>
        </div>
        <div v-else>
          <span :class="unum_status()"><i class="fas fa-circle"></i></span>
          <span v-if="is_active()">
            {{$I18n.t('unums_index.online_since', { "since": lan_status.uptime })}}
          </span>
          <span v-else>
            {{last_active()}}
          </span>
        </div>
      </li>
      <li v-if="lan_status.external_ip_address && lan_status.external_ip_address.length > 0" class="ip_addr">
        {{$I18n.t('ip_addr')}}: <strong>{{lan_status.external_ip_address}}</strong>
      </li>
      <li v-if="lan_status.primary_mac_address && lan_status.primary_mac_address.length > 0" class="mac_addr">
        {{$I18n.t('mac_addr')}}: <strong>{{lan_status.primary_mac_address}}</strong>
      </li>
    </ul>
  </div>
</template>

<script>
import LanWanStatusModel from 'shared/models/lan_wan_status.js';
import moment from 'moment';

export default {
  props: ['lanId'],

  mounted() {
    LanWanStatusModel.getAndSub(this.lanId, (data) => {
      this.lan_status = data;
      this.isUnumUnmanaged = data.is_unmanaged;
    });
  },

  beforeDestroy() {
    LanWanStatusModel.unsubscribe();
  },

  methods: {
    unum_status() {
      return (this.lan_status.active ? 'status-ok' : 'status-alert');
    },

    is_active() {
      return this.lan_status.active && this.lan_status.uptime;
    },

    last_active() {
      if (!this.lan_status.last_active) {
        return this.$I18n.t('unums_index.never_seen');
      } else {
        let lastActive = moment().locale(this.$I18n.locale).to(moment(this.lan_status.last_active));
        return this.$I18n.t('unums_index.last_seen_when', { 'when': lastActive });
      }
    }
  },

  data() {
    return {
      lan_status: {},
      isUnumUnmanaged: false
    };
  }
};
</script>
