import Vue from 'vue';
import minim_api from 'mobile/shared/utils/minim_api';

const POLL_TIMEOUT = 30 * 60 * 1000; // 30 minutes

let unumStatusPollIntervals = {};

function shouldPollForStatus(unum){
  // Poll if the given unum is a managed device that is offline, updating or rebooting
  // and we aren't already polling for the status of the given unum
  const inPollableState = (unum.is_rebooting || unum.is_updating || !unum.is_online);
  const alreadyPolling = unumStatusPollIntervals[unum.id];

  return !unum.is_unmanaged && inPollableState && !alreadyPolling;
}

export default {
  namespaced: true,

  state: {
    unums: []
  },

  mutations: {
    set(state, new_unum) {
      let i = state.unums.findIndex(access_point => access_point.id === new_unum.id);

      // If the index is not found...
      // we want to push it in at the end
      i = (i !== -1) ? i : state.unums.length;

      Vue.set(state.unums, i, new_unum);
    },

    setSupportedChannel(state, data) {
      state.supported_channels = data;
      return state;
    },

    many(state, data) {
      state.unums = data;
      return state;
    },

    // Mutation to empty this store
    clear(state) {
      Object.keys(unumStatusPollIntervals).forEach(unumId => {
        clearInterval(unumStatusPollIntervals[unumId]);
        delete unumStatusPollIntervals[unumId];
      });

      state.unums = [];
    },

    remove(state, unumId) {

      if (unumStatusPollIntervals[unumId]) {
        clearInterval(unumStatusPollIntervals[unumId]);
        delete unumStatusPollIntervals[unumId];
      }

      let i = state.unums.findIndex(unum => unum.id === unumId);
      if( i === -1) {
        return;
      }
      state.unums.splice(i, 1);
    }
  },

  actions: {
    async index({ commit, dispatch }) {
      const unums = await minim_api.multiget('api/v1/lans/{lan_id}/unums');
      commit('many', unums);

      unums.forEach((unum) => {
        if (shouldPollForStatus(unum)) {
          dispatch('pollVolatileStateStatus', unum.id);
        }
      });
    },

    async create(context, data) {
      const unum = (await minim_api.post('api/v1/lans/{lan_id}/unums/', data)).data;
      context.commit('set', unum);
      return unum;
    },

    async show(context, { id, shouldPoll = true }) {
      const unum = (await minim_api.get(`api/v1/lans/{lan_id}/unums/${id}`)).data;

      context.commit('set', unum);

      // If we load the unum and it's rebooting or updating start polling to
      // find out when it finishes so the UI updates when it's done
      if (shouldPoll && shouldPollForStatus(unum)) {
        context.dispatch('pollVolatileStateStatus', id);
      }

      return unum;
    },

    async reboot({ commit, dispatch }, unum) {
      await minim_api.post(`api/v1/lans/{lan_id}/unums/${unum['id']}/enqueue_command`, { command: 'diagnostics_reboot' });

      commit('set', { ...unum, is_rebooting: true });

      dispatch('pollVolatileStateStatus', unum.id);
    },

    async rebootAll({ commit, dispatch }, { lanId }) {
      await minim_api.post(`api/v1/lans/${lanId}/reboot`);
      const unums = await minim_api.multiget(`api/v1/lans/${lanId}/unums`);
      for (const unum of unums) {
        if(unum.is_online) {
          commit('set', { ...unum, is_rebooting: true });
          dispatch('pollVolatileStateStatus', unum.id);
        }
      }
    },

    // Starts a long polling session that continues until the unum is neither rebooting or updating
    // both of which are volatile states that we want to watch and update the UI once they've expired
    pollVolatileStateStatus({ dispatch, getters }, unumId) {
      // If we're already polling don't try to start another polling session
      if (unumStatusPollIntervals[unumId]) return;

      const THIRTY_SECONDS = 30 * 1000;
      const quitTime = Date.now() + POLL_TIMEOUT;

      unumStatusPollIntervals[unumId] = setInterval(async () => {
        const unum = getters.getUnum(unumId) || {};

        if ((!unum.is_rebooting && !unum.is_updating && unum.is_online) || Date.now() > quitTime) {
          clearInterval(unumStatusPollIntervals[unumId]);
          delete unumStatusPollIntervals[unumId];
        } else {
          try {
            await dispatch('show', { id: unumId });
          } catch (err) {
            console.log(err);
          }
        }
      }, THIRTY_SECONDS);
    },

    async update(context, unum) {
      await minim_api.patch(`api/v1/lans/{lan_id}/unums/${unum.id}`, { unum });

      return await context.dispatch('show', { id: unum.id, shouldPoll: false });
    },

    async delete(context, { unumId }) {
      await minim_api.delete(`api/v1/lans/{lan_id}/unums/${unumId}`);
      context.commit('remove', unumId);
    }
  },

  getters: {
    unums(state) {
      return state.unums;
    },

    primaryUnum(state) {
      let unum = state.unums.find(u => u.is_gateway || u.is_bridge);
      if (!unum) {
        // if no gateway, see if we have a modem
        unum = state.unums.find(u => u.is_modem);
      }

      if (unum) {
        return unum;
      } else {
        return state.unums[0] || {};
      }
    },

    nonPrimaryUnums(state, getters) {
      const primaryUnum = getters.primaryUnum;

      return state.unums.filter(unum => unum.id !== primaryUnum.id);
    },

    features(_state, getters) {
      return getters.primaryUnum.features || [];
    },

    getUnum: (state) => (unumId) => {
      if (!unumId) {
        return false;
      }

      return state.unums.find(unum => unum.id.toLowerCase() === unumId.toLowerCase());
    },

    getAllSambaSupportedUnums: (state) => {
      return state.unums.filter(unum => {
        if(unum.features) {
          return unum.features.includes('supports_samba');
        }
        return false;
      });
    },

    unumExists: (_state, getters) => (unumId) => {
      return !!getters.getUnum(unumId);
    },

    hasMeshUnums(state) {
      return state.unums.some(u => !!u.mesh_type);
    },

    onlineUnums(state) {
      return state.unums.filter(u => u.is_online);
    },

    offlineUnums(state) {
      return state.unums.filter(u => !u.is_online && !u.is_unmanaged);
    },

    rebootingUnums(state) {
      return state.unums.filter(u => u.is_rebooting && !u.is_unmanaged);
    },

    updatingUnums(state) {
      return state.unums.filter(u => u.is_updating && !u.is_unmanaged);
    },

    applyingConfigUnums(state) {
      return state.unums.filter(u => u.is_applying_config && !u.is_unmanaged);
    },

    allUnumsAreUnmanaged: (state) => {
      return state.unums.every(u => u.is_unmanaged);
    },

    // TODO Mark: this is whack
    supportsMesh(_state, getters) {
      return [
        'gl_b1300',
        'zoom_7020',
        'zoom_7600',
        'minim_q11',
        'minim_g11',
        'minim_q14',
        'minim_q14_openwifi',
        'zoom_7020_openwifi',
        'minim_r14',
        'minim_q15'
      ].includes(getters.primaryUnum.hardware_kind_id);
    },

    currentOnboardingUnum(_state, getters, rootState) {
      const currentUnumId = rootState.OnboardingStore.currentUnumId;

      return getters.getUnum(currentUnumId);
    },

    supportedChannel(state) {
      return state.supported_channels;
    }
  }
};
