<template>
  <div v-if="inEditMode" class="save-or-cancel-section">
    <button @click="$emit('save')" class="save-button">
      {{ $I18n.t('apply') }}
    </button>
    <button @click="handleCancel" class="cancel-button">
      {{ $I18n.t('cancel') }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    componentId: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapGetters('RouterConfigsEditModeStore', ['componentIsInEditMode']),

    inEditMode() {
      return this.componentIsInEditMode(this.componentId);
    }
  },

  methods: {
    handleCancel() {
      // Release control of edit mode
      this.$store.commit('RouterConfigsEditModeStore/releaseEditMode');

      // Emit an event for the parent to handle
      this.$emit('cancel');
    }
  }
};
</script>
