<template>
  <portal to="modal-portal">
    <div v-if="open" class="minim-modal">
      <div class="shadow"></div>

      <transition name="modal">
        <div class="modal-container" :style="containerStyle">
            <div class="modal-body" :style="body_style">
              <i
                @click="close"
                v-if="showCloseButton"
                class="fas fa-times close minim-modal-close-btn"
                :style="iconStyle"
                style="cursor: pointer;"
                >X</i>
              <slot />
            </div>
          </div>
      </transition>
    </div>
  </portal>
</template>

<script>
export default {
  props: {
    scrollable: {
      type: Boolean,
      default: false
    },

    height: {
      type: [Number, String],
      default: ''
    },

    open: {
      type: Boolean,
      default: false
    },

    iconStyle: {
      type: String,
      default: ''
    },

    containerStyle:{
      type: String,
      default: ''
    },

    showCloseButton: {
      type: Boolean,
      default: true
    },

  },

  methods: {
    close() {
      this.$emit('close');
      return(false);
    }
  },

  computed: {
    body_style() {
      let style = '';

      if(this.height) {
        style += `height: ${this.height};`;
      }

      if(this.scrollable) {
        style += 'overflow-y: scroll;';
      }

      return style;
    },
  }
};
</script>
