<template>
  <div class="lan-event" :data-test-id="'lan-event-' + this.eventData.id">
    <lan-activity-timestamp :timestamp="this.eventData.created_at"></lan-activity-timestamp>
    <div class='event-body'>
      <div class='event-type'>{{ eventData.title }}</div>
      <h2>{{ eventData.message }}</h2>
    </div>
  </div>
</template>

<script>
import LanActivityTimestamp from './lan_activity_timestamp.vue';

export default {
  props: ['lanId', 'eventData'],

  components: {
    'lan-activity-timestamp': LanActivityTimestamp
  }
};
</script>
