export default {
  namespaced: true,

  state: {
    build_info: null,
    device_data: null
  },

  mutations: {
    // Info about the application build downloaded on the user's phone
    setBuildInfo(state, build_info) {
      state.build_info = build_info;
      return state;
    },

    // Info about the device the user is currently running the applicaiton on
    setDeviceData(state, device_data) {
      state.device_data = device_data;
      return state;
    }
  },

  actions: {
    async fetchBuildInfo(context) {
      const build_info = await window.native.exec('get_build_info') || {};
      context.commit('setBuildInfo', build_info);
      return build_info;
    },

    async fetchDeviceData(context) {
      const device_data = await window.native.exec('device_data') || {};
      context.commit('setDeviceData', device_data);
      return device_data;
    }
  },

  getters: {
    currentPlatform(state) {
      return state.device_data.platform;
    },

    deviceModel(state) {
      return state.device_data.model;
    }
  }
};
