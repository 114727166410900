import minim_api from 'mobile/shared/utils/minim_api';

export default {
  namespaced: true,

  state: {
    access_requests: []
  },

  mutations: {
    many(state, data) {
      state.access_requests = data;
      return state;
    }
  },

  actions: {
    index(context) {
      return minim_api
        .get('mobile/lans/{lan_id}/access_requests')
        .then(response => (context.commit('many', response.data)));
    },

    approve(context, access_request) {
      return minim_api.post(`mobile/lans/{lan_id}/access_requests/${access_request.id}/grant`)
        .then(response => {
          context.commit('many', response.data);
          this.dispatch('UserStore/showMany', access_request.user_id);
        });
    },

    reject(context, access_request) {
      return minim_api.post(`mobile/lans/{lan_id}/access_requests/${access_request.id}/reject`)
        .then(response => {
          context.commit('many', response.data);
        });
    },
  }
};
