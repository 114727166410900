<template>
  <div class="minim-tooltip-container" :style="`height: ${containerHeight}; width: ${containerWidth};`">
    <!-- Content that you want to hover over to make the tooltip appear goes into this slot -->
    <slot ref="hover_content" name="hover-content" />
    <!-- Content that you want to show in the tooltip goes in this slot -->
    <div class="minim-tooltip" style="margin-bottom:14px" :class="[tooltipPlacement, tooltipAlignment]">
      <slot name="tooltip-content" />
    </div>
  </div>
</template>

<script>
// Supported directions and alignments for the tooltip.
// These map directly to CSS classes, to add a new one add a CSS class and add it to one of these arrays
const SUPPORTED_DIRECTIONS = ['above', 'below'];
const SUPPORTED_ALIGNMENTS = ['left', 'right', 'center'];

export default {
  props: {
    // The placement of the tooltip in relation to the element being hovered over
    // ex: if 'below' is passed the tooltip will be placed below the element being hovered over
    tooltipPlacement: {
      type: String,
      default: 'above',
      validator(value) {
        if (!value) return true;
        else return SUPPORTED_DIRECTIONS.includes(value);
      }
    },

    // The way the tooltip should be aligned in correlation to the element being hovered over
    // ex: if 'left' is passed as this prop, the element being hovered over will be aligned
    // with the left side of the tooltip
    tooltipAlignment: {
      type: String,
      default: 'center',
      validator(value) {
        if (!value) return true;
        else return SUPPORTED_ALIGNMENTS.includes(value);
      }
    }
  },

  data() {
    return {
      containerHeight: 'auto',
      containerWidth: 'auto'
    };
  },

  mounted() {
    // This sets the container's height and width to exactly that of the slot content.
    // This makes it so that the tooltip really will only ever pop up when hovered over the slot.
    this.containerHeight = this.$slots['hover-content'][0].elm.clientHeight + 'px';
    this.containerWidth = this.$slots['hover-content'][0].elm.clientWidth + 'px';
  }
};
</script>
