<template>
  <tr class="grid-row">
    <div class="cell accordion-icon">
      <i class="minim-mobile-icon minim-icon-forward clickable" :class="rotateIcon" @click="toggleIncidentInfo" ></i>
    </div>
    <div class="cell severity">
      <severity-indicator :severity="incident.issue.severity"/>
    </div>
    <div class="cell malware-name">
      <p class="minim-mobile-text secondary-font medium less-light">
        <b>{{ incident.issue.short_name }}</b>
      </p>
    </div>
    <div class="cell malware-description">
      <p class="minim-mobile-text secondary-font medium less-light clickable" @click="openIssueModal">
      {{ text }}
      </p>
      <i class="minim-mobile-icon minim-icon-forward clickable" @click="openIssueModal"></i>
    </div>
    <div class="cell">
      <p class="minim-mobile-text secondary-font medium less-light">
        {{ lastDetectedAt }}
      </p>
    </div>
    <div class="cell malware-buttons btns">
      <div class="btn-1">
      <button
        @click="updateIncident('false_positive')"
        :class="`${disableButtons ? 'disabled' : 'clear'}`"
        :content="$I18n.t('lans.security_incidents.tooltips.false_positive')"
        v-tippy="{ placement: 'top-end' }"
      ><span>{{ $I18n.t('components.security_incidents.false_positive') }}</span></button>
      </div>
      <div>
      <button
        @click="updateIncident('confirmed')"
        :class="`${disableButtons ? 'disabled' : 'good'}`"
        :content="$I18n.t('lans.security_incidents.tooltips.resolve_issue')"
        v-tippy="{ placement: 'top-end' }"
      >{{ $I18n.t('components.security_incidents.resolved') }}</button>
      </div>
    </div>
    <div class="cell incident-info" :class="incidentInfoStatus">
      <incident-info
        :incident="incident"
      />
    </div>

  </tr>
</template>

<script>
import SeverityIndicator from './severity_indicator.vue';
import IncidentInfo from './incident_info.vue';
import moment from 'moment-timezone';

export default {
  props: {
    incident: {
      type: Object
    },
    disableButtons: {
      type: Boolean,
      default: false
    }
  },

  components: {
    'severity-indicator': SeverityIndicator,
    'incident-info': IncidentInfo,
  },

  data() {
    return {
      showIncidentInfo: false,
    };
  },

  methods: {
    toggleIncidentInfo() {
      this.showIncidentInfo = !this.showIncidentInfo;
    },

    openIssueModal() {
      window.eventBus.$emit('open-issue-modal', this.incident.issue);
    },

    updateIncident(status) {
      window.eventBus.$emit('update-incident', this.incident.uuid, status);
    }
  },

  computed: {
    text() {
      let explanation = this.incident.issue.explanation;
      if(!explanation){
        return '';
      }
      var sliced = explanation.slice(0,40);

      return sliced.substr(0,sliced.lastIndexOf(' '));
    },

    rotateIcon(){
      return this.showIncidentInfo ? 'rotate-90' : 'rotate-0';
    },

    incidentInfoStatus(){
      return this.showIncidentInfo ? '' : 'inactive';
    },

    device(){
      return this.incident['device'];
    },

    lastDetectedAt(){
      return moment.tz(this.incident.updated_at, moment.tz.guess()).format('hh:mm A M/D/YY');
    }
  }
};
</script>
