<template>
  <div class="time-scale graph bandwidth_graph minim-graph" :class="{'is-mobile': isMobile}">
    <div class="vue-graph" :class="desired_graph_type">
      <div v-if="is_loading" class="graph-spinner-container">
        <i class="fas fa-spinner fa-spin" />
      </div>
      <div class="banner">
        <h2 v-if="title" id="minimGraphTitle">{{title}}</h2>

        <ul v-if="toggles" class="buttons graph_type">
          <li
            v-for="(toggle, index) in toggles"
            @click="set_desired_graph_type(toggle.value)"
            class="button"
            :class="{selected: desired_graph_type == toggle.value}"
            :data-test-id="toggle.value"
            :key="`toggle-${index}`"
          >
            <span>{{toggle.name}}</span>
          </li>
        </ul>

        <ul v-if="time_range_enabled" class="buttons time_scale">
          <li v-if="is_zoomed" @click="zoom_undo" class="button"><i class="fas fa-undo"></i></li>
          <li v-if="is_zoomed" class="button selected"><span>Custom</span></li>
          <li class="button" :class='{selected: (by=="last_hour" && !is_zoomed)}' @click='set_time_scale("last_hour")'><span>{{ $I18n.t('past_hour') }}</span></li>
          <li class='button' :class='{selected: (by=="last_day"  && !is_zoomed)}' @click='set_time_scale("last_day")'><span>{{ $I18n.t('past_day') }}</span></li>
          <li class="button" :class='{selected: (by=="last_week" && !is_zoomed)}' @click='set_time_scale("last_week")'><span>{{ $I18n.t('past_week') }}</span></li>
          <li v-if="supportsNetworkBreakdown" @click="toggle_network_breakdown" class="button" :class="{selected: (range_select_function == 'network_breakdown')}"><span>NB</span></li>
        </ul>
      </div>
      <div class="graph-content-container">
        <!-- If the chart has data, show it -->
        <highcharts ref="highcharts" v-if="has_data" :options="new_options" />
        <!-- else if we've failed to retrieve data and we're not loading, show a message that we've failed -->
        <p v-else-if="!is_loading" class="no-data banner-message">
          {{ $I18n.t('graphs.not_enough_data') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import applyGraphFormatters from 'graph_formatters/index.js';
import $ from 'jquery';

/**
 * Used to deep merge the graph options with any options tweaks passed in as props
 * Adds any keys in tweaks that do not exist in options to options, and replaces the values of any
 * duplicate keys between options and tweaks with the value in tweaks
 */
function deep_merge_options(options, tweaks) {
  Object.keys(tweaks).forEach((key) => {
    // If options has the property "key" and that property is an object go one level deeper to avoid overwriting anything in the object we don't want to
    if (Object.prototype.hasOwnProperty.call(options, key) && typeof options[key] === 'object' && !(options[key] instanceof Array)) {
      deep_merge_options(options[key], tweaks[key]);
    } else {
      // Else add/overwrite the key in options
      options[key] = tweaks[key];
    }
  });

  return options;
}

export default {
  props: [
    // REQUIRED
    'queryOpts',
    'graphName',
    ////

    'supportsNetworkBreakdown',
    'timeRangeEnabled',
    'title',
    'toggles',
    'width',
    'isMobile',
    'isMultiseries',
    'autoRefresh',
    'filters',
    'hideInfo',
    'reloadInterval',
    'optionsTweaks', // last minute tweaks applied to the highcharts config options, intended for handling differences between mobile & care portal charts
  ],

  mounted() {
    this.desired_graph_type = this.graphName;

    if (this.timeRangeEnabled == null) {
      this.time_range_enabled = true;
    } else if (this.timeRangeEnabled == 'false') {
      this.time_range_enabled = false;
    } else {
      this.time_range_enabled = !!this.timeRangeEnabled;
    }
    this.auto_refresh = this.autoRefresh == null ? true : !!this.autoRefresh;
    if (this.reloadInterval) {
      this.reload_interval = this.reloadInterval;
    }

    if(this.auto_refresh) {
      this.interval_handler = setInterval(() => {
        this.debounced_reload_graph_data();
      }, this.reload_interval);
    }

    this.reload_graph_data().then(()=>{
      $(this.$el).css('min-height', this.$el.offsetHeight);
    });
  },

  beforeDestroy() {
    if(this.interval_handler) clearInterval(this.interval_handler);
  },

  methods: {
    debounced_reload_graph_data() {
      // Cancel the reload if the user is zoomed in on a specific area of the graph
      if (this.is_zoomed) {
        return;
      }

      this.is_loading = true;

      if(this.debounce_timeout !== null) {
        clearTimeout(this.debounce_timeout);
      }

      this.debounce_timeout = setTimeout(() => {
        this.debounce_timeout = null;
        this.reload_graph_data();
      }, this.debounce_interval);
    },

    reload_graph_data() {
      let graph_request_data = {
        graph: this.desired_graph_type,
        time_scale: this.by,
        query_opts: Object.assign({}, this.queryOpts)
      };

      graph_request_data['query_opts']['end'] = this.ends_at || this.queryOpts.end;
      graph_request_data['query_opts']['start'] = this.starts_at || this.queryOpts.start;

      if(this.filters) {
        if(this.filters.selected_toggle != 'all') {
          graph_request_data.query_opts['last_connection_kind_string'] = this.filters.selected_toggle;
        }

        if(this.filters.fuzzy != '' && this.filters.mac_addresses) {
          // if we have no mac_addresses to show for a text search
          // we shouldnt render the graph
          if(this.filters.mac_addresses.length == 0) {
            this.new_options.series = [];
            this.new_options.xAxis.plotBands = [];
            this.is_loading = false;
            return;
          } else {
            graph_request_data.query_opts['mac_addresses'] = this.filters.mac_addresses;
          }
        }
      }

      // Do the graph data request
      this.is_loading = true;
      return $.get('/graph/show.json', graph_request_data, this.on_new_data.bind(this));
    },

    set_time_scale(scale) {
      this.by = scale;

      this.reset_zooming();

      this.reload_graph_data();
    },

    set_desired_graph_type(type) {
      this.desired_graph_type = type;
      this.reload_graph_data();
    },

    on_new_data(data) {
      if (data) {
        applyGraphFormatters(data);
        this.new_options = data;
        if(this.new_options.xAxis) this.new_options.xAxis.events = { setExtremes: this.on_range_select.bind(this) };
        if(this.width) this.new_options.chart.width = this.width;
        if(this.width == 'auto') this.new_options.chart.width = null;

        if(this.new_options.series) {
          for (let i = 0; i < this.new_options.series.length; i++) {
            // Add click event to each series item to save its visibility in state
            this.new_options.series[i].events = {
              legendItemClick: () => {
                // If the visible state is undefined clicking it should set the visibility to false because
                // Highcharts defaults undefined values to being visible, else use opposite of previous state
                this.visible_series[this.new_options.series[i].name]
                  = this.new_options.series[i].visible === undefined ? false : !(this.new_options.series[i].visible);
              }
            };
            // Set the visibility of series items to the remembered value in the state
            this.new_options.series[i].visible = this.visible_series[this.new_options.series[i].name];
          }
        }

        // tweak some stuff if we're mobile
        if(this.isMobile) {
          this.new_options.customData.is_mobile = true;
          this.new_options.yAxis.title = null;
        }

        // Finally, apply any passed in tweaks to the new_options object
        if (this.optionsTweaks) {
          this.new_options = deep_merge_options(this.new_options, this.optionsTweaks);
        }
      } else {
        this.new_options.series = [];
        this.new_options.xAxis.plotBands = [];
      }

      // Emit the chart options once we're done processing them
      this.$emit('options', this.new_options);

      // After the DOM updates emit the chart, if it exists
      this.$nextTick(() => {
        if (this.$refs.highcharts && this.$refs.highcharts.chart) {
          this.$emit('chart', this.$refs.highcharts.chart);
        }
      });

      this.is_loading = false;
    },

    on_range_select(event) {
      event.preventDefault();

      let starts_at = event.min ? event.min / 1000 : null;
      let ends_at = event.max ? event.max / 1000 : null;

      this.$emit('range_select', {starts_at: starts_at, ends_at: ends_at, method: this.range_select_function});

      switch(this.range_select_function) {
      case 'zoom':
        this.process_zoom(starts_at, ends_at);
        break;
      default:
        this.range_select_function = 'zoom';
        break;
      }
    },

    toggle_network_breakdown() {
      if(this.range_select_function == 'network_breakdown') {
        this.range_select_function = 'zoom';
      } else {
        this.range_select_function = 'network_breakdown';
      }
    },

    process_zoom(starts_at, ends_at) {
      if(!starts_at && !ends_at) return;

      // Round our times to the nearest minute, with some padding...
      starts_at = Math.round(starts_at/60)*60 - 10;
      ends_at = Math.round(ends_at/60)*60 + 10;

      let total_time_in_seconds = (ends_at - starts_at) / 60;

      // Only continue if we can acutally display 2 points on the graph
      if(total_time_in_seconds < 1) return;

      // Start recording zoom history if we have history to record...
      // and we are actually on a different zoom level
      if(this.starts_at && this.ends_at && !(this.starts_at == starts_at && this.ends_at == ends_at)) {
        this.zoom_history.push({
          starts_at: this.starts_at,
          ends_at: this.ends_at,
        });
      }

      // Set our starts and ends at off of the zoom values
      this.starts_at = starts_at;
      this.ends_at = ends_at;

      this.reload_graph_data();
    },

    zoom_undo() {
      let history_data = this.zoom_history.pop();

      // If we have history data, jump to that,
      // if else, we just want to reset the zooming
      if(history_data) {
        this.starts_at = history_data.starts_at;
        this.ends_at = history_data.ends_at;
      } else {
        this.reset_zooming();
      }

      this.reload_graph_data();
    },

    reset_zooming() {
      this.starts_at = null;
      this.ends_at = null;
      this.$emit('range_select', {starts_at: this.starts_at, ends_at: this.ends_at, method: this.range_select_function});

      this.zoom_history = [];
    }
  },

  watch: {
    filters: {
      handler() {
        this.debounced_reload_graph_data();
      },
      deep: true
    },

    desired_graph_type(new_graph_type) {
      this.$emit('graph_type_change', new_graph_type);
    }
  },

  computed: {
    has_data() {
      if (!this.new_options.series) {
        return false;
      }

      // if we are dealing with a graph that has multiple series we should return false if any of the series don't have data
      if (this.isMultiseries) {
        for (let i = 0; i < this.new_options.series.length; i++) {
          if (this.new_options.series[i].data.length === 0) {
            return false;
          }
        }
        return true;
      }

      return this.new_options.series.length > 0;
    },

    is_zoomed() {
      return !!this.ends_at && !!this.starts_at;
    }
  },

  data() {
    return {
      time_range_enabled: null,
      auto_refresh: null,
      reload_interval: (60 * 1000),
      interval_handler: null,
      debounce_interval: 500,
      debounce_timeout: null,
      by: 'last_hour',
      desired_graph_type: null,
      new_options: {},
      is_loading: true,

      // zoom state management
      range_select_function: 'zoom',
      zoom_history: [],
      ends_at: null,
      starts_at: null,
      visible_series: {}
    };
  }
};
</script>
