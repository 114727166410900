import moment from 'moment';
import format from 'mobile/shared/utils/formatters';

/* eslint-disable */
function nice_number(number) {
  number = number < 10 ? number.toFixed(1) : number.toFixed();

  return window.$I18n.n(number);
}

function rounded_data_max(max) {
  if (max < 1024) return max.toFixed();
  max = max / 1024;
  if (max < 1024) return max.toFixed() * 1024;
  max = max / 1024;
  if (max < 1024) return max.toFixed() * Math.pow(1024, 2);
  max = max / 1024;
  if (max < 1024) return max.toFixed() * Math.pow(1024, 3);
  max = max / 1024;
  if (max < 1024) return max.toFixed() * Math.pow(1024, 4);
  max = max / 1024;
  if (max < 1024) return max.toFixed() * Math.pow(1024, 5);
}

function add_binary_prefix(num, extra, unit) {
  extra = extra || '';
  let rate = Math.abs(num);

  let unit_type = (unit == 'B') ? 'bytes_abbrev' : 'bitpersecond_abbrev';

  if (rate < 1024) return nice_number(rate) + extra + window.$I18n.t('units.' + unit_type);
  rate = rate / 1024;
  if (rate < 1024) return nice_number(rate) + extra + window.$I18n.t('units.kilo' + unit_type);
  rate = rate / 1024;
  if (rate < 1024) return nice_number(rate) + extra + window.$I18n.t('units.mega' + unit_type);
  rate = rate / 1024;
  if (rate < 1024) return nice_number(rate) + extra + window.$I18n.t('units.giga' + unit_type);
  rate = rate / 1024;
  if (rate < 1024) return nice_number(rate) + extra + window.$I18n.t('units.tera' + unit_type);
  rate = rate / 1024;
  return nice_number(rate) + extra + window.$I18n.t('units.peta' + unit_type);
}

function convert_negative_num_to_positive(num) {
  if (typeof num === 'number' && num < 0) {
    return num * -1;
  }

  return num;
}

function formatTime(date) {
  //date = createDateAsUTC(date);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;

  var monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  return date.getDate() + ' of ' + monthNames[date.getMonth()] + ' at ' + strTime;
}

function signalStrengthRank(signalStrength) {
  const textCss = 'minim-mobile-text primary-font medium';
  const fixedSignalStrength = signalStrength.toFixed(0);

  if (fixedSignalStrength >= -60) return `<span class="${textCss} text-positive-color">${window.$I18n.t('mobile.devices.show.signal_strength_card.strength_levels.good')}</span>`;
  else if (fixedSignalStrength >= -70) return `<span class="${textCss} text-secondary-color">${window.$I18n.t('mobile.devices.show.signal_strength_card.strength_levels.ok')}</span>`;
  else return `<span class="${textCss} text-negative-color">${window.$I18n.t('mobile.devices.show.signal_strength_card.strength_levels.poor')}</span>`;
}

function getUnitsFromRange(range) {
  if (range > 86400000 * 2) return window.$I18n.t('past_day').toLowerCase();
  else if (range > 3600000 * 2) return window.$I18n.t('past_hour').toLowerCase();
  else if (range > 60000 * 2) return window.$I18n.t('past_minute').toLowerCase();
  else return window.$I18n.t('past_second').toLowerCase();
}

function bandwidth_stacked_x_axis_week_html(val) {
  return `<div class="minim-mobile-text secondary-font small">${val}</div>`;
}

function bandwidth_stacked_x_axis_html(startTime, endTime) {
  return `<div class="minim-mobile-text secondary-font small">${startTime} -<br/>${endTime}</div>`;
}

var formatters = {
  open_lan: function() {
    window.open(`/lans/${names_to_macs[this.name]}`, '_blank');
  },

  bytes_with_unit: function() {
    return add_binary_prefix(this.value, '', 'B');
  },

  bytes_to_nice_name() {
    const is_mobile = !!this.chart.options.customData.is_mobile;
    const numBytes = this.value;

    const extra = is_mobile ? '<br>' : '';

    if (numBytes == 0) return '0';

    return add_binary_prefix(numBytes, extra, 'bps');
  },

  stacked_bandwidth_graph_y_axis_formatter() {
    const is_bottom_label = this.value === 0;
    const axis_label = is_bottom_label ? 0 : add_binary_prefix(this.value, '&nbsp;', 'B')
    const extra_classes = is_bottom_label ? 'bottom-label' : '';

    return `
      <div class="border-rounded shadow-small mobile-stacked-bandwidth-y-axis-label ${extra_classes}">
        <span class="minim-mobile-text primary-font text-neutral-darker-color small nowrap">${axis_label}</span>
      </div>
    `;
  },

  empty: function() {
    return('');
  },

  bps_shared_tooltip: function() {
    var s = formatTime(new Date(this.x));

    this.points.sort(function(a,b) {
      return b.y - a.y;
    });

    $.each(this.points, function(i, point) {
      if(i > 3) return;
      if(point.y != 0) {
        s += '<br/><span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + add_binary_prefix(point.y, '', 'bps') + '</b>';
      }
    });

    return s;
  },

  bps_tooltip: function() {
    var s = formatTime(new Date(this.x));

    s += '<br/><span style="color:' + this.point.color + '">\u25CF</span> ' + this.point.series.name + ': <b>' + add_binary_prefix(this.point.y, '', 'bps') + '</b>';

    return s;
  },

  by_device_bps_tooltip: function() {
    var s = formatTime(new Date(this.x));

    s += '<br/><b>' + this.point.series.name + '</b>';
    s += '<br/><span style="color:' + this.point.color + '">\u25CF</span> ' + window.$I18n.t('download') + ': <b>' + add_binary_prefix(this.point.download, '', 'bps') + '</b>';
    s += '<br/><span style="color:' + this.point.color + '">\u25CF</span> ' + window.$I18n.t('upload') + ': <b>' + add_binary_prefix(this.point.upload, '', 'bps') + '</b>';

    return s;
  },

  ssid_signal_strength_tooltip: function() {
    var strength = '';
    if (this.point.y.toFixed(0) != -100) {
      strength = '' + this.point.y.toFixed(0) + ' ' + window.$I18n.t('units.decibel_milliwatts_abbrev') + '</b>';
    }
    return '' + this.point.series.name + '<br/><b>' + strength;
  },

  signal_strength_tooltip: function() {
    var s = formatTime(new Date(this.x));

    $.each(this.points, function(i, point) {
      if(point.y != 0) {
        s += '<br/><span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + point.y.toFixed(0) + ' ' + window.$I18n.t('units.decibel_milliwatts_abbrev') + '</b>';
      }
    });

    return s;
  },

  link_to_lan: function() {
    window.open('/lans/' + this.name, '_blank');
  },

  busiest_lans_tooltip: function() {
    var s = this.series.chart.options.customData.lans_names_by_macs[this.point.name];
    s += '<br/><span style="color:' + this.point.color + '">\u25CF</span> ' + this.point.series.name + ': <b>' + add_binary_prefix(this.point.y, '', 'B') + '</b>';
    return s;
  },

  problem_lans_tooltip: function() {
    var s = this.series.chart.options.customData.lans_names_by_macs[this.point.name];
    s += '<br/><span style="color:' + this.point.color + '">\u25CF</span> ' + this.point.series.name + ': <b>' + this.point.y + '</b>';
    return s;
  },

  mac_to_lan_name: function() {
    return this.chart.options.customData.lans_names_by_macs[this.value];
  },

  mobile_service_traffic_data_label() {
    // Hide the data label if the point does not take up enough of the pie chart in order to remove clutter
    return this.point.percentage > 8
      ? `<span class="minim-mobile-text secondary-font text-neutral-lightest-color medium traffic-data-label-font">${this.point.name}</<span>`
      : '';
  },

  mobile_service_traffic_tooltip() {
    const percentage = window.$I18n.n(((this.point.percentage || 0) / 100), { style: 'percent' });

    if(format.checkIfIPv6(this.point.name)) {
      let strArr = this.point.name.match(/.{1,10}/g);
      let str = `<div style="background: #fff;" class="border-rounded shadow-medium p-12">`;
      strArr.forEach((val) => {
        str += (`<h6 class="minim-mobile-text secondary-font large block" style="color: ${this.point.color}">${val}</h6>`);
      });
      str += `<p class="minim-mobile-text secondary-font medium">${window.$I18n.t('total_usage')}: <span style="color: ${this.point.color}">${percentage}<span /></p></div>`;
      return str;
    }else {
      return `
        <div style="background: #fff;" class="border-rounded shadow-medium p-12">
          <h6 class="minim-mobile-text secondary-font large block" style="color: ${this.point.color}">${this.point.name}</h6>
          <p class="minim-mobile-text secondary-font medium">${window.$I18n.t('total_usage')}: <span style="color: ${this.point.color}">${percentage}<span /></p>
        </div>
      `;
    }
  },

  mobile_signal_strength_y_axis_label() {
    return `
      <div class="border-rounded shadow-small mobile-signal-strength-yaxis-label">
        <span class="minim-mobile-text primary-font text-neutral-darker-color small less-light">${window.$I18n.n(this.value.toFixed(0))} ${window.$I18n.t('units.decibel_milliwatts_abbrev')}</span>
      </div>
    `;
  },

  mobile_signal_strength_tooltip() {
    return `
      <div style="background: #fff;" class="w-full border-rounded p-16 shadow-medium">
        ${signalStrengthRank(this.points[0].y)}
        <br />
        <span class="minim-mobile-text secondary-font block medium p-t-6">
          ${window.$I18n.n(this.points[0].y.toFixed(0))} ${window.$I18n.t('units.decibel_milliwatts_abbrev')}
        </span>
        <span class="minim-mobile-text x-small text-neutral-darker-color block secondary-font p-t-8">
          ${formatTime(new Date(this.x))}
        </span>
      </div>
    `;
  },

  mobile_signal_strength_legend_label() {
    let labelMarkup = `
      <div style="background: #fff;" class="mobile-bandwidth-legend-label ${this.visible ? null : 'opacity-50'}">
        <span class="minim-mobile-text secondary-font medium m-l-8">${this.name}</span>
      </div>
    `;

    return labelMarkup;
  },

  mobile_bandwidth_tooltip() {
    let tooltipMarkup = `<div class="mobile-bandwidth-graph-tooltip">`;

    this.points.forEach(point => {
      tooltipMarkup += `
        <div class="bw-usage">
          <span class="legend-dot" style="background-color: ${point.color};"></span>
          <span class="minim-mobile-text secondary-font block medium p-t-6">${add_binary_prefix(point.y, ' ', 'bps')}</span>
        </div>
      `
    });

    tooltipMarkup += `
      <span class="minim-mobile-text x-small neutral-darker-color block secondary-font p-t-8">
        ${formatTime(new Date(this.x))}
      </span>
    `;

    tooltipMarkup += `</div>`;

    return tooltipMarkup;
  },

  mobile_bandwidth_label() {
    let labelMarkup = `
      <div class="border-rounded shadow-small" style="background: #fff; padding: 2px 8px; z-index: 1;">
        <span class="minim-mobile-text primary-font small text-neutral-darker-color">${add_binary_prefix(this.value, ' ', 'bps')}</span>
      </div>
    `;

    return labelMarkup;
  },

  mobile_bandwidth_legend_label() {
    let labelMarkup = `
      <div style="background: #fff;" class="mobile-bandwidth-legend-label ${this.visible ? null : 'opacity-50'}">
        <div style="background: ${this.color};" class="legend-label-circle"></div>
        <span class="minim-mobile-text secondary-font medium m-l-8">${this.name}</span>
      </div>
    `;

    return labelMarkup;
  },

  // Equally distributes ticks at four different points and ensures that the ticks will always start and end with the
  // min/max possible ticks
  mobile_tick_positioner(min, max) {
    let range = max - min;
    let step = Math.floor(range / 6);
    let ticks = [min, min + (step * 2), min + (step * 4), max];

    ticks.info = {
      unitName: getUnitsFromRange(range),
      higherRanks: {},
      totalRange: range
    };

    return ticks;
  },

  online_offline_tooltip_point() {
    let formatted_y = convert_negative_num_to_positive(this.y);
    formatted_y = Math.round(formatted_y);

    return `
      <p><span style="color: ${this.color}">\u25CF</span> ${this.series.name}: <strong>${formatted_y}</strong></p>
    `;
  },

  online_offline_y_axis_label() {
    return convert_negative_num_to_positive(this.value);
  },

  bandwidth_stacked_mouse_over() {
    this.series.chart.series.forEach(series => {
      if(series){
        series.points.forEach(dataPoint => {
          if (dataPoint.category !== this.category) {
            dataPoint.setState('ignored');
          }
        });
      }
    });
  },

  bandwidth_stacked_mouse_out() {
    this.series.chart.series.forEach(series => {
      if(series){
        series.points.forEach(dataPoint => {
          dataPoint.setState('normal');
        });
      }
    });
  },

  bandwidth_by_device_stacked_tooltip() {
    const convertedtBytes = add_binary_prefix(this.total, ' ', 'B')

    return `
      <div class="p-t-4 p-b-4 p-r-12 p-l-12">
        <p class="minim-mobile-text secondary-font large text-neutral-darkest-color m-b-4">${window.$I18n.t('insights.total')}</p>
        <p class="minim-mobile-text secondary-font medium text-neutral-darker-color">${convertedtBytes}</p>
      </div>
    `
  },

  min_and_max_ticks() {
    if (!this.max) return [0, 0];

    const max = rounded_data_max(this.max);

    return [0, max]
  },

  bandwidth_stacked_x_axis_week_label: function() {
    return bandwidth_stacked_x_axis_week_html(moment.utc(this.value).local().format('M/DD'));
  },

  bandwidth_stacked_x_axis_day_label() {
    const startTime = moment.utc(this.value).local().format('h:mm A');
    var endTime = moment.utc(this.value).add(15, 'minutes').local().format('h:mm A');

    if (this.chart.options.customData.by == 'last_day') {
      endTime = moment.utc(this.value).add(6, 'hours').local().format('h:mm A');
    }

    return bandwidth_stacked_x_axis_html(startTime, endTime);
  }
};

/**
 * Applies any formatter callback functions that were specified in a set of given graph options
 */
export default function applyGraphFormatters(object) {
  for(var x in object) {
    if(object.hasOwnProperty(x)) {
      if(typeof object[x] == 'object') {
        applyGraphFormatters(object[x]);
      }
      if(typeof object[x] == 'string' && object[x].match(/^FUNCTION\|/)) {
        object[x] = formatters[object[x].match(/\.(.*)/)[1]];
      }
    }
  }
};
