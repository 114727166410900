// GLi.Net B-1300
import GLB1300 from 'mobile/modules/onboarding/images/custom_setup_instructions/gl_b1300/device-image.png';
import GLB1300Failure from 'mobile/modules/onboarding/images/custom_setup_instructions/gl_b1300/device-failure.png';
import GLB1300Success from 'mobile/modules/onboarding/images/custom_setup_instructions/gl_b1300/device-success.png';
import GLB1300SetupStep1 from 'mobile/modules/onboarding/images/custom_setup_instructions/gl_b1300/setup-step-1.png';
import GLB1300SetupStep2 from 'mobile/modules/onboarding/images/custom_setup_instructions/gl_b1300/setup-step-2.png';
import GLB1300SetupStep3 from 'mobile/modules/onboarding/images/custom_setup_instructions/gl_b1300/setup-step-3.png';
import GLB1300SetupStep4 from 'mobile/modules/onboarding/images/custom_setup_instructions/gl_b1300/setup-step-4.png';
import GLB1300FirmwareUpdateStart from 'mobile/modules/onboarding/images/custom_setup_instructions/gl_b1300/firmware-update-start.png';
import GLB1300DevicePower from 'mobile/modules/onboarding/images/custom_setup_instructions/gl_b1300/device-power.png';
import GLB1300DeviceUpdating from 'mobile/modules/onboarding/images/custom_setup_instructions/gl_b1300/device-updating.png';
import GLB1300FirmwareUpdateComplete from 'mobile/modules/onboarding/images/custom_setup_instructions/gl_b1300/firmware-update-complete.png';
import GLB1300FirmwareUpdateComplete2 from 'mobile/modules/onboarding/images/custom_setup_instructions/gl_b1300/firmware-update-complete-2.png';
import GLB1300MeshSetupStep1 from 'mobile/modules/onboarding/images/add_mesh_extender_setup/gl_b1300/setup-step-1.png';

// Zoom 7020
import Zoom7020 from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7020/device-image.png';
import Zoom7020Failure from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7020/device-failure.png';
import Zoom7020Success from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7020/device-success.png';
import Zoom7020SetupStep1 from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7020/setup-step-1.png';
import Zoom7020SetupStep2 from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7020/setup-step-2.png';
import Zoom7020SetupStep3 from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7020/setup-step-3.png';
import Zoom7020SetupStep4 from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7020/setup-step-4.png';
import Zoom7020FirmwareUpdateStart from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7020/firmware-update-start.png';
import Zoom7020DevicePower from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7020/device-power.png';
import Zoom7020DeviceUpdating from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7020/device-updating.png';
import Zoom7020FirmwareUpdateComplete from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7020/firmware-update-complete.png';
import Zoom7020FirmwareUpdateComplete2 from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7020/firmware-update-complete-2.png';
import Zoom7020MeshSetupStep1 from 'mobile/modules/onboarding/images/add_mesh_extender_setup/zoom_7020/setup-step-1.png';
import Zoom7020EthernetSetupStep1 from 'mobile/modules/onboarding/images/ethernet_instructions/zoom_7020/ethernet-setup-1.png';
import Zoom7020EthernetSetupStep2 from 'mobile/modules/onboarding/images/ethernet_instructions/zoom_7020/ethernet-setup-2.png';
import Zoom7020EthernetSetupStep3 from 'mobile/modules/onboarding/images/ethernet_instructions/zoom_7020/ethernet-setup-3.png';
import Zoom7020EthernetSetupStep4 from 'mobile/modules/onboarding/images/ethernet_instructions/zoom_7020/ethernet-setup-4.png';

// MH7600
import Zoom7600 from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7600/device-image.png';
import Zoom7600Failure from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7600/device-failure.png';
import Zoom7600Success from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7600/device-success.png';
import Zoom7600SetupStep1 from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7600/setup-step-1.png';
import Zoom7600SetupStep2 from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7600/setup-step-2.png';
import Zoom7600SetupStep3 from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7600/setup-step-3.png';
import Zoom7600SetupStep4 from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7600/setup-step-4.png';
import Zoom7600FirmwareUpdateStart from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7600/firmware-update-start.png';
import Zoom7600DevicePower from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7600/device-power.png';
import Zoom7600DeviceUpdating from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7600/device-updating.png';
import Zoom7600FirmwareUpdateComplete from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7600/firmware-update-complete.png';
import Zoom7600FirmwareUpdateComplete2 from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_7600/firmware-update-complete-2.png';
import Zoom7600MeshSetupStep1 from 'mobile/modules/onboarding/images/add_mesh_extender_setup/zoom_7600/setup-step-1.png';

// MG8702
import MG8702 from 'mobile/modules/onboarding/images/custom_setup_instructions/zoomrg_bcm_9339/device-image.png';
import MG8702Failure from 'mobile/modules/onboarding/images/custom_setup_instructions/zoomrg_bcm_9339/device-failure.png';
import MG8702Success from 'mobile/modules/onboarding/images/custom_setup_instructions/zoomrg_bcm_9339/device-success.png';
import MG8702SetupStep1 from 'mobile/modules/onboarding/images/custom_setup_instructions/zoomrg_bcm_9339/setup-step-1.png';
import MG8702FirmwareUpdateStart from 'mobile/modules/onboarding/images/custom_setup_instructions/zoomrg_bcm_9339/firmware-update-start.png';
import MG8702DevicePower from 'mobile/modules/onboarding/images/custom_setup_instructions/zoomrg_bcm_9339/device-power.png';
import MG8702DeviceUpdating from 'mobile/modules/onboarding/images/custom_setup_instructions/zoomrg_bcm_9339/device-updating.png';
import MG8702FirmwareUpdateComplete from 'mobile/modules/onboarding/images/custom_setup_instructions/zoomrg_bcm_9339/firmware-update-complete.png';
import MG8702FirmwareUpdateComplete2 from 'mobile/modules/onboarding/images/custom_setup_instructions/zoomrg_bcm_9339/firmware-update-complete-2.png';

// MT8733 & MH8725
import MT8733 from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_8733/device-image.png';
import MT8733Failure from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_8733/device-failure.png';
import MT8733Success from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_8733/device-success.png';
import MT8733SetupStep1 from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_8733/setup-step-1.png';
import MT8733FirmwareUpdateStart from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_8733/firmware-update-start.png';
import MT8733DevicePower from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_8733/device-power.png';
import MT8733DeviceUpdating from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_8733/device-updating.png';
import MT8733FirmwareUpdateComplete from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_8733/firmware-update-complete.png';
import MT8733FirmwareUpdateComplete2 from 'mobile/modules/onboarding/images/custom_setup_instructions/zoom_8733/firmware-update-complete-2.png';

// Q14
import Q14 from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_q14/device-image.png';
import Q14Failure from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_q14/device-failure.png';
import Q14Success from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_q14/device-success.png';
import Q14SetupStep1 from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_q14/setup-step-1.png';
import Q14SetupStep2 from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_q14/setup-step-2.png';
import Q14SetupStep3 from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_q14/setup-step-3.png';
import Q14SetupStep4 from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_q14/setup-step-4.png';
import Q14FirmwareUpdateStart from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_q14/firmware-update-start.png';
import Q14DevicePower from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_q14/device-power.png';
import Q14DeviceUpdating from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_q14/device-updating.png';
import Q14FirmwareUpdateComplete from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_q14/firmware-update-complete.png';
import Q14FirmwareUpdateComplete2 from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_q14/firmware-update-complete-2.png';
import Q14MeshSetupStep1 from 'mobile/modules/onboarding/images/add_mesh_extender_setup/minim_q14/setup-step-1.png';

// Additional mobile onboarding images
import Camera from 'mobile/modules/onboarding/images/scan_qr_code/camera.png';
import QRCodeIcon from 'mobile/modules/onboarding/images/qr_code_fork/qr-code-icon.png';
import SetupQRScan from 'mobile/modules/onboarding/images/scanner/setup-qr-scan.png';
import GreenCheckMark from 'mobile/shared/images/green-check-mark.png';
import Cloud from 'mobile/modules/onboarding/images/poll_router_for_connection/cloud.png';
import GenericFirmwareUpdatePrompt from 'mobile/modules/onboarding/images/firmware_update/prompt.png';
import GenericFirmwareUpdateInstructions from 'mobile/modules/onboarding/images/firmware_update/instructions.png';
import GenericFirmwareUpdateUpdating from 'mobile/modules/onboarding/images/firmware_update/updating.png';
import GenericFirmwareUpdateSuccess from 'mobile/modules/onboarding/images/firmware_update/success.png';
import GenericFirmwareUpdateSuccess2 from 'mobile/modules/onboarding/images/firmware_update/success-2.png';
import GenericFirmwareUpdateFailure from 'mobile/modules/onboarding/images/firmware_update/failure.png';
import MotosyncDevicesImage from 'mobile/modules/onboarding/images/join_existing_network/motosync-devices.png';
import DefaultDevicesImage from 'mobile/modules/onboarding/images/join_existing_network/default-devices.png';

// B12
import B12 from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_b12/setup-device.png';
import B12Failure from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_b12/setup-connected-x.png';
import B12Success from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_b12/setup-connected-checkmark.png';

// G11
import G11 from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_g11/setup-device.png';
import G11Failure from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_g11/setup-connected-failure.png';
import G11Success from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_g11/setup-connected-checkmark.png';
import G11ConnectPower from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_g11/setup-connect-power.png';
import G11ConnectLight from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_g11/setup-connected-light.png';
import G11FirmwareUpdateComplete from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_g11/setup-firmware-update-complete.png';
import G11FirmwareUpdateComplete2 from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_g11/setup-firmware-update-complete-2.png';
import G11FirmwareUpdateInProgress from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_g11/setup-firmware-update-inprogress.png';
import G11FirmwareUpdatePower from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_g11/setup-firmware-update-power.png';
import G11FirmwareUpdate from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_g11/setup-firmware-update.png';

// R14

import R14 from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_r14/setup-device.png';
import R14Failure from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_r14/setup-connected-x.png';
import R14Success from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_r14/setup-connected-checkmark.png';
import R14PlugIn from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_r14/setup-disconnect-power-ethernet.png';
import R14ConnectPower from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_r14/setup-connect-power.png';
import R14ConnectAntennas from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_r14/connect-antennas.png';
import R14ConnectEthernet from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_r14/setup-connect-ethernet.png';
import R14ConnectLight from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_r14/setup-connected-light.png';
import R14FirmwareUpdateComplete from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_r14/setup-firmware-update-complete.png';
import R14FirmwareUpdateComplete2 from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_r14/setup-firmware-update-complete-2.png';
import R14FirmwareUpdateInProgress from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_r14/setup-firmware-update-inprogress.png';
import R14FirmwareUpdatePower from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_r14/setup-firmware-update-power.png';
import R14FirmwareUpdate from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_r14/setup-firmware-update.png';
import R14MeshSetupStep1 from 'mobile/modules/onboarding/images/custom_setup_instructions/minim_r14/setup-satellite-connected.png';


const hardwareImages = {
  gl_b1300: {
    device_image: GLB1300,
    device_success: GLB1300Success,
    device_failure: GLB1300Failure,
    setup_step_1: GLB1300SetupStep1,
    setup_step_2: GLB1300SetupStep2,
    setup_step_3: GLB1300SetupStep3,
    setup_step_4: GLB1300SetupStep4,
    firmware_update_start: GLB1300FirmwareUpdateStart,
    device_power: GLB1300DevicePower,
    device_updating: GLB1300DeviceUpdating,
    firmware_update_complete: GLB1300FirmwareUpdateComplete,
    firmware_update_complete_2: GLB1300FirmwareUpdateComplete2,
    mesh_setup_step_1: GLB1300MeshSetupStep1
  },
  zoom_7020: {
    device_image: Zoom7020,
    device_success: Zoom7020Success,
    device_failure: Zoom7020Failure,
    setup_step_1: Zoom7020SetupStep1,
    setup_step_2: Zoom7020SetupStep2,
    setup_step_3: Zoom7020SetupStep3,
    setup_step_4: Zoom7020SetupStep4,
    firmware_update_start: Zoom7020FirmwareUpdateStart,
    device_power: Zoom7020DevicePower,
    device_updating: Zoom7020DeviceUpdating,
    firmware_update_complete: Zoom7020FirmwareUpdateComplete,
    firmware_update_complete_2: Zoom7020FirmwareUpdateComplete2,
    mesh_setup_step_1: Zoom7020MeshSetupStep1,
    ethernet_setup_1: Zoom7020EthernetSetupStep1,
    ethernet_setup_2: Zoom7020EthernetSetupStep2,
    ethernet_setup_3: Zoom7020EthernetSetupStep3,
    ethernet_setup_4: Zoom7020EthernetSetupStep4
  },
  zoom_7600: {
    device_image: Zoom7600,
    device_success: Zoom7600Success,
    device_failure: Zoom7600Failure,
    setup_step_1: Zoom7600SetupStep1,
    setup_step_2: Zoom7600SetupStep2,
    setup_step_3: Zoom7600SetupStep3,
    setup_step_4: Zoom7600SetupStep4,
    firmware_update_start: Zoom7600FirmwareUpdateStart,
    device_power: Zoom7600DevicePower,
    device_updating: Zoom7600DeviceUpdating,
    firmware_update_complete: Zoom7600FirmwareUpdateComplete,
    firmware_update_complete_2: Zoom7600FirmwareUpdateComplete2,
    mesh_setup_step_1: Zoom7600MeshSetupStep1
  },
  zoomrg_bcm_9339: {
    device_image: MG8702,
    device_success: MG8702Success,
    device_failure: MG8702Failure,
    setup_step_1: MG8702SetupStep1,
    firmware_update_start: MG8702FirmwareUpdateStart,
    device_power: MG8702DevicePower,
    device_updating: MG8702DeviceUpdating,
    firmware_update_complete: MG8702FirmwareUpdateComplete,
    firmware_update_complete_2: MG8702FirmwareUpdateComplete2,
  },
  zoom_8733: {
    device_image: MT8733,
    device_success: MT8733Success,
    device_failure: MT8733Failure,
    setup_step_1: MT8733SetupStep1,
    firmware_update_start: MT8733FirmwareUpdateStart,
    device_power: MT8733DevicePower,
    device_updating: MT8733DeviceUpdating,
    firmware_update_complete: MT8733FirmwareUpdateComplete,
    firmware_update_complete_2: MT8733FirmwareUpdateComplete2
  },
  zoom_8733_213: {
    device_image: MT8733,
    device_success: MT8733Success,
    device_failure: MT8733Failure,
    setup_step_1: MT8733SetupStep1,
    firmware_update_start: MT8733FirmwareUpdateStart,
    device_power: MT8733DevicePower,
    device_updating: MT8733DeviceUpdating,
    firmware_update_complete: MT8733FirmwareUpdateComplete,
    firmware_update_complete_2: MT8733FirmwareUpdateComplete2
  },
  // the 8725 uses the exact same images as the 8733
  zoom_8725: {
    device_image: MT8733,
    device_success: MT8733Success,
    device_failure: MT8733Failure,
    setup_step_1: MT8733SetupStep1,
    firmware_update_start: MT8733FirmwareUpdateStart,
    device_power: MT8733DevicePower,
    device_updating: MT8733DeviceUpdating,
    firmware_update_complete: MT8733FirmwareUpdateComplete,
    firmware_update_complete_2: MT8733FirmwareUpdateComplete2
  },
  zoom_8725_213: {
    device_image: MT8733,
    device_success: MT8733Success,
    device_failure: MT8733Failure,
    setup_step_1: MT8733SetupStep1,
    firmware_update_start: MT8733FirmwareUpdateStart,
    device_power: MT8733DevicePower,
    device_updating: MT8733DeviceUpdating,
    firmware_update_complete: MT8733FirmwareUpdateComplete,
    firmware_update_complete_2: MT8733FirmwareUpdateComplete2
  },
  // the Q11 uses the same images as the MH7600
  minim_q11: {
    device_image: Zoom7600,
    device_success: Zoom7600Success,
    device_failure: Zoom7600Failure,
    setup_step_1: Zoom7600SetupStep1,
    setup_step_2: Zoom7600SetupStep2,
    setup_step_3: Zoom7600SetupStep3,
    setup_step_4: Zoom7600SetupStep4,
    firmware_update_start: Zoom7600FirmwareUpdateStart,
    device_power: Zoom7600DevicePower,
    device_updating: Zoom7600DeviceUpdating,
    firmware_update_complete: Zoom7600FirmwareUpdateComplete,
    firmware_update_complete_2: Zoom7600FirmwareUpdateComplete2,
    mesh_setup_step_1: Zoom7600MeshSetupStep1
  },
  minim_q14: {
    device_image: Q14,
    device_success: Q14Success,
    device_failure: Q14Failure,
    setup_step_1: Q14SetupStep1,
    setup_step_2: Q14SetupStep2,
    setup_step_3: Q14SetupStep3,
    setup_step_4: Q14SetupStep4,
    firmware_update_start: Q14FirmwareUpdateStart,
    device_power: Q14DevicePower,
    device_updating: Q14DeviceUpdating,
    firmware_update_complete: Q14FirmwareUpdateComplete,
    firmware_update_complete_2: Q14FirmwareUpdateComplete2,
    mesh_setup_step_1: Q14MeshSetupStep1
  },
  minim_b12: {
    device_image: B12,
    device_success: B12Success,
    device_failure: B12Failure
  },
  minim_g11: {
    device_image: G11,
    device_success: G11Success,
    device_failure: G11Failure,
    setup_step_1: G11ConnectPower,
    setup_step_2: G11ConnectLight,
    firmware_update_start: G11FirmwareUpdate,
    device_power: G11FirmwareUpdatePower,
    device_updating: G11FirmwareUpdateInProgress,
    firmware_update_complete: G11FirmwareUpdateComplete,
    firmware_update_complete_2: G11FirmwareUpdateComplete2
  },
  minim_r14: {
    device_image: R14,
    device_success: R14Success,
    device_failure: R14Failure,
    setup_step_1: R14PlugIn,
    setup_step_2: R14ConnectAntennas,
    setup_step_3: R14ConnectEthernet,
    setup_step_4: R14ConnectPower,
    setup_step_5: R14ConnectLight,
    firmware_update_start: R14FirmwareUpdate,
    device_power: R14FirmwareUpdatePower,
    device_updating: R14FirmwareUpdateInProgress,
    firmware_update_complete: R14FirmwareUpdateComplete,
    firmware_update_complete_2: R14FirmwareUpdateComplete2,
    mesh_setup_step_1: R14MeshSetupStep1
  }
};

const images = {
  motosync_devices: MotosyncDevicesImage,
  default_devices: DefaultDevicesImage,
  camera: Camera,
  qr_code_icon: QRCodeIcon,
  setup_qr_scan: SetupQRScan,
  green_check_mark: GreenCheckMark,
  cloud: Cloud,
  generic_firmware_update_prompt_img: GenericFirmwareUpdatePrompt,
  generic_firmware_update_instructions_img: GenericFirmwareUpdateInstructions,
  generic_firmware_update_updating_img: GenericFirmwareUpdateUpdating,
  generic_firmware_update_success_img: GenericFirmwareUpdateSuccess,
  generic_firmware_update_success_img_2: GenericFirmwareUpdateSuccess2,
  generic_firmware_update_failure_img: GenericFirmwareUpdateFailure,
};

export default {
  namespaced: true,

  state: {
    preloadedHardwareImages: {},
    preloadedImages: {}
  },

  mutations: {
    preloadHardwareImages(state, hardwareKind) {
      if (!hardwareImages[hardwareKind]) {
        return;
      }

      const hardwareKindImages = hardwareImages[hardwareKind];

      return Object.keys(hardwareKindImages).map(image => {
        const img = new Image();
        img.src = hardwareKindImages[image];
        if (!state.preloadedHardwareImages[hardwareKind]) {
          state.preloadedHardwareImages[hardwareKind] = {};
        }
        state.preloadedHardwareImages[hardwareKind][image] = img;
        return img;
      });
    },

    preloadGenericImages(state){
      const image_keys = Object.keys(images);

      return image_keys.map(image => {
        const img = new Image();
        img.src = images[image];
        state.preloadedImages[image] = img;
        return img;
      });
    }
  },

  getters: {
    getHardwareImage: (state, _getters, rootState) => (imageName, hardwareKindOverride = null) => {
      let img;
      let hardwareKind = hardwareKindOverride;

      // If no hardware kind override was passed use the current unum's hardware kind
      if (!hardwareKind) {
        hardwareKind = rootState.OnboardingStore.currentHardwareKindId;
      }
      // If there is still no hardware kind, we won't have any images, so return null
      if (!hardwareKind) {
        return null;
      }

      // Try to get the preloaded version of the image if there is one
      const preloadedImages = (state.preloadedHardwareImages || {})[hardwareKind] || {};
      img = (preloadedImages[imageName] || {}).src;

      // If there's no preloaded version try to load the image directly from
      // the hardware images object
      if (!img) {
        img = (hardwareImages[hardwareKind] || {})[imageName];
      }

      return img;
    },

    getImage: (state) => (imageName) => {
      const preloadedImages = state.preloadedImages || {};
      const img = (preloadedImages[imageName] || {}).src || images[imageName];

      return img;
    }
  }
};
