<template>
  <minim-tooltip tooltipPlacement="above">
    <i class="minim-mobile-icon minim-icon-cp-alert known-issues-icon" slot="hover-content" />
    <p slot="tooltip-content">{{ $I18n.t('isps.unum_search_results.known_issues_tooltip') }}</p>
  </minim-tooltip>
</template>

<script>
import MinimTooltip from 'dashboard/components/shared/minim_tooltip';

export default {
  components: {
    'minim-tooltip': MinimTooltip
  }
};
</script>
