import axios from 'axios';

function checkIfAccessPointsAreOnSameNetwork(ap1, ap2) {
  return (ap1.ssid === ap2.ssid && ap1.shared_key === ap2.shared_key);
}

function initialState() {
  return {
    accessPoints: []
  };
}

export default {
  namespaced: true,

  state: {
    ...initialState()
  },

  mutations: {
    many(state, accessPoints) {
      state.accessPoints = accessPoints;
      return state;
    },

    reset(state) {
      const s = initialState();

      // We loop over the state setting each key, rather than just overwriting "state"
      // entirely because overwiting it would cause state to lose it's observer and therefore
      // break reactivity
      Object.keys(state).forEach(key => { state[key] = s[key]; });
    }
  },

  actions: {
    async index(context, { lanId, radios }) {
      let accessPoints = [];

      await Promise.all(radios.map(async radio => {
        const aps = await Promise.all(radio.access_points.map(async ap => {
          return (await axios.get(`/api/v1/lans/${lanId}/radios/${radio.id}/access_points/${ap}`)).data;
        }));

        accessPoints = [...accessPoints, ...aps];
      }));

      context.commit('many', accessPoints);
    },
  },

  getters: {
    accessPointsCount(state) {
      return state.accessPoints.length;
    },

    getAccessPointsForRadio: (state) => (radioId) => {
      return state.accessPoints.filter(ap => ap.radio_id === radioId);
    },

    guestAccessPointsCount(state) {
      return state.accessPoints.filter(ap => ap.is_guest).length;
    },

    accessPointsGroupedByNetwork(state) {
      return state.accessPoints.reduce((networks, ap) => {
        const indexOfNetwork = networks.findIndex(aps => {
          return checkIfAccessPointsAreOnSameNetwork(aps[0], ap);
        });

        if (indexOfNetwork === -1) {
          networks.push([ap]);
        } else {
          networks[indexOfNetwork].push(ap);
        }

        return networks;
      }, []);
    },

    accessPointsForRadio: (state) => (radioId) => {
      return state.accessPoints.filter(ap => ap.radio_id === radioId);
    },

    accessPointCountForRadio: (_state, getters) => (radioId) => {
      return getters.accessPointsForRadio(radioId).length;
    },

    guestAccessPointsForRadio: (_state, getters) => (radioId) => {
      return getters.accessPointsForRadio(radioId).filter(ap => ap.is_guest);
    },

    guestAccessPointCountForRadio: (_state, getters) => (radioId) => {
      return getters.guestAccessPointsForRadio(radioId).length;
    },

    standardAccessPointsForRadio: (_state, getters) => (radioId) => {
      return getters.accessPointsForRadio(radioId).filter(ap => !ap.is_guest);
    },

    standardAccessPointCountForRadio: (_state, getters) => (radioId) => {
      return getters.standardAccessPointsForRadio(radioId).length;
    },

    isLastAccessPointOnRadio: (_state, getters) => (accessPoint, includeGuestAPs = true) => {
      const radioId = accessPoint.radio_id;
      const accessPointsCountForRadio = includeGuestAPs ? getters.accessPointCountForRadio(radioId) : getters.standardAccessPointCountForRadio(radioId);

      return accessPointsCountForRadio <= 1;
    }
  }
};
