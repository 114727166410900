import minim_api from 'mobile/shared/utils/minim_api';

export default {
  namespaced: true,

  state: {
    paused_status: []
  },

  mutations: {
    many(state, data) {
      state.paused_status = data;
    }
  },

  actions: {
    index(context) {
      return minim_api.get('api/v1/lans/{lan_id}/network_blocks')
        .then((response) => {
          context.commit('many', response.data.network_blocks);
        });
    }
  },

  getters: {
    // Get all the objects that are the "paused" reason
    // or other restrictions that arent snoozed
    pausedDevices(state) {
      return state.paused_status.filter((pd) => (pd.reason == 'paused' || !pd.snoozed_until));
    },

    activeRestrictions(state) {
      return state.paused_status.filter((pd) => (pd.reason == 'restriction' && !pd.snoozed_until));
    },

    visibleRestrictions(state) {
      return state.paused_status.filter((pd) => (pd.reason == 'restriction' && !(pd.snoozed_until == pd.paused_until)));
    },

    activeRestrictionsForPerson: (state, getters) => (person_id) => {
      // Find for person...
      let arr = getters.activeRestrictions.filter((ar) => (ar.person_id == person_id));

      // Unique by restriction_id
      return arr.filter((value, index, self) => {
        return self.findIndex((val) => (value.restriction_id == val.restriction_id)) === index;
      });
    },

    visibleRestrictionsForPerson: (state, getters) => (person_id) => {
      // Find for person...
      let arr = getters.visibleRestrictions.filter((ar) => (ar.person_id == person_id));

      // Unique by restriction_id
      return arr.filter((value, index, self) => {
        return self.findIndex((val) => (value.restriction_id == val.restriction_id)) === index;
      });
    },

    forDevice: (state, getters) => (device_id) => {
      return getters.pausedDevices.find((d) => (d.device_id == device_id));
    },

    personPausedDevices: (state, getters) => (person_id) => {
      return getters.pausedDevices.filter((d) => (d.person_id == person_id));
    },

    pausedDeviceCount(state, getters) {
      return getters.pausedDevices.filter((value, index, self) => {
        return self.findIndex((val) => (value.device_id == val.device_id)) === index;
      }).length;
    },

    personPausedDeviceCount: (state, getters) => (person_id) => {
      return getters.personPausedDevices(person_id).length;
    }
  }
};
