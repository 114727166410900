<template>
  <div class="lan-map-container" ref="lan_map_container"></div>
</template>

<script>
export default {
  props: ['lan'],

  mounted() {
    this.insert_map();
  },

  methods: {
    insert_map() {
      if (this.lan.lat && this.lan.lon && !this.map) {
        this.init_map();
      }
    },

    init_map() {
      var loc = { lat: this.lan.lat, lng: this.lan.lon };
      var map_options = { zoom: 12,
        center: loc,
        mapTypeControl: false,
        scrollwheel: false,
        streetViewControl: false };

      this.map = new window.google.maps.Map(this.$refs.lan_map_container, map_options);
      (new window.google.maps.Marker({ position: loc, map: this.map }));
    }
  },

  data() {
    return {
      map: null
    };
  }
};
</script>
