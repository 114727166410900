import minim_api from 'mobile/shared/utils/minim_api.js';
import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    subscriptions: []
  },

  mutations: {
    many(state, subscriptions) {
      state.subscriptions = subscriptions;
      return state;
    },

    set(state, subscription) {
      let i = state.subscriptions.findIndex(s => s.id === subscription.id);

      // If the index is not found...
      // we want to push it in at the end
      i = (i !== -1) ? i : state.subscriptions.length;

      Vue.set(state.subscriptions, i, subscription);
    }
  },

  actions: {
    async index(context) {
      const user = context.rootGetters['UserStore/currentUser'];
      const subscriptions = await minim_api.multiget(`api/v1/users/${user.id}/subscriptions`);

      context.commit('many', subscriptions);

      return subscriptions;
    },

    async show(context, { userId, id }) {
      const usp = (await minim_api.get(`api/v1/users/${userId}/subscriptions/${id}`)).data;
      context.commit('set', usp);
      return usp;
    },

    async create(context, {
      productOptionId,
      paymentServiceReceipt,
      paymentServiceType
    }) {
      const user = context.rootGetters['UserStore/currentUser'];
      const res = await minim_api.post(`api/v1/users/${user.id}/subscriptions`, {
        product_option_id: productOptionId,
        payment_service_receipt: paymentServiceReceipt,
        payment_service_type: paymentServiceType
      });

      return await context.dispatch('show', {
        userId: user.id,
        id: res.data.id
      });
    }
  },

  getters: {
    // Getter used to check if a subscription we were alerted of by the
    // CdvPurchase plugin already exists
    validateCdvSubscription: (state) => (productOptionId) => {
      const subscription = state.subscriptions.find(s => {
        return productOptionId === s.product_option_id;
      });

      return !!subscription;
    },

    activeSubscriptions(state) {
      return state.subscriptions.filter(s => {
        return s.subscription_status !== 'inactive';
      });
    },

    getSubscription: (state) => (subscriptionId) => {
      return state.subscriptions.find(s => {
        return s.id === subscriptionId;
      });
    },

    getSubscriptionByProductOptionId: (state) => (productOptionId) => {
      return state.subscriptions.find( s => {
        return s.product_option_id === productOptionId;
      });
    },

    getSubscriptionByProductId: (state, _getters, _rootState, rootGetters) => (productId) => {
      return state.subscriptions.find(s => {
        const productOption = rootGetters['CpfProductOptionStore/getProductOption'](s.product_option_id);
        return productOption.product_id === productId;
      });
    },

    getActiveSubscriptions: (state) => (withFreeTrial = false) => {
      if(!state.subscriptions) return [];
      return state.subscriptions.filter(s => {
        return s.subscription_status === 'active' && (!withFreeTrial ? (['google', 'apple'].includes(s.payment_service_type)): true);
      });
    },

    hasSubscription: (state) => (productKey, onlyActive = true) => {
      return state.subscriptions.find(s => {
        if(onlyActive && !s.subscription_status === 'active') {
          return false;
        }
        return s.product_key === productKey;
      });
    }
  }
};
