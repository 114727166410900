<template>
  <div class="detected-malware">
    <div class="headers">
      <h2 class="title">{{ $I18n.t('components.security_incidents.detected_malware') }}</h2>
      <h2 class="info">{{ $I18n.t('components.security_incidents.ident_malware') }}</h2>
    </div>

    <malware-table
      :incidents="incidents.filter(incident => {
        const isMalware = incident.issue.classification === 'malware';
        const deviceDoesNotExist = !incident.device || !Object.keys(incident.device).length;
        return !(isMalware && deviceDoesNotExist);
      })"
      :renownMetrics="renownMetrics"
      :disabledButtons="disableButtons"
    />
    <renown-monitor
      :renownMetrics="renownMetrics"
      :numberOfIncidents="incidents.length"
    />
  </div>
</template>

<script>
import RenownMonitor from './renown_monitor.vue';
import MalwareTable from './malware_table.vue';

export default {
  props: {
    incidents: {
      type: Array
    },
    renownMetrics: {
      type: Object
    },
    disableButtons: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    'renown-monitor': RenownMonitor,
    'malware-table': MalwareTable
  },
};
</script>
