<template>
  <div v-if="display_gateway_info" class="page-section unums-index inset">
    <div v-if="loading" class="centered" style="font-size: 50px;">
      {{$I18n.t('loading')}} <i class="fas fa-cog fa-spin"></i>
    </div>
    <div v-else>
      <div class="flex-container justify-between align-center m-b-40">
        <h2>{{$I18n.t('unums_index.network_devices')}} </h2>
        <button @click='on_unum_new' style="margin: 0; padding: 9px 15px; height: 40px" data-test-id="add-unum-to-lan">
          {{ $I18n.t('unums_index.add_a_device') }}
        </button>
      </div>
      <table v-if="unums.length > 0">
        <unum-show
          tag-name="tr"
          v-for="(unum, idx) in unums"
          :unum="unum"
          :lan="lan"
          :device_uuid="unum.device_id"
          :devices="devices"
          :supports6ghz="supports6ghz"
          :lanId="lanId"
          :disableCommandButtons="userIsReadOnly"
          :businessPortal="businessPortal"
          :key="idx"
          @change="on_number_click"
          @unum-updating="unumIsUpdating"
          @update-unum="updateUnum"
          @unum-rebooting="unumRebooting"
        />
      </table>
      <h3 v-else>
        {{$I18n.t('unums_index.no_unums')}}
      </h3>
      <div v-if="ethernetCount > 0">
        <div class="ethernet-stats">
          <h2 class="minim-mobile-text primary-font medium-large bold">{{$I18n.t('unums_index.ethernet')}}</h2>
          <div></div>
          <button>
            <div class="flex-container align-center justify-between">
              <p class="minim-mobile-text secondary-font medium bold" style="color: white"> {{ $I18n.t('unums_index.wired') }} </p>
              <number-stat @change="on_number_click" :number="ethernetCount" :blob="{ filters: { selected_toggle: 'ethernet' } }" :label="$I18n.t('unums_index.devices')"></number-stat>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UnumShow from './unums/unum_show';
import $ from 'jquery';
import cp_api from 'shared/utils/cp_api.js';
import axios from 'axios';

export default {
  props: ['lan', 'lanId', 'userIsReadOnly', 'businessPortal', 'supports6ghz'],

  async mounted() {
    await this.loadUnums();
  },


  data() {
    return {
      loaded: false,
      unums: [],
      devices: null
    };
  },

  computed: {
    display_gateway_info() {
      let { manages_gateway, wired_connections_only, primary_unum_is_bridge } = this.lan || {};

      return manages_gateway || wired_connections_only || primary_unum_is_bridge;
    },

    loading() {
      return !this.loaded;
    },

    ethernetCount(){
      let count = 0;
      for(let i = 0; i < this.devices.length; i++){
        if(this.devices[i].interface_kind === 'ethernet'){
          count ++;
        }
      }
      return count;
    }
  },

  watch: {
    lan() {
      this.loadUnums();
    }
  },

  methods: {
    on_unum_new() {
      $.featherlight(`/lans/${this.lanId}/unums/new`);
    },

    findIndex(id){
      return this.unums.findIndex(unum => unum.id === id);
    },

    unumIsUpdating(data) {
      const index = this.findIndex(data.id);
      this.unums[index].is_updating = data.is_updating;
    },

    updateUnum(data) { 
      const index = this.findIndex(data.id);
      this.unums[index].is_updating = data.is_updating;
      this.unums[index].firmware_version = data.firmware_version;
      this.unums[index].is_firmware_update_available = data.is_firmware_update_available;
    },

    unumRebooting(data) {
      const index = this.findIndex(data.id);
      this.unums[index].is_rebooting = data.is_rebooting;
    },

    on_router_image_click(e) {
      $.featherlight($(e.srcElement));
    },

    on_number_click(values) {
      this.$emit('change', values);
    },

    async loadUnums() {
      let unums_data = (await cp_api.multiget(`/api/v1/lans/${this.lanId}/unums`));
      // Always put the modem on top, followed by the gateway
      this.unums = unums_data.sort((a, b) => {
        if(a.is_gateway && b.is_lte_modem) return 1;
        if(a.is_lte_modem) return -1;
        if(a.is_gateway) return -1;
        return 0;
      });

      const report = (await axios.get(`/api/v1/lans/${this.lanId}/device_connection_report`)).data;

      this.devices = report.devices;
      this.loaded = true;
    }
  },

  components: {
    'unum-show': UnumShow
  }
};
</script>
