import AccordionTable from './accordion_table';
import AlarmToggler from './alarm_toggler';
import ChosenSelect from './chosen_select';
import Countdown from './countdown';
import FormToggler from './form_toggler';
import HasManyItems from './has_many_items';
import Inventory from './inventory';
import IspSwitcher from './isp_switcher';
import LanRouterSettings from './lan_router_settings';
import LanSearchBox from './lan_search_box';
import MenuDismisser from './menu_dismisser';
import MinimForm from './minim_form';
import PasswordToggler from './password_toggler';
import RouterTestRuns from './router_test_runs';
import SendCredentials from './send_credentials';
import SortableTable from './sortable_table';
import Subscriptions from './subscriptions';
import Tabs from './tabs';
import Tooltips from './tooltips';

import $ from 'jquery';

let modules = [
  AccordionTable,
  AlarmToggler,
  ChosenSelect,
  Countdown,
  FormToggler,
  HasManyItems,
  Inventory,
  IspSwitcher,
  LanRouterSettings,
  LanSearchBox,
  MenuDismisser,
  MinimForm,
  PasswordToggler,
  RouterTestRuns,
  SendCredentials,
  SortableTable,
  Subscriptions,
  Tabs,
  Tooltips
];

export default {
  init() {
    modules.forEach((module) => {
      if($(module.selector).length !== 0) {
        console.log(`Loading modules -> ${module.selector}`);
        module.init();
      }
    });
  },

  destroy() {
    modules.forEach((module) => {
      if($(module.selector).length !== 0) {
        if(module.destroy) module.destroy();
      }
    });
  }
};
