import $ from 'jquery';

export default {
  selector: '.edit-router',
  initial_data: null,
  guestToggling: false,

  init() {
    this.$form = $('.edit_router_config');
    this.initial_data = this.$form.serializeArray();

    $('.ap-enabled-toggle').on('click', this.onToggleRadio.bind(this));
    $('.guest-toggle').on('click', this.onToggleGuest.bind(this));

    this.$form.find('input, select').on('change keyup', this.toggleEdited.bind(this));

    $('.mirrored .main [data-radio=wifi_5] .ssid-input input').on('input', () => {
      $('.mirrored .main [data-radio=wifi_2_4] .ssid-input input').val($('.mirrored .main [data-radio=wifi_5] .ssid-input input').val());
    });

    $('.mirrored .main [data-radio=wifi_2_4] .ssid-input input').on('input', () => {
      $('.mirrored .main [data-radio=wifi_5] .ssid-input input').val($('.mirrored .main [data-radio=wifi_2_4] .ssid-input input').val());
    });

    $('.mirrored .main [data-radio=wifi_5] .passphrase-input input').on('input', () => {
      $('.mirrored .main [data-radio=wifi_2_4] .passphrase-input input').val($('.mirrored .main [data-radio=wifi_5] .passphrase-input input').val());
    });

    $('.mirrored .main [data-radio=wifi_2_4] .passphrase-input input').on('input', () => {
      $('.mirrored .main [data-radio=wifi_5] .passphrase-input input').val($('.mirrored .main [data-radio=wifi_2_4] .passphrase-input input').val());
    });



  },

  onToggleRadio(evt) {
    var radio_pane = $(evt.target).closest('.radio-section');
    var toggle_switch = radio_pane.find('.toggle-switch');
    var hidden_inputs = radio_pane.find('.access-point-enabled');
    var settings_pane = radio_pane.find('.radio-settings');

    if (hidden_inputs[0].value == 'f') {
      hidden_inputs.val('t');
      toggle_switch.addClass('on');
      settings_pane.removeClass('hidden');
    } else {
      hidden_inputs.val('f');
      toggle_switch.removeClass('on');
      settings_pane.addClass('hidden');
    }

    hidden_inputs.trigger('change');

    if (!this.guestToggling) { this.updateMasterToggle(evt); }
  },

  onToggleGuest(evt) {
    var radio_pane = $(evt.target).closest('.pane-bar');
    var guest_toggle = radio_pane.find('.guest-toggle');
    var linker_toggle = radio_pane.find('.linker-toggle');
    if (guest_toggle) {
      this.guestToggling = true;
      radio_pane.find('.panelet .toggle-switch').each((_, toggle) => {
        if ($(toggle).hasClass('on') == $(guest_toggle).hasClass('on')) {
          $(toggle).trigger('click');
        }
      });
      this.guestToggling = false;
      guest_toggle.toggleClass('on');
      linker_toggle.toggleClass('on');
    }
  },

  updateMasterToggle(evt) {
    var radio_pane = $(evt.target).closest('.pane-bar');
    var guest_toggle = radio_pane.find('.guest-toggle');
    var linker_toggle = radio_pane.find('.linker-toggle');
    if (guest_toggle) {
      if (radio_pane.find('.panelet .toggle-switch.on').length > 0) {
        guest_toggle.addClass('on');
        linker_toggle.addClass('on');
      } else {
        guest_toggle.removeClass('on');
        linker_toggle.removeClass('on');
      }
    }
  },

  toggleEdited(evt) {
    let $field = $(evt.currentTarget);
    let $edited = $field.parent().find('.label .edited');
    let field_name = $field.attr('name');
    let initial_value = this.get_inital_value(field_name);

    if(initial_value != $field.val()) {
      $edited.show();
    } else {
      $edited.hide();
    }
  },

  get_inital_value(key) {
    return this.initial_data.find((obj) => { return obj.name == key; }).value;
  },
};
