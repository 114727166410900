<template>
  <div @click="handleSelect" :class="[radioButtonClass, isSelected]" >
    <div class="check bg-primary-color" />
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    isInCarePortal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    radioButtonClass(){
      return this.isInCarePortal ? 'minim-cp-radio-button' : 'minim-mobile-radio-button';
    },
    isSelected(){
      return this.selected ? 'selected' : '';
    }
  },

  methods: {
    handleSelect() {
      // Emit an event that the button has been selected for the parent component to handle
      this.$emit('selected');
    }
  }
};
</script>