export default {
  namespaced: true,

  state: {
    inUseBy: null
  },

  mutations: {
    setInUseBy(state, componentId) {
      state.inUseBy = componentId;
      return state;
    },

    releaseEditMode(state) {
      state.inUseBy = null;
      return state;
    }
  },

  getters: {
    editModeIsTaken(state) {
      return !!state.inUseBy;
    },

    componentIsInEditMode: (state) => (componentId) => {
      return state.inUseBy === componentId;
    }
  }
};
