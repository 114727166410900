import Vue from 'vue/dist/vue.esm';
import minim_api from 'mobile/shared/utils/minim_api';

export default {
  namespaced: true,

  state: {
    people: [],
    queryStatsByPerson: {},
    domainListsByPerson: {}
  },

  mutations: {
    many(state, data) {
      state.people = data;
    },

    set(state, data) {
      let i = state.people.findIndex((p) => (p.id == data.id));

      // If the person index is not found...
      // we want to push it in at the end
      i = (i !== -1) ? i : state.people.length;

      Vue.set(state.people, i, data);
    },

    setRestrictions(state, newRestrictions) {
      newRestrictions.forEach((newRestriction)=>{
        let person = state.people.find(person => (person.id == newRestriction.person_id));
        let found = false;

        person.restrictions.forEach((restriction, index) => {
          if (restriction.id == newRestriction.id) {
            Vue.set(person.restrictions, index, newRestriction);
            found = true;
          }
        });

        if(!found) Vue.set(person.restrictions, person.restrictions.length, newRestriction);
      });
    },

    update(state, { personId, data }) {
      const i = state.people.findIndex((p) => (p.id === personId));

      if (i === -1) {
        return;
      }

      Vue.set(state.people, i, { ...state.people[i], ...data });
    },

    setNestedResource(state, { resourceName, personId, data }) {
      Vue.set(state[resourceName], personId, data);
    },

    setDomainList(state, { personId, listKind, domains }) {
      const domainListsByPerson = { ...state.domainListsByPerson };

      if (!domainListsByPerson[personId]) {
        domainListsByPerson[personId] = {};
      }

      domainListsByPerson[personId][listKind] = domains;

      state.domainListsByPerson = domainListsByPerson;
    }
  },

  actions: {
    async index({ state, commit }) {
      const people = (await minim_api.get('mobile/lans/{lan_id}/people')).data;
      commit('many', people);
      return state.people;
    },

    show(context, query) {
      let person_id = query['id'];

      return minim_api
        .get(`mobile/lans/{lan_id}/people/${person_id}`)
        .then((response) => {
          context.commit('set', response.data);

          return context.getters.getPerson(person_id);
        });
    },

    create(context, data) {
      return minim_api
        .post('mobile/lans/{lan_id}/people', data)
        .then((response) => {
          const person = response.data;

          context.commit('set', person);
          context.commit('DeviceStore/syncProfiles', person, { root: true });

          return response.data;
        });
    },

    update(context, data) {
      let personId = data.person.id;
      delete data.person.id;

      return minim_api
        .put(`mobile/lans/{lan_id}/people/${personId}`, data)
        .then((response) => {
          const person = response.data.resource;

          context.commit('set', person);
          context.commit('DeviceStore/syncProfiles', person, { root: true });

          return response.data;
        });
    },

    delete(context, query) {
      return minim_api
        .delete(`mobile/lans/{lan_id}/people/${query['id']}`)
        .then(() => {
          let people = context.rootState['PersonStore'].people;
          people.splice(people.indexOf(query), 1);
        });
    },

    pause(context, data) {
      return minim_api
        .post(`mobile/lans/{lan_id}/people/${data['id']}/pause`, {paused_until: data['paused_until']})
        .then(response => {
          context.commit('set', response.data);

          return this.dispatch('PauseStatusStore/index').then(() => {
            return response.data;
          });
        });
    },

    unpause(context, data) {
      return minim_api
        .post(`mobile/lans/{lan_id}/people/${data['id']}/unpause`)
        .then(response => {
          context.commit('set', response.data);

          return this.dispatch('PauseStatusStore/index').then(() => {
            return response.data;
          });
        });
    },

    async setFilterSettings(context, { personId, newSettings }) {
      const currentSettings = context.getters.getPerson(personId).filter_settings;
      const data = { filter_settings: newSettings };

      try {
        // Commit the changes right away to make the UI update
        context.commit('update', { personId, data });
        await minim_api.patch(`api/v1/lans/{lan_id}/people/${personId}`, { person: data });
      } catch (err) {
        // If we failed to set the settings, rollback the store and
        // rethrow the error for the component to handle
        context.commit('update', { personId, data: { filter_settings: currentSettings } });
        throw err;
      }
    },

    async fetchQueryStats({ commit }, personId) {
      const queryStats = (await minim_api.get(`api/v1/lans/{lan_id}/people/${personId}/dns_query_stats`)).data;
      commit('setNestedResource', { resourceName: 'queryStatsByPerson', personId, data: queryStats });

      return queryStats;
    },

    async fetchDomainListForPerson(context, { personId, listKind }) {
      const route = `${listKind}_domains`;
      const { domains } = (await minim_api.get(`api/v1/lans/{lan_id}/people/${personId}/${route}`)).data;

      context.commit('setDomainList', { personId, listKind, domains });
    },

    async clearDomain(context, { personId, listKind, domain }) {
      const lists = context.state.domainListsByPerson[personId] || {};
      const domains = lists[listKind] || [];

      const indexOfDomain = domains.indexOf(domain);

      if (indexOfDomain === -1) {
        return;
      }

      await minim_api.post(`api/v1/lans/{lan_id}/people/${personId}/clear_domain`, { domain });

      domains.splice(indexOfDomain, 1);
      context.commit('setDomainList', { personId, listKind, domains });
    },

    async addDomainToList(context, { personId, listKind, domain }) {
      const action = listKind === 'blocked' ? 'block_domain' : 'allow_domain';

      const lists = context.state.domainListsByPerson[personId] || {};
      const domains = lists[listKind] || [];

      await minim_api.post(`api/v1/lans/{lan_id}/people/${personId}/${action}`, { domain });

      domains.push(domain);
      context.commit('setDomainList', { personId, listKind, domains });
    }
  },

  getters: {
    people: (state) => {
      return(state.people);
    },

    pausedUntil: (state, getters, rootState, rootGetters) => (person_id) => {
      return((rootGetters['PauseStatusStore/personPausedDevices'](person_id) || []).reduce((max, d) => d.paused_until < max ? d : max, {}).paused_until);
    },

    pausedStatus: (state, getters) => (person_id) => {
      return(getters.pausedDeviceCount(person_id) > 0 ? 'paused' : 'unpaused');
    },

    personDevices: (state, getters, rootState) => (person_id) => {
      return (rootState['DeviceStore'].devices || []).filter(device => device.person_id === person_id);
    },

    personRestrictions: (state, getters) => (person_id) => {
      return(getters.getPerson(person_id).restrictions || []);
    },

    getPerson: (state) => (person_id) => {
      return(state.people.find((person) => (person.id == person_id)) || {});
    },

    pausedDeviceCount: (_state, _getters, _rootState, rootGetters) => (person_id) => {
      return(rootGetters['PausedStatusStore/personPausedDeviceCount'](person_id));
    },

    getFilterSettingsForPerson: (_state, getters) => (personId) => {
      const person = getters.getPerson(personId) || {};
      const filterSettingsForPerson = person.filter_settings || [];

      return filterSettingsForPerson;
    },

    checkIfPersonHasFilterEnabled: (_state, getters) => (personId, filterTags) => {
      const filterSettingsForPerson = getters.getFilterSettingsForPerson(personId);
      const matchingTags = filterSettingsForPerson.filter(tag => filterTags.includes(tag));

      return filterTags.length === matchingTags.length;
    },

    queryStatsForPerson: state => personId => {
      return state.queryStatsByPerson[personId] || {};
    },

    getDomainListsForPerson: state => personId => {
      return state.domainListsByPerson[personId] || {};
    }
  }
};
