<template>
  <div class="severity-indicators" :content="severityTooltip"
    v-tippy="{ placement: 'top-start', arrow: true, offset: '-19, 0', theme: 'minim severity'}">
    <div
      v-for="index in 4"
      class="severity-wrapper"
      :key="`malware-severity-indicator-${index}`"
    >
      <div class="severity-indicator" :class="severityClassForIndex(index)"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    severity: {
      type: Number
    },
  },

  methods: {
    severityClassForIndex(index){
      return index < this.severity ? this.severityClass : '';
    }
  },

  computed: {
    severityClass(){
      switch(this.severity){
      case(5):
        return 'emergency';
      case(4):
        return 'high';
      case(3):
        return 'medium';
      case(2):
        return 'low';
      default:
        return 'informational';
      }
    },

    severityTooltip(){
      let header = `<b>${ this.$I18n.t('severity') }</b>: ${this.severityClass.charAt(0).toUpperCase() + this.severityClass.slice(1)}`;
      let tag_1 = this.$I18n.t(`lans.security_incidents.tooltips.severity.${this.severityClass}_1`);
      let tag_2 = this.$I18n.t(`lans.security_incidents.tooltips.severity.${this.severityClass}_2`);

      return `<div class="tooltip ${this.severityClass}">${header}</div><div>${tag_1}</div><div>${tag_2}</div>`;
    },
  }
};
</script>
