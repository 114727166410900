import Vue from 'vue/dist/vue.esm';
import Toasted from 'vue-toasted';

export default function initializeVueToasted() {
  Vue.use(Toasted);

  // Create custom toasts for success and failure user feedback in the mobile app
  // Usage ex: this.$toasted.global.minim_success({ message: YOUR MESSAGE HERE });
  Vue.toasted.register('minim_success', (message) => message, {
    duration: 3000,
    fullWidth: true,
    keepOnHover: true,
    closeOnSwipe: false,
    className: ['minim-toast', 'blue-toast'],
  });

  Vue.toasted.register('minim_error', (message) => message, {
    duration: 3000,
    fullWidth: true,
    keepOnHover: true,
    closeOnSwipe: false,
    className: ['minim-toast', 'pink-toast'],
  });
}
