import highlightsData from 'mobile/data/feature_highlights.json';


export async function getHighlightHistory() {
  let history = await window.native.exec('fetch_preference', 'minim_feature_highlight_history');
  history = JSON.parse(history) || {};
  return history;
}

export async function markHighlightSeen(key) {
  let history = await getHighlightHistory();
  history[key] = true;
  await window.native.exec('set_preference', { key: 'minim_feature_highlight_history', value: JSON.stringify(history) });
}

async function checkIfHighlightAlreadySeen(key) {
  const history = await getHighlightHistory();
  return !!history[key];
}

function checkIfHighlightExpired(highlight) {
  const currentDate = new Date();
  let expirationDate = new Date(highlight.released_at);
  expirationDate.setDate(expirationDate.getDate() + 14);

  return expirationDate <= currentDate;
}

export default {
  namespaced: true,

  state: {
    highlights: []
  },

  mutations: {
    setHighlightsData(state, data) {
      state.highlights = data;
    }
  },

  actions: {
    async loadHighlightsData(context, currentRoute) {
      let highlights = [];

      for (let i = 0; i < highlightsData.length; i++) {
        const highlight = highlightsData[i];

        if (!context.rootGetters['LanStore/currentLanSupportsAllFeatures'](highlight.feature_filters)) {
          continue;
        }

        if (await checkIfHighlightAlreadySeen(highlight.key)) {
          continue;
        }

        if (currentRoute !== highlight.path_name) {
          continue;
        }

        if (checkIfHighlightExpired(highlight)) {
          continue;
        }

        // Don't show this highlight if we want a highlighted element
        // but it's currently not on the pag
        if (highlight.highlighted_element_id && !document.getElementById(highlight.highlighted_element_id)) {
          continue;
        }

        if (new Date(context.rootGetters['UserStore/currentUser'].created_at) >= new Date(highlight.released_at)) {
          continue;
        }

        highlights.push(highlight);
      }

      context.commit('setHighlightsData', highlights);
    },
  },

  getters: {
    currentPlatform(state) {
      return state.device_data.platform;
    }
  }
};
