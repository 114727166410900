import minim_api from 'mobile/shared/utils/minim_api';

export default {
  namespaced: true,

  state: {
    incidents: [],
    devices: [],
    metrics: []
  },

  mutations: {
    many(state, data) {

      state.incidents = data.incidents.filter(incident => {
        const isMalware = incident.issue.classification === 'malware';
        const deviceDoesNotExist = !incident.device || !Object.keys(incident.device).length;
        return !(isMalware && deviceDoesNotExist);
      });

      state.devices = data.devices;
      state.metrics = data.metrics;
    },

    deleteDevice(state, data) {
      const idx = state.devices.findIndex((device_uuid) => {
        return device_uuid === data.uuid;
      });
      state.devices.splice(idx, 1);
    },

    deleteDevices(state) {
      state.devices = [];
    },

    updateIncident(state, data) {
      const idx = state.incidents.findIndex((obj) => {
        return obj.uuid === data.uuid;
      });

      state.incidents[idx].status = data.status;
    }
  },

  actions: {
    async index({ commit }) {
      const security_audit = (await minim_api.get('lans/{lan_id}/security_incidents.json')).data;
      commit('many', security_audit);
      return security_audit;
    },

    async resolveIncident({ commit }, uuid) {
      const res = await minim_api.patch(`lans/{lan_id}/security_incidents/${uuid}`, {'status': 'confirmed'});
      commit('updateIncident', { uuid: uuid, status: 'resolved' });
      return res;
    },

    async markIncidentAsFalsePositive({ commit }, uuid) {
      const res = await minim_api.patch(`lans/{lan_id}/security_incidents/${uuid}`, {'status': 'false_positive'});
      commit('updateIncident', { uuid: uuid, status: 'false_positive' });
      return res;
    },

    async resolveIncidents({ commit, getters}, data) {
      const res = await minim_api.post('lans/{lan_id}/security_incidents/bulk_update', data);
      getters.getIncidentsForIssue(data.security_issue_id).forEach((incident) => {
        commit('updateIncident', { uuid: incident.uuid, status: data.status });
      });
      return res;
    },

    async pauseDevice({ commit }, uuid) {
      const res = await this.dispatch('DeviceStore/pause', { id: uuid });
      commit('deleteDevice', { 'uuid': uuid });
      return res;
    },

    async pauseNewDevices({ commit }) {
      const res = await this.dispatch('DeviceStore/pauseNewDevices');
      commit('deleteDevices');

      return res;
    },

    async approveDevice({ commit }, uuid) {
      const res = await minim_api.post(`api/v1/lans/{lan_id}/devices/${uuid}/approve`);
      commit('deleteDevice', { 'uuid': uuid });
      return res;
    },

    async approveNewDevices({ commit }) {
      const res = await this.dispatch('DeviceStore/approveNewDevices');
      commit('deleteDevices');
      return res;
    },
  },

  getters: {
    malware(state) {
      return state.incidents.filter((incident) => {
        return incident.issue.classification === 'malware' && incident.status === 'unknown';
      });
    },

    malwareGroupedByDevice(state, getters) {
      let groupedMalware = {};
      getters.malware.forEach((incident) => {
        if(!groupedMalware[incident.device.uuid]){
          groupedMalware[incident.device.uuid] = [incident];
        } else {
          groupedMalware[incident.device.uuid].push(incident);
        }
      });
      return groupedMalware;
    },

    vulnerabilitiesGroupedByDevice(state, getters) {
      let groupedVulnerabilities = {};
      getters.vulnerabilities.forEach((incident) => {
        if(!groupedVulnerabilities[incident.device.uuid]){
          groupedVulnerabilities[incident.device.uuid] = [incident];
        } else {
          groupedVulnerabilities[incident.device.uuid].push(incident);
        }
      });

      return groupedVulnerabilities;
    },

    malwareForDevice: (_, getters) => (deviceUUID) => {
      return getters.malwareGroupedByDevice[deviceUUID] || [];
    },

    vulnerabilitiesForDevice: (_, getters) => (deviceUUID) => {
      return getters.vulnerabilitiesGroupedByDevice[deviceUUID];
    },

    intrusions(state) {
      return state.incidents.filter((incident) => {
        return incident.issue.classification === 'intrusion' && incident.status === 'unknown';
      });
    },

    vulnerabilities(state) {
      return state.incidents.filter((incident) => {
        return incident.issue.classification === 'vulnerability' && incident.status === 'unknown';
      });

    },

    unknownDevices(state) {
      return state.devices;
    },

    rogueAccessPoints(state) {
      return state.incidents.filter((incident) => {
        return incident.issue.key === 'ssid_collisions' && incident.status === 'unknown';
      });
    },

    metrics(state) {
      return state.metrics;
    },

    lanIsSafe(_, getters) {
      return getters.threatCount === 0;
    },

    threatCount(_, getters) {
      return getters.malware.length + getters.vulnerabilities.length + getters.intrusions.length + getters.unknownDevices.length;
    },

    getIncident: (state) => (uuid) => {
      return state.incidents.find((incident) => {
        return incident.uuid === uuid;
      });
    },

    getUnknownDevice: (state) => (uuid) => {
      return state.devices.find((device) => {
        return device.uuid === uuid;
      });
    },

    getIncidentsForIssue: (state) => (id) => {
      return state.incidents.filter((incident) => {
        return incident.issue.id === id;
      });
    },
  }
};
