import $ from 'jquery';

export default {
  initialize() {},

  // Just reload because that's what we do in the active admin page
  received(target, data) {
    if ($(target).data('id') == data.id) {
      location.reload();
    }
  },

  unload() {}
};
