<template>
  <div>
    <div class="headers">
      <h2 class="title">{{ $I18n.t('components.security_incidents.detected_intrusions') }}</h2>
      <h2 class="info">{{ $I18n.t('components.security_incidents.potential_unauth') }}</h2>
    </div>
    <div class="intrusions" v-if="Object.keys(incidents).length != 0 || Object.keys(unknownDevices).length != 0">
      <table>
        <thead>
          <tr>
            <th>{{ $I18n.t('components.security_incidents.intrusion') }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-for='incident in incidents' >
            <template v-if="incident['issue']['key'] == 'ssid_collisions'" >
              <rogue-access-point-row
                :disableButtons="disableButtons"
                :incident="incident"
                :key="incident.uuid"
              />
            </template>
          </template>
          <template v-for='(device, index) in unknownDevices' >
            <unknown-device-row
              :disableButtons="disableButtons"
              :unknownDevice="device"
              :uuid="device.uuid"
              :key="index"
            />
          </template>
        </tbody>
      </table>
    </div>
    <div class="all-good" v-else>
      <table>
        <tr>
          <td class="cell">
            <i class="fas fa-circle"></i>
            <span>{{ $I18n.t('components.security_incidents.no_unath') }}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>


<script>
import RogueAccessPointRow from './rogue_access_point_row.vue';
import UnknownDeviceRow from './unknown_device_row.vue';

export default {
  props: {
    incidents: {
      type: Array
    },
    unknownDevices:{
      type: Array
    },
    disableButtons: {
      type: Boolean,
      default: false
    },
  },

  components: {
    'rogue-access-point-row': RogueAccessPointRow,
    'unknown-device-row': UnknownDeviceRow,
  },
};
</script>
