import 'dashboard/lib/isp_overview';
import Vue from 'vue';
import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import VueHighcharts from 'vue-highcharts';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import PortalVue from 'portal-vue';
import VueTippy, { TippyComponent } from 'vue-tippy';
import $ from 'jquery';
import initializeVueToasted from 'shared/setup/vue_toasted';

import ComponentLoader from 'shared/vue_component_loader';
import dashboard_components from 'dashboard/components/index';
import modules from './modules/index';
import BrowserId from 'shared/lib/browser_id';
import MinimCable from 'shared/lib/minim_cable';
import RetroStripe from 'shared/lib/stripe';
import store from 'dashboard/vuex';

// Subscription handlers
import IspClusterStateSubscriber from 'dashboard/lib/sub_handlers/isp_cluster_state_subscriber';
import IspStateSubscriber from 'dashboard/lib/sub_handlers/isp_state_subscriber';
import RefreshPageSubscriber from 'dashboard/lib/sub_handlers/refresh_page_subscriber';
import RouterTestRunStateSubscriber from 'dashboard/lib/sub_handlers/router_test_run_state_subscriber';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
window.Highcharts = Highcharts;
window.RetroStripe = RetroStripe;

const app = {
  async init() {
    Bugsnag.start({
      apiKey: '1f15571769b62744e863c9193ccd5aa4',
      releaseStage: window.environment,
      enabledReleaseStages: ['staging', 'production'],
    });

    Vue.use(VueTippy, { arrow: true, theme: 'minim' });
    Vue.component('tippy', TippyComponent);
    Vue.use(VueHighcharts, { highcharts: Highcharts });
    Vue.use(PortalVue);

    Vue.prototype['$store'] = store;

    await ComponentLoader.init(dashboard_components);
    window.ComponentLoader = ComponentLoader;

    window.eventBus = new Vue({ name: 'event-bus'} );

    MinimCable.init({
      'IspClusterStateSubscriber': IspClusterStateSubscriber,
      'IspStateSubscriber': IspStateSubscriber,
      'RefreshPageSubscriber': RefreshPageSubscriber,
      'RouterTestRunStateSubscriber': RouterTestRunStateSubscriber
    });

    document.addEventListener('turbolinks:load', () => {
      const token = document.getElementsByName('csrf-token')[0].getAttribute('content');
      axios.defaults.headers.common['X-CSRF-Token'] = token;

      BrowserId.init();
      ComponentLoader.process();
      modules.init();
      initializeVueToasted();

      $('.toggle-button').click((evt) => {
        let $btn = $(evt.currentTarget);

        $btn.toggleClass('active inactive');
        $btn.parent().find('.toggle-target').slideToggle();
      });
    });

    document.addEventListener('turbolinks:before-cache', () => {
      modules.destroy();
    });
  }
};

export default app;
