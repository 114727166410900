<template>
  <div style="width: 448px;">
    <label for="ssid">{{ $I18n.t('password') }}</label>
    <div class="password-field" :class="passwordIsVisible ? 'visible' : 'invisible'">
      <input
        @input="$emit('input', $event)"
        :disabled="editingDisabled"
        :type="passwordIsVisible ? 'text' : 'password'"
        :value="password"
        data-lpignore="true"
      >
      <i
        @click="togglePasswordVisibility"
        class="far password-toggler-icon"
        :class="togglePasswordVisibilityIcon"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    password: {
      type: String,
      required: true
    },

    editingDisabled: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      passwordIsVisible: false
    };
  },

  computed: {
    togglePasswordVisibilityIcon() {
      return this.passwordIsVisible ? 'fa-eye-slash' : 'fa-eye';
    }
  },

  methods: {
    togglePasswordVisibility() {
      this.passwordIsVisible = !this.passwordIsVisible;
    }
  }
};
</script>
