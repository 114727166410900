<template>
  <div class="speed-test" :data-test-id="'lan-event-' + this.eventData.id">
    <lan-activity-timestamp :timestamp="this.eventData.created_at" />
    <div class="event-body">
      <div class="event-type">{{ $I18n.t('timeline.speed_test') }}</div>
      <h2 v-if="show_where_str">{{ eventData.custom_data.where_str }}</h2>
      <speedtest-display :test_data="eventData.custom_data" />
      <server-info
        v-if="eventData && eventData.custom_data['endpoint']"
        style="float: left;"
        :endpoint="eventData.custom_data['endpoint']"
        :location_name="eventData.custom_data['location_name']"
        :lat="eventData.custom_data['lat']"
        :lon="eventData.custom_data['lon']"
      />
    </div>
  </div>
</template>

<script>
import SpeedtestDisplay from 'shared/components/speedtest/display.vue';
import ServerInfo from 'shared/components/speedtest/server_info.vue';
import LanActivityTimestamp from './lan_activity_timestamp.vue';


export default {
  props: ['lanId', 'eventData'],

  computed: {
    show_where_str() {
      return this.eventData.custom_data.where_str != null;
    }
  },

  components: {
    'speedtest-display': SpeedtestDisplay,
    'server-info': ServerInfo,
    'lan-activity-timestamp': LanActivityTimestamp
  }
};
</script>
