import DeviceFilters from './device_filters.vue';
import Devices from './devices.vue';
import LanMap from './lan_map.vue';
import SummaryPanel from './summary_panel.vue';
import Unums from './unums.vue';

export default {
  'device-filters': DeviceFilters,
  'devices': Devices,
  'lan-map': LanMap,
  'summary-panel': SummaryPanel,
  'unums': Unums
};
