<template>
  <div :class="connectivity_class">
    <div v-if="(kind == 'wifi_5') || (kind == 'wifi_2_4') || (kind == 'wifi_6')" class="wireless-connection">
      <minim-tooltip>
        <div slot="hover-content">
          <i v-if="signal_strength" class="minim-icon-cp-wireless-device-5" :style="smallIcon ? 'font-size: 24px': ''"></i>
          <i :class="wireless_connectivity_icon" :style="smallIcon ? 'font-size: 24px': ''"></i>
        </div>
        <div class="tooltip-text" slot="tooltip-content">
          {{ dbm_string }}
          <div :class="signal_in_words.toLowerCase()">
            {{ signal_in_words }}
          </div>
        </div>
      </minim-tooltip>
    </div>

    <span v-if="kind === 'Wireless Extender'" class='centered'>
      <i class='fas fa-arrow-circle-o-right' title='Connected through a wireless extender.'></i>
    </span>

    <span v-if="kind == 'moca'" class='centered'>
      <img height="12" :src="moca_connectivity_src">
    </span>

    <span v-if="kind === 'ethernet'" class='centered'>
      <minim-tooltip>
        <div slot="hover-content">
          <i :class='wired_connectivity_icon'></i>
        </div>
        <div class="tooltip-text" slot="tooltip-content">
          {{ $I18n.t('devices.show.ethernet') }}
          <div :class="ethernet_class" >
            {{ ethernet_signal_in_words }}
          </div>
        </div>
      </minim-tooltip>
    </span>
  </div>
</template>

<script>
import $ from 'jquery';
import SignalStrengthHelper from 'shared/lib/signal_strength_helper.js';
import MinimTooltip from 'dashboard/components/shared/minim_tooltip.vue';


export default {
  props: {
    kind:{
      type: String
    },
    signal_strength: {
      type: Number
    },
    active: {
      type: Boolean
    },
    smallIcon: {
      type: Boolean,
      default: false
    },
    device: {
      type: Object
    }
  },

  computed: {
    ethernet_connectivity_src() {
      return $('#ethernet-plug').attr('src');
    },
    connectivity_class() {
      return `connectivity ${this.kind}`;
    },

    moca_connectivity_src() {
      return $('#moca-plug').attr('src');
    },

    signal_strength_class() {
      let signalStrengthMap = {
        1: 'alert-conn',
        2: 'alert-conn',
        3: 'warning-conn',
        4: 'good-conn',
        5: 'good-conn'
      };
      return signalStrengthMap[this.signalStrengthRank];
    },

    wireless_connectivity_icon() {
      return 'minim-icon-cp-wireless-device-' + this.signalStrengthRank + ' ' + this.signal_strength_class;
    },

    wired_connectivity_icon() {
      let css_class = 'minim-icon-cp-wired-device';
      return this.active ? css_class + ' active' : css_class;
    },

    signalStrengthRank(){
      return SignalStrengthHelper.signalStrengthRank(this.signal_strength);
    },

    signal_in_words() {
      return SignalStrengthHelper.signalRankInWords(this.$I18n, this.signalStrengthRank);
    },
    ethernet_signal_in_words() {
      return this.active ? this.$I18n.t('online') : this.$I18n.t('offline');
    },
    ethernet_class() {
      return (this.device.last_seen == 'Now') || (this.active) ? 'great' : 'offline';
    },
    dbm_string() {
      return this.signal_strength ? this.signal_strength + ' ' + this.$I18n.t('units.decibel_milliwatts_abbrev') : this.$I18n.t('no_signal');
    },

  },
  components: {
    'minim-tooltip': MinimTooltip
  }
};
</script>
