<template>
  <div class="lan-activity-timestamp timestamp">
    {{ this.event_timestamp }}
    <br>
    {{ this.event_date }}
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: ['timestamp'],

  computed: {
    event_date() {
      return(moment(this.timestamp).format('l'));
    },

    event_timestamp() {
      return(moment(this.timestamp).format('LT'));
    }
  }
};
</script>
