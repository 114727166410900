<template>
  <div style="width: 100%;">
  <div :class="device.id ? 'incident-header clickable' : 'incident-header'" style="width: fit-content;" @click="onDeviceClick">
    <minim-device-icon class="device-icon" :category="device.category || 'uncategorized'" icon_font_size="32px" />
    <span :class="device.id ? 'linkable' :''">{{ device.name || $I18n.t('unknown_device') }}</span>
  </div>
  <table class="incident-table">
    <thead></thead>
    <tbody>
      <tr>
        <td><div><b>{{ $I18n.t('mac_addr') }}:</b> {{ device.mac_address  || $I18n.t('unavailable') }}</div></td>
        <td><div><b>{{ $I18n.t('components.security_incidents.first_detection') }}:</b> {{ firstDetection }}</div></td>
      </tr>
      <tr>
        <td><div><b>{{ $I18n.t('ip_addr') }}:</b> {{ device.ip_address  || $I18n.t('unavailable') }}</div></td>
        <td><div><b>{{ $I18n.t('components.security_incidents.detection_count') }}:</b> <span class="clickable linkable" @click="onIncidentClick">{{ incident.event_counter}} events</span></div></td>
      </tr>
      <tr>
        <td><div class="hostname"><b>{{ $I18n.t('devices.show.hostname') }}</b> {{ device.name || $I18n.t('unavailable')}}</div></td>
        <td class="category"><div><b>{{ $I18n.t('category') }}: </b> {{ device.category || $I18n.t('devices.categories.uncategorized') }}</div></td>
      </tr>
    </tbody>
  </table>
  </div>
</template>

<script>
import moment from 'moment';
import $ from 'jquery';

export default {
  props: {
    incident: {
      type: Object
    }
  },

  methods: {
    onDeviceClick(){
      if(this.device.uuid){
        let uri =  'devices/' + this.device.uuid + '.html';
        $.ajax({ type: 'GET', url: uri, success: function(data) { $.featherlight(data); } });
      }
    },

    onIncidentClick(){
      let uri = 'security_incidents/' + this.incident.uuid + '.html';
      $.ajax({ type: 'GET', url: uri, success: function(data) { $.featherlight(data, { variant: 'no-bottom-border' }); } });
    }
  },

  computed: {
    device() {
      return this.incident.device;
    },

    events() {
      return this.incident.events;
    },

    firstDetection(){
      return moment(this.incident.created_at).format('hh:mm A M/D/YY');
    }
  }
};
</script>
