import $ from 'jquery';

export default {
  selector: '.has-many-items',

  init() {
    $('.has-many-items').each((_, attribute) => {
      this.setup_remove_buttons(attribute);

      $(attribute).find('button.add-has-many-item').each((_, add_button) => {
        $(add_button).on('click', (event) => {
          event.stopPropagation();
          event.preventDefault();

          let attribute = $(event.target).parents('.has-many-items');
          let template = attribute.find('.has-many-template');
          let new_item = template.children().clone();
          $(add_button).before(new_item);
          this.setup_remove_buttons(new_item);
          this.fix_indices(attribute);
        });
      });
    });
  },

  setup_remove_buttons(attribute) {
    $(attribute).find('button.remove-has-many-item').each((_, remove_button) => {
      $(remove_button).on('click', (event) => {
        event.stopPropagation();
        event.preventDefault();

        let attribute = $(event.target).parents('.has-many-items');
        $(event.target).parent('.has-many-item').remove();
        this.fix_indices(attribute);
      });
    });
  },

  fix_indices(attribute) {
    setTimeout(() => {
      $(attribute).find('> .has-many-item').each((index, item) => {
        $(item).find('*').each((_, element) => {
          let name_attr = $(element).attr('name');
          if (name_attr && name_attr.length > 0) {
            $(element).attr('name', name_attr.replace(/_attributes\]\[([0-9X]+)\]/, '_attributes][' + index + ']'));
          }

          let id_attr = $(element).attr('id');
          if (id_attr && id_attr.length > 0) {
            $(element).attr('id', id_attr.replace(/attributes_([0-9X]+)_/, 'attributes_' + index + '_'));
          }

          let for_attr = $(element).attr('for');
          if (for_attr && for_attr.length > 0) {
            $(element).attr('for', for_attr.replace(/attributes_([0-9X]+)_/, 'attributes_' + index + '_'));
          }
        });
      });
    });
  }
};
