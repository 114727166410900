import minim_api from 'mobile/shared/utils/minim_api';

export default {
  namespaced: true,

  state: {
    productOptions: []
  },

  mutations: {
    many(state, productOptions) {
      state.productOptions = productOptions;
      return state;
    }
  },

  actions: {
    async index(context) {
      await context.dispatch('CpfProductStore/index', null, { root: true });

      const products = context.rootState.CpfProductStore.products;

      let productOptions = [];

      for (let i = 0; i < products.length; i++) {
        const options = await minim_api.multiget(`api/v1/cpf/products/${products[i].id}/product_options`);
        productOptions = productOptions.concat(options);
      }

      context.commit('many', productOptions);

      return productOptions;
    }
  },

  getters: {
    getProductOption: (state) => (product_option_id) => {
      return state.productOptions.find(po => {
        return po.id === product_option_id;
      });
    },

    getProductOptionsByProductId: (state) => (product_id) => {
      return state.productOptions.filter(po => {
        return po.product_id === product_id;
      });
    },

    getProductOptionByProductId: (state) => (productId) => {
      const productOptions = state.productOptions;

      return productOptions.find(po => {
        return po.product_id === productId;
      });
    },

    getOptionByAppStoreProductId: (state) => ({ appStoreProductId, paymentServiceType }) => {
      return state.productOptions.find(po => {
        if (paymentServiceType === 'google') {
          return po.google_base_plan_id === appStoreProductId;
        } else if (paymentServiceType === 'apple') {
          return po.apple_subscription_product_id === appStoreProductId;
        } else {
          return false;
        }
      });
    }
  }
};
