<template>
  <div class="status-icon" :class="status">
    <i class="fa" :class="icon" aria-hidden="true"></i>
  </div>
</template>

<script>
export default {
  props: ['status', 'tooltip'],

  computed: {
    icon() {
      return {
        ok: 'fa-check-circle',
        alert: 'fa-exclamation-circle',
        error: 'fa-times-circle',
        info: 'fa-info'
      }[this.status];
    }
  }
};
</script>
