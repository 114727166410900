<template>
  <i :class="icon" :style="`font-size: ${icon_font_size};`" ></i>
</template>

<script>
export default {
  props: {
    category: {
      type: String
    },

    os: {
      type: String
    },

    icon_font_size: {
      type: String,
      default: '1.25em'
    }
  },

  computed: {
    icon() {
      if (this.$props.category) {
        let icon = null;

        switch(this.$props.category) {
        case 'automation_other':
          icon = 'automation';
          break;
        case 'entertainment_other':
          icon = 'entertainment';
          break;
        case 'game_console':
          icon = 'console';
          break;
        case 'network_other':
          icon = 'network';
          break;
        case 'security_lock':
        case 'security_other':
          icon = 'security-system';
          break;
        case 'tv_box':
          icon = 'tv-box';
          break;
        case 'uncategorized':
          icon = 'unknown';
          break;
        default:
          icon = this.$props.category.toLowerCase().replace(/ /g, '-');
          break;
        }

        if (this.$props.category.toLowerCase() === 'unknown' && this.$props.os !== null) {
          icon = this.$props.os;
        } else {
          icon = 'icon minim-icon-' + (icon ?  icon : 'unknown');
        }
        return icon;
      }
      return null;
    }
  }
};
</script>
