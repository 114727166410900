import $ from 'jquery';

export default {
  selector: '.tab-group',

  init() {
    $('.tab-group').each((_, tab_group_element) => {
      let $tab_group = $(tab_group_element);
      let $tabs = $tab_group.find('> .tabs .tab-control');
      let $tab_contents = $tab_group.find('.tab-contents');

      this.initActiveTab($tab_group, $tabs, $tab_contents);

      $tabs.off('click');

      $tabs.click((evt) => {
        let $tab = $(evt.currentTarget);
        let activates = $tab.data('activates');

        $tabs.removeClass('active');
        $tab.addClass('active');

        let $newActive = $('#' + activates);
        $('.tab-contents').height($newActive.outerHeight());
        $tab_group.find('> .tab-contents .tab-content').removeClass('active');
        $newActive.addClass('active');
      });
    });
  },

  initActiveTab($tab_group, $tabs, $tab_contents) {
    let active_tab_id = $tab_group.data('active-tab');
    let $active_tab = (active_tab_id && active_tab_id.length > 0) ? $('#' + active_tab_id) : $tab_contents.find('.tab-content').first();
    let $active_tab_control = (active_tab_id && active_tab_id.length > 0) ? $tab_group.find(`[data-activates='${active_tab_id}']`) : $tabs.first();

    $active_tab_control.addClass('active');
    $active_tab.addClass('active');

    setTimeout(() => {
      $tab_contents.height($active_tab.outerHeight());
    }, 300);
  }
};
