<template>
  <minim-modal :open="this.isOpen" iconStyle="display: none;" @close="close" >
    <div class="issue-modal-header">
      <h1>{{issue['short_name']}}</h1>
      <button @click="close" class="close-issue-modal">✕</button>
    </div>
    <div class="issue-modal-body">
      <p v-html="issue['explanation']"></p>
      <a target="_blank" rel="noopener noreferrer" :href="issue['information_uri']" >{{ $I18n.t('components.security_incidents.more_information') }}</a>
    </div>
  </minim-modal>
</template>

<script>

export default{
  props: {
    issue: {
      type: Object,
    },

    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  }
};
</script>
