import axios from 'axios';
import qs from 'qs';

export default {
  api_endpoint: '/',

  init() {
    let token = document.getElementsByName('csrf-token')[0].getAttribute('content');
    axios.defaults.headers.common['X-CSRF-Token'] = token;
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'brackets' });
  },

  /**
   * Fetches ALL the paginated IDs from a given api/v1 INDEX endpoint
   *
   * @param {*} url - INDEX endpoint for a controller - ex: api/v1/lans/{lan_id}/devices
   */
  async fetch_all_ids(url) {
    let aggregate_data = [];
    let offset = 0;
    let finished = false;

    while(!finished) {
      const res = await axios.get(url, { params: { offset } });
      const ids = res.data.map(({ id }) => id);

      aggregate_data = aggregate_data.concat(ids);

      if (aggregate_data.length >= parseInt(res.headers['x-total-count'])) {
        finished = true;
      } else {
        offset = aggregate_data.length;
      }
    }

    return aggregate_data;
  },

  async multiget(
    url,
    opts = {
      max_multiget_limit: 10,
      callback: null,
      ids: null,
      params: {}
    }
  ) {

    const ids = opts.ids || await this.fetch_all_ids(url); // default to fetching all the IDs for a given resource
    const max_multiget_limit = opts.max_multiget_limit;

    let ids_in_chunks = [];
    let aggregate_data = [];

    for(let i = 0; i < ids.length; i += max_multiget_limit) {
      ids_in_chunks.push(ids.slice(i, i + max_multiget_limit));
    }

    for (let i = 0; i < ids_in_chunks.length; i++) {
      // Re-assign params to be an object that contains whatever was in the
      // original params object but override the current chunk of IDs
      const chunk = ids_in_chunks[i];
      const params = { ...(opts.params || {}), id: chunk.join(',') };

      const res = await axios.get(url, { params });

      // Pass the response to a callback if we have one
      if (opts.callback) {
        opts.callback(res);
      }

      aggregate_data = [...aggregate_data, ...res.data];
    }

    return aggregate_data;
  }
};