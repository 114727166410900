<template>
  <div>
    <h2 class="m-b-24">{{ $I18n.t('components.wifi_networks') }}</h2>

    <network
      @cancelNetworkCreation="cancelNetworkCreation"
      v-for="(accessPoints, networkIndex) in accessPointsByNetwork"
      :lanId="lanId"
      :accessPoints="accessPoints"
      :networkIndex="networkIndex"
      :radios="radios"
      :maxApsPerRadio="maxApsPerRadio"
      :maxGuestApsPerRadio="maxGuestApsPerRadio"
      :readOnly="readOnly"
      :businessPortal="businessPortal"
      :lanIsMesh="lanIsMesh"
      :key="`network-${networkIndex}`"
    />

    <add-network-button
      @click="addNetworkToLocalState"
      :maxApsPerRadio="maxApsPerRadio"
      :maxGuestApsPerRadio="maxGuestApsPerRadio"
      :radios="radios"
      :readOnly="readOnly"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import Network from './network/index';
import AddNetworkButton from './add_network_button';

export default {
  components: {
    network: Network,
    'add-network-button': AddNetworkButton
  },

  props: {
    lan: {
      type: Object,
      required: true
    },

    lanId: {
      type: String,
      required: true
    },

    radios: {
      type: Array,
      required: true
    },

    maxApsPerRadio: {
      type: Number,
      required: true
    },

    maxGuestApsPerRadio: {
      type: Number,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: true
    },

    businessPortal: {
      type: Boolean,
      required: true
    },

    lanIsMesh: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      accessPointsByNetwork: []
    };
  },

  async mounted() {
    this.$store.commit('LanStore/setLan', this.lan);
    await this.$store.dispatch('AccessPointStore/index', { lanId: this.lanId, radios: this.radios });

    this.setAccessPointsByNetwork();
  },

  beforeDestroy() {
    this.$store.commit('AccessPointStore/reset');
    this.$store.commit('LanStore/reset');
  },

  computed: {
    ...mapState('AccessPointStore', ['accessPoints']),
    ...mapGetters('LanStore', ['lanSupportsFeature']),
    ...mapGetters('AccessPointStore', [
      'accessPointsGroupedByNetwork',
      'accessPointCountForRadio',
      'guestAccessPointCountForRadio'
    ]),

    maxAllowedAccessPoints() {
      return this.maxApsPerRadio * this.radios.length;
    }
  },

  methods: {
    setAccessPointsByNetwork() {
      const accessPointsByNetwork = this.accessPointsGroupedByNetwork;
      const sortedAccessPoints = this.sortNetworkAccessPoints(accessPointsByNetwork);

      this.accessPointsByNetwork = sortedAccessPoints;
    },

    sortNetworkAccessPoints(accessPoints) {
      const sortPriorityByNetworkType = { 'home': 0, 'work': 1 };

      return accessPoints.sort((a, b) => {
        const a_label = a[0].label;
        const b_label = b[0].label;

        return sortPriorityByNetworkType[a_label] > sortPriorityByNetworkType[b_label] ? 1 : -1;
      });
    },

    checkIfAPCanBeAddedToRadio(radioId, isGuest) {
      const exceedsMaxApCount = this.accessPointCountForRadio(radioId) >= this.maxApsPerRadio;
      const exceedsMaxGuestApCount = isGuest && this.guestAccessPointCountForRadio >= this.maxGuestApsPerRadio;

      return !exceedsMaxApCount && !exceedsMaxGuestApCount;
    },

    addNetworkToLocalState() {
      // Make sure to generate the default password for the new network HERE above
      // the reduce call below, else each access point on the network will have a different password
      const password = Math.random().toString(36).substr(2, 8);
      const isGuest = this.lanSupportsFeature('guest_network_only');

      const networkAccessPoints = this.radios.reduce((accessPoints, radio) => {
        // If we've hit the max number of APs for the current radio don't add another access point
        // just return the current array and continue the loop
        if (!this.checkIfAPCanBeAddedToRadio(radio.id, isGuest)) {
          return accessPoints;
        }

        accessPoints.push({
          radio_id: radio.id,
          ssid: this.$I18n.t('components.new_network'),
          shared_key: password,
          enabled: true,
          label: 'home',
          is_guest: isGuest,
          broadcasts_ssid: true,
          is_read_only: false,
          hidden: false
        });

        return accessPoints;
      }, []);

      this.accessPointsByNetwork.push(networkAccessPoints);
      this.$store.commit('RouterConfigsEditModeStore/setInUseBy', `network-${this.accessPointsByNetwork.length - 1}`);
    },

    cancelNetworkCreation() {
      this.accessPointsByNetwork.pop();
      this.$store.commit('RouterConfigsEditModeStore/releaseEditMode');
    }
  }
};
</script>
