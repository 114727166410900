import minim_api from 'mobile/shared/utils/minim_api';
import Vue from 'vue/dist/vue.esm';

export default {
  namespaced: true,

  state: {
    forwarded_ports: []
  },

  mutations: {
    set(state, data) {
      let i = state.forwarded_ports.findIndex((d) => (d.id === data.id));

      // If the index is not found, push it in at the end
      i = (i !== -1) ? i : state.forwarded_ports.length;

      Vue.set(state.forwarded_ports, i, data);
    },

    many(state, data) {
      state.forwarded_ports = data;
      return state;
    },

    delete(state, port_id) {
      const idx = state.forwarded_ports.findIndex((port) => port.id === port_id);
      state.forwarded_ports.splice(idx, 1);
      return state;
    }
  },

  actions: {
    async index({commit}) {
      const forwarded_ports = (await minim_api.multiget('api/v1/lans/{lan_id}/forwarded_ports'));
      commit('many', forwarded_ports);
    },

    async show({ commit }, portId) {
      const fwPort = (await minim_api.get(`api/v1/lans/{lan_id}/forwarded_ports/${portId}`)).data;
      commit('set', fwPort);

      return fwPort;
    },

    create(context, data) {
      return minim_api
        .post('api/v1/lans/{lan_id}/forwarded_ports', data)
        .then((response) => {
          const fwPort = response.data;

          context.commit('set', fwPort);
          return fwPort;
        });
    },

    async update(context, port) {
      const fwPort = await minim_api.patch(`api/v1/lans/{lan_id}/forwarded_ports/${port.forwarded_port.id}`, port );
      context.commit('set', fwPort);
    },

    delete(context, port_id) {
      return minim_api
        .delete(`api/v1/lans/{lan_id}/forwarded_ports/${port_id}`)
        .then(() => {
          context.commit('delete', port_id);
        });
    }
  },

  getters: {
    getForwardedPort: (state) => (port_id) => {
      return state.forwarded_ports.find((fwPort) => (fwPort.id == port_id)) || {};
    }
  }
};