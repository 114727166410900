<template>
  <div class="speed-test">
    <div class="start-button" v-if="this.progress >= 0">
      <div class="btn speedtest-running start-button" style="border-radius: 3px;">
        <span class="progress" v-bind:style="{ width: progress + '%' }"></span>
        <span class="fas fa-refresh fa-spin"></span> {{ $I18n.t('speed_tests.test_running_html') }}
      </div>
      <div class="last-run">{{ $I18n.t('speed_tests.wait_a_minute') }}</div>
    </div>
    <div v-if="this.progress < 0">
      <div v-if="!disableRunButton" @click="run_test" class="btn start-button">{{ $I18n.t('speed_tests.run_speed_test') }}</div>
      <div v-else class="btn start-button disabled">{{ $I18n.t('speed_tests.run_speed_test') }}</div>

      <div v-if="test_data">
        <div v-if="test_data.failed" class="last-run">{{ $I18n.t('speed_tests.last_run_failed') }} {{test_data.last_run_at}}</div>
        <div v-else class="last-run">{{ $I18n.t('speed_tests.last_run') }} {{test_data.last_run_at}}</div>
      </div>
      <server-info v-if="test_data && test_data.endpoint"
        style="padding-bottom: 10px;"
        :endpoint="test_data.endpoint"
        :location_name="test_data.location_name"
        :lon="test_data.lon"
        :lat="test_data.lat"
      />
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import ServerInfo from './server_info.vue';

export default {
  props: ['initial_test_data', 'lan', 'unumId', 'disableButtons'],
  components: {
    'server-info': ServerInfo,
  },

  mounted() {
    this.test_data = this.initial_test_data;
    if(this.test_data && this.test_data.is_running) {
      this.interval = 30;
      this.progress = 60;
    }
  },

  data() {
    return {
      interval: 50,
      is_running: false,
      progress: -1,
      current_interval: null,
      test_data: null
    };
  },

  methods: {
    run_test() {
      if(this.is_running || this.current_interval) return;
      this.progress = 0;
      let url = this.unumId ? `/lans/${this.lanId}/unums/${this.unumId}/run_speedtest` : `/lans/${this.lanId}/run_speedtest`;

      $.ajax({ type: 'POST', url: url}).then((data) => {
        this.test_data = data;
        this.$emit('newTestRun', data);
      });
    }
  },

  computed: {
    lanId() { return this.lan.id; },
    disableRunButton() {
      return this.disableButtons || (!this.lan.active && !this.lan.uptime);
    }
  },

  watch: {
    initial_test_data() {
      this.test_data = this.initial_test_data;
    },

    test_data() {
      let is_running = this.test_data ? this.test_data.is_running : false;
      if (!this.is_running && is_running) {
        // The test wasn't running and now it is, start a timer to handle the animation.
        if(this.current_interval) {
          clearInterval(this.current_interval);
        }
        // Increment progress, which is measured in percentage complete.
        // 100% over "interval" seconds, 10 times per second.
        let incr = (100 / this.interval) / 10;
        let finincr;
        this.current_interval = setInterval(() => {
          if (this.is_running) {
            // Stop at 90% while the test is running
            if (this.progress <= 90) {
              this.progress += incr;
            }
          } else if(this.progress < 100) {
            if (!finincr) {
              // Finish the remaining progress in 5 * 100 millis
              finincr = (100 - this.progress) / 5;
              $(this.$el).find('.progress').css('background-color', '#41cad6');
            }
            this.progress += finincr;
          } else {
            // Progress has reached 100%, test is done.
            clearInterval(this.current_interval);
            this.current_interval = null;
            this.interval = 50;
            this.progress = -1;
          }
        }, 100);
      }
      this.is_running = is_running;
    }
  }
};
</script>
