<template>
  <div>
    <div v-tippy="{ placement: 'top-center', flip: false }" :content="$I18n.t('components.text_creds')">
      <button
        @click="toggleModal"
        slot="trigger"
        class="share-network-credentials-button"
      >
        <minim-device-icon category="text-message" icon_font_size="22px" />
      </button>
    </div>

    <minim-modal :open="modalIsOpen" @close="toggleModal" containerStyle="width: 569px;">
      <div>
        <h2 class="m-b-24">{{ $I18n.t('components.text_creds') }}</h2>
        <label for="phone_number" class="block m-b-12">{{ $I18n.t('phone_number') }}</label>
        <input
          type="tel"
          placeholder="(123) 456 7890"
          style="width: 100%; height: 40px; background: #FFFFFF; border: 1px solid #CCCCCC; border-radius: 5px; display: block; padding: 6px;"
          v-model="phoneNumber"
        >
        <p v-if="error" class="error-color">{{ error }}</p>
        <div class="flex-container justify-end">
          <button @click="sendCredentials" class="modal-button m-t-12">{{ $I18n.t('send') }}</button>
        </div>
      </div>
    </minim-modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    lanId: {
      type: String,
      required: true
    },

    accessPoints: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      modalIsOpen: false,
      phoneNumber: '',
      error: null
    };
  },

  methods: {
    async sendCredentials() {
      try {
        await axios.post(`/lans/${this.lanId}/send_credentials.json`, {
          access_point_key: this.accessPoints[0].id,
          delivery_medium: 'sms',
          phone_number: this.phoneNumber
        });

        this.toggleModal();
      } catch (err) {
        try {
          this.error = err.response.data.error;
        } catch (_) {
          this.error = this.$I18n.t('components.failed_to_text_creds');
        }
      }
    },

    toggleModal() {
      this.modalIsOpen = !this.modalIsOpen;
    }
  }
};
</script>
