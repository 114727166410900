import BandAccessPointGraph from './lans/router_configs/band_access_point_graph';
import Lan from './lan.vue';
import LanWanStatus from './lan_wan_status.vue';
import DeviceGraph from './device_graph.vue';
import UnumFirmwareVersion from './lans/unums/firmware_version.vue';
import SecurityPanel from './security_incidents/security_panel.vue';
import IncidentEventsTable from './security_incidents/incident_events_table.vue';
import ResetMinimScoreButton from './lans/reset_minim_score_button';
import MinimIcon from './minim_icon.vue';
import MinimScoreGraph from './lans/minim_score_graph';
import MinimToggle from './minim_toggle.vue';
import PortalTargetRenderer from './portal_target_renderer';
import KnownIssuesIcon from './unum_search_results/known_issues_icon';
import NetworkSettings from './lans/router_configs/network_settings';
import EditButton from './lans/router_configs/edit_button';
import RadioChannelSettings from './lans/router_configs/radio_channel_settings';
import RadioChannelSelect from './lans/router_configs/radio_channel_select';
import SaveSection from './lans/router_configs/save_section';
import ScanNeighboringApsButton from './lans/router_configs/scan_neighboring_aps_button';
import WifiOptimizationModal from './lans/router_configs/wifi_optimization_modal';
import WifiOptimizationSettings from './lans/router_configs/wifi_optimization_settings';

export default {
  'band-access-point-graph': BandAccessPointGraph,
  'lan': Lan,
  'lan-wan-status': LanWanStatus,
  'unum-firmware-version': UnumFirmwareVersion,
  'device-graph': DeviceGraph,
  'security-panel': SecurityPanel,
  'incident-events-table': IncidentEventsTable,
  'reset-minim-score-button': ResetMinimScoreButton,
  'portal-target-renderer': PortalTargetRenderer,
  'known-issues-icon': KnownIssuesIcon,
  'minim-icon': MinimIcon,
  'minim-score-graph': MinimScoreGraph,
  'minim-toggle': MinimToggle,
  'network-settings': NetworkSettings,
  'router-configs-edit-button': EditButton,
  'radio-channel-select': RadioChannelSelect,
  'radio-channel-settings': RadioChannelSettings,
  'router-configs-save-section': SaveSection,
  'scan-neighboring-aps-button': ScanNeighboringApsButton,
  'wifi-optimization-modal': WifiOptimizationModal,
  'wifi-optimization-settings': WifiOptimizationSettings
};
