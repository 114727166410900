<template>
  <div>
    <minim-graph
      width="988"
      graphName="service_traffic_bandwidth_by_device"
      :queryOpts="{ lan_id: lanId }"
      :timeRangeEnabled="true"
      :autoRefresh="false"
      :isMultiseries="true"
      @chart="handleChartUpdate"
      :optionsTweaks="{
        chart: {
          height: 700
        },
        plotOptions: {
          pie: {
            size: 420,
            dataLabels: {
              formatter: function() {
                return dataLabelFormatter(this);
              }
            }
          }
        }
      }"
    />
    <traffic-graph-legend :chart="chart" />
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import MinimGraph from '../minim_graph.vue';
import TrafficGraphLegend from './traffic_graph_legend.vue';

export default {
  components: {
    'minim-graph': MinimGraph,
    'traffic-graph-legend': TrafficGraphLegend
  },

  props: {
    lanId: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      chart: null
    };
  },

  methods: {
    handleChartUpdate(chart) {
      this.chart = chart;
    },

    dataLabelFormatter(instance) {
      const seriesType = instance.series.options.series_type;
      const percentage = instance.point.percentage;
      const formattedPercentage = this.$I18n.n(percentage / 100, { style: 'percent' });

      if (seriesType === 'device_bandwidth_usage') {
        return Math.round(percentage) > 9 ? `${instance.point.name} ${formattedPercentage}` : null;
      } else if (seriesType === 'service_bandwidth_usage') {
        instance.point.color = new Highcharts.Color(instance.point.color).brighten(Math.random() / 4).get();
        return Math.round(percentage) >= 2 ? `${instance.point.name} ${formattedPercentage}` : null;
      }
    }
  }
};
</script>