import $ from 'jquery';

export default {
  selector: '.password-toggler',

  init(){
    $('.password-toggler').on('click', this.onTogglePassword.bind(this));
  },

  onTogglePassword(evt) {
    let mirrored = $('.mirrored .main');
    let controls = $(evt.target).closest('.toggleable-password');
    if(mirrored.has(controls.get(0)).length > 0){
      controls = $('.mirrored .main .toggleable-password');
    }
    let input = controls.find('input');
    let icon = controls.find('i');
    this.togglePassword(input, icon);
  },

  togglePassword(input, icon){
    if (input.attr('type') == 'password') {
      input.attr('type', 'text');
      icon.removeClass('fa-eye');
      icon.addClass('fa-eye-slash');
    } else {
      input.attr('type', 'password');
      icon.removeClass('fa-eye-slash');
      icon.addClass('fa-eye');
    }
  }
};
