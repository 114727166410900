<template>

  <ul class="monitor">
    <div class="monitor-data">
      <i class="minim-icon-security-other" style="color: #4EC9A3; font-size: 72px;" v-if="numberOfIncidents == 0"></i>
      <i class="minim-icon-security-alert" style="color: #F06A7F; font-size: 72px;" v-else></i>
      <div class="info">
        <div class="title">{{ $I18n.t('components.security_incidents.monitoring') }}</div>
        <li>
          <div class="count">{{ renownMetrics.ip_indicator_count }}</div>
          <div class="label">{{ $I18n.t('components.security_incidents.ips') }}</div>
        </li>
        <li>
          <div class="count">{{ renownMetrics.domain_indicator_count }}</div>
          <div class="label">{{ $I18n.t('components.security_incidents.domains') }}</div>
        </li>
      </div>
    </div>
  </ul>

</template>

<script>

export default{
  props: {
    renownMetrics: {
      type: Object
    },
    numberOfIncidents: {
      type: Number
    }
  },
};
</script>
