export default {
  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },

  updateQueryParam(url, param, value)
  {
    var re = new RegExp('[\\?&]' + param + '=([^&#]*)'), match = re.exec(url), delimiter, newString;

    if (match === null) {
      // append new param
      var hasQuestionMark = /\?/.test(url);
      delimiter = hasQuestionMark ? '&' : '?';
      newString = url + delimiter + param + '=' + value;
    } else {
      delimiter = match[0].charAt(0);
      newString = url.replace(re, delimiter + param + '=' + value);
    }

    return newString;
  }
};
