<template>
  <div v-if="!(isMalware && !doesDeviceExist)" class="security-incident" :data-test-id="'lan-event-' + this.eventData.id">
    <lan-activity-timestamp :timestamp="this.eventData.created_at" />
    <div class='event-body'>
      <div class='event-type'>{{ eventData.title }}</div>
      <h2>{{ eventData.custom_data.description }}</h2>
      <div class='meta'>
        <div class='meta-item flex-container align-center'>
          <minim-device-icon :style="{lineHeight: 0}" icon_font_size='32px' :category="this.eventData.custom_data.device_category || 'uncategorized'"/>
            <p class='p-l-10' :style="{fontSize: '14px'}">{{eventData.device_name || $I18n.t('unknown_device')}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanActivityTimestamp from './lan_activity_timestamp.vue';

export default {
  props: ['lanId', 'eventData'],

  data (){
    return {
      isMalware: this.eventData.custom_data.security_incident_type === 'malware',
      doesDeviceExist: this.eventData.device_name
    };
  },

  components: {
    'lan-activity-timestamp': LanActivityTimestamp
  }
};
</script>
