<template>
  <div class="speedtest-stat">
    <div class="label">
      <span :class="`fas ${icon}`" />
    </div>
    <div v-if="is_maxed_out" class="stat maxed-out">
      <span>{{ roundKbpsToMbps(this.hardwareKind.max_speed_test_speed_kbps) }}</span>
      <i
        class="fas fa-info-circle"
        style="width: 13px;"
        v-tippy="{ placement: 'bottom-start' }"
        :content="$I18n.t('speed_tests.maxed_out_tooltip', { max: roundKbpsToMbps(this.hardwareKind.max_speed_test_cpu_utilization) })"
      />
    </div>
    <div v-else v-html="test_result_value" class="stat" />
    <div class="unit">
      <span class="unit">{{ test_result_units }}</span>
    </div>
  </div>
</template>

<script>
import SpeedTestsMixin from 'mobile/shared/mixins/speed_tests_mixin';

export default {
  props: {
    is_maxed_out: {
      type: Boolean,
      default: false
    },

    icon: {
      type: String,
      required: true
    },

    test_result_value: {
      type: [String, Number]
    },

    test_result_units: {
      type: String
    },
    hardwareKind: {
      type: Object
    }
  },
  mixins: [SpeedTestsMixin]
};
</script>
