export default {
  methods: {
    roundKbpsToMbps(val) {
      if (!val && val !== 0) return '-';

      const kbps = (val > 1024) ? (val / 1024) : val;

      return Math.round(kbps);
    },

    determineKbpsOrMbps(val) {
      return (val > 1024) ? this.$I18n.t('units.megabitpersecond_abbrev') : this.$I18n.t('units.kilobitpersecond_abbrev');
    }
  }
};
