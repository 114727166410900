<template>
  <div>
    <div v-tippy="{ placement: 'top-center', flip: false }" :content="tooltipText">
      <button
        @click="toggleModal"
        slot="trigger"
        class="share-network-credentials-button"
        :class="disabled ? 'disabled' : ''"
        style="margin-top: 33px;"
        :disabled="disabled"
      >
        <minim-device-icon category="notification-bell" icon_font_size="22px" />
      </button>
    </div>

    <minim-modal containerStyle="width: 600px; height: 220px;" :open="modalIsOpen" @close="toggleModal" :showCloseButton="false">
      <div>
        <h2 style="width: 400px; margin: 20px auto; text-align: center;">
          {{ this.$I18n.t('lans.push_credentials.delivery_confirmation_message', { 'who': this.$I18n.t(businessPortal ? 'customer' : 'user').toLowerCase() }) }}
        </h2>
        <div style="margin: auto; width: 160px;">
          <button @click="sendCredentials" class="modal-button m-r-12">{{ $I18n.t('send') }}</button>
          <button @click="toggleModal" class="modal-button outlined m-l-12">{{ $I18n.t('cancel') }}</button>
        </div>
      </div>
    </minim-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  props: {
    lanId: {
      type: String,
      required: true
    },

    accessPoints: {
      type: Array,
      required: true
    },

    businessPortal: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      modalIsOpen: false
    };
  },

  computed: {
    ...mapState('LanStore', ['lan']),

    disabled() {
      return !this.lan.has_pushable_users;
    },

    tooltipText() {
      return this.disabled
        ? this.$I18n.t('components.no_users_to_push_to')
        : this.$I18n.t('components.push_creds');
    }
  },

  methods: {
    async sendCredentials() {
      if (this.disabled) return;

      try {
        await axios.post(`/lans/${this.lanId}/send_credentials.json`, {
          access_point_key: this.accessPoints[0].id,
          delivery_medium: 'push_notification'
        });
      } catch (err) {
        console.log(err);
      }

      this.toggleModal();
    },

    toggleModal() {
      this.modalIsOpen = !this.modalIsOpen;
    }
  }
};
</script>
