<template>
  <tr>
    <td>
      <div class="table-cell-content">
        <minim-device-icon class="device-icon" :category="incident.device.category" icon_font_size="32px" />
          <span class="device-name" @click="onDeviceClick(incident.device.uuid)">{{ incident.device.name }}</span><span> has a {{ incident.issue.short_name }}</span>
        <p class="additional-incident-info">{{ $I18n.t('components.security_incidents.known_vuln') }} <a target="_blank" rel="noopener noreferrer" :href="incident.issue.information_uri"><i class="minim-mobile-icon minim-icon-forward"></i></a></p>
      </div>
    </td>
    <td>
      <div class="btns table-cell-content">
        <button
          @click="updateIncident('confirmed')"
          class="m-l-auto"
          :class="`${disableButtons ? 'disabled' : 'clear'}`"
          :content="$I18n.t('lans.security_incidents.tooltips.ignore_issue')"
          v-tippy
          >{{ $I18n.t('ignore') }}</button>
        <button
          @click="updateIncident('confirmed')"
          class="m-l-10 m-r-24"
          :class="`${disableButtons ? 'disabled' : 'good'}`"
          :content="$I18n.t('lans.security_incidents.tooltips.address_issue')"
          v-tippy
        >{{ $I18n.t('components.security_incidents.addressed') }}</button>
      </div>
    </td>
  </tr>
</template>

<script>
import MinimDeviceIcon from '../../../../shared/components/minim_device_icon.vue';

export default {
  props: {
    incident: {
      type: Object,
    },
    uuid: {
      type: String,
    },
    disableButtons: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'minim-device-icon': MinimDeviceIcon,
  },
  methods: {
    updateIncident(status){
      window.eventBus.$emit('update-incident', this.incident.uuid, status);
    },
    onDeviceClick(){
      window.eventBus.$emit('on-device-click', this.incident.device.uuid);
    },
  },
};
</script>
