<template>
  <div class="lan-activity-filters filter-group">
    <div class='radio-buttons'>
      <button
        v-for="filter_option in filterOptions"
        @click="onFilterClick($event, filter_option['filter'])"
        class="radio-button"
        :class="{selected: isSelected(filter_option['filter'])}"
        :data-test-id="'timeline-filter-' + filter_option['filter']"
        :key="`filter-option-${filter_option['filter']}`"
      >
        <div class='filter-container'>
          <i :class="isSelected(filter_option['filter']) ? 'far fa-check-square' : 'far fa-square'" class="filter-check fa-2s"></i>
          <div class='filter-label'>{{filter_option['display']}}</div>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'filterOptions'],

  mounted() {
    this.applied_filters = this.value;
  },

  data() {
    return {
      applied_filters: []
    };
  },

  methods: {
    onFilterClick(evt, filter_option) {
      if(filter_option == 'all') {
        this.applied_filters = [];
      } else {
        this.toggleFilter(filter_option);
      }
      this.$emit('input', this.applied_filters);
    },

    toggleFilter(filter_option) {
      if(this.applied_filters.indexOf(filter_option) == -1) {
        this.applied_filters.push(filter_option);
      } else {
        this.applied_filters.splice(this.applied_filters.indexOf(filter_option), 1);
      }
    },

    isSelected(filter_option) {
      if(filter_option == 'all') {
        return(this.applied_filters.length == 0);
      } else {
        return(this.applied_filters.indexOf(filter_option) != -1);
      }
    }
  }
};
</script>
