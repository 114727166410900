<template>
  <div class="ping-flood-test" v-if="device_supported">
    <div style="clear: both;">&nbsp;</div>
    <hr style="clear: both;" />
    <div style="clear: both;">&nbsp;</div>
    <div v-bind:style="{ width: '50%' }">
      <div v-if="ping_flood_running">
        <button class="btn"><span class="fas fa-refresh fa-spin"></span> {{ $I18n.t('components.ping_floods.wifi_speed_test_running_html') }}</button>
      </div>
      <div v-else-if="can_test_now && !this.disable_run_button">
        <button @click="runPingFlood" class="btn"><span class="fas fa-refresh"></span> {{ $I18n.t('components.ping_floods.run_wifi_speed_test') }}</button>
      </div>
      <div v-else>
        <button class="btn disabled"><span class="fas fa-refresh"></span> {{ $I18n.t('components.ping_floods.run_wifi_speed_test') }}</button>
        <div class="speed-test p-t-10" v-if="no_ip_present">
          <div class="server-info">
            <i class="fas fa-exclamation-circle alert"></i><span class="m-l-10">{{ $I18n.t('components.ping_floods.no_ip_present') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="device.recent_ping_flood" class="device-info">
      <ul class="device-details">
        <li>
          {{ $I18n.t('components.ping_floods.last_run') }}:
          <span>{{ capitalized_last_run_at }}</span>
        </li>
        <li>
          {{ $I18n.t('components.ping_floods.status') }}:
          <span>{{ last_run_status }}</span>
        </li>
        <li v-if="test_from_description">
          {{ $I18n.t('components.ping_floods.tested_from') }}:
          <span>{{ test_from_description }} ({{ test_from_mac }})</span>
        </li>
        <li v-if="connection_kind">
          {{ $I18n.t('components.ping_floods.wifi_band') }}:
          <span>{{ connection_kind }}</span>
        </li>
        <li v-if="speed">
          {{ $I18n.t('components.ping_floods.wifi_speed') }}:
          <span>{{ speed }}</span>
        </li>
        <li v-if="showSpeedLegend()">
          <div style="margin-left: 1em;">
            <i v-bind:class="speedIconClassString(64, 512)" style="margin-right: 1em;" /><span>{{ $I18n.t('components.ping_floods.browsing') }}</span>
            <br />
            <i v-bind:class="speedIconClassString(384, 512)" style="margin-right: 1em;" /><span>{{ $I18n.t('components.ping_floods.audio') }}</span>
            <br />
            <i v-bind:class="speedIconClassString(1000, 3000)" style="margin-right: 1em;" /><span>{{ $I18n.t('components.ping_floods.sd_video') }}</span>
            <br />
            <i v-bind:class="speedIconClassString(4500, 5000)" style="margin-right: 1em;" /><span>{{ $I18n.t('components.ping_floods.hd_video') }}</span>
            <br />
            <i v-bind:class="speedIconClassString(22500, 25000)" style="margin-right: 1em;" /><span>{{ $I18n.t('components.ping_floods.4k_video') }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import MinimCable from 'shared/lib/minim_cable';
import $ from 'jquery';

export default {
  props: ['deviceId', 'lanId', 'deviceIp', 'disableButtons'],

  beforeDestroy() {
    this.subscription.unsubscribe();
  },

  data() {
    return {
      device: {},
      can_test_now: false,
      device_supported: false,
      lan: {},
      ping_flood_running: false,
      request_pending: false,
    };
  },

  computed: {
    disable_run_button() {
      return this.disableButtons || !this.deviceIp;
    },

    no_ip_present() {
      return !this.deviceIp;
    },

    capitalized_last_run_at() {
      return this.device.recent_ping_flood.last_run_at.replace(/^\w/, (c) => c.toUpperCase());
    },

    speed() {
      let speed_kbps = -1;
      if (this.device.recent_ping_flood && this.device.recent_ping_flood.speed_kbps >= 0) {
        speed_kbps = this.device.recent_ping_flood.speed_kbps;
      }
      if (speed_kbps <= 0) {
        return '';
      } else if (speed_kbps < 512) {
        return speed_kbps + ' ' + this.$I18n.t('units.kilobitpersecond_abbrev');
      }
      return Math.round((speed_kbps * 100) / 1024) / 100 + ' ' + this.$I18n.t('units.megabitpersecond_abbrev');
    },

    connection_kind() {
      let kind = null;
      if (this.device.recent_ping_flood) {
        kind = this.device.recent_ping_flood.connection_kind;
      }
      if (kind == 'wifi_2_4') {
        return this.$I18n.t('wifi_2_4');
      } else if (kind == 'wifi_5') {
        return this.$I18n.t('wifi_5');
      } else if (kind == 'wifi_6') {
        return this.$I18n.t('wifi_6');
      }
      return '';
    },

    last_run_status() {
      let speed_kbps = -1;
      if (this.device.recent_ping_flood && this.device.recent_ping_flood.speed_kbps >= 0) {
        speed_kbps = this.device.recent_ping_flood.speed_kbps;
      }
      if (speed_kbps <= 0) {
        if (this.device.recent_ping_flood && this.device.recent_ping_flood.failed) {
          return this.$I18n.t('components.ping_floods.failed');
        }
        if (this.ping_flood_running) {
          return this.$I18n.t('components.ping_floods.in_progress');
        }
        if (speed_kbps === 0) {
          return this.$I18n.t('components.ping_floods.invalid_result');
        }
        return this.$I18n.t('components.ping_floods.unknown_error');
      }
      return this.$I18n.t('components.ping_floods.success');
    },

    test_from_description() {
      return this.device.recent_ping_flood.unum_description;
    },

    test_from_mac() {
      return this.device.recent_ping_flood.unum_mac;
    },
  },

  mounted() {
    this.requestDeviceData();
    this.subscribeToUpdates();
  },

  updated() {
    if (this.refreshTimer && !this.ping_flood_running) {
      clearInterval(this.refreshTimer);
      this.refreshTimer = null;
    }
    if (!this.refreshTimer && this.ping_flood_running) {
      this.refreshTimer = setInterval(this.timerExpired, 5000);
    }
  },

  methods: {
    handleChannelUpdate(data) {
      if (data.device_id == this.deviceId) {
        this.requestDeviceData();
      }
    },

    requestDeviceData() {
      if (!Object.prototype.hasOwnProperty.call(this, 'lanId') || !Object.prototype.hasOwnProperty.call(this, 'deviceId')) {
        // This is likely an error in using the component
        console.log('Required parameters are missing from ping flood test.');
        return;
      }

      this.request_pending = true;

      $.get(`/lans/${this.lanId}/devices/${this.deviceId}.json`, (data) => this.updateDevice(data)).always(this.requestComplete());
    },

    runPingFlood() {
      if (!this.can_test_now || this.ping_flood_running) return;

      this.ping_flood_running = true;

      let ping_flood_url = `/lans/${this.lanId}/devices/${this.deviceId}/run_ping_flood`;
      $.ajax({ type: 'POST', url: ping_flood_url });
    },

    showSpeedLegend() {
      if (this.device.recent_ping_flood && this.device.recent_ping_flood.speed_kbps > 0) {
        return true;
      }
      return false;
    },

    speedIconClassString(min_speed_kbps, good_speed_kbps) {
      let speed_kbps = this.device.recent_ping_flood.speed_kbps;
      if (speed_kbps < min_speed_kbps) {
        return ['fa', 'fa-times-circle', 'status-alert'];
      }
      if (speed_kbps < good_speed_kbps) {
        return ['fa', 'fa-check-circle', 'status-warning'];
      }
      return ['fa', 'fa-check-circle', 'status-ok'];
    },

    subscribeToUpdates() {
      let cable = MinimCable.cable;
      this.subsciption = cable.subscriptions.create({ channel: 'PingFloodChannel', device_id: this.deviceId, lan_id: this.lanId }, { received: this.handleChannelUpdate.bind(this) });
    },

    timerExpired() {
      if (!this.request_pending) {
        this.requestDeviceData();
      }
    },

    requestComplete() {
      this.request_pending = false;
    },

    updateDevice(data) {
      this.device = data;
      this.device_supported = this.device.last_connection_kind !== 'ethernet';
      this.can_test_now = this.device.active && this.device_supported && this.device.signal_strength > 0;

      if (this.device.recent_ping_flood) {
        this.ping_flood_running = this.device.recent_ping_flood.is_running;
      } else {
        this.ping_flood_running = false;
      }

      this.request_pending = false;
    },

    updateLan(data) {
      this.lan = data;
    },
  },
};
</script>
