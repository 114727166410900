<template>
  <div class="tooltip-container">
  <div  class="dbm-tooltip">
    <div v-if="connection_kind" class="tooltip-text">
      {{ dbm_string }}
      <div :class="signal_in_words.toLowerCase()">
        {{ signal_in_words }}
      </div>
    </div>
    <div v-else class="tooltip-text">
      {{ $I18n.t('devices.show.ethernet') }}
      <div :class="ethernet_class" >
        {{ ethernet_signal_in_words }}
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import SignalStrengthHelper from 'shared/lib/signal_strength_helper.js';

export default {
  props: ['device'],

  computed: {
    signal_in_words() {
      return SignalStrengthHelper.signalRankInWords(this.$I18n, this.device.signal_strength);
    },
    ethernet_signal_in_words() {
      return this.device.active ? this.$I18n.t('online') : this.$I18n.t('offline');
    },
    ethernet_class() {
      return this.device.last_seen == 'Now' ? 'great' : 'offline';
    },
    dbm_string() {
      if (this.device.signal_strength_decibels) {
        return this.device.signal_strength_decibels + ' ' + this.$I18n.t('units.decibel_milliwatts_abbrev');
      }
      else {
        return this.$I18n.t('no_signal');
      }
    },
    connection_kind() {
      switch (this.device.last_connection_kind) {
      case 'wifi_2_4':
        return true;
      case 'wifi_5':
        return true;
      case 'wifi_6':
        return true;
      default:
        return false;
      }
    }
  },

};
</script>
