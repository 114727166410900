import Vue from 'vue';
import minim_api from 'mobile/shared/utils/minim_api';

export default {
  namespaced: true,

  state: {
    sambaDevices: {}
  },

  mutations: {
    set(state, data) {
      let i = state.sambaDevices.findIndex((d) => (d.id === data.id));

      // If the index is not found, push it in at the end
      i = (i !== -1) ? i : state.sambaDevices.length;

      Vue.set(state.sambaDevices, i, data);
    },

    many(state, data) {
      state.sambaDevices = data;
      return state;
    }
  },

  actions: {
    async index({ commit, state }, { unumId }) {
      if(!unumId) return;
      const data = await minim_api.multiget(`api/v1/lans/{lan_id}/unums/${unumId}/samba_devices`);

      const sambaDevices = {
        ...state.sambaDevices,
        [unumId]: data
      };

      commit('many', sambaDevices);
      return sambaDevices;
    }
  },

  getters: {
    sambaDevices(state) {
      return state.sambaDevices;
    },
    isCompatibleDeviceConnected: (state) => (device) => {
      if (!device) return false;
      return (state.sambaDevices[device.id] || []).length > 0;
    }
  }
};
