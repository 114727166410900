import Turbolinks from 'turbolinks';

export default {
  initialize() {},

  received() {
    Turbolinks.visit(window.location);
  },

  unload() {}
};
