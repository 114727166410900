<template>
  <div class="care-portal-traffic-legend-container" v-if="chart && chart.series">
    <div v-for="(point, index) in chart.series[0].data" :key="`${point.name}-${index}`">
      <traffic-graph-legend-item :point="point" :total="chart.series[1].total" />
    </div>
  </div>
</template>

<script>
import TrafficGraphLegendItem from './traffic_graph_legend_item.vue';

export default {
  components: {
    'traffic-graph-legend-item': TrafficGraphLegendItem
  },

  props: {
    chart: {
      type: Object
    }
  }
};
</script>
