<template>
  <minim-modal
    containerStyle="width: 500px; height: 200px;"
    :open="open"
    :showCloseButton="false"
    @close="$emit('close')"
  >
    <div class="p-32">
      <h2 class="text-center">{{ $I18n.t('components.delete_ssid', { 'ssid': currentSsid }) }}</h2>
      <div class="flex-container justify-center m-12">
        <button @click="$emit('deleteNetwork')" class="modal-button secondary-color outlined m-16">{{ $I18n.t('yes') }}</button>
        <button @click="$emit('close')" class="modal-button secondary-color m-16">{{ $I18n.t('no') }}</button>
      </div>
    </div>
  </minim-modal>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true
    },

    currentSsid: {
      type: String,
      required: true
    }
  }
};
</script>
