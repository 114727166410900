import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';
Vue.use(Vuex);

import Bugsnag from '@bugsnag/js';
import minim_api from 'mobile/shared/utils/minim_api';

import AccessPointStore from './stores/access_point_store';
import AccessRequestStore from './stores/access_request_store';
import BrandingStore from './stores/branding_store';
import CdvPurchaseStore from './stores/cdv_purchase_store';
import ConfigurationsStore from './stores/configurations_store';
import CpfProductStore from './stores/cpf_product_store';
import CpfProductOptionStore from './stores/cpf_product_option_store';
import CustomerSupportInfoStore from './stores/customer_support_info_store';
import DeviceStore from './stores/device_store';
import DeviceConnectionReportStore from './stores/device_connection_report_store';
import ForwardedPortStore from './stores/forwarded_port_store';
import HardwareKindStore from './stores/hardware_kind_store';
import HighlightsStore from './stores/highlights_store';
import OnboardingImageStore from './stores/onboarding_image_store';
import HistoryStore from './stores/history_store';
import LanEventStore from './stores/lan_event_store';
import LanStore from './stores/lan_store';
import LocalStorageStore from './stores/local_storage_store';
import NativeInfoStore from './stores/native_info_store';
import NetworkIncidentStore from './stores/network_incident_store';
import OnboardingStore from './stores/onboarding_store';
import PauseStatusStore from './stores/pause_status_store';
import PersonStore from './stores/person_store';
import PingFloodStore from './stores/ping_flood_store';
import RadioStore from './stores/radio_store';
import RestrictionStore from './stores/restriction_store';
import SambaDeviceStore from './stores/samba_device_store';
import SecurityAuditStore from './stores/security_audit_store';
import SetupWizardStore from './stores/setup_wizard_store';
import SpeedTestStore from './stores/speed_test_store';
import ServiceTermsStore from './stores/service_terms_store';
import TimeZoneStore from './stores/time_zone_store';
import ToastStore from './stores/toast_store';
import UnumStore from './stores/unum_store';
import UserProductSubscriptionsStore from './stores/user_product_subscriptions_store';
import UserServiceTermsStore from './stores/user_service_terms_store';
import UserStore from './stores/user_store';
import AppFeedbackStore from './stores/app_feedback_store';

let initialStateCopy;

export const storeConfig = {
  state() {
    return {
      applicationKey: 0,
    };
  },

  mutations: {
    reset(state, extraIgnoredStores = []) {
      let ignored_stores = ['UserStore', 'LanStore', 'NativeInfoStore', 'BrandingStore', 'HardwareKindStore'];
      ignored_stores = [...ignored_stores, ...extraIgnoredStores];
      const recovery_state = JSON.parse(JSON.stringify(initialStateCopy));

      for (const key in recovery_state) {
        if(ignored_stores.includes(key)) continue;
        state[key] = recovery_state[key];
      }
    },

    // The following mutation is used to invalidate the key placed on the base element for the app (see application.vue)
    // Invalidating the key will cause all lifecycle methods for all components on the current page to re-fire, causing a
    // "refresh" of the app without forcing a hard application refresh
    invalidateApplicationKey(state) {
      state.applicationKey += 1;
    },
  },

  actions: {
    async switchLan(context, { lanId, extraIgnoredStores = []}) {
      // wipe the store since everything is based on the LAN
      context.commit('reset', extraIgnoredStores);
      // clear the UnumStore too, mostly to ensure we clear any dangling intervals set by polling
      context.commit('UnumStore/clear');

      if (!lanId) return;
      const lan = await context.dispatch('LanStore/show', { isCurrentLan: true, lanId }, { root: true });

      try {
        // Save that this user accessed this LAN through the mobile app
        await minim_api.post('api/v1/lans/{lan_id}/mobile_app_access');
      } catch (error) {
        // If an error occurs just bugsnag it and move on.
        // This isn't worth disrupting the UX over.
        console.error(error);
        Bugsnag.notify(error);
      }

      return lan;
    }
  },

  modules: {
    AccessPointStore,
    AccessRequestStore,
    BrandingStore,
    CdvPurchaseStore,
    ConfigurationsStore,
    CpfProductStore,
    CpfProductOptionStore,
    CustomerSupportInfoStore,
    DeviceStore,
    DeviceConnectionReportStore,
    ForwardedPortStore,
    HardwareKindStore,
    HighlightsStore,
    OnboardingImageStore,
    HistoryStore,
    LanEventStore,
    LanStore,
    LocalStorageStore,
    NativeInfoStore,
    NetworkIncidentStore,
    OnboardingStore,
    PauseStatusStore,
    PersonStore,
    PingFloodStore,
    RadioStore,
    RestrictionStore,
    SecurityAuditStore,
    SpeedTestStore,
    SetupWizardStore,
    ServiceTermsStore,
    TimeZoneStore,
    ToastStore,
    UnumStore,
    UserProductSubscriptionsStore,
    UserServiceTermsStore,
    UserStore,
    SambaDeviceStore,
    AppFeedbackStore
  }
};

const store = new Vuex.Store(storeConfig);

// Store an empty copy of the store's state
initialStateCopy = JSON.parse(JSON.stringify(store.state));

window.store = store;

export default store;
