import $ from 'jquery';

export default {
  selector: '.page.inventory',
  initial_data: null,

  init() {
    this.setup_flashing_download_links();
  },

  setup_flashing_download_links() {
    let base = 'https://s3.amazonaws.com/provisioner-tool/';
    $('.downloads .download').addClass('disabled');

    $.get(`${base}latest-mac.yml`, (contents) => {
      let file_name = contents.match(/url: (.*\.dmg)/)[1];
      let version = contents.match(/version: (.*)/)[1];

      $('.downloads .download.macos').removeClass('disabled');
      $('.downloads .download.macos').attr('href', `${base}${file_name}`);
      $('.downloads .download.macos .version').html(`v${version}`);
    }).fail(() => {
      $('.downloads .download.macos').addClass('disabled');
      $('.downloads .download.macos .version').html('Error..');
    });

    $.get(`${base}latest.yml`, (contents) => {
      let file_name = contents.match(/url: (.*\.exe)/)[1];
      let version = contents.match(/version: (.*)/)[1];

      $('.downloads .download.windows').removeClass('disabled');
      $('.downloads .download.windows').attr('href', `${base}${file_name}`);
      $('.downloads .download.windows .version').html(`v${version}`);
    }).fail(() => {
      $('.downloads .download.windows').addClass('disabled');
      $('.downloads .download.windows .version').html('Error..');
    });
  }
};
