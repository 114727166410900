import minim_api from 'mobile/shared/utils/minim_api';

export default {
  namespaced: true,

  state: {
    pingFloods: [],
    mostRecentPingFlood: {}
  },

  mutations: {
    many(state, data) {
      state.pingFloods = data;
    },

    prepend(state, data) {
      state.pingFloods = [data].concat(state.pingFloods);
    },

    mostRecent(state, data) {
      state.mostRecentPingFlood = data || {};
    },
  },

  actions: {
    // fetches the ping floods for a given device
    async index({ commit }, deviceId) {
      const pingFloodEndpoint = `api/v1/lans/{lan_id}/devices/${deviceId}/ping_floods`;

      const pingFloodIds = (await minim_api.get(pingFloodEndpoint)).data;

      const pingFloods = await Promise.all(pingFloodIds.map(async ({ id }) => {
        return (await minim_api.get(`${pingFloodEndpoint}/${id}`)).data;
      }));

      commit('many', pingFloods);
      return pingFloods;
    },

    async fetchMostRecent({ commit }, deviceId) {
      const pingFloodEndpoint = `api/v1/lans/{lan_id}/devices/${deviceId}/ping_floods`;

      // fetch the ping floods index route with a limit of 1
      // this will get us the most recent ping flood, since they're returned in the order they were added
      let mostRecentPingFloodIds = (await minim_api.get(`${pingFloodEndpoint}?limit=1`)).data;

      // if there isn't a ping flood, call it a day man
      if (!mostRecentPingFloodIds.length) {
        const mostRecentPingFlood = {};
        commit('mostRecent', mostRecentPingFlood);
        return mostRecentPingFlood;
      }

      const mostRecentPingFlood = (await minim_api.get(`${pingFloodEndpoint}/${mostRecentPingFloodIds[0].id}`)).data;

      commit('mostRecent', mostRecentPingFlood);
      return mostRecentPingFlood;
    },

    async runPingFlood(_context, deviceId) {
      const res = await minim_api.post(`api/v1/lans/{lan_id}/devices/${deviceId}/ping_floods`);
      return res;
    }
  }
};
