import $ from 'jquery';

export default {
  selector: '.alarm-rule .toggler',

  init() {
    $('.alarm-rule .toggler, .alarm-rule .gripper').click((evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      let alarm_rule_id = $(evt.target.closest('.alarm-rule')).data('alarm-rule-id');
      let enabled = !$(evt.target.closest('.toggle-switch')).hasClass('on');
      let data = {'_method': 'put', 'alarm_rule': { enabled: enabled }};

      $.post('alarms/' + alarm_rule_id, data).then(() => {
        $(evt.target.closest('.toggle-switch')).toggleClass('on');
      });
    });
  }
};
