<template>
  <minim-modal
    :open="open"
    :showCloseButton="false"
    containerStyle="padding: 0px !important; width: 480px; height: 485px; border-radius: 5px;"
  >
    <div class="wifi-optimization-modal-container">
      <h2 class="p-24">
        {{$I18n.t('lans.edit.wifi_optimization.choose_profile')}}
      </h2>
      <hr>
      <ul v-for="option in optimizationOptions" :key="option.value" class="p-24">
        <li
          class="flex-container"
          @click="selectOptimization(option.value)"
        >
          <minim-mobile-radio-button :isInCarePortal="true" :selected="isSelected(option.value)"/>
          <div class="flex-container column m-l-16">
            <p class="minim-mobile-text secondary-font large less-bold"> {{option.header}} </p>
            <p class="minim-mobile-text secondary-font medium less-bold text-neutral-darker-color"> {{ option.environment}} </p>
            <p class="minim-mobile-text secondary-font medium light text-neutral-darker-color"> {{option.description}} </p>
          </div>
        </li>
      </ul>
      <div class="p-b-24 p-r-24 p-l-24 flex-container justify-end">
        <button @click="cancel" class="m-r-6 cancel-button"> {{$I18n.t('cancel') }} </button>
        <button @click="updateOptimization" class="m-l-4 set-profile-button"> {{ $I18n.t('lans.edit.wifi_optimization.set_profile') }} </button>
      </div>
    </div>
  </minim-modal>
</template>

<script>
import MinimMobileRadioButton from 'mobile/shared/components/global/minim_mobile_radio_button';

export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    lanId: {
      type: String,
      required: true
    },
    currentWifiSetting: {
      type: String,
      required: true
    }
  },

  components: {
    'minim-mobile-radio-button': MinimMobileRadioButton
  },

  data(){
    return{
      optimizationOptions: [
        {
          header: this.$I18n.t('lans.edit.wifi_optimization.balanced.header'),
          environment: this.$I18n.t('lans.edit.wifi_optimization.balanced.environment'),
          description: this.$I18n.t('lans.edit.wifi_optimization.balanced.description'),
          value: 'noisy'
        },
        {
          header: this.$I18n.t('lans.edit.wifi_optimization.prioritize_speed.header'),
          environment: this.$I18n.t('lans.edit.wifi_optimization.prioritize_speed.environment'),
          description: this.$I18n.t('lans.edit.wifi_optimization.prioritize_speed.description'),
          value: 'non_noisy'
        },
        {
          header: this.$I18n.t('lans.edit.wifi_optimization.prioritize_stability.header'),
          environment: this.$I18n.t('lans.edit.wifi_optimization.prioritize_stability.environment'),
          description: this.$I18n.t('lans.edit.wifi_optimization.prioritize_stability.description'),
          value: 'very_noisy'
        },
        {
          header: this.$I18n.t('lans.edit.wifi_optimization.custom_settings.header'),
          value: 'custom_settings'
        }
      ],
      wifiSetting: ''
    };
  },

  mounted() {
    this.wifiSetting = this.currentWifiSetting;
  },

  methods: {
    cancel(){
      this.$emit('close');
    },

    isSelected(value){
      return this.wifiSetting === value;
    },

    selectOptimization(value){
      this.wifiSetting = value;
    },

    updateOptimization(){
      this.$emit('update', this.wifiSetting);
      this.$emit('close');
    }
  }
};
</script>
